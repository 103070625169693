import { call, takeEvery, put, select } from 'redux-saga/effects';
import { exhibitorAPI } from '@/api/exhibitorAPI';
import { exhibitorPageActions } from '@/constants/actions/exhibitorPageActions';
import {
  setExhibitor,
  setIsFetchingExhibitor,
  setIsInactiveStand,
  setDescriptionVideo,
  setIsActiveEditHeader,
  setIsActiveEditDescription,
  setStandImage,
  setStandVideo,
  setIsBookmarkExhibitorSession,
  setIsBookmarkExhibitor,
  setErrorDescriptionMsg,
  setIsLoading,
} from './actions';
import { setIsBookmarkExhibitors } from '@/store/expoPage/actions';
import { expoAPI } from '@/api/expoAPI';
import { awsAPI } from '@/api/awsAPI';
import { getDelegateInfo } from '../platformInfo';
import {setIsBookmarkProfileExhibitor} from "../profilePage";

function* getExhibitor({ payload: { eventId, sessionId, locationPathname, exhibitorId } }) {
  try {
    yield put(setIsFetchingExhibitor(true));
    const delegateInfo = yield select(getDelegateInfo);
    yield put(setIsInactiveStand(false));
    yield put(setIsActiveEditHeader(false));
    yield put(setIsActiveEditDescription(false));
    yield locationPathname &&
      call(awsAPI.pingIsUserOnline, eventId, delegateInfo.id, locationPathname, sessionId);
    const response = yield call(exhibitorAPI.getExhibitor, exhibitorId);
    yield put(setExhibitor(response.data));
    yield put(setIsFetchingExhibitor(false));
  } catch (error) {
    console.log(error);
    if (error.response.status === 404) {
      yield put(setIsInactiveStand(true));
    }
    yield put(setIsFetchingExhibitor(false));
  }
}

function* updateVideoDescription({ payload: { exhibitorId, textareaValue } }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(exhibitorAPI.editVideoDescription, exhibitorId, textareaValue);
    yield put(setDescriptionVideo(response.data.description));
    yield put(setIsLoading(false));
    yield put(setIsActiveEditDescription(false));
    yield put(setErrorDescriptionMsg(''));
  } catch (error) {
    yield put(setIsLoading(false));
    yield put(setErrorDescriptionMsg(error.response.data.description[0]));
  }
}

function* updateVideo({ payload: { exhibitorId, standVideo } }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(exhibitorAPI.editVideo, exhibitorId, standVideo);
    yield put(setStandVideo(response.data.stand_video));
    yield put(setIsLoading(false));
    yield put(setIsActiveEditHeader(false));
  } catch (error) {
    yield put(setIsLoading(false));
  }
}

function* updateImage({ payload: { exhibitorId, standImage } }) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(exhibitorAPI.editImage, exhibitorId, standImage);
    yield put(setStandVideo(response.data.stand_video));
    yield put(setStandImage(response.data.stand_image));
    yield put(setIsLoading(false));
    yield put(setIsActiveEditHeader(false));
  } catch (error) {
    yield put(setIsLoading(false));
  }
}

export function* bookmarkExhibitorSession({ id, sessionId, isBookmark }) {
  try {
    yield put(setIsBookmarkExhibitorSession(sessionId, isBookmark));
  } catch (error) {
    yield put(setIsBookmarkExhibitorSession(sessionId, false));
  }
}

export function* unBookmarkExhibitorSession({ id, sessionId, isBookmark }) {
  try {
    yield put(setIsBookmarkExhibitorSession(sessionId, isBookmark));
  } catch (error) {
    yield put(setIsBookmarkExhibitorSession(sessionId, true));
  }
}

function* bookmarkExhibitor({ payload: { exhibitorId, isBookmark } }) {
  try {
    yield put(setIsBookmarkExhibitor(isBookmark));
    yield put(setIsBookmarkExhibitors(exhibitorId, isBookmark));
    yield call(expoAPI.bookmarkExhibitor, exhibitorId);
  } catch (error) {
    yield put(setIsBookmarkExhibitor(false));
    console.log(error);
  }
}

function* unBookmarkExhibitor({ payload: { exhibitorId, isBookmark } }) {
  try {
    yield put(setIsBookmarkExhibitor(isBookmark));
    yield put(setIsBookmarkProfileExhibitor(isBookmark));
    yield put(setIsBookmarkExhibitors(exhibitorId, isBookmark));
    yield call(expoAPI.unBookmarkExhibitor, exhibitorId);
  } catch (error) {
    yield put(setIsBookmarkExhibitor(true));
    console.log(error);
  }
}

function* addResourcesSaga(action) {
  try {
    yield call(
      exhibitorAPI.addResources,
      action.payload.event_pk,
      action.payload.exhibitorId,
      action.payload.data,
    );
    yield put(setErrorDescriptionMsg(''));
    yield put(setIsFetchingExhibitor(true));
    const response = yield call(exhibitorAPI.getExhibitor, action.payload.exhibitorId);
    yield put(setExhibitor(response.data));
    yield put(setIsFetchingExhibitor(false));
  } catch (error) {
    yield put(
      setErrorDescriptionMsg(Object.values(error.response.data).join(', ')) ||
        'failed to load file',
    );
  }
}

function* removeResourcesSaga(action) {
  try {
    yield call(
      exhibitorAPI.removeResources,
      action.payload.event_pk,
      action.payload.exhib_pk,
      action.payload.id,
    );
    yield put(setIsFetchingExhibitor(true));
    const response = yield call(exhibitorAPI.getExhibitor, action.payload.exhib_pk);
    yield put(setExhibitor(response.data));
    yield put(setIsFetchingExhibitor(false));
  } catch (error) {
    console.log(error);
  }
}

function* downloadExhibitorDocSaga(action) {
  try {
    const response = yield call(
      exhibitorAPI.downloadExhibitorDoc,
      action.payload.event_pk,
      action.payload.exhib_pk,
      action.payload.id,
    );
    yield window.open(response.data.path);
  } catch (error) {
    console.log(error);
  }
}

export function* watchExhibitor() {
  yield takeEvery(exhibitorPageActions.GetExhibitor, getExhibitor);
  yield takeEvery(exhibitorPageActions.updateDescriptionVideo, updateVideoDescription);
  yield takeEvery(exhibitorPageActions.UpdateVideo, updateVideo);
  yield takeEvery(exhibitorPageActions.UpdateImage, updateImage);
  yield takeEvery(exhibitorPageActions.BookmarkSession, bookmarkExhibitorSession);
  yield takeEvery(exhibitorPageActions.UnBookmarkSession, unBookmarkExhibitorSession);
  yield takeEvery(exhibitorPageActions.BookmarkExhibitor, bookmarkExhibitor);
  yield takeEvery(exhibitorPageActions.UnBookmarkExhibitor, unBookmarkExhibitor);
  yield takeEvery(exhibitorPageActions.AddResources, addResourcesSaga);
  yield takeEvery(exhibitorPageActions.RemoveResources, removeResourcesSaga);
  yield takeEvery(exhibitorPageActions.DownloadExhibitorDoc, downloadExhibitorDocSaga);
}
