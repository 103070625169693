import styled from 'styled-components';

export const EditBiographyContainer = styled.div`
  width: 100%;
`;

export const EditBiographyTextarea = styled.textarea`
  padding: 15px;
  border: ${({ theme }) => `1px solid ${theme.colors.logoBorder}`};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px #f2f4fa;
  border-radius: 4px;
  width: 100%;
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.textDescriptionDarkGrey};
  margin-bottom: 20px;
  resize: none;
`

export const EditBiographyButtons = styled.div`
  display: flex;
  margin-bottom: 30px;
  
  > button:nth-child(1) {
    width: 100px;
    margin-right: 15px;
  }

  > button:nth-child(2) {
    width: 115px;
  }
`;
