import {pollsActions} from "@/constants/actions/pollsActions";

const initialState = {
  polls: [],
};

export const polls= (state = initialState, action) => {
  switch (action.type) {
    case pollsActions.SetPolls:
      return {
        ...state,
        polls: action.payload,
      };
    default:
      return state;
  }
};
