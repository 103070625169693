import React from 'react';
import { IconWrapper, Icon } from './styles';

export const DeleteIcon = () => {
  return (
    <IconWrapper style={{ paddingBottom: '87.5%' }}>
      {/* 
        paddingBottom is calculated from the view port aspect ratio  
        to maintain responsiveness of the svg
      */}
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 28">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 32h20l2-22h-24zM20 4v-4h-8v4h-10v6l2-2h24l2 2v-6h-10zM18 4h-4v-2h4v2z"
        />
      </Icon>
    </IconWrapper>
  );
};
