import React, { useMemo } from 'react';
import styles from './styles.module.css';

export const Divider = ({ isSignUp, placeholder, base: isBase }) => {
  const style = useMemo(() => (isBase ? styles['container-base'] : styles['container']), [isBase]);
  return (
    <div className={style}>
      <div className={styles['border']} />
      {placeholder && (
        <span className={`${isSignUp ? styles['content-sign-up'] : styles['content']}`}>
          {placeholder}
        </span>
      )}
      <div className={styles['border']} />
    </div>
  );
};
