import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  padding: 20px 30px 0px 30px;
  position: relative;
  height: 100vh;
  overflow-y: scroll;
`;

export const Title = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

export const Text = styled.div`
  font-weight: bold;
  font-size: 45px;
  line-height: 54px;
  margin-right: 25px;
  color: ${({ theme }) => theme.colors.originBlack};
`;

export const StatisticSection = styled.div`
  //this styles for test
  border: 1px solid #dfe0eb;
  border-radius: 4px;
  height: 450px;
  width: 870px;
  margin-bottom: 50px;
`;
export const NetworkingSection = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  flex-basis: 100%;
`;
