import React from 'react';

export const closeIcon = (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3785 1.41L13.8297 0L7.68927 5.59L1.54884 0L0 1.41L6.14043 7L0 12.59L1.54884 14L7.68927 8.41L13.8297 14L15.3785 12.59L9.23811 7L15.3785 1.41Z"
      fill="#949DA6"
    />
  </svg>
);
