import axios from 'axios';
import { baseURL } from '@/constants/baseURL';
import { objectParserToQueryString } from '../helpers';
import { responseHandlerInterceptor, sendTokenInterceptor } from '../helpers/interceptors';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

instance.interceptors.request.use(request => {
  return sendTokenInterceptor(request);
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return responseHandlerInterceptor(error, instance);
  },
);

export const networkingPageAPI = {
  getNetworkingFilters(event_pk) {
    return instance.get(`/events/event/${event_pk}/filters/`);
  },
  getDelegates(event_pk, search, sort, filters) {
    return instance.get(
      `/events/event/${event_pk}/networking/?limit=200${search ? `&search=${search}` : ''}${
        sort ? `&sort=${sort}` : ''
      }${
        filters
          ? Object.keys(filters).some(key => filters[key].length > 0)
            ? `&${objectParserToQueryString(filters)}`
            : ''
          : ''
      }`,
    );
  },
  getNextDelegatesList(nextDelegateUrl) {
    return instance.get(nextDelegateUrl);
  },
  bookmarkDelegate(event_pk, delegateId) {
    return instance.post(`/events/event/${event_pk}/favorite-delegate/`, {
      added: delegateId,
    });
  },
  unBookmarkDelegate(event_pk, delegateId) {
    return instance.delete(`/events/event/${event_pk}/favorite-delegate/${delegateId}/`);
  },
};
