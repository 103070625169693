import axios from 'axios';
import { baseURL } from '@/constants/baseURL';
import { responseHandlerInterceptor, sendTokenInterceptor } from '../helpers/interceptors';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

instance.interceptors.request.use(request => {
  return sendTokenInterceptor(request);
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return responseHandlerInterceptor(error, instance);
  },
);

export const exhibitorAPI = {
  getExhibitor(exhibitorTag) {
    return instance.get(`/events/exhibitor/${exhibitorTag}/`);
  },
  editVideoDescription(exhibitorTag, description) {
    return instance.patch(`/events/exhibitor/${exhibitorTag}/`, { description });
  },
  editVideo(exhibitorTag, standVideo) {
    return instance.patch(`/events/exhibitor/${exhibitorTag}/`, {
      stand_video: standVideo,
    });
  },
  editImage(exhibitorTag, standImage) {
    const formData = new FormData();
    formData.append('stand_image', standImage);
    return instance.patch(`/events/exhibitor/${exhibitorTag}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  addResources(event_pk, exhibitorId, data) {
    const formData = new FormData();
    formData.append('document_name', data.document_name);
    formData.append('path', data.path);
    return instance.post(`/events/exhibitor/${event_pk}/add-document/${exhibitorId}/`, formData);
  },
  removeResources(event_pk, exhib_pk, id) {
    return instance.delete(`/events/exhibitor/${event_pk}/delete-document/${exhib_pk}/${id}/`);
  },
  downloadExhibitorDoc(event_pk, exhib_pk, id) {
    return instance.get(`events/exhibitor/${event_pk}/upload_document/${exhib_pk}/${id}`);
  },
};
