import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'deep-equal';
import {
  StyledLabel,
  StyledOption,
  StyledSelect,
  StyledSmall,
} from '../../../controls/CustomSelect/styles';

const SelectCountries = ({ name, options, form, label, onBlur }) => {
  const { errors, touched, setFieldValue, values, setFieldError } = form;
  const [value, setValue] = useState('');

  const handleSelect = useCallback(
    id => {
      setFieldValue(name, id);
    },
    [name, setFieldValue],
  );

  const showInvalid = useMemo(() => {
    return { display: errors[name] && touched[name] ? 'block' : 'none' };
  }, [errors, name, touched]);

  const onFocusSelect = useCallback(() => {
    setFieldError(name, '');
  }, [name]);

  useEffect(() => {
    values[name] !== undefined && setValue(values[name]);
  }, [values, name]);

  return (
    <div>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelect
        showSearch
        id={name}
        onBlur={onBlur}
        onFocus={onFocusSelect}
        value={value ? value : undefined}
        onChange={handleSelect}
        isError={!!(errors[name] && touched[name])}
        placeholder="-- Select option --"
        filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
        }
      >
        {options.map(item => (
          <StyledOption key={item.id} value={item.id}>{item.label}</StyledOption>
        ))}
      </StyledSelect>
      <StyledSmall style={showInvalid}>{errors[name]}</StyledSmall>
    </div>
  );
};

export default React.memo(SelectCountries, isEqual);
