import { call, put, takeEvery } from 'redux-saga/effects';
import { addDetailsInfo, setRegistrationStep, setErrorMsg, setIsLoading } from './actions';
import { signUpAPI } from '@/api/signUpAPI';
import { setSpinnerValue } from '@/store/spinner/actions';
import { RegistrationActions } from '@/constants/actions/registrationActions';
import { localStorageServices } from '@/helpers';

function* signUpUser(action) {
  try {
    yield put(setIsLoading(true));
    const response = yield call(signUpAPI.signUp, action.payload);
    yield localStorageServices.setTokens(response.data);
    yield put(setRegistrationStep('profile'));
    yield put(setErrorMsg(''));
    yield put(addDetailsInfo(action.payload));
    yield put(setSpinnerValue(false));
  } catch (error) {
    yield put(setSpinnerValue(false));
    yield put(setErrorMsg(Object.values(error.response.data).join(', ')));
  } finally {
    yield put(setIsLoading(false));
  }
}

export function* watchRegistration() {
  yield takeEvery(RegistrationActions.OnUserRegistration, signUpUser);
}
