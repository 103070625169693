import styled from 'styled-components';

export const MessageConainer = styled.div`
  margin-bottom: 30px;
  max-width: 330px;
  display: flex;
  flex-direction: ${({ isCurrentUserMessage }) => isCurrentUserMessage && 'row-reverse'};
  align-self: ${({ isCurrentUserMessage }) => isCurrentUserMessage && 'flex-end'};

  &:first-child {
    margin-top: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Author = styled.div`
  padding-bottom: 5px;
  align-self: ${({ isCurrentUserMessage }) => isCurrentUserMessage && 'flex-end'};
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  font-size: 13px;
  line-height: 16px;
`;

export const ContentBuble = styled.div`
  position: ${({ isCurrentUserMessage }) => isCurrentUserMessage && 'relative'};
  padding: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  background: ${({ theme, isCurrentUserMessage }) =>
    isCurrentUserMessage
      ? theme.colors.currentUserMessageBackground
      : theme.colors.backgroundDownloadCard};
`;

export const Text = styled.span`
  font-size: 15px;
  color: ${({ isCurrentUserMessage, theme }) =>
    isCurrentUserMessage ? theme.colors.originWhite : theme.colors.originBlack};
  line-height: 20px;
`;
