import React from 'react';
import { isEqual } from 'deep-equal';
import { EditInterestsButtons, EditInterestsContainer } from './styles';
import Button from '@/components/controls/buttons/GenericButton/component';
import { useDispatch, useSelector } from 'react-redux';
import { setIsActiveEditInterests } from '@/store/profilePage';
import { useParams } from 'react-router-dom';
import styles from '@/components/forms/MultiSelectForm/styles.module.css';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { CustomCheckbox } from '@/components/controls/Checkbox';
import { multiSelectSchema } from '@/components/controls/validationSchemas/multiSelectSchema';
import HelpText from '@/components/controls/HelpText';
import { getEventInterestsInfo, updateInterests, getProfileInfo } from '@/store/profilePage';

const EditInterests = ({ delegateId }) => {
  const dispatch = useDispatch();
  const eventInterests = useSelector(getEventInterestsInfo);
  const {interests_set} = useSelector(getProfileInfo);
  const { id } = useParams();

  const onSubmit = ({ selectInterests }) => {
    dispatch(
      updateInterests({
        id,
        delegateId,
        interests: selectInterests.map(i => {
          return { interest: i.id };
        }),
      }),
    );
  };

  return (
    <EditInterestsContainer>
      <div>
        <Formik
          initialValues={{ selectInterests: interests_set }}
          onSubmit={onSubmit}
          validationSchema={multiSelectSchema}
          validateOnChange={false}
        >
          {({ values, resetForm }) => (
            <Form className={styles['form-container']}>
              <FieldArray
                name="selectInterests"
                render={arrayHelpers => (
                  <div>
                    <div className={styles.form}>
                      {eventInterests.map((interest, index) => (
                        <div key={interest.id} className={styles['form__field']}>
                          <Field name={'selectInterests'}>
                            {({ field, form }) => (
                              <CustomCheckbox
                                {...field}
                                form={form}
                                value={interest.id}
                                checked={values.selectInterests.some(
                                  selectInt => selectInt.id === interest.id,
                                )}
                                item
                                label={interest.name}
                                onChange={e => {
                                  if (e.target.checked)
                                    arrayHelpers.push({ id: interest.id, name: interest.name });
                                  else {
                                    const idx = values.selectInterests.findIndex(
                                      selectInt => selectInt.id === interest.id,
                                    );
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      ))}
                    </div>
                    <ErrorMessage name="selectInterests">
                      {msg => <div className={styles['error-text']}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                )}
              />
              <EditInterestsButtons>
                <Button label="Save" type="submit" />
                <Button
                  label="Cancel"
                  styleType="outline"
                  onClick={() => dispatch(setIsActiveEditInterests(false))}
                />
                <div className={styles['form__help-text']}>
                  <HelpText>Please select at least 2 interests</HelpText>
                </div>
              </EditInterestsButtons>
            </Form>
          )}
        </Formik>
      </div>
    </EditInterestsContainer>
  );
};
export default React.memo(EditInterests, isEqual);
