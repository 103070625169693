export const networkingActions = {
  SetSearch: 'networking/SET_SEARCH',
  SetCheckedFilters: 'networking/SET_CHECKED_FILTERS',
  SetSort: 'networking/SET_SORT',
  RemoveCheckedFilters: 'networking/REMOVE_CHECKED_FILTERS',
  ClearAllFilters: 'networking/CLEAR_ALL_FILTERS',
  ClearSomeCheckedFilter: 'networking/CLEAR_SOME_CHECKED_FILTERS',
  AddCheckedFilterProperties: 'networking/ADD_CHECKED_FILTER_PROPERTIES',
  OnFilteringDelegates: 'networking/ON_FILTERING_DELEGATES',
  GetNetworkingFilters: 'networking/GET_NETWORKING_FILTERS',
  SetDelegates: 'networking/SET_DELEGATES',
  GetDelegates: 'networking/GET_DELEGATES',
  SetIsFetchingFilters: 'networking/SET_IS_FETCHING_FILTERS',
  SetIsFetchingDelegates: 'networking/SET_IS_FETCHING_DELEGATES',
  SetFilters: 'networking/SET_FILTERS',
  SetSelectedSort: 'networking/SET_SELECTED_SORT',
  BookmarkDelegate: 'networking/BOOKMARK_DELEGATE',
  UnBookmarkDelegate: 'networking/UN_BOOKMARK_DELEGATE',
  SetIsBookmark: 'networking/SET_IS_BOOKMARK',
  SetNextDelegatesUrl: 'networking/SET_NEXT_DELEGATES_URL',
  SetIsLoadingNextDelegateList: 'networking/SET_IS_LOADING_NEXT_DELEGATE_LIST',
  SetNextDelegatesList: 'networking/SET_NEXT_DELEGATES_LIST',
  GetNextDelegatesList: 'networking/GET_NEXT_DELEGATES_LIST'
};
