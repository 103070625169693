import styled from 'styled-components'

export const SponsorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 165px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const SponsorsTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
`

export const PartnersTitle = styled.div`
  width: 100%;
  margin: 35px 0px;
  display: flex;
  align-items: center;
`

export const PartnersTitleLineText = styled.div`
  margin: 0px 15px;
  display: block;
  word-wrap: normal!important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #52595E;
`

export const PartnersTitleLine = styled.div`
  flex: 1;
  border: 1px solid #DBE2EB;
`
