import React from 'react';
import { isEqual } from 'deep-equal';
import AddToCalendar from 'react-add-to-calendar';

/* I had to use both css and css module in order to style this 3rd party component*/
import styles from './styles.module.css';
import './styles.css';

const event = {
  title: 'Sample Event',
  description: 'This is the sample event provided as an example only',
  location: 'Portland, OR',
  startTime: '2016-09-16T20:15:00-04:00',
  endTime: '2016-09-16T21:45:00-04:00',
};

const AddToCalendarDropdown = () => {
  return (
    <AddToCalendar
      tabIndex="0"
      buttonLabel="Add To Calendar"
      rootClass={styles['button-dropdown-wrapper']}
      buttonWrapperClass={`${styles['button-dropdown__button']} ${styles['button-dropdown__button_outline']}`}
      event={event}
    />
  );
};

export default React.memo(AddToCalendarDropdown, isEqual);
