import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
`;

export const PlayIconContainer = styled.div`
  position: absolute;
  height: 30%;
  width: 17%;
  top: 35%;
  left: 41.5%;
`;
