import React from 'react';
import { useParams } from 'react-router-dom';
import { TwitterShareButton, LinkedinShareButton, FacebookShareButton, EmailShareButton } from 'react-share';
import { Container, MenuTitle, MenuContent } from './styles';
import { icoLinkedin, icoTwitter, icoFacebook, icoEmail } from './icons';
import { generateAgendaSessionURL } from '@/constants/sharingURLs';
import { ShareContent } from './shareContent';

const Menu = ({ dayID, sessionID }) => {
  const { id: event_pk } = useParams();
  const shareUrl = generateAgendaSessionURL(event_pk, dayID, sessionID);

  return (

    <Container>
      <MenuTitle>Share via</MenuTitle>
      <MenuContent>
        <TwitterShareButton
          url={shareUrl}
          title={'#agenda'}
        >
          <ShareContent
            icon={icoTwitter}
            content={'Twitter'}
          />
        </TwitterShareButton>

        <LinkedinShareButton url={shareUrl} >
          <ShareContent
            icon={icoLinkedin}
            content={'LinkedIn'}
          />
        </LinkedinShareButton>

        <FacebookShareButton
          url={shareUrl}
          quote={'agenda'}
        >
          <ShareContent
            icon={icoFacebook}
            content={'Facebook'}
          />
        </FacebookShareButton>

        <EmailShareButton
          url={shareUrl}
          subject={'agenda'}
        >
          <ShareContent
            icon={icoEmail}
            content={'E-mail'}
          />
        </EmailShareButton>
      </MenuContent>
    </Container>
  );
};

export default Menu;
