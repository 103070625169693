import { call, put, takeEvery } from 'redux-saga/effects';
import { eventExistsActions } from '../../constants/actions/eventExists';
import { landingAPI } from '@/api/landingApi';
import { setEventCheckedValue } from './actions';
import { history } from '../rootReducer';
import { generateEventNotFoundUrl } from '../../constants/routes';

function* fetchEventExists(action) {
  try {
    const response = yield call(landingAPI.eventExists, action.payload);
    yield response.data.status === 'draft'
      ? history.push(generateEventNotFoundUrl(action.payload))
      : null;
  } catch (e) {
    history.push(generateEventNotFoundUrl(action.payload));
  } finally {
    yield put(setEventCheckedValue(false));
  }
}

export function* watchEventExists() {
  yield takeEvery(eventExistsActions.CheckEvent, fetchEventExists);
}
