import React, { useEffect, useRef, useState } from 'react';
import { EditDescriptionContainer, EditDescriptionTextarea } from './styles';
import { isEqual } from 'deep-equal';
import { ButtonsSection } from '../EditHeaderSection/styles';
import Button from '../../../controls/buttons/GenericButton/component';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateDescriptionVideo,
  setIsActiveEditDescription,
  getErrDescriptionMsgInfo,
} from '@/store/exhibitorPage';
import { useParams } from 'react-router-dom';
import { ErrorMsg } from '../styles';
import { getIsLoading } from '@/store/exhibitorPage';

const EditDescriptionSection = ({ description }) => {
  const dispatch = useDispatch();
  const descriptionError = useSelector(getErrDescriptionMsgInfo);
  const isLoading = useSelector(getIsLoading);
  const [textareaValue, setTextareaValue] = useState(description);
  const textareaEl = useRef(null);
  const { exhibitorId } = useParams();

  useEffect(() => {
    textareaEl.current.focus();
  }, []);

  const onChangeDescription = e => {
    setTextareaValue(e.target.value);
  };
  const onSaveChanges = () => {
    dispatch(updateDescriptionVideo({ exhibitorId, textareaValue }));
  };
  return (
    <EditDescriptionContainer>
      <EditDescriptionTextarea
        value={textareaValue}
        ref={textareaEl}
        onChange={onChangeDescription}
        onFocus={e => {
          let val = e.target.value;
          e.target.value = '';
          e.target.value = val;
          e.target.scrollTop = e.target.scrollHeight;
        }}
      />
      {descriptionError && <ErrorMsg>{descriptionError}</ErrorMsg>}
      <ButtonsSection>
        <Button label="Save" onClick={onSaveChanges} isLoading={isLoading} />
        <Button
          label="Cancel"
          styleType="outline"
          onClick={() => dispatch(setIsActiveEditDescription(false))}
        />
      </ButtonsSection>
    </EditDescriptionContainer>
  );
};

export default React.memo(EditDescriptionSection, isEqual);
