import * as Yup from 'yup';

const FILE_SIZE = 5242880;

export const upLoadResourcesSchema = Yup.object().shape({
  fileName: Yup.string()
    .required('This is a required field')
    .min(2, 'Must be at least 2 characters, maximum 50 characters long')
    .max(50, 'Must be at least 2 characters, maximum 50 characters long'),
  file: Yup.mixed()
    .required('A file is required')
    .test(
      'fileSize',
      'The file size must be less than 5 MB',
      value => value && value.size <= FILE_SIZE,
    ),
});
