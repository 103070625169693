import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.agenda.border};
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
`;

export const DayName = styled.span`
  display: block;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.agenda.dayDelimiterLanding.name};

  /* break long words */
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
`;

export const DayTopic = styled.span`
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.agenda.dayDelimiterLanding.topic};
`;
