import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const InputDatePicker = styled(DatePicker)`
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  margin-top: 10px;
  background: ${({ theme }) => theme.colors.originWhite};
  border: 1px solid
    ${({ theme, error, touched }) =>
      error && touched ? theme.colors.alert : theme.colors.logoBorder};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px
    ${({ theme, error, touched }) =>
      error && touched
        ? 'rgba(223, 53, 65, 0.2)'
        : theme.colors.backgroundDownloadCard};
  border-radius: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  caret-color: ${({ theme }) => theme.colors.boulder};

  :focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.currentUserMessageBackground};
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 3px rgba(37, 123, 222, 0.2);
  }
`;

export const InputLabel = styled.label`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
`;

export const DataPickerError = styled.div`
  margin-top: 10px;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.alert};
`;
