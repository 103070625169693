import { call, put, takeEvery, select } from 'redux-saga/effects';
import { eventRegistrationAPI } from '@/api/eventRegistrationApi';
import { getInterestsInfo, getInterestsInInfo } from '../registration';
import { setIsLoadingSummaryForm, setIsShowSummaryModal } from './actions';
import { setRegistrationStep } from '@/store/registration/actions';
import { RegistrationActions } from '@/constants/actions/registrationActions';
import { profileAPI } from '@/api/profileAPI';

function* setDelegate(action) {
  try {
    yield put(setIsLoadingSummaryForm(true));
    yield call(
      eventRegistrationAPI.registerDelegate,
      action.payload.delegate,
      action.payload.event_pk,
    );
    const { selectInterests: interests } = yield select(getInterestsInfo);
    yield call(
      profileAPI.editInterests,
      action.payload.event_pk,
      interests.map(i => {
        return { interest: i.id };
      }),
    );
    const { selectInterests: interestsIn } = yield select(getInterestsInInfo);
    yield call(
      profileAPI.editInInterests,
      action.payload.event_pk,
      interestsIn.map(i => {
        return { name: i.value };
      }),
    );
    yield !!(action.payload.isFree || action.payload.promocode) && put(setIsShowSummaryModal(true));
    yield !!(action.payload.isFree || action.payload.promocode) && sessionStorage.removeItem('ticketType')
    yield !!(action.payload.isFree || action.payload.promocode) && sessionStorage.removeItem('promocode')
    yield !!(action.payload.isFree || action.payload.promocode) && sessionStorage.removeItem('promocodeStatus')
    yield !!(!action.payload.isFree && !action.payload.promocode) && put(setRegistrationStep('checkout'));
  } catch (error) {
  } finally {
    yield put(setIsLoadingSummaryForm(false));
  }
}

export function* watchRegistrationDelegate() {
  yield takeEvery(RegistrationActions.SetRegistrationDelegate, setDelegate);
}
