import styled from 'styled-components';

export const InterestsApp = styled.div`
  width: 100%;
`;

export const InterestTitle = styled.span`
  display: inline-block;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  margin-bottom: 15px;
`;

export const InterestsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InterestSection = styled.div`
  border: 1px solid #808b94;
  box-sizing: border-box;
  border-radius: 17px;
  padding: 7px 21px;
  margin: 0 15px 30px 0;
`;
