import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { TicketChoice } from '@/components/blocks/TicketChoice';
import { setRegistrationStep, setTicketType } from '@/store/registration';
import { checkPromocode, setPromocodeError } from '@/store/promocodeValidation/actions';
import { generateRegisterUrl } from '@/constants/routes';
import { history } from '@/store/rootReducer';
import {
  getInterestsInfo,
  getInterestsInInfo,
  getProfileData,
} from '@/store/registration'
import styles from './styles.module.css';

export const TicketChoiceForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams()
  const RegisterRoute = generateRegisterUrl(id)
  const interests = useSelector(getInterestsInfo);
  const interestsIn = useSelector(getInterestsInInfo);
  const profile = useSelector(getProfileData);

  const onSubmit = values => {
    if (values.promotionalCode !== '') {
      sessionStorage.setItem('promocodeStatus', true)
      sessionStorage.setItem('promocode', values.promotionalCode)
      dispatch(checkPromocode({ data: values.promotionalCode, event_pk: id }));
    } else {
      const token = localStorage.getItem('accessToken');
      if (token) {
        if (Object.keys(profile).length > 0
          && Object.keys(interests).length > 0
          && Object.keys(interestsIn).length > 0) {
          dispatch(setRegistrationStep('summary'))
        } else {
          dispatch(setRegistrationStep('profile'));
        }
      }
      dispatch(setPromocodeError(''))
      dispatch(setTicketType(false))
      sessionStorage.setItem('ticketType', false)
      sessionStorage.setItem('promocode', '')
      sessionStorage.setItem('promocodeStatus', false)
      history.push(RegisterRoute);
    }
  };

  const conferenceOptions = [
    { id: 1, label: 'A commercial organisation' },
    { id: 2, label: 'An individual' },
  ];
  const freeOptions = [
    { id: 1, label: 'A student' },
    { id: 2, label: 'An employee of an NGO' },
    { id: 3, label: 'A public sector employee' },
    { id: 4, label: 'An employee of a not-for-profit organisation' },
  ];

  return (
    <div className={styles['container']}>
      <div>
        <Formik onSubmit={onSubmit} initialValues={{ promotionalCode: '' }}>
          {() => (
            <Form>
              <TicketChoice
                label="Conference ticket"
                options={conferenceOptions}
                isPromotionalCode
                isFree={false}
              />
            </Form>
          )}
        </Formik>
      </div>
      <div>
        <TicketChoice label="Free ticket" options={freeOptions} isFree />
      </div>
    </div>
  );
};
