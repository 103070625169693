import styled from 'styled-components';

export const PollCardContainer = styled.div`
  width: auto;
  border: 1px solid ${({ theme }) => theme.colors.cards.border};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const TextContainer = styled.div`
  margin-right: 100px;
`;
export const TextTitle = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;
export const TextResults = styled.div`
  font-family: ' Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.blue};
  margin-top: 35px;
  cursor: pointer;
`;
