import React, { useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isEqual } from 'deep-equal';
import { history } from '@/store/rootReducer';
import { generateChooseTicketUrl } from '@/constants/routes';
import Button from '@/components/controls/buttons/GenericButton';

import { Container, Wrapper, Message, ButtonWrapper, Or, LinkWrapper } from './styles';

const RegisterAsDelegatePromptPage = () => {
  const { id: event_pk } = useParams();
  const ticketsRoute = useMemo(() => generateChooseTicketUrl(event_pk), [event_pk]);
  const handleRegisterClick = useCallback(() => history.replace(ticketsRoute), [ticketsRoute]);

  return (
    <Container>
      <Wrapper>
        <Message>{`Sorry, you haven’t registered for this event`}</Message>
        <ButtonWrapper>
          <Button label="Register" onClick={handleRegisterClick} />
        </ButtonWrapper>
        <Or>or</Or>
        <LinkWrapper>
          <Link to={`/${event_pk}`}>Go back to the event page</Link>
        </LinkWrapper>
      </Wrapper>
    </Container>
  );
};

export default React.memo(RegisterAsDelegatePromptPage, isEqual);
