import { SignInActions } from '@/constants/actions/signInActions';

const initialState = {
  userData: {},
  errorMsg: '',
  isLoading: false
};

export const loginPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SignInActions.AddUserData:
      return {
        ...state,
        userData: action.payload,
      };
    case SignInActions.SetErrorMsg:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case SignInActions.SetIsLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
