import { escalatedQuestionsActions } from '@/constants/actions/escalatedQuestionsActions';

export const setEscalatedQuestions = data => ({
  type: escalatedQuestionsActions.SetEscalatedQuestions,
  payload: data,
});

export const setIsFetchingQuestions = data => ({
  type: escalatedQuestionsActions.SetIsFetchingQuestions,
  payload: data,
});

export const setEscalatedSignInErrMsg = data => ({
  type: escalatedQuestionsActions.SetEscalatedSignInErrMsg,
  payload: data,
});

export const setIsLoadingSignIn = data => ({
  type: escalatedQuestionsActions.SetIsLoadingSignIn,
  payload: data,
});
//saga actions
export const getEscalatedQuestions = data => ({
  type: escalatedQuestionsActions.GetEscalatedQuestions,
  payload: data,
});

export const signInChair = data => ({
  type: escalatedQuestionsActions.SignInChair,
  payload: data,
});

export const startGetEscalatedQuestionsPolling = data => ({
  type: escalatedQuestionsActions.StartGetEscalatedQuestionsPolling,
  payload: data,
});

export const stopGetEscalatedQuestionsPolling = data => ({
  type: escalatedQuestionsActions.StopGetEscalatedQuestionsPolling,
  payload: data,
});

