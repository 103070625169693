import * as Yup from 'yup';

export const uploadProfileSchema = Yup.object().shape({
  biography: Yup.string()
    .min(30, 'Ensure this value has at least 30 characters')
    .max(250, 'Maximum 250 characters'),
  linkedin_url: Yup.string().matches(
    /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/,
    'Not a valid linkedin url!',
  ),
  twitter_url: Yup.string().matches(
    /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/,
    'Not a valid twitter url!',
  ),
});
