import { RegistrationFieldsValues } from '@/constants/actions/registrationFieldsValues'

export const onGetRegistrationValues = (data) => ({
  type: RegistrationFieldsValues.GetRegistrationFieldsValues,
  payload: data,
})

export const setRegistrationFieldsValues = (data) => ({
  type: RegistrationFieldsValues.SetRegistrationFieldsValues,
  payload: data,
})


