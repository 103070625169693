import styled from 'styled-components';
import Avatar from 'react-avatar';
import { NavLink } from 'react-router-dom';

export const CardContainer = styled.div`
  display: flex;
  position: relative;
  min-height: 36px;
  width: 100%;
`;

export const CustomAvatar = styled(Avatar)`
  img {
    max-width: 36px;
  }
`;

export const StyledLink = styled(NavLink)`
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    display: ${({ isonline }) => (isonline ? 'inline-block' : 'none')};
    width: 5px;
    height: 5px;
    border-radius: 50%;
    left: 30px;
    top: 30px;
    background-color: #2AC262;
  }
`;

export const UserInformation = styled.div`
  position: relative;
  display: flex;
  justify-content: ${({ withIcons }) => withIcons && 'space-between'};
  width: 100%;
  align-items: center;
  margin-left: 50px;

  &::after {
    content: ' ';
    position: absolute;
    top: 50px;
    display: ${({ withIcons }) => (withIcons ? 'inline-block' : 'none')};
    width: 100%;
    height: 1px;
    background-color: #dbe2eb;
  }
`;

export const UserInformationTextSection = styled.div`
  align-self: start;
`;

export const UserName = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme, colorSchema }) =>
    colorSchema ? theme.colors.agenda[colorSchema].speakerName : theme.colors.regularText};
`;
export const Corporation = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme, colorSchema }) =>
    colorSchema
      ? theme.colors.agenda[colorSchema].speakerDetails
      : theme.colors.fieldsSummaryCardTitleGrey};
`;

export const IconsSection = styled.div`
  display: flex;
  margin-right: 10px;
  img {
    cursor: pointer;
  }
  > img:nth-child(1) {
    margin-right: 35px;
  }
`;
