import styled from 'styled-components';

export const SortContainerWrapper = styled.div`
  position: relative;
  user-select: none;
  width: 100%;
`;

export const SortContainer = styled.div`
  position: relative;
  display: flex;

  > label {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    margin-right: 17px;
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  }
`;

export const SortContainerTrigger = styled.div`
  position: relative;
  display: flex;

  padding: 10px 39px 10px 15px;

  height: 40px;
  width: 100%;

  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};

  cursor: pointer;

  background: ${({ theme }) => theme.colors.originWhite};
  border: 1px solid ${({ theme }) => theme.colors.logoBorder};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px ${({ theme }) => theme.colors.backgroundDownloadCard};
  border-radius: 4px;
`;
export const SpanDefault = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  white-space: nowrap;
  overflow: hidden;
`;

export const Span = styled.span`
  white-space: nowrap;
  overflow: hidden;
`;

export const ArrowContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  transform: rotate(${({ display }) => (display ? '180deg' : '0deg')});
`;

export const CustomOptions = styled.div`
  position: absolute;
  display: block;
  top: calc(100% + 1px);
  left: 0;
  right: 0;

  max-height: 214px;
  overflow: auto;

  padding: 1px 5px;

  background: ${({ theme }) => theme.colors.originWhite};
  border: 1px solid ${({ theme }) => theme.colors.logoBorder};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px ${({ theme }) => theme.colors.backgroundDownloadCard};
  border-radius: 4px;

  transition: all 0.1s;
  pointer-events: all;
  z-index: 2;
`;

export const CustomOption = styled.span.attrs(() => ({
  tabIndex: 0,
}))`
  position: relative;
  display: block;
  padding: 5px 10px;
  margin: 10px 0;

  font-size: 15px;
  line-height: 20px;

  color: #6b7178;
  cursor: pointer;
  transition: all 0.1s;
`;

// export const SortTitle = styled.div`
//   font-size: 15px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 18px;
//   text-align: left;
//   margin-right: 17px;
//   color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
// `;
// export const Input = styled.input`
//   width: 100%;
//   height: 40px;
//   border: 1px solid #cad4dc;
//   border-radius: 4px;
//   font-size: 15px;
//   line-height: 20px;
//   color: ${({ theme }) => theme.colors.textDescriptionGrey};
//   padding-left: 15px;
// `;
// export const IconArrow = styled.div`
//   position: absolute;
//   display: flex;
//   align-items: center;
//   margin-right: 16px;
// `;
