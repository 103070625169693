import React from 'react';
import { isEqual } from 'deep-equal';
import { Badge } from './styles';

const BadgeCount = ({ count, color }) => {
  return (
    <Badge color={color}>
      <div>{count}</div>
    </Badge>
  );
};
export default React.memo(BadgeCount, isEqual);
