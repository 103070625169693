import React from 'react';
import { isEqual } from 'deep-equal';
import { InputContainer, IconSearch, Input } from './styles';
import searchIcon from './icons/searchIcon.svg';
import { useDispatch } from 'react-redux';
import { setSearchMsg } from '@/store/networkingPage';

const InputSearch = ({ placeholder, value, onFilteringDelegate }) => {
  const dispatch = useDispatch();
  const onInputChange = e => {
    dispatch(setSearchMsg(e.currentTarget.value));
  };

  const onSearchDelegate = e => {
    if (e.keyCode === 13) {
      onFilteringDelegate();
    }
  };

  return (
    <InputContainer>
      <IconSearch>
        <img src={searchIcon} alt="search_icon"/>
      </IconSearch>
      <Input placeholder={placeholder} value={value} onChange={onInputChange} onKeyUp={onSearchDelegate}/>
    </InputContainer>
  );
};
export default React.memo(InputSearch, isEqual);
