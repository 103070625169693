import React from 'react';
import { isEqual } from 'deep-equal';
import { BodyContainer, ButtonSection, FiltersContainer } from './styles';
import NetworkingFilters from '@/components/blocks/NetworkingFilters/component';
import Sort from './Sort/component';
import Tabs from './Tabs/components';
import Button from '@/components/controls/buttons/GenericButton/component';
import { useDispatch, useSelector } from 'react-redux';
import {
  onFilteringDelegates,
  getSearchMsg,
  getSelectedSort,
  getCheckedFilters,
} from '@/store/networkingPage';
import { useParams } from 'react-router-dom';

const ResponsiveFilters = ({
  currentTab,
  handleSetCurrentTab,
  setResponsiveFilters,
  setCurrentTab,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const searchMsg = useSelector(getSearchMsg);
  const selectedSort = useSelector(getSelectedSort);
  const checkedFilters = useSelector(getCheckedFilters);

  const onViewResults = () => {
    dispatch(onFilteringDelegates({ id, searchMsg, selectedSort, checkedFilters }));
    setCurrentTab('');
    setResponsiveFilters(false);
  };

  return (
    <FiltersContainer>
      <Tabs handleSetCurrentTab={handleSetCurrentTab} currentTab={currentTab} />
      <BodyContainer>
        {currentTab === 'filters' && <NetworkingFilters />}
        {currentTab === 'sort' && <Sort />}
      </BodyContainer>
      <ButtonSection>
        <Button label="View results" styleType="outline" onClick={onViewResults} />
      </ButtonSection>
    </FiltersContainer>
  );
};

export default React.memo(ResponsiveFilters, isEqual);
