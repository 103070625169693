import React, { useContext, useState, useEffect, useRef, useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import isEqual from 'deep-equal';
import NavMenu from './components/NavMenu';
import PollPanel from './components/PollPanel';
import StreamIFrame from './components/YouTubeStream';
import InteractionPanel from '@/components/blocks/InteractionPanel';
import CommonHeader from '@/components/blocks/CommonHeader';
import Agenda from '@/components/pages/Agenda';
import Networking from '@/components/pages/Networking';
import Dashboard from '@/components/pages/Dashboard';
import MyProfile from '@/components/pages/MyProfile';
import LiveStream from '@/components/pages/LiveStream';
import EventManagement from '@/components/pages/EventManagement';
import { useWindowSize } from '@/helpers/hooks';
import { getPolls } from '@/store/polls/selectors';
import { getLiveSessionsInfo } from '@/store/sessions/selectors';

import { useIsVideoScrolled } from './hooks';
import {
  PLATFORM_URL,
  AGENDA_URL,
  NETWORKING_URL,
  EXHIBITOR_URL,
  DASHBOARD_URL,
  PROFILE_URL,
  EXPO_URL,
  USER_URL,
  EVENT_MANAGEMENT_URL,
  DEMAND_URL,
} from '@/constants/routes';

import {
  Platform,
  BodyPlatform,
  StreamingSection,
  StreamContainer,
  PlatformWrapperSection,
  MiniVideoStreamPlaceholder,
  ChatSection,
  MenuWrapper,
  MenuSection,
  MobileTabletHeader,
  PageContainer,
  ScrollContainer,
} from './styles';
import Expo from '../ExpoPage/component';
import Exhibitor from '../ExhibitorPage/component';
import Toaster from './components/Toaster';
import PlatformLogo from './components/PlatformLogo';
import DemandPage from '../DemandPage/component';
import { getEventInfo } from '@/store/platformInfo';
import { youtubeValidation } from '@/helpers';

const PlatformPage = () => {
  const RESIZE_DELAY = 10;
  const SCROLL_THROTTLE_DELAY = 50;
  const location = useLocation();
  const contentContainerRef = useRef();
  const videoPlaceholderRef = useRef();
  const isVideoScrolled = useIsVideoScrolled(
    videoPlaceholderRef,
    contentContainerRef,
    SCROLL_THROTTLE_DELAY,
  );
  const { breakpoints } = useContext(ThemeContext);
  const [windowWidth, _] = useWindowSize(RESIZE_DELAY);
  const [isInteractionPanelOpen, setIsInteractionPanelOpen] = useState(false);
  const isAtHomepage = useMemo(() => location.pathname.search('platform/') === -1, [location]);
  const isTablet =
    windowWidth <= parseInt(breakpoints.minWidth.portrait.tabletV.replace(/\D/g, ''));
  const displayMiniStream = !isAtHomepage || (isAtHomepage && isVideoScrolled);
  const getDataPolls = useSelector(getPolls);
  const { preEventVideo, streamingLink } = useSelector(getEventInfo);
  const { current_sessions } = useSelector(getLiveSessionsInfo);
  const [isPollPanelShown, setIsPollPanelShown] = useState(false);

  const isGetDataPolls = () => {
    if (typeof getDataPolls !== 'undefined' && getDataPolls.length && current_sessions !== null) {
      setIsPollPanelShown(true);
    } else {
      setIsPollPanelShown(false);
    }
  };

  useEffect(() => {
    !isTablet && setIsInteractionPanelOpen(false);
    isGetDataPolls();
  }, [isInteractionPanelOpen, windowWidth, breakpoints.minWidth.portrait.tabletV, getDataPolls, current_sessions]);

  const isValidEventVideo = useMemo(() => {
    return youtubeValidation(preEventVideo) && youtubeValidation(streamingLink);
  }, [preEventVideo, streamingLink]);

  return (
    <Platform>
      <MenuSection>
        <MenuWrapper isHorizontal={isTablet}>
          <NavMenu
            isHorizontal={isTablet}
            isInteractionPanelOpen={isInteractionPanelOpen}
            setIsInteractionPanelOpen={setIsInteractionPanelOpen}
          />
        </MenuWrapper>
      </MenuSection>
      <BodyPlatform>
        <Toaster />
        {isInteractionPanelOpen && isTablet && (
          <Fragment>
            <InteractionPanel />
            {isPollPanelShown && <PollPanel setIsPollPanelShown={setIsPollPanelShown} />}
          </Fragment>
        )}
        <PlatformWrapperSection>
          {windowWidth > parseInt(breakpoints.minWidth.portrait.tabletV.replace(/\D/g, '')) && (
            <CommonHeader>
              <PlatformLogo />
            </CommonHeader>
          )}
          <ScrollContainer isAtHomepage={isAtHomepage} ref={contentContainerRef}>
            <PageContainer>
              {isValidEventVideo && (
                <StreamContainer displayTopRight={displayMiniStream}>
                  {/* This component uses "position: fixed" on displayTopRight and small devices */}
                  <StreamIFrame preEventVideo={preEventVideo} streamingLink={streamingLink} />
                </StreamContainer>
              )}
              <Switch>
                <Route path={AGENDA_URL} exact component={Agenda} />
                <Route path={DEMAND_URL} exact component={DemandPage} />
                <Route path={NETWORKING_URL} exact component={Networking} />
                <Route path={EXPO_URL} exact component={Expo} />
                <Route path={EXHIBITOR_URL} exact component={Exhibitor} />
                <Route path={DASHBOARD_URL} exact component={Dashboard} />
                <Route path={PROFILE_URL} exact component={MyProfile} />
                <Route path={USER_URL} exact component={MyProfile} />
                <Route path={EVENT_MANAGEMENT_URL} exact component={EventManagement} />
                <Route
                  path={PLATFORM_URL}
                  component={() => (
                    <LiveStream
                      ref={videoPlaceholderRef}
                      isValidEventVideo={isValidEventVideo}
                    />
                  )}
                />
              </Switch>
            </PageContainer>
          </ScrollContainer>
        </PlatformWrapperSection>
        {!isTablet ? (
          <StreamingSection>
            {displayMiniStream && (
              <MiniVideoStreamPlaceholder isValidationEventVideo={isValidEventVideo} />
            )}
            <ChatSection>
              <InteractionPanel />
              {isPollPanelShown && (
                <PollPanel
                  setIsPollPanelShown={setIsPollPanelShown}
                />
              )}
            </ChatSection>
          </StreamingSection>
        ) : (
          <MobileTabletHeader>
            <CommonHeader>
              <PlatformLogo />
            </CommonHeader>
            {!isAtHomepage && (
              <Fragment>
                <MiniVideoStreamPlaceholder isValidationEventVideo={isValidEventVideo} />
              </Fragment>
            )}
          </MobileTabletHeader>
        )}
      </BodyPlatform>
    </Platform>
  );
};

export default React.memo(PlatformPage, isEqual);
