import { sessionsActions } from '@/constants/actions/sessionsActions';

const initialState = {
  liveSessionInfo: {},
};

export const sessionsInfo = (state = initialState, action) => {
  switch (action.type) {
    case sessionsActions.SetLiveSession:
      return {
        ...state,
        liveSessionInfo: action.payload,
      };
    case sessionsActions.SetIsBookmarkRemainingSession:
      return {
        ...state,
        liveSessionInfo: {
          ...state.liveSessionInfo,
          remaining_sessions: state.liveSessionInfo.remaining_sessions.map(session => {
            if (session.id === action.sessionId) {
              return { ...session, is_bookmark: action.isBookmark };
            }
            return session;
          }),
        },
      };
    default:
      return state;
  }
};
