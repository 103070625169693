import {generateUniqueId} from "./generateUniqueId";

export const exibitorsMapper = data => {
  const sponsors = {};
  const sponsorsArray = [];

  const orderData = data.filter(el => el.label).sort((a, b) => a.label.sequence_order - b.label.sequence_order)

  orderData.forEach(exhibitor => {
    if (exhibitor.label) {
      if (!sponsors[exhibitor.label.text]) {
        sponsors[exhibitor.label.text] = [
          {
            id: exhibitor.id,
            name: exhibitor.name,
            label: exhibitor.label,
            logo: exhibitor.logo,
            description: exhibitor.description,
          },
        ];
      } else {
        sponsors[exhibitor.label.text].push({
          id: exhibitor.id,
          name: exhibitor.name,
          label: exhibitor.label,
          logo: exhibitor.logo,
          description: exhibitor.description,
        });
      }
    }
  });

  for (const key in sponsors) {
    sponsorsArray.push({
      id: generateUniqueId(),
      title: `${key}s`,
      partners: sponsors[key],
    });
  }

  return sponsorsArray
}
