import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import TextInput from '@/components/controls/TextInput';
import CustomSelect from '@/components/controls/CustomSelect';
import { CheckboxWithLabel } from 'formik-material-ui';
import GenericButton from '@/components/controls/buttons/GenericButton';
import { eventProfileSchema } from '@/components/controls/validationSchemas/eventProfileSchema';
import { getRegistrationFieldsValues } from '@/store/registrationFieldsValues/selector';
import { onGetRegistrationValues } from '@/store/registrationFieldsValues/actions';
import { getRegistrationError, getRegistrationData } from '@/store/registration/selectors';
import { getIsLoadingInfo } from '@/store/checkFormValidation';
import SelectCountries from './SelectCountries/component';
import { getRegistrationStep } from '@/store/registration';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.module.css';

export const EventProfileForm = ({ submitAction, addStoreData }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const formRef = useRef();
  const { profile } = useSelector(getRegistrationData);
  const errorMsg = useSelector(getRegistrationError);
  const isLoading = useSelector(getIsLoadingInfo);
  const step = useSelector(getRegistrationStep);
  const fieldValues = useSelector(getRegistrationFieldsValues);
  const [countries, setCountries] = useState([]);
  const [role, setRole] = useState([]);
  const [organisation, setOrganisation] = useState([]);

  useEffect(() => {
    id && dispatch(onGetRegistrationValues(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (fieldValues !== null) {
      fieldValues.countries && setCountries(fieldValues.countries);
      fieldValues.role && setRole(fieldValues.role);
      fieldValues.organisation && setOrganisation(fieldValues.organisation);
    }
  }, [fieldValues]);

  const onSubmit = values => {
    dispatch(submitAction({ values, next: 'bio', putFunc: addStoreData, event_pk: id }));
  };

  const CustomCheckbox = withStyles({
    root: {
      color: '#00967F',
      '&$checked': {
        color: '#00967F',
      },
    },
    checked: {},
  })((props) => <CheckboxWithLabel color="default" {...props} />);

  useEffect(() => {
    if (step === 'profile' && Object.keys(profile).length > 0) {
      const { current } = formRef;
      for (const key in profile) {
        current.setFieldValue(key, profile[key]);
      }
    }
    const isRegistrationInProgress = localStorage.getItem('isRegistrationInProgress');
    if (!isRegistrationInProgress) {
      localStorage.setItem('isRegistrationInProgress', 'true');
    }
  }, [profile, formRef, step]);

  return (
    <>
      {
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            job_title: '',
            role: '',
            organisation: '',
            organisation_type: '',
            country: '',
            phone: '',
            is_marketing_allowed: false,
          }}
          validationSchema={eventProfileSchema}
          validateOnChange={false}
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => (
            <Form className={styles['event-profile-form']}>
              <Field name="job_title">
                {({ field, form }) => (
                  <TextInput {...field} form={form} placeholder="e.g CEO..." label="Job title" />
                )}
              </Field>
              <Field name="role">
                {({ field, form }) => (
                  <CustomSelect options={role} {...field} form={form} label="Job role" />
                )}
              </Field>
              <Field name="organisation">
                {({ field, form }) => (
                  <TextInput
                    {...field}
                    form={form}
                    placeholder="e.g Climate Action..."
                    label="Organisation"
                  />
                )}
              </Field>
              <Field name="organisation_type">
                {({ field, form }) => (
                  <CustomSelect
                    options={organisation}
                    {...field}
                    form={form}
                    label="Organisation type"
                  />
                )}
              </Field>
              <Field name="country">
                {({ field, form }) => (
                  <SelectCountries options={countries} {...field} form={form} label="Country" />
                )}
              </Field>
              <div className={styles['phone-number']}>
                <Field name="phone">
                  {({ field, form }) => (
                    <TextInput
                      {...field}
                      form={form}
                      placeholder="e.g 0207 123 4321..."
                      label="Phone number (optional)"
                    />
                  )}
                </Field>
              </div>
              <Field
                name="is_marketing_allowed"
                component={CustomCheckbox}
                type="checkbox"
                color="primary"
                Label={{ label: 'Please check this box if you agree to allow us to share your information with carefully selected third-parties' }}
              />
              {errorMsg !== '' &&
                errorMsg.map(error => (
                  <div key={error} className={styles['error-text']}>
                    {error}
                  </div>
                ))}
              <GenericButton
                label="Continue"
                styleType="primary"
                type="submit"
                isLoading={isLoading}
              />
            </Form>
          )}
        </Formik>
      }
    </>
  );
};
