import React, { useCallback, useState, useEffect } from 'react';
import { isEqual } from 'deep-equal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import GenericButton from '@/components/controls/buttons/GenericButton';
import TextArea from '@/components/controls/TextArea';
import Preloader from '@/components/blocks/Preloader';
import { broadcastMessageSchema } from '@/components/controls/validationSchemas/broadcastMessageSchema';
import {
  getbroadcastMessageInfo,
  getloadingBroadcastMessageInfo,
  getloadingPatchBroadcastInfo,
} from '@/store/eventManagementPage/selectors';
import {
  getModeratorMessage,
  postBroadcastMessage,
  patchBroadcastMessage,
} from '@/store/eventManagementPage/actions';
import {
  BroadcastSection,
  BroadcastTitle,
  RadioBtnContainer,
  Message,
  MessageInputField,
  BtnSave,
  MessageOutputField,
  Text,
  BtnEdit,
  BtnContainer,
  BtnWrapper,
  RadioBtnWrapper,
  LabelBtn,
} from './styles';

const Broadcast = () => {
  const dispatch = useDispatch();
  const { id: event_pk } = useParams();
  const [messageInput, setMessageInput] = useState(false);
  const broadcastMessage = useSelector(getbroadcastMessageInfo);
  const loadingBroadcastMessage = useSelector(getloadingBroadcastMessageInfo);
  const loadingPatchBroadcast = useSelector(getloadingPatchBroadcastInfo);
  const { message, type, is_active: active, id } = broadcastMessage;

  useEffect(() => {
    dispatch(getModeratorMessage(event_pk));
  }, [event_pk, dispatch]);

  const publishMessageHandler = useCallback(() => {
    dispatch(patchBroadcastMessage(event_pk, id, { is_active: true }));
  }, [dispatch, event_pk, id]);

  const unpublishMessageHandler = useCallback(() => {
    dispatch(patchBroadcastMessage(event_pk, id, { is_active: false }));
  }, [dispatch, event_pk, id]);

  const editMesageHandler = useCallback(() => setMessageInput(true), [messageInput]);

  const isEmpty = obj => {
    for (let key in obj) {
      return false;
    }
    return true;
  };

  const noBroadcastMessage = isEmpty(broadcastMessage);

  const onSubmit = useCallback(
    e => {
      const messageData = {
        message: e.message,
        type: e.picked,
      };

      const firstMessageData = {
        event: event_pk,
        message: e.message,
        type: e.picked,
      };

      noBroadcastMessage
        ? dispatch(postBroadcastMessage(event_pk, firstMessageData))
        : dispatch(patchBroadcastMessage(event_pk, id, messageData));

      setMessageInput(false);
    },
    [dispatch, event_pk, id],
  );

  const checkedHandler = useCallback((visible, type, button, picked) => {
    if (visible) {
      return picked === button;
    } else if (type === button) {
      return true;
    } else {
      return false;
    }
  }, []);

  return (
    <BroadcastSection>
      {loadingBroadcastMessage ? (
        <Preloader />
      ) : (
        <>
          <Formik
            initialValues={{
              picked: type || (noBroadcastMessage && 'alert'),
              message,
            }}
            onSubmit={onSubmit}
            validationSchema={broadcastMessageSchema}
          >
            {({ values }) => (
              <Form>
                <BroadcastTitle>
                  Broadcast message
                  <RadioBtnContainer role="group">
                    <RadioBtnWrapper>
                      <Field
                        type="radio"
                        name="picked"
                        value="alert"
                        disabled={!messageInput}
                        checked={checkedHandler(messageInput, type, 'alert', values.picked)}
                      />
                      <LabelBtn>Alert</LabelBtn>
                    </RadioBtnWrapper>
                    <RadioBtnWrapper>
                      <Field
                        type="radio"
                        name="picked"
                        value="announcement"
                        disabled={!messageInput}
                        checked={checkedHandler(messageInput, type, 'announcement', values.picked)}
                      />
                      <LabelBtn>Announcement</LabelBtn>
                    </RadioBtnWrapper>
                  </RadioBtnContainer>
                </BroadcastTitle>
                <Message>
                  {messageInput ? (
                    <MessageInputField>
                      <Field name="message">
                        {({ field, form }) => <TextArea {...field} form={form} />}
                      </Field>
                      <BtnSave type="submit">Save</BtnSave>
                    </MessageInputField>
                  ) : (
                    <MessageOutputField>
                      <Text>{message}</Text>
                      <BtnEdit type="button" onClick={editMesageHandler}>
                        Edit
                      </BtnEdit>
                    </MessageOutputField>
                  )}
                </Message>
              </Form>
            )}
          </Formik>
          <BtnContainer>
            {loadingPatchBroadcast ? (
              <span>
                <Preloader />
              </span>
            ) : (
              <>
                {active ? (
                  <BtnWrapper>
                    <GenericButton
                      label="Unpublish"
                      type="submit"
                      styleType="outline"
                      color="red"
                      onClick={unpublishMessageHandler}
                      disabled={messageInput}
                    />
                  </BtnWrapper>
                ) : (
                  <BtnWrapper>
                    <GenericButton
                      label="Publish"
                      type="submit"
                      styleType="outline"
                      color="green"
                      onClick={publishMessageHandler}
                      disabled={messageInput}
                    />
                  </BtnWrapper>
                )}
              </>
            )}
          </BtnContainer>
        </>
      )}
    </BroadcastSection>
  );
};

export default React.memo(Broadcast, isEqual);
