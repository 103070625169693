import { call, delay, put, race, take, takeEvery } from 'redux-saga/effects';
import { sessionsActions } from '@/constants/actions/sessionsActions';
import { sessionsAPI } from '@/api/sessionsAPI';
import {setIsBookmarkRemainingSession, setLiveSession} from './actions';
import { GET_LIVE_SESSIONS_DELAY } from '@/constants/longPollingDelay';
import {setUserStatus} from "../isDelegateCheck";

function* getLiveSession(action) {
  while (true) {
    try {
      const response = yield call(sessionsAPI.getLiveSession, action.payload);
      yield put(setLiveSession(response.data));
      yield put(setUserStatus(response.data.user_status));
      yield delay(GET_LIVE_SESSIONS_DELAY);
    } catch (error) {
      console.log(error);
    }
  }
}

function* bookmarkRemainingSession({ payload: { eventId, sessionId, isBookmark } }) {
  try {
    yield put(setIsBookmarkRemainingSession(sessionId, isBookmark));
    yield call(sessionsAPI.bookmarkSession, eventId, sessionId);
  } catch (error) {
    yield put(setIsBookmarkRemainingSession(sessionId, false));
    console.log(error);
  }
}

function* unBookmarkRemainingSession({ payload: { eventId, sessionId, isBookmark } }) {
  try {
    yield put(setIsBookmarkRemainingSession(sessionId, isBookmark));
    yield call(sessionsAPI.unBookmarkSession, eventId, sessionId);
  } catch (error) {
    yield put(setIsBookmarkRemainingSession(sessionId, true));
    console.log(error);
  }
}

export function* watchGetLiveSession() {
  while (true) {
    const payload = yield take(sessionsActions.StartGetLiveSessionPolling);
    yield race([call(getLiveSession, payload), take(sessionsActions.StopGetLiveSessionPolling)]);
  }
}

export function* watchSessionsInfo() {
  yield takeEvery(sessionsActions.BookmarkRemainingSession, bookmarkRemainingSession)
  yield takeEvery(sessionsActions.UnBookmarkRemainingSession, unBookmarkRemainingSession)
}
