import styled from 'styled-components';

export const InvitedUsersContainer = styled.div`
  height: 650px;
  box-sizing: border-box;
  padding: 30px 60px;
  overflow-y: scroll;
`;
export const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.originBlack};
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseTitleIcon = styled.span`
  cursor: pointer;
`;

export const Label = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
`;
export const DateContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;
export const InputWrapper = styled.div`
  margin-bottom: 30px;
`;
export const DateWrapper = styled.div`
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    right: 10px;
  }
  @media (max-width: 450px) {
    margin-bottom: 30px;
  }
`;
export const TimeWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-left: 45px;
  svg {
    position: absolute;
    top: 50%;
    right: 10px;
  }
  @media (max-width: 450px) {
    margin-left: 0px;
  }
`;

export const InvitationErrorMsg = styled.span`
  display: inline-block;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.alert};
`;

export const SuccessMsgSpan = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.primaryGreen};
  text-align: center;
`;
