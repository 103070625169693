import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    margin: 0px;
  }
`;
export const LabelContainer = styled.div`
  > div {
    position: absolute;
    left: 80px;
  }
`;

export const ButtonContainer = styled.div``;

export const Btn = styled.div`
  display: flex;
  align-items: center;
  :before {
    content: '';
    display: inline-block;
    width: 50px;
    height: 25px;
    border-radius: 14px;
    background-color: ${({ active, theme }) =>
      active ? theme.colors.managerAgenda.observatory : theme.colors.managerAgenda.scorpion};
    margin-right: 10px;
  }
  :after {
    content: '';
    position: absolute;
    top: 2px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.originWhite};
    left: ${({ active }) => active && '27px'};
  }
`;
export const Input = styled.input`
  display: none;
`;
export const Label = styled.label`
  font-size: 10px;
  line-height: 24px;
  text-align: center;
  justify-content: center;
  color: ${({ active, theme }) =>
    active ? theme.colors.managerAgenda.persianGreen : theme.colors.managerAgenda.osloGray};
`;
