import React, { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'deep-equal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import VisitorsCard from './components/VisitorsCard';
import DashboardCard from './components/DashboardCard';
import InvitationCardsTable from './components/InvitationCardsTable';
import InvitationCardModal from './components/InvitationCardModal';
import { getExibitorDetail, getStatisticVisitorsInfo } from '@/store/dashboardPage/selectors';
import {
  startExibitorDataPolling,
  stopExibitorDataPolling,
  startStatisticVisitorsPolling,
  stopStatisticVisitorsPolling,
} from '@/store/dashboardPage/actions';
import {
  Container,
  HeaderContainer,
  HeaderTitle,
  VisitorsCardWrapper,
  BodyContainer,
  Resource,
  ResourceTitle,
  DashboardCardWrapper,
} from './styles';
import { getPermissionsInfo } from '@/store/platformInfo';

const Dashboard = () => {
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const { right_to_send_invitations: rightToSendInvitations, exhibitor: exhibitorId } = useSelector(
    getPermissionsInfo,
  );

  const dispatch = useDispatch();
  const exibitor = useSelector(getExibitorDetail);
  const statisticVisitors = useSelector(getStatisticVisitorsInfo);
  const urlTag = exibitor.stand_url_tag;

  useEffect(() => {
    dispatch(startExibitorDataPolling(exhibitorId));
    return () => dispatch(stopExibitorDataPolling(exhibitorId));
  }, [exhibitorId]);

  useEffect(() => {
    if (urlTag) {
      dispatch(startStatisticVisitorsPolling(id, urlTag));
      return () => dispatch(stopStatisticVisitorsPolling(id, urlTag));
    }
  }, [urlTag]);

  const handleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  return (
    <Container>
      {showModal && <InvitationCardModal hideModal={handleShowModal} setShowModal={setShowModal}/>}
      <HeaderContainer>
        <HeaderTitle>Dashboard</HeaderTitle>
        <VisitorsCardWrapper>
          <VisitorsCard
            title="Unique stand visitors"
            subTitle="Click on the card to download a detailed visitors activity report"
            count={statisticVisitors}
            urlTag={urlTag}
          />
        </VisitorsCardWrapper>
      </HeaderContainer>
      <BodyContainer rights={!rightToSendInvitations}>
        {!!exibitor.documents?.length && 
          <Resource>
            <ResourceTitle>resource downloads report</ResourceTitle>
            {exibitor.documents?.map((document, index) => (
                <DashboardCardWrapper key={index}>
                  <DashboardCard
                    title={document.document_name}
                    subTitle={document.path.split('/').reverse().slice(0, 1).toString()}
                    count={document.statistic}
                  />
                </DashboardCardWrapper>
              ))}
          </Resource>
        }
        {rightToSendInvitations && (
          <InvitationCardsTable invitations={exibitor.invitations} onClick={handleShowModal} />
        )}
      </BodyContainer>
    </Container>
  );
};

export default React.memo(Dashboard, isEqual);
