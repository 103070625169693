import { call, put, select, takeEvery } from 'redux-saga/effects';
import { livestreamActions } from '@/constants/actions/livestreamActions';
import { isFetchingLivestream, setEventPartners } from './actions';
import { expoAPI } from '@/api/expoAPI';
import { exibitorsMapper } from '@/helpers/sponsorsMapper';
import { getEventPartnersInfo } from './selectors';

function* getEventPartners(action) {
  try {
    const eventPartners = yield select(getEventPartnersInfo);
    if (!eventPartners.length > 0) {
      yield put(isFetchingLivestream(true));
      const response = yield call(expoAPI.getExhibitors, action.payload);
      if (response.data.length > 0) {
        const sponsors = yield exibitorsMapper(response.data);
        yield put(setEventPartners(sponsors));
      }
    }
  } catch (error) {
  } finally {
    yield put(isFetchingLivestream(false));
  }
}

export function* watchLiveStreamPage() {
  yield takeEvery(livestreamActions.GetEventPartners, getEventPartners);
}
