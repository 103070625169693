import { call, put, takeEvery } from 'redux-saga/effects';
import { demandActions } from '@/constants/actions/demandActions';
import { setIsFetchingDemand, setSession, setEventPartners } from './actions';
import {sessionsAPI} from "@/api/sessionsAPI";
import {expoAPI} from "@/api/expoAPI";
import {exibitorsMapper} from "@/helpers/sponsorsMapper";

function* getSession(action) {
  try {
    yield put(setIsFetchingDemand(true));
    const response = yield call(sessionsAPI.getSession, action.payload);
    yield put(setSession(response.data));
  } catch (error) {
  } finally {
    yield put(setIsFetchingDemand(false));
  }
}

function* getEventPartners(action) {
  try {
    yield put(setIsFetchingDemand(true));
    const response = yield call(expoAPI.getExhibitors, action.payload);
    const sponsors = yield exibitorsMapper(response.data)
    yield put(setEventPartners(sponsors));
  } catch (error) {
  }
}

export function* watchDemandPage() {
  yield takeEvery(demandActions.GetSession, getSession);
  yield takeEvery(demandActions.GetEventPartners, getEventPartners);
}
