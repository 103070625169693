import React from 'react';
import { isEqual } from 'deep-equal';
import InformationCard from './components/InformationCard';
import Button from '@/components/controls/buttons/GenericButton';
import AddToCalendarDropdown from './components/AddToCalendarDropdown';

import {
  Text,
  Header,
  InfromationCardsContainer,
  InfromationCardWrapper,
  ButtonsContainer,
  ButtonFlexWrapper,
} from './styles';

const details = {
  'Order ID': '798534',
  'Stripe Confirmation': '798534-798534-798534-798534',
  Date: '26/08/2020 14:33',
};

const ConfirmationForm = () => {
  return (
    <>
      <Text>
        Thank you for purchasing a ticket to the virtual event, we look forward to hosting you. The
        Climate Action team will review your application and will get back to you within 24 hours.
      </Text>
      <Text>
        We’ve sent you an email to verify your email address, simply click the link in the email to
        complete verification.
      </Text>
      <Header>Order Details</Header>
      <InfromationCardsContainer>
        {Object.entries(details).map(([header, content], idx) => (
          <InfromationCardWrapper key={idx}>
            <InformationCard header={header} content={content} />
          </InfromationCardWrapper>
        ))}
      </InfromationCardsContainer>
      <ButtonsContainer>
        <ButtonFlexWrapper>
          <Button label="Download invoice" type="outline" />
        </ButtonFlexWrapper>
        <ButtonFlexWrapper>
          <AddToCalendarDropdown />
        </ButtonFlexWrapper>
        <Button label="Return to the homepage" type="primary" />
      </ButtonsContainer>
    </>
  );
};

export default React.memo(ConfirmationForm, isEqual);
