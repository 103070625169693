import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'deep-equal';
import GenericButton from '@/components/controls/buttons/GenericButton';
import { ForgotPasswordForm } from '@/components/forms/forgotPasswordForm';
import Logo from '@/components/controls/Logo';
import { getResetPasswordEmail } from '@/store/resetPassword/selectors';
import { onPutResetPasswordEmail } from '@/store/resetPassword/actions';
import { generateSignInUrl } from '@/constants/routes';
import { history } from '@/store/rootReducer';
import { getResetPassworError } from '@/store/resetPassword/selectors';
import styles from './styles.module.css';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const SignInUrl = generateSignInUrl(id);
  const email = useSelector(getResetPasswordEmail);
  const errorMsg = useSelector(getResetPassworError);

  const onBackToSignIn = () => {
    dispatch(onPutResetPasswordEmail(''));
    history.push(SignInUrl);
  };

  return (
    <div className={styles['sign-in-form-wrapper']}>
      <div className={styles['sign-in-content']}>
        <div className={styles['logo-wrapper']}>
          <Logo />
        </div>
        {email === '' ? (
          <>
            <p className={styles['sign-in-title']}>Enter your email to reset password</p>
            <>
              <ForgotPasswordForm />
            </>
          </>
        ) : (
          <>
            <p className={styles['sign-in-title']}>Reset password link has been sent</p>
            <GenericButton label="Back to sign in" styleType="primary" onClick={onBackToSignIn} />
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(ForgotPasswordPage, isEqual);
