import { call, takeEvery, put } from 'redux-saga/effects';
import { profileAPI } from '@/api/profileAPI';
import { profilePageActions } from '@/constants/actions/profilePageActions';
import { eventRegistrationAPI } from '@/api/eventRegistrationApi';
import {
  setProfile,
  setIsFetchingProfile,
  setSocialLinks,
  setIsActiveEditSocialLinks,
  setIsActiveEditBiography,
  setBiography,
  setEventInterests,
  setIsActiveEditInterests,
  setIsMarketingAllowed,
  setIsAnonymous,
  setIsBookmark,
  setInterests,
  setBiographyErrorMsg,
  setIsBookmarkSession,
  setUpdateSocialLinksLoading,
  setUpdateSocialLinksError,
} from './actions';
import { networkingPageAPI } from '@/api/networkingPageApi';
import { addFavoriteDelegate, removeFavoriteDelegate } from '../interactionPanel/actions';
import { ContactsOutlined } from '@material-ui/icons';

function* getProfile(action) {
  try {
    yield put(setIsFetchingProfile(true));
    const response = yield call(profileAPI.getProfile, action.payload);
    const eventInterests = yield call(eventRegistrationAPI.fieldsValues, action.payload);
    yield put(setProfile(response.data));
    yield put(setEventInterests(eventInterests.data.data.interests));
  } catch (error) {
  } finally {
    yield put(setIsFetchingProfile(false));
  }
}

function* getDelegateProfile({ payload: { id, delegateId } }) {
  try {
    yield put(setIsFetchingProfile(true));
    const response = yield call(profileAPI.getDelegateProfile, id, delegateId);
    yield put(setProfile(response.data));
  } catch (error) {
  } finally {
    yield put(setIsFetchingProfile(false));
  }
}

function* updateSocialLinks({ payload: { id, delegateId, linkedinUrl, twitterUrl } }) {
  yield put(setUpdateSocialLinksError(''));
  yield put(setUpdateSocialLinksLoading(true));
  try {
    const response = yield call(
      profileAPI.editSocialLinks,
      id,
      delegateId,
      linkedinUrl,
      twitterUrl,
    );
    yield put(setSocialLinks(response.data.linkedin_url, response.data.twitter_url));
    yield put(setUpdateSocialLinksLoading(false));
    yield put(setIsActiveEditSocialLinks(false));
  } catch (error) {
    yield put(setUpdateSocialLinksLoading(false));
    yield put(setUpdateSocialLinksError(Object.values(error.response.data)[0] ? Object.values(error.response.data)[0][0] : 'An error occured'));
  }
}

function* updateBiography({ payload: { id, delegateId, biography } }) {
  try {
    const response = yield call(profileAPI.editBiography, id, delegateId, biography);
    yield put(setBiography(response.data.biography));
    yield put(setIsActiveEditBiography(false));
    yield put(setBiographyErrorMsg(''));
  } catch (error) {
    yield put(setBiographyErrorMsg(error.response.data.biography[0]));
  }
}

function* updateInterests({ payload: { id, interests } }) {
  try {
    const response = yield call(profileAPI.editInterests, id, interests);
    yield put(setInterests(response.data));
    yield put(setIsActiveEditInterests(false));
  } catch (error) {
    console.log(error);
  }
}

function* updateIsMarketingAllowed({ payload: { id, delegateId, isMarketingAllowed } }) {
  try {
    const response = yield call(
      profileAPI.editIsMarketingAllowed,
      id,
      delegateId,
      isMarketingAllowed,
    );
    yield put(setIsMarketingAllowed(response.data.is_marketing_allowed));
  } catch (error) {
    console.log(error);
  }
}

function* updateIsAnonymous({ payload: { id, delegateId, isAnonymous } }) {
  try {
    const response = yield call(profileAPI.editIsAnonymous, id, delegateId, isAnonymous);
    yield put(setIsAnonymous(response.data.is_anonymous));
  } catch (error) {
    console.log(error);
  }
}

export function* bookmarkProfileSession(payload) {
  try {
    yield put(setIsBookmarkSession(payload.sessionId, payload.isBookmark));
  } catch (error) {
    yield put(setIsBookmarkSession(payload.sessionId, false));
    console.log(error);
  }
}

export function* unBookmarkProfileSession(payload) {
  try {
    yield put(setIsBookmarkSession(payload.sessionId, payload.isBookmark));
  } catch (error) {
    yield put(setIsBookmarkSession(payload.sessionId, true));
    console.log(error);
  }
}

function* bookmarkDelegate({ payload: {  delegate, event_pk, delegateId  } }) {
  try {
    yield put(setIsBookmark(true));
    yield put(addFavoriteDelegate(delegate));
    yield call(networkingPageAPI.bookmarkDelegate, event_pk, delegateId);
  } catch (error) {
    yield put(setIsBookmark(false));
    yield put(removeFavoriteDelegate(delegate));
    console.log(error);
  }
}

function* unBookmarkDelegate({ payload: {  delegate, event_pk, delegateId  } }) {
  try {
    yield put(setIsBookmark(false));
    yield put(removeFavoriteDelegate(delegate));
    yield call(networkingPageAPI.unBookmarkDelegate, event_pk, delegateId);
  } catch (error) {
    yield put(setIsBookmark(true));
    yield put(addFavoriteDelegate(delegate));
    console.log(error);
  }
}

export function* watchProfilePage() {
  yield takeEvery(profilePageActions.GetProfile, getProfile);
  yield takeEvery(profilePageActions.UpdateSocialLinks, updateSocialLinks);
  yield takeEvery(profilePageActions.UpdateBiography, updateBiography);
  yield takeEvery(profilePageActions.UpdateInterests, updateInterests);
  yield takeEvery(profilePageActions.UpdateIsMarketingAllowed, updateIsMarketingAllowed);
  yield takeEvery(profilePageActions.UpdateIsAnonymous, updateIsAnonymous);
  yield takeEvery(profilePageActions.GetDelegateProfile, getDelegateProfile);
  yield takeEvery(profilePageActions.BookmarkDelegate, bookmarkDelegate);
  yield takeEvery(profilePageActions.UnBookmarkDelegate, unBookmarkDelegate);
}
