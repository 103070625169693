import React, { useCallback, useState, useEffect } from 'react';
import { isEqual } from 'deep-equal';
import Poll from 'react-polls';
import Modal from '@/components/blocks/Modal';
import { closeIcon } from './icons/index';
import { Container, Title, CloseIcon } from './styles';

const ResultsModal = ({ onClick, question, options }) => {
  const [vote, setVote] = useState(null);
  const checkNumbers = number => (!!Number(number) ? Number(number) : 0);
  const pollAnswers = options.map(item => ({
    option: item.option,
    votes: checkNumbers(item.votes_number),
  }));

  useEffect(() => {
    if (pollAnswers[0] !== undefined) {
      setVote(pollAnswers[0].option);
    }
  }, [pollAnswers]);

  const handleVote = useCallback(() => pollAnswers, [pollAnswers]);

  return (
    <Modal adaptiveSize={true}>
      <Container>
        <Title>
          <CloseIcon onClick={onClick}>{closeIcon}</CloseIcon>
        </Title>
        {vote && (
          <div>
            <Poll question={question} answers={pollAnswers} onVote={handleVote} vote={vote} />
          </div>
        )}
      </Container>
    </Modal>
  );
};
export default React.memo(ResultsModal, isEqual);
