import styled from 'styled-components'
import Avatar from 'react-avatar'

export const SpeakersBlockContainer = styled.div`
  width: 100%;
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
`

export const SpeakersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 75px;
`

export const OneSpeaker = styled.div`
  width: 260px;
  height: 260px;
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 210px;
  }
`

export const SpeakerImage = styled(Avatar)`
  width: 165px;
  height: 165px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 130px;
    height: 130px;
  }
`

export const SpeakerName = styled.div`
  margin-top: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #17191A;
`

export const SpeakerInfo = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #52595E;
`
