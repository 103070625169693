import styled from 'styled-components';

export const ChangePasswordContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LogoWrapper = styled.div``;

export const ChangePasswordHeader = styled.div`
  font-weight: bold;
  font-size: 19px;
  line-height: 25px;
  margin-top: 65px;
  margin-bottom: 45px;

  > span {
    color: ${({ theme }) => theme.colors.currentUserMessageBackground};
  }
`;

export const ChangePasswordWrapper = styled.div`
  width: 360px;
`;

export const ChangePasswordFormContainer = styled.div`
    
`


