import styled from 'styled-components';

export const MicroAgendaSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid
    ${({ isNowLive, theme }) =>
      isNowLive ? theme.colors.agenda.liveBorder : theme.colors.cards.border};
  box-sizing: border-box;
  height: ${({ isWithSpeakers }) => (isWithSpeakers ? '111px' : '90px')};
  padding: 20px 0 20px 15px;
  background: ${({ isNowLive, theme }) =>
    isNowLive ? theme.colors.agenda.liveBackground : theme.colors.originWhite};

  &:last-child {
    border-bottom: 1px solid
      ${({ isNowLive, theme }) =>
        isNowLive ? theme.colors.agenda.liveBorder : theme.colors.cards.border};
  }
`;

export const PanelDiscussion = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-all;
`;

export const PanelInformation = styled.div`
  display: flex;
  align-items: center;
`;

export const TimeSection = styled.div`
  display: flex;
  align-items: center;
`;

export const OrganisationName = styled.div`
  padding-left: 15px;
  font-size: 15px;
  font-weight: bold;
`;

export const BookmarkSection = styled.div`
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  background: ${({ theme }) => theme.colors.transparent};
  height: 100%;
  cursor: pointer;
`;

export const PanelMain = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 15px 15px;
`;

export const PanelSpan = styled.span`
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: ${({ isNowLive, theme }) =>
    isNowLive ? theme.colors.backgroundDownloadCard : theme.colors.regularText};
  margin-bottom: 8px;

  &:nth-child(1) {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${({ isNowLive, theme }) =>
      isNowLive ? theme.colors.logoBorder : theme.colors.fieldsSummaryCardTitleGrey};
  }
  &:nth-child(3) {
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;
    color: ${({ isNowLive, theme }) =>
      isNowLive ? theme.colors.logoBorder : theme.colors.fieldsSummaryCardTitleGrey};
  }
`;

export const TimeSpan = styled.span`
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 12px;
  line-height: 14px;
  color: ${({ isNowLive, theme }) =>
    isNowLive ? theme.colors.managerAgenda.osloGray : theme.colors.regularText};
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const UpNextSection = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  min-width: 66px;
`;

export const UpNextSpan = styled.span`
  font-size: 12px;
  line-height: 15px;
  margin-left: 5px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.regularText};
`;

export const NowLiveSection = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  min-width: 73px;
`;

export const NowLiveSpan = styled(UpNextSpan)`
  margin-right: 15px;
  color: ${({ theme }) => theme.colors.backgroundDownloadCard};
`;

export const LogoSection = styled.div`
  position: relative;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.originWhite};
  width: 60px;
  height: 60px;
  border: 1px solid ${({ theme }) => theme.colors.cards.border};
  box-sizing: border-box;
  margin-right: 50px auto;
  cursor: pointer;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
`;
