import { call, takeEvery, put } from 'redux-saga/effects';
import { authAPI } from '@/api/authAPI';
import { inactiveUserPageActions } from '@/constants/actions/inactiveUserPageActions';
import { setIsLoadingResend, setIsEmailResend } from './actions';

function* resendTicket(action) {
  try {
    yield put(setIsLoadingResend(true));
    yield call(authAPI.resendTicket, action.payload);
    yield put(setIsEmailResend(true));
  } catch (error) {
  } finally {
    yield put(setIsLoadingResend(false));
  }
}

export function* watchInactiveUserPage() {
  yield takeEvery(inactiveUserPageActions.ResendTicket, resendTicket);
}
