import React, { useCallback, useState, useRef } from 'react';
import { isEqual } from 'deep-equal';
import { useDispatch } from 'react-redux';
import { Popover } from 'antd';
import GenericButton from '@/components/controls/buttons/GenericButton';
import { downloadExhibitorDoc, removeResources } from '@/store/exhibitorPage';
import {
  DownloadCardInfo,
  DownloadCardMain,
  DownloadCardSection,
  DownloadCardSpan,
  IconContainer,
  DownloadImg,
  DeleteImg,
  FileCopyImg,
  PopoverTitle,
  BtnContainer,
} from './styles';
import fileCopyIcon from './icons/fileCopyIcon.svg';
import downloadIcon from './icons/downloadIcon.svg';
import { DeleteIcon } from './icons/DeleteIcon';

const DocumentCard = ({ fileName, filePath, id, event_pk, exhib_pk, isVisible }) => {
  const dispatch = useDispatch();
  const containerRef = useRef();

  const deleteDocumentHandler = useCallback(() => {
    dispatch(removeResources(event_pk, exhib_pk, id));
  }, [event_pk, exhib_pk, id, dispatch]);

  const [state, setState] = useState({
    clicked: false,
    hovered: false,
  });

  const hide = useCallback(() => {
    setState({
      clicked: false,
      hovered: false,
    });
  }, [state]);

  const handleClickChange = visible => {
    setState({
      clicked: visible,
      hovered: false,
    });
  };

  const downloadDocHandler = useCallback(() => {
    dispatch(downloadExhibitorDoc(event_pk, exhib_pk, id));
  }, [event_pk, exhib_pk, id]);

  return (
    <DownloadCardSection ref={containerRef}>
      <DownloadCardMain>
        <FileCopyImg>
          <img src={fileCopyIcon} alt="file_copy" />
        </FileCopyImg>
        <DownloadCardInfo>
          <DownloadCardSpan>{fileName}</DownloadCardSpan>
          <DownloadCardSpan>{filePath}</DownloadCardSpan>
        </DownloadCardInfo>
      </DownloadCardMain>
      <IconContainer>
        {isVisible && (
          <Popover
            getPopupContainer={() => containerRef?.current}
            content={
              <BtnContainer>
                <GenericButton label="Yes" styleType="outline" onClick={deleteDocumentHandler} />
                <GenericButton label="No" styleType="outline" color="red" onClick={hide} />
              </BtnContainer>
            }
            title={<PopoverTitle>Are you sure?</PopoverTitle>}
            trigger="click"
            visible={state.clicked}
            onVisibleChange={handleClickChange}
          >
            <DeleteImg>
              <DeleteIcon />
            </DeleteImg>
          </Popover>
        )}
        <DownloadImg onClick={downloadDocHandler}>
          <img src={downloadIcon} alt="download_icon" />
        </DownloadImg>
      </IconContainer>
    </DownloadCardSection>
  );
};

export default React.memo(DocumentCard, isEqual);
