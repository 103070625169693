import styled from 'styled-components';

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  height: 40px;
`;
export const Input = styled.input`
width: 100%;
  background: ${({ theme }) => theme.colors.originWhite};
  border: 1px solid #cad4dc;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px ${({ theme }) => theme.colors.cards.buttonBackground};
  border-radius: 4px;
  font-size: 15px;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  padding-left: 40px;
`;
export const IconSearch = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;
