import * as Yup from 'yup';

export const editSocialLinksSchema = Yup.object().shape({
  linkedin_url: Yup.string()
    .matches(
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/,
      'Not a valid linkedin url!',
    ),
  twitter_url: Yup.string()
    .matches(
      /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/,
      'Not a valid twitter url!',
    ),
});
