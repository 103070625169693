import React from 'react';
import { isEqual } from 'deep-equal';
import Modal from '@/components/blocks/Modal';
import ContactCard from '@/components/blocks/ContactCard';
import { closeIcon } from './icons/index';
import { Container, Title, Icon, Text, CloseIcon, UsersList } from './styles';

const InvitedUsersModal = ({ userList, onClick, icon, text }) => {
  return (
    <Modal adaptiveSize={true}>
      <Container>
        <Title>
          <Icon>{icon}</Icon>
          <Text>{text}</Text>
          <CloseIcon onClick={onClick}>{closeIcon}</CloseIcon>
        </Title>
        <UsersList>
          {userList.map((user, index) => (
            <ContactCard
              delegateId={user.recipient.id}
              key={index}
              photo={user.recipient.headshot}
              name={`${user.recipient.first_name} ${user.recipient.last_name}`}
              organisation={user.recipient.organisation}
              jobRole={user.recipient.role}
              isOnline={user.online}
              isBookmark={user.is_bookmark}
              delegate={user.recipient}
              isBookmarkedIcon
              isOnDashboardPage
            />
          ))}
        </UsersList>
      </Container>
    </Modal>
  );
};
export default React.memo(InvitedUsersModal, isEqual);
