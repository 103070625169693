import React, { useContext, useMemo, useRef } from 'react';
import {
  BackgroundGradient,
  BackgroundSection,
  ExpoCardApplication,
  ExpoCardContainer,
  ExpoLabel,
  ExpoLabelSection,
  HeaderSection,
  LogoSection,
  MainSection,
  MainSectionDescription,
  MainSectionTitle,
} from './styles';
import { isEqual } from 'deep-equal';
import bookmarkIcon from './icons/bookmark.svg';
import activeBookmarkIcon from './icons/activeBookmark.svg';
import { VectorIcon } from './icons/VectorIcon';
import { ThemeContext } from 'styled-components';
import { useWindowSize } from '@/helpers/hooks';
import { generateExhibitorUrl } from '@/constants/routes';
import { useDispatch } from 'react-redux';
import { bookmarkExhibitor, unBookmarkExhibitor } from '@/store/expoPage';
import { history } from '@/store/rootReducer';
import { setIsBookmarkProfileExhibitor } from '@/store/profilePage';

const ExpoCard = ({
  id,
  eventId,
  stand_url_tag,
  logo,
  label,
  name,
  description,
  color,
  is_bookmark,
  isOnProfilePage,
}) => {
  const dispatch = useDispatch();
  const { breakpoints } = useContext(ThemeContext);
  const [windowWidth, _] = useWindowSize(0);

  const visibleExpoLabel = useMemo(() => {
    if (windowWidth > parseInt(breakpoints.minWidth.landscape.tablet.replace(/\D/g, ''))) {
      return true;
    } else {
      return undefined;
    }
  }, [windowWidth]);

  const bookmarkRef = useRef();

  const onIsBookmarkExhibitors = () => {
    if (is_bookmark) {
      dispatch(unBookmarkExhibitor({ exhibitorId: id, isBookmark: false }));
      isOnProfilePage && dispatch(setIsBookmarkProfileExhibitor(false));
    } else {
      dispatch(bookmarkExhibitor({ exhibitorId: id, isBookmark: true }));
      isOnProfilePage && dispatch(setIsBookmarkProfileExhibitor(true));
    }
  };

  const onRedirectExhibitor = e => {
    if (e.target !== bookmarkRef.current)
      history.push(generateExhibitorUrl(eventId, stand_url_tag));
  };

  return (
    <>
      <ExpoCardApplication onClick={onRedirectExhibitor}>
        <BackgroundGradient />
        <BackgroundSection>
          <VectorIcon color={color} />
        </BackgroundSection>
        <ExpoCardContainer backgroundColor={color}>
          <HeaderSection>
            <LogoSection>
              <img src={`${logo}`} alt="logo" />
            </LogoSection>
            <ExpoLabelSection>
              {visibleExpoLabel && (
                <ExpoLabel labelColor={label?.color} labelTextColor={label?.text_color}>
                  {label?.text}
                </ExpoLabel>
              )}
              <img
                ref={bookmarkRef}
                src={`${is_bookmark ? activeBookmarkIcon : bookmarkIcon}`}
                alt="bookmark_icon"
                onClick={onIsBookmarkExhibitors}
              />
            </ExpoLabelSection>
          </HeaderSection>
          <MainSection>
            {!visibleExpoLabel && (
              <ExpoLabel labelColor={label?.color} labelTextColor={label?.text_color}>
                {label?.text}
              </ExpoLabel>
            )}
            <MainSectionTitle>{name}</MainSectionTitle>
            <MainSectionDescription>{description}</MainSectionDescription>
          </MainSection>
        </ExpoCardContainer>
      </ExpoCardApplication>
    </>
  );
};

export default React.memo(ExpoCard, isEqual);
