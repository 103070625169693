import styled from 'styled-components'

export const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1140px;
  align-items: center;
  @media (
    max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 924px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
  }
`

export const LandingPage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

