import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'deep-equal';
import CircularProgress from '@material-ui/core/CircularProgress';
import MicroAgenda from '@/components/blocks/MicroAgendaCardInteraction';
import { getDateString, getTimeIntervalString } from '@/helpers/date';
import { pullAgendaDataOnBookmarks } from '@/store/agenda/actions';
import { getExhibitorsOnBookmarks } from '@/store/expoPage/actions';
import { getAreBookmarksLoadingSelector } from '@/store/interactionPanel/selectors';
import { getBookmarkedExhibitors } from '@/store/expoPage/selectors';
import { getBookmarkedAgenda } from '@/store/agenda/selectors';
import { generateAgendaUrl, generateExpoUrl } from '@/constants/routes';
import {
  AgendaContainer,
  Body,
  Controls,
  MessagesContainer,
  SectionTitle,
  AgendaSection,
} from './styles';
import { EmptyContainer, EmptyContainerButtonWrapper, CustomButtonOutline } from '../../styles';

const Bookmarks = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const agendaRoute = generateAgendaUrl(id);
  const expoRoute = generateExpoUrl(id);
  const favoriteSessions = useSelector(getBookmarkedAgenda);
  const favoriteExhibitors = useSelector(getBookmarkedExhibitors);
  const isLoading = useSelector(getAreBookmarksLoadingSelector);

  useEffect(() => {
    dispatch(pullAgendaDataOnBookmarks(id));
    dispatch(getExhibitorsOnBookmarks(id));
  }, [dispatch, id]);

  return (
    <AgendaContainer>
      {((favoriteSessions && favoriteSessions.length) ||
        (favoriteExhibitors && favoriteExhibitors.length)) &&
      !isLoading ? (
        <>
          <MessagesContainer>
            <Body>
              <AgendaSection>
                <SectionTitle>Sessions</SectionTitle>
                {favoriteSessions && Boolean(favoriteSessions.length) ? (
                  favoriteSessions.map(item => (
                    <MicroAgenda
                      key={item.id}
                      time={`${getDateString(new Date(item.start_time))}, ${getTimeIntervalString(
                        item.start_time,
                        item.end_time,
                      )}`}
                      isWithSpeakers
                      speakers={item.speakers_set.map(
                        speaker => `${speaker.first_name} ${speaker.last_name}`,
                      )}
                      {...item}
                    />
                  ))
                ) : (
                  <EmptyContainer>
                    <span>You don't have any bookmarked sessions yet</span>
                    <EmptyContainerButtonWrapper>
                      <Link to={agendaRoute}>
                        <CustomButtonOutline text="Add sessions" />
                      </Link>
                    </EmptyContainerButtonWrapper>
                  </EmptyContainer>
                )}
              </AgendaSection>
            </Body>
            {favoriteSessions && Boolean(favoriteSessions.length) && (
              <Controls>
                <Link to={agendaRoute}>
                  <CustomButtonOutline text="View full agenda" />
                </Link>
              </Controls>
            )}
          </MessagesContainer>
          <MessagesContainer>
            <Body>
              <AgendaSection>
                <SectionTitle>bookmarked organisations</SectionTitle>
                {favoriteExhibitors && Boolean(favoriteExhibitors.length) ? (
                  favoriteExhibitors.map(item => (
                    <MicroAgenda key={item.id} isOrganisation {...item} />
                  ))
                ) : (
                  <EmptyContainer>
                    <span>You don't have any bookmarked organisations yet</span>
                    <EmptyContainerButtonWrapper>
                      <Link to={expoRoute}>
                        <CustomButtonOutline text="Add organisations" />
                      </Link>
                    </EmptyContainerButtonWrapper>
                  </EmptyContainer>
                )}
              </AgendaSection>
            </Body>
            {favoriteExhibitors && Boolean(favoriteExhibitors.length) && (
              <Controls>
                <Link to={expoRoute}>
                  <CustomButtonOutline text="View all organisations" />
                </Link>
              </Controls>
            )}
          </MessagesContainer>
        </>
      ) : isLoading ? (
        <EmptyContainer>
          <CircularProgress />
        </EmptyContainer>
      ) : (
        <EmptyContainer>
          <span>You don't have any bookmarked sessions or exhibitors yet</span>
          <EmptyContainerButtonWrapper>
            <Link to={agendaRoute}>
              <CustomButtonOutline text="Add sessions" />
            </Link>
          </EmptyContainerButtonWrapper>
          <EmptyContainerButtonWrapper>
            <Link to={expoRoute}>
              <CustomButtonOutline text="Add organisations" />
            </Link>
          </EmptyContainerButtonWrapper>
        </EmptyContainer>
      )}
    </AgendaContainer>
  );
};

export default React.memo(Bookmarks, isEqual);
