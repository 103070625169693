import React from 'react';
import { isEqual } from 'deep-equal';
import PlayIcon from '../../icons/Play';

import { Wrapper, Thumbnail, PlayIconContainer } from './styles';

const VideoThumbnailLink = ({ videoId }) => {
  return (
    <Wrapper>
      <Thumbnail src={`http://img.youtube.com/vi/${videoId}/0.jpg`} />
      <PlayIconContainer>
        <PlayIcon />
      </PlayIconContainer>
    </Wrapper>
  );
};

export default React.memo(VideoThumbnailLink, isEqual);
