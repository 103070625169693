import { call, takeEvery, put, delay, take, race } from 'redux-saga/effects';
import { escalatedQuestionsAPI } from '@/api/escalatedQuestionsAPI';
import { escalatedQuestionsActions } from '@/constants/actions/escalatedQuestionsActions';
import {
  setEscalatedQuestions,
  setIsFetchingQuestions,
  setEscalatedSignInErrMsg,
  setIsLoadingSignIn,
} from './actions';
import {
  generateEscalatedQuestionsUrl,
  generateEscalatedQuestionsSignInUrl,
} from '@/constants/routes';
import { history } from '../rootReducer';
import { GET_ESCALATED_QUESTIONS_DELAY } from '@/constants/longPollingDelay';

function* getEscalatedQuestions(action) {
  try {
    yield put(setIsFetchingQuestions(true));
    const response = yield call(escalatedQuestionsAPI.getEscalatedQuestions, action.payload);
    yield put(setEscalatedQuestions(response.data));
  } catch (error) {
    history.push(generateEscalatedQuestionsSignInUrl(action.payload));
  } finally {
    yield put(setIsFetchingQuestions(false));
  }
}

function* pollingEscalatedQuestions(action) {
  while (true) {
    try {
      const response = yield call(escalatedQuestionsAPI.getEscalatedQuestions, action.payload);
      yield put(setEscalatedQuestions(response.data));
      yield delay(GET_ESCALATED_QUESTIONS_DELAY);
    } catch (error) {}
  }
}

function* signInChair({ payload: { email, password, eventId } }) {
  try {
    yield put(setIsLoadingSignIn(true));
    const response = yield call(escalatedQuestionsAPI.signInChair, email, password);
    localStorage.setItem('chairAccessToken', response.data.access);
    localStorage.setItem('chairRefreshToken', response.data.refresh);
    history.push(generateEscalatedQuestionsUrl(eventId));
    yield put(setEscalatedSignInErrMsg(''));
  } catch (error) {
    yield put(setEscalatedSignInErrMsg('This delegate is not chair'));
  } finally {
    yield put(setIsLoadingSignIn(false));
  }
}

export function* watchEscalatedQuestions() {
  yield takeEvery(escalatedQuestionsActions.GetEscalatedQuestions, getEscalatedQuestions);
  yield takeEvery(escalatedQuestionsActions.SignInChair, signInChair);
}

export function* watchPollingEscalatedQuestions() {
  while (true) {
    const payload = yield take(escalatedQuestionsActions.StartGetEscalatedQuestionsPolling);
    yield race([
      call(pollingEscalatedQuestions, payload),
      take(escalatedQuestionsActions.StopGetEscalatedQuestionsPolling),
    ]);
  }
}
