import { StripeActions } from '@/constants/actions/stripe'

export const onBuyTicket = (data) => ({
  type: StripeActions.OnBuyTicket,
  payload: data,
})

export const addClientSecret = (data) => ({
  type: StripeActions.AddClientSecret,
  payload: data,
})

export const onCheckTicket = (data) => ({
  type: StripeActions.CheckTicket,
  payload: data,
})

export const setCheckTicket = (data) => ({
  type: StripeActions.OnCheckTicket,
  payload: data,
})
