import React from 'react';

export const acceptIcon = (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#009789" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 23.7143C11 21.5708 12.7373 20 14.6831 20H20.4985C22.4442 20 24.1815 21.5708 24.1815 23.7143V25.1429C24.1815 25.6162 23.8344 26 23.4062 26C22.9779 26 22.6308 25.6162 22.6308 25.1429V23.7143C22.6308 22.7018 21.7645 21.7143 20.4985 21.7143H14.6831C13.4171 21.7143 12.5508 22.7018 12.5508 23.7143V25.1429C12.5508 25.6162 12.2036 26 11.7754 26C11.3472 26 11 25.6162 11 25.1429V23.7143Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1969 14C13.1969 11.7909 15.1641 10 17.5907 10C20.0174 10 21.9846 11.7909 21.9846 14C21.9846 16.2091 20.0174 18 17.5907 18C15.1641 18 13.1969 16.2091 13.1969 14ZM17.5907 11.5484C16.1034 11.5484 14.8977 12.646 14.8977 14C14.8977 15.354 16.1034 16.4516 17.5907 16.4516C19.0781 16.4516 20.2838 15.354 20.2838 14C20.2838 12.646 19.0781 11.5484 17.5907 11.5484Z"
      fill="white"
    />
  </svg>
);

export const rejectIcon = (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#DF3541" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 23.7143C11 21.5708 12.7373 20 14.6831 20H20.4985C22.4442 20 24.1815 21.5708 24.1815 23.7143V25.1429C24.1815 25.6162 23.8344 26 23.4062 26C22.9779 26 22.6308 25.6162 22.6308 25.1429V23.7143C22.6308 22.7018 21.7645 21.7143 20.4985 21.7143H14.6831C13.4171 21.7143 12.5508 22.7018 12.5508 23.7143V25.1429C12.5508 25.6162 12.2036 26 11.7754 26C11.3472 26 11 25.6162 11 25.1429V23.7143Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1969 14C13.1969 11.7909 15.1641 10 17.5907 10C20.0174 10 21.9846 11.7909 21.9846 14C21.9846 16.2091 20.0174 18 17.5907 18C15.1641 18 13.1969 16.2091 13.1969 14ZM17.5907 11.5484C16.1034 11.5484 14.8977 12.646 14.8977 14C14.8977 15.354 16.1034 16.4516 17.5907 16.4516C19.0781 16.4516 20.2838 15.354 20.2838 14C20.2838 12.646 19.0781 11.5484 17.5907 11.5484Z"
      fill="white"
    />
  </svg>
);

export const closeIcon = (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3785 1.41L13.8297 0L7.68927 5.59L1.54884 0L0 1.41L6.14043 7L0 12.59L1.54884 14L7.68927 8.41L13.8297 14L15.3785 12.59L9.23811 7L15.3785 1.41Z"
      fill="#949DA6"
    />
  </svg>
);
