import styled from 'styled-components';

export const SummaryModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
`;

export const SummaryModalTitle = styled.div`
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.regularText};
  margin-bottom: 30px;
`;

export const SummaryModalSection = styled.div`
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  margin-bottom: 40px;
`;

export const SummaryModalButton = styled.div`
  cursor: pointer;
  width: 65%;
`;
