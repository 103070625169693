import axios from 'axios';
import { baseURL } from '@/constants/baseURL';
import { sendTokenInterceptor } from '../helpers/interceptors';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

instance.interceptors.request.use(request => {
  return sendTokenInterceptor(request);
});

export const eventRegistrationAPI = {
  formValidation(data, event_pk) {
    return instance.post(`/events/event/${event_pk}/delegate/validation/`, data);
  },
  fieldsValues(event_pk) {
    return instance.get(`/events/event/${event_pk}/associations/`);
  },
  registerDelegate(data, event_pk) {
    return instance.post(`/events/event/${event_pk}/delegate/`, data);
  },
  buyTicket(data, event_pk) {
    return instance.post(`/events/event/${event_pk}/buy-ticket/`, data);
  },
  confirmTicket(pi_token, event_pk) {
    return instance.post(`/events/event/${event_pk}/confirm-ticket/`, pi_token);
  },
  ticketPrice(event_pk) {
    return instance.get(`/events/event/${event_pk}/ticket-price/`);
  },
  userInfo() {
    return instance.get(`/users/info/`);
  },
  checkPromocode(data, event_pk) {
    return instance.post(`/events/event/${event_pk}/promocode-validation/`, data);
  },
};
