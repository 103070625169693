import styled from 'styled-components';
import { Form } from 'formik';

export const SignInChairWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.portrait.phone}) {
    margin: 30px 30px 15px 30px;
  }
`;

export const SignInChairSection = styled.div``;

export const SignInChairForm = styled(Form)`
  > div {
    margin-bottom: 30px;
  }

  > button {
    margin-top: 15px;
  }
`;

export const SignInChairTitle = styled.div`
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 45px;
`;

export const ErrorMsg = styled.div`
  margin-top: 20px;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.alert};
`;
