import React, { useEffect } from 'react';
import { isEqual } from 'deep-equal';
import { ExpoContainer, ExpoSection } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getExhibitorsInfo, getExhibitors } from '@/store/expoPage';
import { useParams } from 'react-router-dom';
import ExpoCard from '../../blocks/ExpoCard/component';
import Preloader from '../../blocks/Preloader';
import { getIsFetchingExhibitors } from '@/store/expoPage';

const Expo = () => {
  const dispatch = useDispatch();
  const exhibitors = useSelector(getExhibitorsInfo);
  const isFetchingExhibitors = useSelector(getIsFetchingExhibitors);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getExhibitors(id));
  }, []);

  return (
    <>
      {isFetchingExhibitors ? (
        <Preloader />
      ) : (
        <ExpoContainer>
          <div>
            {exhibitors.map(ex => (
                <ExpoSection key={ex.id}>
                  <div>
                    <ExpoCard {...ex} eventId={id} />
                  </div>
                </ExpoSection>
            ))}
          </div>
        </ExpoContainer>
      )}
    </>
  );
};

export default React.memo(Expo, isEqual);
