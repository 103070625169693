import { toast } from 'react-toastify';

export const toasterTypesAssociations = {
    alert: toast.TYPE.DEFAULT,
    announcement: toast.TYPE.WARNING,
}

export const toasterFunctionsAssociations = {
    alert: toast,
    announcement: toast.warn,
}