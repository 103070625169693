import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SpinnerContainer, Label } from './styles';

const Preloader = ({ label }) => {
  return (
    <SpinnerContainer>
      <CircularProgress />
      {label && <Label>{label}</Label>}
    </SpinnerContainer>
  );
};

export default Preloader;
