import React from 'react';
import { SignUpForm } from '@/components/forms/SignUpForm';
import { EventProfileForm } from '@/components/forms/EventProfileForm';
import { UploadProfileForm } from '@/components/forms/UploadProfileForm';
import { MultiSelectForm } from '@/components/forms/MultiSelectForm';
import { PaymentForm } from '@/components/forms/PaymentForm';
import SummaryForm from '@/components/forms/SummaryForm';
import ConfirmationForm from '@/components/forms/ConfirmationForm';
import { onUserRegistration, addProfileInfo, addBioInfo, addInterestsInfo, addInterestsInInfo } from '@/store/registration/actions';
import { checkFormValidation } from '@/store/checkFormValidation/actions';
import {
  generateSignInUrl,
} from '@/constants/routes';

const config = {
  signUp: {
    stepIdx: 0,
    showNavbar: true,
    header: 'Register your details',
    info: 'Add some basic details to help setup your account.',
    prompt: {
      text: 'Already have an account?',
      link: {
        text: 'Sign-in here',
        to: `${generateSignInUrl}`,
      },
    },
    content: <SignUpForm submitAction={onUserRegistration} />,
  },
  profile: {
    stepIdx: 1,
    showNavbar: true,
    header: 'Tell us about you',
    info: 'These details will help to tailor your experience.',
    content: <EventProfileForm submitAction={checkFormValidation} addStoreData={addProfileInfo} />,
  },
  bio: {
    stepIdx: 2,
    showNavbar: true,
    header: 'Add your bio',
    info: 'These details will allow other delegates to learn a bit about you.',
    content: <UploadProfileForm submitAction={checkFormValidation} addStoreData={addBioInfo} />,
  },
  interests: {
    stepIdx: 3,
    showNavbar: true,
    header: 'I’m interested in',
    info:
      'These choices will help to connect you with other relevant delegates. Select all that apply.',
    content: <MultiSelectForm submitAction={checkFormValidation} addStoreData={addInterestsInfo} next="interestedIn" />,
  },
  interestedIn: {
    stepIdx: 4,
    showNavbar: true,
    header: 'I’d like to meet',
    info:
      'These choices will help to connect you with other relevant delegates. Select all that apply.',
    content: <MultiSelectForm submitAction={checkFormValidation} addStoreData={addInterestsInInfo} next="summary" />,
  },
  summary: {
    stepIdx: 5,
    header: 'Summary',
    info: 'Here is a summary of your responses, please ensure all details are correct.',
    content: <SummaryForm />,
  },
  checkout: {
    stepIdx: 6,
    header: 'Checkout',
    info: 'Please enter your payment details.',
    content: <PaymentForm />,
  },
  confirmation: {
    stepIdx: 7,
    header: 'Payment confirmation',
    info: null,
    content: <ConfirmationForm />,
  },
};

export default config;
