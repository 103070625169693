import React from 'react';
import { isEqual } from 'deep-equal';
import {
  DownloadCardInfo,
  DownloadCardMain,
  DownloadCardSection,
  DownloadCardSpan,
  DownloadImg,
  FileCopyImg,
} from './styles';
import fileCopyIcon from './icons/fileCopyIcon.svg';
import downloadIcon from './icons/downloadIcon.svg';

const DownloadCard = ({ fileName, filePath, hideDownload, path }) => {
  return (
    <DownloadCardSection href={path} target='_blank' download={filePath}>
      <DownloadCardMain>
        <FileCopyImg>
          <img src={fileCopyIcon} alt="file_copy" />
        </FileCopyImg>
        <DownloadCardInfo>
          <DownloadCardSpan>{fileName}</DownloadCardSpan>
          <DownloadCardSpan>{filePath}</DownloadCardSpan>
        </DownloadCardInfo>
      </DownloadCardMain>
      <DownloadImg hideDownload={hideDownload}>
        <img src={downloadIcon} alt="download_icon" />
      </DownloadImg>
    </DownloadCardSection>
  );
};

export default React.memo(DownloadCard, isEqual);
