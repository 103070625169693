import React, { useEffect, useMemo } from 'react';
import { isEqual } from 'deep-equal';
import ExhibitorCard from '../../blocks/ExhibitorCard/component';
import {
  Container,
  ExhibitorInfo,
  ExhibitorMainSection,
  ExhibitorSessionSection,
  ExhibitorTeamSection,
  ExhibitorVideoSection,
  InactiveStand,
  MemberSection,
  SessionSection,
  VideoDescriptionSection,
  ResourcesSectionWrapper,
  ResourcesSectionWrapperAdaptive,
} from './styles';
import ContactCard from '../../blocks/ContactCard';
import MicroAgenda from '../../blocks/MicroAgendaCard';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getExhibitor,
  getExhibitorInfo,
  getIsFetchingExhibitor,
  getIsInactiveStand,
  getIsActiveEditHeader,
  getIsActiveEditDescription,
  setIsActiveEditHeader,
  setIsActiveEditDescription,
} from '@/store/exhibitorPage';
import Preloader from '../../blocks/Preloader';
import YoutubeVideo from '../../blocks/ExhibitorCard/YoutubeVideo/component';
import EditHeader from './EditHeader/component';
import EditHeaderSection from './EditHeaderSection/component';
import ExhibitorResourcesSection from './ExhibitorResourcesSection/component';
import EditDescriptionSection from './EditDescriptionSection/component';
import { getPermissionsInfo } from '@/store/platformInfo';
import { youtubeValidation } from '@/helpers';
import { youtubeRegExp } from '@/constants/regExp';
import { generateUniqueId } from '@/helpers';

const Exhibitor = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const exhibitor = useSelector(getExhibitorInfo);
  const isFetchingExhibitor = useSelector(getIsFetchingExhibitor);
  const isInactiveStand = useSelector(getIsInactiveStand);
  const permissions = useSelector(getPermissionsInfo);
  const { id, exhibitorId } = useParams();
  const isActiveEditHeader = useSelector(getIsActiveEditHeader);
  const isActiveEditDescription = useSelector(getIsActiveEditDescription);

  const {
    documents = [],
    description,
    stand_video,
    stand_image,
    our_team = [],
    our_sessions,
  } = exhibitor;

  useEffect(() => {
    dispatch(
      getExhibitor({
        eventId: id,
        sessionId: generateUniqueId(id),
        locationPathname: location.pathname,
        exhibitorId,
      }),
    );
  }, [exhibitorId, dispatch]);

  // useEffect(() => {
  //   dispatch(pullAgendaData(id));
  //   dispatch(pullAgendaDataOnBookmarks(id));
  // }, [id, dispatch]);

  const isEditExhibitorStand = useMemo(() => {
    if (
      (permissions.right_to_edit_exhibitor_stand && exhibitor.id === permissions.exhibitor) ||
      permissions.is_admin
    ) {
      return true;
    } else {
      return undefined;
    }
  }, [exhibitor.id]);

  return (
    <>
      {isFetchingExhibitor ? (
        <Preloader />
      ) : isInactiveStand ? (
        <InactiveStand>This stand is inactive</InactiveStand>
      ) : (
        <Container>
          <ExhibitorInfo>
            <ExhibitorCard />
            {(!!documents[0] || isEditExhibitorStand) && (
              <ResourcesSectionWrapper>
                <ExhibitorResourcesSection
                  id={id}
                  documents={documents}
                  exhibitor={exhibitor}
                  exhibitorId={exhibitorId}
                  isVisible={isEditExhibitorStand}
                />
              </ResourcesSectionWrapper>
            )}
          </ExhibitorInfo>
          <ExhibitorMainSection>
            {isEditExhibitorStand && (
              <EditHeader
                label="Edit header"
                active={isActiveEditHeader}
                setActive={setIsActiveEditHeader}
              />
            )}
            {isActiveEditHeader && <EditHeaderSection />}
            <ExhibitorVideoSection>
              {!isActiveEditHeader && youtubeValidation(stand_video) ? (
                <YoutubeVideo idVideo={stand_video.match(youtubeRegExp)[1]} />
              ) : !isActiveEditHeader && stand_image ? (
                <img src={stand_image} alt="stand_image" />
              ) : null}
              {isEditExhibitorStand && (
                <EditHeader
                  label="Edit description"
                  active={isActiveEditDescription}
                  setActive={setIsActiveEditDescription}
                />
              )}
              {!isActiveEditDescription && (
                <VideoDescriptionSection>
                  <span>{description}</span>
                </VideoDescriptionSection>
              )}
              {isActiveEditDescription && <EditDescriptionSection description={description} />}
            </ExhibitorVideoSection>
            {!!our_team[0] && (
              <ExhibitorTeamSection>
                <span>Our team</span>
                <div>
                  {our_team.map(member => (
                    <MemberSection key={member.id}>
                      <ContactCard
                        delegateId={member.id}
                        photo={member.headshot}
                        name={`${member.first_name} ${member.last_name}`}
                        organisation={member.organisation}
                        jobRole={member.role}
                        isOnline={member.is_online}
                      />
                    </MemberSection>
                  ))}
                </div>
              </ExhibitorTeamSection>
            )}
            {our_sessions.length > 0 && (
              <ExhibitorSessionSection>
                <span>Sessions we’re participating in</span>
                <div>
                  {our_sessions.map(session => (
                    <SessionSection key={session.id}>
                      <MicroAgenda
                        {...session}
                        eventId={id}
                        speakers={session.speakers_set}
                        isWithSpeakers
                        isOnLivestream
                      />
                    </SessionSection>
                  ))}
                </div>
              </ExhibitorSessionSection>
            )}
            {(!!documents[0] || isEditExhibitorStand) && (
              <ResourcesSectionWrapperAdaptive>
                <ExhibitorResourcesSection
                  id={id}
                  documents={documents}
                  exhibitor={exhibitor}
                  exhibitorId={exhibitorId}
                  isVisible={isEditExhibitorStand}
                />
              </ResourcesSectionWrapperAdaptive>
            )}
          </ExhibitorMainSection>
        </Container>
      )}
    </>
  );
};

export default React.memo(Exhibitor, isEqual);
