import { PromocodeActions } from '@/constants/actions/promocodeValidation'

export const onCheckPromocode = data => ({
  type: PromocodeActions.OnCheckPromocode,
  payload: data,
})

export const checkPromocode = data => ({
  type: PromocodeActions.CheckPromocode,
  payload: data,
})

export const setPromocodeError = data => ({
  type: PromocodeActions.SetPromocodeError,
  payload: data,
})

export const setPromocodeStatus = data => ({
  type: PromocodeActions.SetPromocodeStatus,
  payload: data,
})