export const eventManagementActions = {
  GetModeratorQuestions: 'eventManagement/GET_MODERATOR_QUESTIONS',
  SetModeratorQuestions: 'eventManagement/SET_MODERATOR_QUESTIONS',
  SetEventStatistic: 'eventManagement/SET_EVENT_STATISTIC',
  StartModeratorQuestionsDataPolling: 'eventManagement/START_MODERATOR_QUESTIONS_DATA_POLLING',
  StartEventStatisticPolling: 'eventManagement/START_EVENT_STATISTIC_POLLING',
  StopModeratorQuestionsDataPolling: 'eventManagement/STOP_MODERATOR_QUESTIONS_DATA_POLLING',
  StopEventStatisticPolling: 'eventManagement/STOP_EVENT_STATISTIC_POLLING',

  ApproveQuestion: 'eventManagement/APPROVE_QUESTION',
  RejectQuestion: 'eventManagement/REJECT_QUESTION',
  RejectAllQuestion: 'eventManagement/REJECT_ALL_QUESTION',
  ClearAllQuestion: 'eventManagement/CLEAR_ALL_QUESTION',
  RestoreQuestion: 'eventManagement/RESORE_QUESTION',
  ClearAllQuestionLoader: 'eventManagement/CLEAR_ALL_QUESTION_LOADING',
  RejectedAllQuestionLoader: 'eventManagement/REJECT_ALL_QUESTION_LOADING',

  GetStreamStatus: 'eventManagement/GET_STREAM_STATUS',
  SetStreamStatus: 'eventManagement/SET_STREAM_STATUS',
  StreamStatusLoader: 'eventManagement/STREAM_STATUS_LOADER',

  GetModeratorMessage: 'eventManagement/GET_MODERATOR_MESSAGE',
  SetLatestBroadcast: 'eventManagement/SET_LATEST_BROADCAST',
  LoadingLatestBroadcast: 'eventManagement/LOADING_LATEST_BROADCAST',
  PostBroadcastMessage: 'eventManagement/POST_BROADCAST_MESSAGE',
  PatchBroadcastMessage: 'eventManagement/PATCH_BROADCAST_MESSAGE',
  LoadingPatchBroadcast: 'eventManagement/LOADING_PATCH_BROADCAST',

  StartModeratorPollsDataPolling: 'eventManagement/START_MODERATOR_POLLS_DATA_POLLING',
  StopModeratorPollsDataPolling: 'eventManagement/STOP_MODERATOR_POLLS_DATA_POLLING',
  SetModeratorPolls: 'eventManagement/SET_MODERATOR_POLLS',

  PatchModeratorPoll: 'eventManagement/PATCH_MODERATOR_POLL',

  PatchSessionStatus: 'eventManagement/PATCH_SESSION_STATUS',
};
