import { createSelector } from 'reselect';

export const getPollsInfo = state => state.polls;

export const getPolls = createSelector([getPollsInfo], state => {
    return state.polls;
});
export const getLastPoll = createSelector([getPollsInfo], state => {
    return state.polls[0];
});

