import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
`;

export const Message = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;

  text-align: center;
  color: ${({ theme }) => theme.colors.regularText};
`;
