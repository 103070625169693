import { createSelector } from 'reselect';

const getRegistration = state => state.registration;

export const getUserDetails = createSelector([getRegistration], state => {
  return state.details;
});

export const getProfileData = createSelector([getRegistration], state => {
  return state.profile;
});

export const getRegistrationStep = createSelector([getRegistration], state => {
  return state.step;
});

export const getIsLoadingInfo = createSelector([getRegistration], state => {
  return state.isLoading;
});

export const getInterestsInfo = createSelector([getRegistration], state => {
  return state.interests;
});

export const getInterestsInInfo = createSelector([getRegistration], state => {
  return state.interestsIn;
});

export const getRegistrationData = createSelector([getRegistration], state => {
  return {
    details: state.details,
    profile: state.profile,
    interests: state.interests,
    bio: state.bio,
    interestsIn: state.interestsIn,
  };
});

export const getRegistrationError = createSelector([getRegistration], state => {
  return state.errorMsg;
});

export const getTicketType = createSelector([getRegistration], state => {
  return state.isFree;
});

export const getTicketPrice = createSelector([getRegistration], state => {
  return state.ticketPrice;
});
