import styled from 'styled-components';

export const NetworkingFiltersContainer = styled.div`
  height: calc(100% - 82.5px);
  overflow: auto;
  padding-bottom: 30px;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    padding-bottom: 0;
    height: 100%;
  }
`;
