import { expoPageActions } from '../../constants/actions/expoPageActions';

export const setExhibitors = data => ({
  type: expoPageActions.SetExhibitors,
  payload: data,
});

export const setIsFetchingExhibitors = data => ({
  type: expoPageActions.SetIsFetchingExhibitors,
  payload: data,
});

export const setIsBookmarkExhibitors = (exhibitorId, isBookmark) => ({
  type: expoPageActions.SetIsBookmark,
  exhibitorId,
  isBookmark,
});

//saga actions
export const getExhibitors = data => ({
  type: expoPageActions.GetExhibitors,
  payload: data,
});

export const getExhibitorsOnBookmarks = data => ({
  type: expoPageActions.GetExhibitorsOnBookmarks,
  payload: data,
});

export const bookmarkExhibitor = data => ({
  type: expoPageActions.BookmarkExhibitor,
  payload: data,
});

export const unBookmarkExhibitor = data => ({
  type: expoPageActions.UnBookmarkExhibitor,
  payload: data,
});
