import { exhibitorPageActions } from '@/constants/actions/exhibitorPageActions';

const initialState = {
  exhibitor: {},
  isFetchingExhibitor: true,
  isInactiveStand: false,
  isActiveEditHeader: false,
  isActiveEditDescription: false,
  errDescriptionMsg: '',
  isLoading: false
};

export const exhibitorPage = (state = initialState, action) => {
  switch (action.type) {
    case exhibitorPageActions.SetExhibitor:
      return {
        ...state,
        exhibitor: action.payload,
      };
    case exhibitorPageActions.SetIsLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    case exhibitorPageActions.SetErrorDescriptionMsg:
      return {
        ...state,
        errDescriptionMsg: action.payload,
      };
    case exhibitorPageActions.SetIsFetchingExhibitor:
      return {
        ...state,
        isFetchingExhibitor: action.payload,
      };
    case exhibitorPageActions.SetIsInactiveStand:
      return {
        ...state,
        isInactiveStand: action.payload,
      };
    case exhibitorPageActions.SetIsActiveEditHeader:
      return {
        ...state,
        isActiveEditHeader: action.payload,
      };
    case exhibitorPageActions.SetIsActiveEditDescription:
      return {
        ...state,
        isActiveEditDescription: action.payload,
      };
    case exhibitorPageActions.SetDescriptionVideo:
      return {
        ...state,
        exhibitor: { ...state.exhibitor, description: action.payload },
      };
    case exhibitorPageActions.SetStandVideo:
      return {
        ...state,
        exhibitor: { ...state.exhibitor, stand_video: action.payload },
      };
    case exhibitorPageActions.SetStandImage:
      return {
        ...state,
        exhibitor: { ...state.exhibitor, stand_image: action.payload },
      };
    case exhibitorPageActions.SetIsBookmarkSession:
      return {
        ...state,
        exhibitor: { ...state.exhibitor, our_sessions:  state.exhibitor.our_sessions.map(s => {
            if(s.id === action.sessionId){
              return {...s, is_bookmark: action.isBookmark}
            }
            return s
          })},
      };
    case exhibitorPageActions.SetIsBookmarkExhibitor:
      return {
        ...state,
        exhibitor: { ...state.exhibitor, is_bookmark: action.payload },
      };
    default:
      return state;
  }
};
