import styled from 'styled-components'
import { Select } from 'antd'

const { Option } = Select;

export const StyledSelect = styled(Select)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  [class^=ant-],
  [class*= ant-],
  [class^=ant-] *,
  [class*= ant-] *,
  [class^=ant-] *::before,
  [class*= ant-] *::before,
  [class^=ant-] *::after,
  [class*= ant-] *::after {
    display: flex!important;
    align-items: center!important;
  }
  .ant-select-selector {
    height: 40px!important;
    border-radius: 4px!important;
    ${({ isError}) => isError ? `
      display: flex;
      align-items: center;
      border: 1px solid #df3541!important;
      box-shadow: 0px 0px 0px 3px rgba(223, 53, 65, 0.2);
    ` : `
      border: 1px solid #cad4dc!important;
    `}
  }
  .ant-select-selection-search-input{
    font-size: 15px;
    height: 40px!important;
    &:before {
      display: flex!important;
      align-items: center!important;
    }
    &:after {
      display: flex!important;
      align-items: center!important;
    }
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    &:before {
      display: flex!important;
      align-items: center!important;
    }
    &:after {
      display: flex!important;
      align-items: center!important;
    }
  }
  .ant-select-arrow {
    ${(errors) => errors.errors ? `color: #df3541` : `color: #52595e;`}
  }
`

export const StyledOption = styled(Option)`
  height: 40px;
`

export const StyledSmall = styled.small`
  margin-top: 10px;
  font-size: 13px;
  line-height: 16px;
  color: #df3541;
`

export const StyledLabel = styled.label`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;

  color: #52595e;
`

