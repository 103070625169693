import { eventManagementActions } from '../../constants/actions/eventManagementActions';

const initialState = {
  streamStatus: null,
  streamStatusLoader: false,
  moderatorQuestions: [],
  moderatorPolls: [],
  eventStatistic: [],
  broadcastMessage: {},
  loadingBroadcastMessage: false,
  rejectedAllQuestionLoader: false,
  clearAllQuestionLoader: false,
  loadingPatchBroadcast: false,
};

export const eventManagementPage = (state = initialState, action) => {
  switch (action.type) {
    case eventManagementActions.SetModeratorQuestions:
      return {
        ...state,
        moderatorQuestions: action.payload,
      };
    case eventManagementActions.SetEventStatistic:
      return {
        ...state,
        eventStatistic: action.payload,
      };
    case eventManagementActions.RejectedAllQuestionLoader:
      return {
        ...state,
        rejectedAllQuestionLoader: action.payload,
      };
    case eventManagementActions.ClearAllQuestionLoader:
      return {
        ...state,
        clearAllQuestionLoader: action.payload,
      };
    case eventManagementActions.SetStreamStatus:
      return {
        ...state,
        streamStatus: action.payload,
      };
    case eventManagementActions.StreamStatusLoader:
      return {
        ...state,
        streamStatusLoader: action.payload,
      };
    case eventManagementActions.SetLatestBroadcast:
      return {
        ...state,
        broadcastMessage: action.payload,
      };
    case eventManagementActions.LoadingLatestBroadcast:
      return {
        ...state,
        loadingBroadcastMessage: action.payload,
      };
    case eventManagementActions.LoadingPatchBroadcast:
      return {
        ...state,
        loadingPatchBroadcast: action.payload,
      };
    case eventManagementActions.SetModeratorPolls:
      return {
        ...state,
        moderatorPolls: action.payload,
      };
    default:
      return state;
  }
};
