import { demandActions } from '@/constants/actions/demandActions';

const initialState = {
  session: null,
  eventPartners: null,
  isFetchingDemand: true
};

export const demand = (state = initialState, action) => {
  switch (action.type) {
    case demandActions.SetSession:
      return {
        ...state,
        session: action.payload,
      };
    case demandActions.SetIsFetchingDemand:
      return {
        ...state,
        isFetchingDemand: action.payload,
      };
    case demandActions.SetEventPartners:
      return {
        ...state,
        eventPartners: action.payload,
      };
    default:
      return state;
  }
};
