import styled from 'styled-components';

export const Text = styled.p`
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.regularText};
`;

export const Header = styled.h2`
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #52595e;
  margin-top: 37px;
`;

export const InfromationCardsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const InfromationCardWrapper = styled.div`
  margin-top: 30px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const ButtonFlexWrapper = styled.div`
  flex-basis: 100%;
  margin-bottom: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tablet}) {
    flex-basis: 47%;
  }
`;
