import React, { useCallback } from 'react';
import { isEqual } from 'deep-equal';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BadgeCount from '../BadgeCount';
import InvitedUsersModal from '../InvitedUsersModal';
import {
  getAcceptedUsers,
  getRejectedUsers,
  setIsAcceptUsersModal,
  setIsRejectUsersModal,
} from '@/store/dashboardPage/actions';
import {
  getAcceptedUsersInfo,
  getRejectedUsersInfo,
  getAcceptUsersModal,
  getRejectUsersModal,
} from '@/store/dashboardPage/selectors';
import { add } from '../../icons/icons';
import { acceptIcon, rejectIcon } from '../InvitedUsersModal/icons/index';
import { InvitationTitle, Header, HeaderItem, Body, BodyItem, Table } from './styles';

const InvitationCardsTable = ({ invitations, onClick }) => {
  const acceptUsersModal = useSelector(getAcceptUsersModal);
  const rejectUsersModal = useSelector(getRejectUsersModal);

  const { id } = useParams();
  const dispatch = useDispatch();

  const acceptedUsers = useSelector(getAcceptedUsersInfo);
  const rejectedUsers = useSelector(getRejectedUsersInfo);

  const headerTitles = ['Timing', 'Event', 'Ivitees', 'Accepted', 'Rejected'];

  const dateParcer = useCallback(date => {
    let parseDate = new Date(JSON.parse(`"${date}"`));
    return format(parseDate, 'dd.MM.yyyy, HH:mm');
  }, []);

  const acceptUserModalHandler = useCallback(
    invitationId => () => {
      dispatch(setIsAcceptUsersModal());
      !acceptUsersModal && dispatch(getAcceptedUsers(id, invitationId));
    },
    [id, acceptUsersModal],
  );

  const rejectUserModalHandler = useCallback(
    invitationId => () => {
      dispatch(setIsRejectUsersModal());
      !rejectUsersModal && dispatch(getRejectedUsers(id, invitationId));
    },
    [id, rejectUsersModal],
  );

  return (
    <>
      {acceptUsersModal && (
        <InvitedUsersModal
          icon={acceptIcon}
          text="Delegates who have accepted your invitation"
          userList={acceptedUsers}
          onClick={acceptUserModalHandler()}
        />
      )}
      {rejectUsersModal && (
        <InvitedUsersModal
          icon={rejectIcon}
          text="Delegates who have rejected your invitation"
          userList={rejectedUsers}
          onClick={rejectUserModalHandler()}
        />
      )}
      <Table>
        <InvitationTitle>
          invitation cards report <div onClick={onClick}>{add}</div>
        </InvitationTitle>
        <Header>
          {headerTitles.map((i, index) => (
            <HeaderItem key={index}>{i}</HeaderItem>
          ))}
        </Header>
        {invitations &&
          invitations.map((i, index) => (
            <Body key={index}>
              <BodyItem>{dateParcer(i.time)}</BodyItem>
              <BodyItem>{i.meeting_title}</BodyItem>
              <BodyItem>{i.sent_count}</BodyItem>
              <BodyItem onClick={acceptUserModalHandler(i.id)}>
                {i.accepted_count !== 0 && <BadgeCount count={i.accepted_count} color="green" />}
              </BodyItem>
              <BodyItem onClick={rejectUserModalHandler(i.id)}>
                {i.rejected_count !== 0 && <BadgeCount count={i.rejected_count} color="red" />}
              </BodyItem>
            </Body>
          ))}
      </Table>
    </>
  );
};
export default React.memo(InvitationCardsTable, isEqual);
