import React from 'react';
import { isEqual } from 'deep-equal';

import { Container, Message, Wrapper } from './styles';
import { CONTACT_EMAIl } from '@/constants/contactEmail';

const BlockedUserPage = () => {
  return (
    <Container>
      <Wrapper>
        <Message>
          You have been blocked. Please write to&nbsp;
          <a href={`mailto: ${CONTACT_EMAIl}`}>{CONTACT_EMAIl}&nbsp;</a>
          for further details
        </Message>
      </Wrapper>
    </Container>
  );
};

export default React.memo(BlockedUserPage, isEqual);
