import React, { useCallback, useState, useEffect } from 'react';
import { isEqual } from 'deep-equal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PendingSection from './components/PendingSection';
import RejectedSection from './components/RejectedSection';
import ApprovedSection from './components/ApprovedSection';
import ClearedSection from './components/ClearedSection';
import BadgeCount from '@/components/pages/Dashboard/components/BadgeCount';
import Preloader from '@/components/blocks/Preloader';
import {
  getModeratorQuestionsInfo,
  getRejectedAllQuestionLoaderInfo,
  getClearAllQuestionLoaderInfo,
} from '@/store/eventManagementPage/selectors';
import {
  startModeratorQuestionsDataPolling,
  stopModeratorQuestionsDataPolling,
  rejectedAllQuestion,
  clearAllQuestions,
} from '@/store/eventManagementPage/actions';
import {
  Container,
  QATitle,
  InteractionContainer,
  TabsContainer,
  Tab,
  BodyContainer,
  Clear,
  BadgeCountWrapper,
} from './styles';

const QASection = () => {
  const [currentTab, setCurrentTab] = useState('pending');
  const dispatch = useDispatch();
  const { id } = useParams();

  const moderatorQuestions = useSelector(getModeratorQuestionsInfo);
  const rejectedAllLoader = useSelector(getRejectedAllQuestionLoaderInfo);
  const clearAllLoader = useSelector(getClearAllQuestionLoaderInfo);

  useEffect(() => {
    dispatch(startModeratorQuestionsDataPolling(id));

    return () => dispatch(stopModeratorQuestionsDataPolling(id));
  }, [id]);

  const handleSetCurrentTab = useCallback(
    tab => () => {
      setCurrentTab(tab);
    },
    [],
  );

  const filterQuestions = useCallback((array, status, invisible) =>
    array.filter(question => question.status === status && question.invisible === invisible),
  );

  const rejectedAllQuestionsHandler = useCallback(() => {
    dispatch(rejectedAllQuestion(id));
  }, []);
  const clearAllQuestionsHandler = useCallback(() => {
    dispatch(clearAllQuestions(id));
  }, []);

  const showBtnRejectAll = !!filterQuestions(moderatorQuestions, 'pending', false)[0];
  const showBtnClearAll = !!filterQuestions(moderatorQuestions, 'approved', false)[0];

  return (
    <Container>
      <QATitle>{'Q&A'}</QATitle>
      <InteractionContainer>
        <TabsContainer>
          <Tab onClick={handleSetCurrentTab('pending')} active={currentTab === 'pending'}>
            Pending
            <BadgeCountWrapper>
              <BadgeCount
                count={filterQuestions(moderatorQuestions, 'pending', false).length}
                color="blue"
              />
            </BadgeCountWrapper>
          </Tab>
          <Tab onClick={handleSetCurrentTab('rejected')} active={currentTab === 'rejected'}>
            Rejected
          </Tab>
          <Tab onClick={handleSetCurrentTab('approved')} active={currentTab === 'approved'}>
            Approved
          </Tab>
          <Tab onClick={handleSetCurrentTab('cleared')} active={currentTab === 'cleared'}>
            Cleared
          </Tab>
        </TabsContainer>
        {clearAllLoader && <Preloader />}
        {rejectedAllLoader && <Preloader />}
        <BodyContainer>
          {currentTab === 'pending' && (
            <PendingSection
              moderatorQuestions={filterQuestions(moderatorQuestions, 'pending', false)}
            />
          )}
          {currentTab === 'rejected' && (
            <RejectedSection
              moderatorQuestions={filterQuestions(moderatorQuestions, 'rejected', false)}
            />
          )}
          {currentTab === 'approved' && (
            <ApprovedSection
              moderatorQuestions={filterQuestions(moderatorQuestions, 'approved', false)}
            />
          )}
          {currentTab === 'cleared' && (
            <ClearedSection
              moderatorQuestions={filterQuestions(moderatorQuestions, 'approved', true)}
            />
          )}
        </BodyContainer>
        {currentTab === 'pending' && showBtnRejectAll && (
          <Clear onClick={rejectedAllQuestionsHandler}>Reject all pendings questions</Clear>
        )}
        {currentTab === 'approved' && showBtnClearAll && (
          <Clear onClick={clearAllQuestionsHandler}>Clear All</Clear>
        )}
      </InteractionContainer>
    </Container>
  );
};
export default React.memo(QASection, isEqual);
