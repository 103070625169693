import { call, put, takeEvery } from 'redux-saga/effects';
import { setTicketPrice } from '../registration/actions';
import { eventRegistrationAPI } from '@/api/eventRegistrationApi';
import { RegistrationActions } from '@/constants/actions/registrationActions';

function* checkTicketPrice(action) {
  try {
    const response = yield call(eventRegistrationAPI.ticketPrice, action.payload);
    yield put(setTicketPrice(response.data));
  } catch (error) {
    console.log(error)
  }
}

export function* watchTicketPrice() {
  yield takeEvery(RegistrationActions.CheckTicketPrice, checkTicketPrice);
}
