import React, { useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import TextInput from '@/components/controls/TextInput';
import PasswordInput from '@/components/controls/PasswordInput';
import { CustomCheckbox } from '@/components/controls/Checkbox';
import { ButtonPrimary } from '@/components/controls/buttons/ButtonPrimary';
import GenericButton from '@/components/controls/buttons/GenericButton';
import { Divider } from '@/components/controls/Divider';
import styles from './styles.module.css';
import { signInSchema } from '@/components/controls/validationSchemas/signInSchema';
import { generatePlatformUrl, generateChooseTicketUrl } from '@/constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getSignInError, isLoadingInfo, onUserSignIn } from '@/store/signIn';
import { LINKEDIN_URL } from '@/constants/linkedinURL';

export const SignInForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const ChooseTicket = generateChooseTicketUrl(id);
  const PlatformRoute = generatePlatformUrl(id);

  const errorMsg = useSelector(getSignInError);
  const isLoading = useSelector(isLoadingInfo);

  const onSubmit = ({ email, password, staySigned }) => {
    dispatch(onUserSignIn({ email, password, PlatformRoute }));
  };

  const onLinkedinSignIn = useCallback(() => {
    localStorage.setItem('event_id', id);
    localStorage.setItem('signIn', 'signIn');
    window.location.href = LINKEDIN_URL;
  }, [LINKEDIN_URL]);

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          email: '',
          password: '',
          staySigned: false,
        }}
        validationSchema={signInSchema}
        validateOnChange={false}
      >
        {() => (
          <Form className={styles['sign-in-form']}>
            <Field name="email">
              {({ field, form }) => (
                <TextInput
                  {...field}
                  form={form}
                  placeholder="e.g j.smith@example.com..."
                  label="Email address"
                />
              )}
            </Field>
            <Field name="password">
              {({ field, form }) => <PasswordInput {...field} form={form} isSignInForm />}
            </Field>
            <Field name="staySigned">
              {({ field, form }) => (
                <CustomCheckbox {...field} form={form} label="Stay signed-in for 2 weeks" />
              )}
            </Field>
            <div className={styles['error-text']}>{errorMsg}</div>
            <GenericButton
              label="Sign-in"
              styleType="primary"
              type="submit"
              isLoading={isLoading}
            />
          </Form>
        )}
      </Formik>
      <Divider placeholder="Or" />
      <ButtonPrimary text="Sign-in with LinkedIn" color="#0E76A8" onClick={onLinkedinSignIn} />
      <div className={styles['sign-in-footer']}>
        <span>
          No account?<Link to={ChooseTicket}>Sign-up here</Link>
        </span>
      </div>
    </>
  );
};
