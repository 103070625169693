import styled from 'styled-components';

export const MessagesContainer = styled.div`
  height: 100%;
`;

export const Body = styled.div`
  max-height: calc(100% - 90px);
  height: calc(100% - 90px);
  padding: 15px 30px 0 30px;
  overflow: auto;
`;

export const Controls = styled.div`
  height: 70px;
  padding: 15px 30px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  box-sizing: border-box;
`;

export const MessagesSection = styled.div`
  padding-top: 15px;
`;

export const SectionTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  text-transform: uppercase;
`;

export const ContactCardWrapper = styled.div`
  padding: 15px;
  display: flex;
  cursor: pointer;
`;

export const UnreadMessagesCounter = styled.div`
  align-self: center;
  width: 28px;
  height: 19px;
  background: ${({ theme }) => theme.colors.alert};
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.originWhite};
  text-align: center;
  font-size: 12px;
  font-weight: 400;
`;
