import * as Yup from 'yup';

export const signUpSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('This is a required field')
    .min(2, 'Must be at least 2 characters, maximum 50 characters long')
    .max(50, 'Must be at least 5 characters, maximum 50 characters long'),
  last_name: Yup.string()
    .required('This is a required field')
    .min(2, 'Must be at least 2 characters, maximum 50 characters long')
    .max(50, 'Must be at least 5 characters, maximum 50 characters long'),
  email: Yup.string().required('This is a required field').email('Not a valid email address!'),
  password: Yup.string()
    .required('This is a required field')
    .matches(
      /^(?=.*\d)[a-zA-Z-!"§$%&/()=?+*~#'_:.,;\d]{8,30}$/,
      'The password must be at least 8 characters long, maximum 30 characters long and contain at least one number',
    ),
  recaptcha: Yup.string().required('Recaptha required'),
});
