import React from 'react';
import { isEqual } from 'deep-equal';
import OnePartner from '../OnePartner';
import {
  PartnersTitle,
  PartnersTitleLineText,
  PartnersTitleLine,
  PartnerGroupContainer,
  PartnersList,
  PartnerContainer,
} from './styles';

const PartnersGroup = ({ partnersGroup, isOnLivestream, isOnDemandPage }) => {
  return (
    <PartnerGroupContainer isOnDemandPage={isOnDemandPage}>
      <PartnersTitle isOnLivestream={isOnLivestream}>
        {!isOnLivestream && <PartnersTitleLine />}
        <PartnersTitleLineText isOnLivestream={isOnLivestream}>
          {partnersGroup.title}
        </PartnersTitleLineText>
        <PartnersTitleLine />
      </PartnersTitle>
      <PartnersList isOnLivestream={isOnLivestream}>
        {partnersGroup.partners.map(partner => (
          <PartnerContainer isOnLivestream={isOnLivestream} key={partner.id}>
            <OnePartner partner={partner} isOnLivestream={isOnLivestream} />
          </PartnerContainer>
        ))}
      </PartnersList>
    </PartnerGroupContainer>
  );
};

export default React.memo(PartnersGroup, isEqual);
