import { createSelector } from 'reselect';

export const getEmailApprovePageInfo = state => state.emailApprovePage;

export const getIsEmailApprove = createSelector([getEmailApprovePageInfo], state => {
  return state.isEmailApprove;
});

export const getEmailApproveErrMsg = createSelector([getEmailApprovePageInfo], state => {
  return state.emailApproveErrMsg;
});
