import React from 'react';

export const icoTwitter = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M20 3.79746C19.2642 4.12412 18.4733 4.34412 17.6433 4.44329C18.4908 3.93579 19.1417 3.13162 19.4475 2.17329C18.655 2.64329 17.7767 2.98496 16.8417 3.16912C16.0942 2.37162 15.0267 1.87329 13.8467 1.87329C11.1975 1.87329 9.25083 4.34496 9.84917 6.91079C6.44 6.73996 3.41667 5.10662 1.3925 2.62412C0.3175 4.46829 0.835 6.88079 2.66167 8.10246C1.99 8.08079 1.35667 7.89662 0.804167 7.58912C0.759167 9.48996 2.12167 11.2683 4.095 11.6641C3.5175 11.8208 2.885 11.8575 2.24167 11.7341C2.76333 13.3641 4.27833 14.55 6.075 14.5833C4.35 15.9358 2.17667 16.54 0 16.2833C1.81583 17.4475 3.97333 18.1266 6.29 18.1266C13.9083 18.1266 18.2125 11.6925 17.9525 5.92162C18.7542 5.34246 19.45 4.61996 20 3.79746Z"
        fill="#00ACEE"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const icoLinkedin = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.15 2.91659C4.15 4.06742 3.225 4.99992 2.08333 4.99992C0.941667 4.99992 0.0166667 4.06742 0.0166667 2.91659C0.0166667 1.76659 0.941667 0.833252 2.08333 0.833252C3.225 0.833252 4.15 1.76659 4.15 2.91659ZM4.16667 6.66658H0V19.9999H4.16667V6.66658ZM10.8183 6.66658H6.67833V19.9999H10.8192V13.0008C10.8192 9.10909 15.8433 8.79075 15.8433 13.0008V19.9999H20V11.5574C20 4.99075 12.565 5.22992 10.8183 8.46242V6.66658Z"
      fill="#2867B2"
    />
  </svg>
);
