import { call, takeEvery, put } from 'redux-saga/effects';
import { expoAPI } from '@/api/expoAPI';
import { expoPageActions } from '@/constants/actions/expoPageActions';
import { setExhibitors, setIsFetchingExhibitors, setIsBookmarkExhibitors } from './actions';
import { setAreBookmarksLoading } from '@/store/interactionPanel/actions';
import { setIsFetchingExhibitor, setIsInactiveStand } from '../exhibitorPage';

function* getExhibitorsOnBookmarks(action) {
  try {
    const response = yield call(expoAPI.getExhibitors, action.payload);
    yield put(setExhibitors(response.data));
    yield put(setAreBookmarksLoading(false));
  } catch (error) {
    console.log(error);
  }
}

function* getExhibitors(action) {
  try {
    yield put(setIsFetchingExhibitor(true));
    const response = yield call(expoAPI.getExhibitors, action.payload);
    yield put(setExhibitors(response.data));
    yield put(setIsFetchingExhibitors(false));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(setIsInactiveStand(false));
  }
}

function* bookmarkExhibitor({ payload: { exhibitorId, isBookmark } }) {
  try {
    yield put(setIsBookmarkExhibitors(exhibitorId, isBookmark));
    yield call(expoAPI.bookmarkExhibitor, exhibitorId);
  } catch (error) {
    yield put(setIsBookmarkExhibitors(exhibitorId, false));
    console.log(error);
  }
}

function* unBookmarkExhibitor({ payload: { exhibitorId, isBookmark } }) {
  try {
    yield put(setIsBookmarkExhibitors(exhibitorId, isBookmark));
    yield call(expoAPI.unBookmarkExhibitor, exhibitorId);
  } catch (error) {
    yield put(setIsBookmarkExhibitors(exhibitorId, true));
    console.log(error);
  }
}

export function* watchExpo() {
  yield takeEvery(expoPageActions.GetExhibitors, getExhibitors);
  yield takeEvery(expoPageActions.GetExhibitorsOnBookmarks, getExhibitorsOnBookmarks);
  yield takeEvery(expoPageActions.BookmarkExhibitor, bookmarkExhibitor);
  yield takeEvery(expoPageActions.UnBookmarkExhibitor, unBookmarkExhibitor);
}
