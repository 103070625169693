export const reduceDelegateData = ({
  id, 
  headshot, 
  first_name, 
  last_name, 
  organisation, 
  organisation_type, 
  role, 
  job_title,
  online,
}) => { 
  return {
    id: `${id}`, 
    headshot, 
    first_name, 
    last_name, 
    organisation, 
    organisation_type, 
    role, 
    job_title,
    online,
  };
}