import axios from 'axios';
import { baseURL } from '@/constants/baseURL';
import { responseHandlerInterceptor, sendTokenInterceptor } from '../helpers/interceptors';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

instance.interceptors.request.use(request => {
  return sendTokenInterceptor(request);
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return responseHandlerInterceptor(error, instance);
  },
);

export const interactionPanelApi = {
  getAllDialogs: event_pk => instance.get(`/events/event/${event_pk}/chat/`),

  getAllMessagesByDialogId: (event_pk, currentDialogId) =>
    instance.get(`/events/event/${event_pk}/chat/${currentDialogId}/`),

  sendMessage: (event_pk, message) => instance.post(`/events/event/${event_pk}/chat/`, message),

  getAllFavoriteDelegates: event_pk => instance.get(`/events/event/${event_pk}/favorite-delegate/`),

  getAllFavoriteSessions: event_pk => instance.get(`/events/event/${event_pk}/favorite-session/`),

  getAllFavoriteExhibitors: event_pk =>
    instance.get(`/events/exhibitor/${event_pk}/my-favorites-exhibitors/`),

  createOrGetChat: (event_pk, recipient) =>
    instance.post(`/events/event/${event_pk}/chat/get-chat/`, { recipient }),

  getAllInvitations: event_pk => instance.get(`/events/event/${event_pk}/invitation/`),

  getAllQuestions: event_pk => instance.get(`/events/event/${event_pk}/question/`),

  sendQuestion: (event_pk, question) =>
    instance.post(`/events/event/${event_pk}/question/`, question),

  getUserNonActiveQuestions: event_pk =>
    instance.get(`/events/event/${event_pk}/question/delegate-questions/`),

  sendInvitationStatus: (event_pk, invitation_pk, status) =>
    instance.patch(
      `/events/event/${event_pk}/invitation-delegate/answer-to-invite/${invitation_pk}/`,
      { status },
    ),
};
