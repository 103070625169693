import { call, put, takeEvery } from 'redux-saga/effects';
import { eventRegistrationAPI } from '@/api/eventRegistrationApi';
import { StripeActions } from '@/constants/actions/stripe';
import { addClientSecret, setCheckTicket } from './actions';

function* signInUser(action) {
  try {
    const response = yield call(eventRegistrationAPI.buyTicket, {
      "currency": action.payload.currency.toLowerCase()
    }, action.payload.event_pk);
    yield put(addClientSecret(response.data))
  } catch (error) {
    console.log(error)
  }
}

function* ticketConfirmation(action) {
  try {
    const response = yield call(eventRegistrationAPI.confirmTicket, {
      pi_token: action.payload.pi_token,
    }, action.payload.event_pk);
    yield put(setCheckTicket(response.data))
    yield sessionStorage.removeItem('ticketType')
    yield sessionStorage.removeItem('promocode')
    yield sessionStorage.removeItem('promocodeStatus')
  } catch (error) {
    console.log(error)
  }
}

export function* watchBuyTicket() {
  yield takeEvery(StripeActions.OnBuyTicket, signInUser);
  yield takeEvery(StripeActions.CheckTicket, ticketConfirmation);
}
