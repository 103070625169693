import styled from 'styled-components';

export const CardContainer = styled.div`
  background: ${({ theme }) => theme.colors.backgroundDownloadCard};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 30px 15px;
`;
export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const DownloadIco = styled.div`
  top: 35px;
  left: 18px;
  width: 35px;
  height: 35px;
  :hover {
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease, color 0.2s linear;
  }
`;
export const Title = styled.div`
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.originBlack};
  margin-left: 20px;
`;
export const Count = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.greenHaze};
  margin-left: 50px;
`;
export const SubTitle = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  margin: 0px 35px 0px 55px;
`;
