import { AgendaActions } from '@/constants/actions/agendaActions';

export const setAgendaData = data => ({
  type: AgendaActions.setAgendaData,
  payload: data,
});

export const setShowAgendaLoader = data => ({
  type: AgendaActions.setAgendaLoader,
  payload: data,
});

export const setIsBookmarkAgendaSession = (sessionId, isBookmark) => ({
  type: AgendaActions.SetIsBookmark,
  sessionId,
  isBookmark,
});

//saga actions
export const pullAgendaData = data => ({
  type: AgendaActions.OnPullAgendaData,
  payload: data,
});

export const pullAgendaDataOnBookmarks = data => ({
  type: AgendaActions.PullAgendaDataOnBookmarks,
  payload: data,
});

export const bookmarkAgendaSessions = data => ({
  type: AgendaActions.BookmarkSessions,
  payload: data,
});

export const unBookmarkAgendaSessions = data => ({
  type: AgendaActions.UnBookmarkSessions,
  payload: data,
});
