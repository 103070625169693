import { createSelector } from 'reselect';

const getLandingData = state => state.landingData;

export const getEvent = createSelector([getLandingData], state => {
  return state.event;
});

export const getEventSpeakers = createSelector([getLandingData], state => {
  return state.speakers;
});

export const getEventSponsor = createSelector([getLandingData], state => {
  return state.sponsors;
});

export const getEventAgenda = createSelector([getLandingData], state => {
  return state.agenda;
});