import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'deep-equal';
import { useParams } from 'react-router-dom';
import RadioButtonRow from '../RadioButtonRow';
import { patchSessionStatus } from '@/store/eventManagementPage/actions';
import { CardContainer, InfoBlock, Session, Time, Speakers, StatusBlock, Status } from './styles';

const AgendaSessionCard = ({ type, session, time, name, status, id, activeDay, holdOn }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState({ [id]: false });
  const { id: eventPk } = useParams();

  const styleDefinition = (type = {}) => {
    if (type === 'danger') {
      return { bg: 'danger', textColor: false, online: false, visible: false };
    } else if (type === 'black') {
      return { bg: 'black', textColor: true, online: true, visible: false };
    } else if (type === 'grey') {
      return { bg: 'grey', textColor: false, online: false, visible: true };
    }
  };

  const style = styleDefinition(type);

  const switchBtnHandler = useCallback(() => {
    setLoading({ [id]: true });
    const dataSession = holdOn
      ? {
          hold_on: false,
        }
      : {
          hold_on: true,
        };
    dispatch(patchSessionStatus(activeDay, id, dataSession, eventPk));
  }, [dispatch, activeDay, id, holdOn]);

  useEffect(() => {
    setLoading({ [id]: false });
  }, [holdOn]);

  return (
    <CardContainer bgColor={style.bg} textColor={style.textColor}>
      <InfoBlock>
        <Session>{session}</Session>
        <Time>{time}</Time>
        <Speakers>{name}</Speakers>
      </InfoBlock>
      <StatusBlock visible={style.visible}>
        <Status online={style.online}>{status}</Status>
        <RadioButtonRow
          textActive="Hold"
          textInactive="Unhold"
          onClick={switchBtnHandler}
          isActive={holdOn}
          isLoading={loading[id]}
        />
      </StatusBlock>
    </CardContainer>
  );
};
export default React.memo(AgendaSessionCard, isEqual);
