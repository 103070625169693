import * as Yup from 'yup';

export const eventProfileSchema = Yup.object().shape({
  job_title: Yup.string()
    .required('This is a required field')
    .min(5, 'Must be at least 5 characters, maximum 50 characters long')
    .max(50, 'Must be at least 5 characters, maximum 50 characters long'),
  role: Yup.string().required('This is a required field'),
  organisation: Yup.string()
    .required('This is a required field')
    .min(3, 'Must be at least 3 characters, maximum 50 characters long')
    .max(50, 'Must be at least 5 characters, maximum 50 characters long'),
  organisation_type: Yup.string().required('This is a required field'),
  country: Yup.string().required('This is a required field'),
  phone: Yup.string().min(5, 'Must be at least 5 characters, maximum 30 characters long').matches(
    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
    'Invalid phone number',
  ),
  is_marketing_allowed: Yup.boolean(),
});
