import styled from 'styled-components';

export const DemandPageContainer = styled.div`
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
`;

export const DemandPageVideo = styled.div`
  width: 100%;
  margin-bottom: -30px;
  padding: 0 30px;
`;

export const PartnersContainer = styled.div`
  margin-top: 45px;
`;

export const PartnersTitle = styled.div`
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  padding: 0 30px;
`;

export const SessionContainer = styled.div`
  padding: 0 30px;
`;
