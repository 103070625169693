import React from 'react';
import styled from 'styled-components';
import Bookmark from './icons/Bookmark';

export const MicroAgendaSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid
    ${({ isNowLive, theme }) =>
      isNowLive ? theme.colors.agenda.liveBorder : theme.colors.cards.border};
  box-sizing: border-box;
  height: ${({ isWithSpeakers }) => (isWithSpeakers ? '111px' : '90px')};
  padding: ${({ isNowLive }) => isNowLive && '20px 0 20px 15px'};
  background: ${({ isNowLive, theme }) =>
    isNowLive ? theme.colors.agenda.liveBackground : theme.colors.originWhite};
`;

export const PanelDiscussion = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PanelInformation = styled.div`
  display: flex;
  align-items: center;
`;

export const OrganisationLogo = styled.div`
  width: 60px;
  height: 60px;
  border: ${({ theme }) => `1px solid ${theme.colors.logoBorder}`};
  border-radius: 4px;
`;

export const OrganisationName = styled.div`
  padding-left: 15px;
  font-size: 15px;
  font-weight: bold;
`;

export const BookmarkSection = styled.div`
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  background: ${({ theme }) => theme.colors.cards.buttonBackground};
  height: 100%;
  > img {
    cursor: pointer;
  }
`;

export const PanelMain = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 15px 15px;
`;

export const PanelSpan = styled.span`
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: ${({ isNowLive, theme }) =>
    isNowLive ? theme.colors.backgroundDownloadCard : theme.colors.regularText};
  margin-bottom: 5px;

  &:nth-child(1) {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${({ isNowLive, theme }) =>
      isNowLive ? theme.colors.logoBorder : theme.colors.fieldsSummaryCardTitleGrey};
  }
  &:nth-child(3) {
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;
    color: ${({ isNowLive, theme }) =>
      isNowLive ? theme.colors.logoBorder : theme.colors.fieldsSummaryCardTitleGrey};
  }
`;

export const UpNextSection = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  min-width: 66px;
`;

export const UpNextSpan = styled.span`
  font-size: 12px;
  line-height: 15px;
  margin-left: 5px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.regularText};
`;

export const NowLiveSection = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  min-width: 73px;
`;

export const NowLiveSpan = styled(UpNextSpan)`
  color: ${({ theme }) => theme.colors.backgroundDownloadCard};
`;

const BookmarkIconContainer = styled.div`
  fill: ${({ theme, colorSchema }) => theme.colors.agenda[colorSchema].iconOutline};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BookmarkIcon = props => (
  <BookmarkIconContainer colorSchema={props.colorSchema}>
    <Bookmark {...props} />
  </BookmarkIconContainer>
);
