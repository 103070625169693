import React from 'react';
import { isEqual } from 'deep-equal';
import {
  Message,
  MessageAuthor,
  MessageText,
} from '@/components/blocks/InteractionPanel/components/Questions/styles';
import ContactCard from "@/components/blocks/ContactCard/component";

const EscalatedQuestion = ({question, delegate_info: delegate}) => {
  return (
    <>
      <Message>
        <MessageText>{question}</MessageText>
        <MessageAuthor withBorderTop>
          <ContactCard
            delegateId={delegate.id}
            name={`${delegate.first_name} ${delegate.last_name}`}
            organisation={delegate.organisation}
            jobRole={delegate.job_title}
            photo={delegate.headshot}
          />
        </MessageAuthor>
      </Message>
    </>
  );
};

export default React.memo(EscalatedQuestion, isEqual);
