import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { isEqual } from 'deep-equal'
import { history } from '@/store/rootReducer'
import { generateChooseTicketUrl } from '@/constants/routes'
import { RegistrationBlockContainer, Title, Button, ButtonText } from './styles'


const RegisterBlock = props => {
  const { id } = useParams()

  const chooseTicketRoute = generateChooseTicketUrl(id)

  const goToRegistration = useCallback(() => {
    history.push(chooseTicketRoute)
  }, [chooseTicketRoute])

  return (
    <RegistrationBlockContainer>
      <Title>
        Plus unique opportunities to network with thought leaders and decision makers
      </Title>
      <Button type="register" onClick={goToRegistration}>
        <ButtonText>
          Register
        </ButtonText>
      </Button>
    </RegistrationBlockContainer>
  )
}

export default React.memo(RegisterBlock, isEqual)
