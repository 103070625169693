import styled from 'styled-components';

export const Header = styled.div`
  padding: 15px 30px 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const User = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;

    & > span {
      text-decoration: underline;
    }
  }
`;

export const Name = styled.span`
  display: block;
  margin: 0 10px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
`;

export const Avatar = styled.div`
  min-width: 40px;
  min-height: 40px;
  background: black;
  border-radius: 50%;
`;
