import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 5px;
`;

export const Date = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.agenda.dayDelimiter.date};
  display: flex;
  align-items: center;
  padding-right: 15px;
`;

export const LineContainer = styled.div`
  flex-grow: 1;
`;
