import axios from 'axios';
import { baseURL } from '@/constants/baseURL';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

export const linkedinAPI = {
  linkedinUp(linkedinToken, clientId, grant_type = 'convert_token', backend = 'linkedin-oauth2') {
    return instance.post(
      `/linkedin/convert-token/?token=${linkedinToken}&grant_type=${grant_type}&backend=${backend}&client_id=${clientId}`,
    );
  },
  linkedinAuth(linkedinToken, redirectUri, grant_type = 'authorization_code') {
    return instance.get(
      `/linkedin/get-access-token?grant_type=${grant_type}&code=${linkedinToken}&redirect_uri=${redirectUri}`,
    );
  },
};
