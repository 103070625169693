import { profilePageActions } from '@/constants/actions/profilePageActions';

const initialState = {
  profile: {},
  eventInterests: [],
  isFetchingProfile: true,
  isActiveEditSocialLinks: false,
  isEditSocialLinksLoading: false,
  editSocialLinksError: '',
  isActiveEditBiography: false,
  isActiveEditInterests: false,
  biographyErrMsg: ''
};

export const profilePage = (state = initialState, action) => {
  switch (action.type) {
    case profilePageActions.SetProfile:
      return {
        ...state,
        profile: action.payload,
      };
    case profilePageActions.SetBiographyErrorMsg:
      return {
        ...state,
        biographyErrMsg: action.payload,
      };
    case profilePageActions.SetIsFetchingProfile:
      return {
        ...state,
        isFetchingProfile: action.payload,
      };
    
    case profilePageActions.SetIsActiveEditSocialLinks:
      return {
        ...state,
        isActiveEditSocialLinks: action.payload,
      };
    case profilePageActions.SetUpdateSocialLinksLoading:
      return {
        ...state,
        isEditSocialLinksLoading: action.payload,
      };
    case profilePageActions.SetUpdateSocialLinksError:
      return {
        ...state,
        editSocialLinksError: action.payload,
      };

    case profilePageActions.SetIsActiveEditBiography:
      return {
        ...state,
        isActiveEditBiography: action.payload,
      };
    case profilePageActions.SetIsActiveEditInterests:
      return {
        ...state,
        isActiveEditInterests: action.payload,
      };
    case profilePageActions.SetEventInterests:
      return {
        ...state,
        eventInterests: action.payload,
      };
    case profilePageActions.SetSocialLinks:
      return {
        ...state,
        profile: {
          ...state.profile,
          linkedin_url: action.linkedinURL,
          twitter_url: action.twitterURL,
        },
      };
    case profilePageActions.SetIsMarketingAllowed:
      return {
        ...state,
        profile: {
          ...state.profile,
          is_marketing_allowed: action.payload,
        },
      };
    case profilePageActions.SetIsAnonymous:
      return {
        ...state,
        profile: {
          ...state.profile,
          is_anonymous: action.payload,
        },
      };
    case profilePageActions.SetBiography:
      return {
        ...state,
        profile: {
          ...state.profile,
          biography: action.payload,
        },
      };
    case profilePageActions.SetIsBookmarkDelegate:
      return {
        ...state,
        profile: {
          ...state.profile,
          is_bookmark: action.payload,
        },
      };
    case profilePageActions.SetIsBookmarkSession:
      return {
        ...state,
        profile: {
          ...state.profile,
          session_set: state.profile.session_set.map(s => {
            if (s.id === action.sessionId) {
              return { ...s, is_bookmark: action.isBookmark };
            }
            return s;
          }),
        },
      };
    case profilePageActions.SetIsBookmarkExhibitor:
      return {
        ...state,
        profile: {
          ...state.profile,
          exhibitor_data: { ...state.profile.exhibitor_data, is_bookmark: action.payload}
        },
      };
    case profilePageActions.SetInterests:
      return {
        ...state,
        profile: {
          ...state.profile,
          interests_set: action.payload
            .map(i => {
              return { id: i.interest, name: i.name };
            })
            .sort((prev, next) => prev.id - next.id),
        },
      };
    default:
      return state;
  }
};
