import { createSelector } from 'reselect';

export const getDashboardPageValues = state => state.dashboardPage;

export const getDelegatesInfo = createSelector([getDashboardPageValues], state => {
  return state.delegates;
});

export const getIsFetchingDelegates = createSelector([getDashboardPageValues], state => {
  return state.isFetchingDelegates;
});

export const getSelectedDelegates = createSelector([getDashboardPageValues], state => {
  return state.selectedDelegates;
});

export const getBookmarkedInfo = createSelector([getDashboardPageValues], state => {
  return state.bookmarked;
});

export const getAcceptUsersModal = createSelector([getDashboardPageValues], state => {
  return state.acceptUsersModal;
});

export const getRejectUsersModal = createSelector([getDashboardPageValues], state => {
  return state.rejectUsersModal;
});

export const getIsFetchingInvitation = createSelector([getDashboardPageValues], state => {
  return state.isFetchingInvitation;
});

export const getIsSuccessInvitation = createSelector([getDashboardPageValues], state => {
  return state.isSuccessInvitation;
});

export const getErrorMsg = createSelector([getDashboardPageValues], state => {
  return state.errorMsg;
});

export const getIsFetchingBookmarked = createSelector([getDashboardPageValues], state => {
  return state.isFetchingBookmarked;
});

export const getExibitorDetail = createSelector([getDashboardPageValues], state => {
  return state.exhibitorData;
});

export const getStatisticVisitorsInfo = createSelector([getDashboardPageValues], state => {
  return state.statisticVisitors;
});

export const getAcceptedUsersInfo = createSelector([getDashboardPageValues], state => {
  return state.acceptedUsers;
});

export const getRejectedUsersInfo = createSelector([getDashboardPageValues], state => {
  return state.rejectedUsers;
});

export const getExhibitorStandCsvInfo = createSelector([getDashboardPageValues], state => {
  return state.standCsv;
});
