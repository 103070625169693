import React from 'react';
import styled from 'styled-components';
import OnDemand from './components/OnDemandLabel';
import NowLive from './components/NowLiveLabel';
import Bookmark from './icons/Bookmark';
import Share from './icons/Share';

export const CardWrapper = styled.div`
  background-color: ${({ theme, colorSchema }) => theme.colors.agenda[colorSchema].background};
  padding: 30px;
  
`;

export const CardBodySection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardContentSpeakersSection = styled.div`
  flex-grow: 1;
  word-break: break-all;
`;

export const CardContentVideoSection = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.landscape.phone}) {
    flex-direction: column;
  }
`;

export const LiveStreamCardContentVideoSection = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: ${({ isBreak }) => (isBreak ? '0px' : '30px')};
  &:after {
    content: ' ';
    display: ${({ isBreak }) => (isBreak ? 'none' : 'block')};
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.textDescriptionDarkGrey};
    margin: 0 -30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.landscape.phone}) {
    flex-direction: column;
  }
`;

export const CardContent = styled.div`
  ${({ isOnDemand }) => isOnDemand && 'padding-right: 15px;'}
`;

export const CardThumbnailContainerRigth = styled.div`
  width: 30%;
  flex-shrink: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.landscape.tablet}) {
    display: none;
  }
`;

export const CardThumbnailContainerTopRigth = styled.div`
  width: 30%;
  flex-shrink: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.landscape.phone}) {
    padding-top: 15px;
    width: 100%;
  }
`;

export const CardHeaderSection = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  line-height: 130%;
  color: ${({ theme, colorSchema }) => theme.colors.agenda[colorSchema].title};
  display: block;
  margin-bottom: 15px;
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme, colorSchema }) => theme.colors.agenda[colorSchema].description};
`;

const NowLiveLabelContainer = styled.div`
  padding-right: 15px;
  display: flex;
  align-items: center;
`;

export const NowLiveLabel = props => (
  <NowLiveLabelContainer>
    <NowLive {...props} />
  </NowLiveLabelContainer>
);

const OnDemandContainer = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.agenda.onDemandLabel};
  padding-right: 15px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

export const DemandLabel = styled.span`
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.tabBorder};
`;

export const OnDemandLabel = props => (
  <OnDemandContainer>
    <OnDemand {...props} />
  </OnDemandContainer>
);

export const Time = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme, colorSchema }) => theme.colors.agenda[colorSchema].timeLabel};
  padding-right: 10px;
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const BookmarkIconContainer = styled.div`
  fill: ${({ theme, colorSchema }) => theme.colors.agenda[colorSchema].iconOutline};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ShareIconContainer = styled.div`
  fill: ${({ theme, colorSchema }) => theme.colors.agenda[colorSchema].iconOutline};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const BookmarkIcon = props => (
  <BookmarkIconContainer colorSchema={props.colorSchema}>
    <Bookmark {...props} />
  </BookmarkIconContainer>
);

export const ShareIcon = props => (
  <ShareIconContainer colorSchema={props.colorSchema} id={'shareIcon'}>
    <Share {...props} />
  </ShareIconContainer>
);

export const SpeakerRole = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme, colorSchema }) => theme.colors.agenda[colorSchema].speakerRole};
  padding-left: 51px;
  margin-bottom: 5px;
`;

export const SpeakerWrapper = styled.div`
  flex-basis: ${({ isOnDemand }) => (isOnDemand ? 'calc(100% / 2 - 5px)' : 'calc(100% / 3 - 5px)')};
  margin-top: 17px;
  margin-right: 5px;

  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.landscape.tablet}) {
    flex-basis: calc(100% / 2 - 5px);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.landscape.phone}) {
    flex-basis: 100%;
  }
`;

export const SpeakersSectionWrapper = styled.div`
  margin-right: -5px;
`;

export const SpeakersSection = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    ${({ isOnDemand }) => isOnDemand && 'padding-right: 15px;'}
  }
`;
