import React, { useCallback, useState, useEffect } from 'react';
import { isEqual } from 'deep-equal';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import AgendaSessionCard from '@/components/pages/EventManagement/components/AgendaSessionCard';
import { getLiveSessionsInfo } from '@/store/sessions/selectors';
import { AgendaTitle, AgendaInfo, AgendaSection, Message } from './styles';

const Agenda = () => {
  const { last_finished, current_sessions, remaining_sessions, active_day } = useSelector(
    getLiveSessionsInfo,
  );

  const [compareId, setCompareId] = useState(true);
  const finishedSession = Boolean(last_finished);
  const currentSession = Boolean(current_sessions);
  const remainingSession = Boolean(remaining_sessions[0]);
  const allSessions = finishedSession || currentSession || remainingSession;

  useEffect(() => {
    if (currentSession && finishedSession) {
      setCompareId(last_finished.id !== current_sessions.id);
    }
    return () => setCompareId(true);
  }, [current_sessions, last_finished]);

  const timeCreator = useCallback((startTime, endTime) => {
    const start = format(new Date(JSON.parse(`"${startTime}"`)), 'HH:mm');
    const end = format(new Date(JSON.parse(`"${endTime}"`)), 'HH:mm');
    return `${start} - ${end}`;
  }, []);

  const speakersListCreator = useCallback(
    arr =>
      arr
        .map(speaker => ` ${speaker.first_name} ${speaker.last_name}`)
        .join(',')
        .toString()
        .trim(),
    [],
  );

  return (
    <AgendaSection>
      <AgendaTitle>Agenda</AgendaTitle>
      <AgendaInfo message={allSessions}>
        {allSessions ? (
          <>
            {finishedSession && compareId && (
              <AgendaSessionCard
                id={last_finished.id}
                type="danger"
                session={last_finished.title}
                time={timeCreator(last_finished.start_time, last_finished.end_time)}
                name={speakersListCreator(last_finished.speakers_set)}
                activeDay={active_day}
                status="Finished"
                holdOn={last_finished.hold_on}
              />
            )}

            {currentSession && (
              <AgendaSessionCard
                id={current_sessions.id}
                type="black"
                session={current_sessions.title}
                time={timeCreator(current_sessions.start_time, current_sessions.end_time)}
                name={speakersListCreator(current_sessions.speakers_set)}
                activeDay={active_day}
                status="Now Live"
                holdOn={current_sessions.hold_on}
              />
            )}

            {remaining_sessions.map((remainingSessions, index) => (
              <AgendaSessionCard
                id={remainingSessions.id}
                key={index}
                type="grey"
                activeDay={active_day}
                session={remainingSessions.title}
                time={timeCreator(remainingSessions.start_time, remainingSessions.end_time)}
                name={speakersListCreator(remainingSessions.speakers_set)}
              />
            ))}
          </>
        ) : (
          <Message>No sessions scheduled for today</Message>
        )}
      </AgendaInfo>
    </AgendaSection>
  );
};

export default React.memo(Agenda, isEqual);
