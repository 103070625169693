import { createSelector } from 'reselect';

export const getEscalatedQuestionsPageInfo = state => state.escalatedQuestions;

export const getEscalatedQuestionsInfo = createSelector([getEscalatedQuestionsPageInfo], state => {
  return state.escalatedQuestions;
});

export const getIsFetchingQuestions = createSelector([getEscalatedQuestionsPageInfo], state => {
  return state.isFetchingQuestions;
});

export const getEscalatedSignInErrMsg = createSelector([getEscalatedQuestionsPageInfo], state => {
  return state.escalatedSignInErrMsg;
});

export const getIsLoadingSignIn = createSelector([getEscalatedQuestionsPageInfo], state => {
  return state.isLoadingSignIn;
});
