import { SpinnerActions } from '@/constants/actions/spinner'

const initialState = false

export const spinner = (state = initialState, action) => {
  switch (action.type) {
    case SpinnerActions.SetSpinnerValue:
      return action.payload
    default:
      return state;
  }
};