import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { isEqual } from 'deep-equal'
import { history } from '@/store/rootReducer'
import { generateChooseTicketUrl } from '@/constants/routes'
import {
  ContentContainer,
  ContentImage,
  ContentInfo,
  ContentMainInfo,
  Button,
  ButtonText,
} from './styles'


const SecondContentBlock = ({ event }) => {
  const { id } = useParams()
  const chooseTicketRoute = generateChooseTicketUrl(id)

  const goToRegistration = useCallback(() => {
    history.push(chooseTicketRoute)
  }, [chooseTicketRoute])

  const info = event.additional_info1

  return (
    <ContentContainer>
      <ContentImage src={event.additional_image1} />
      <ContentInfo>
        <ContentMainInfo dangerouslySetInnerHTML={{ __html: info }} />
        <Button type="register" onClick={goToRegistration}>
          <ButtonText>
            Register
          </ButtonText>
        </Button>
      </ContentInfo>
    </ContentContainer>
  )
}

export default React.memo(SecondContentBlock, isEqual)
