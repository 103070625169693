import { call, put, takeEvery, delay, take, race } from 'redux-saga/effects';
import ApexCharts from 'apexcharts';
import { eventManagementActions } from '@/constants/actions/eventManagementActions';
import { eventManagementApi } from '../../api/eventManagementApi';
import { sessionsAPI } from '@/api/sessionsAPI';
import { setLiveSession } from '../sessions/actions';
import {
  setEventStatistic,
  setModeratorQuestionsData,
  clearAllQuestionLoader,
  rejectedAllQuestionLoader,
  setStreamStatus,
  streamStatusLoader,
  setLatestBroadcast,
  loadingLatestBroadcast,
  loadingPatchBroadcast,
  setModeratorPolls,
} from './actions';
import {
  GET_EVENT_STATISTIC_DELAY,
  GET_ADMIN_POLLS_DELAY,
  GET_ADMIN_QA_DELAY,
} from '@/constants/longPollingDelay';

function* getModeratorQuestions(action) {
  try {
    const response = yield call(eventManagementApi.getModeratorQuestions, action.payload);
    yield put(setModeratorQuestionsData(response.data));
  } catch (error) {
    console.log(error);
    return;
  }
}

function* getModeratorQuestionsSaga(action) {
  while (true) {
    try {
      yield getModeratorQuestions(action);
      yield delay(GET_ADMIN_QA_DELAY);
    } catch (error) {
      console.log(error);
      return;
    }
  }
}

export function* watchModeratorQuestionsSaga() {
  while (true) {
    const event_pk = yield take(eventManagementActions.StartModeratorQuestionsDataPolling);
    yield race([
      call(getModeratorQuestionsSaga, event_pk),
      take(eventManagementActions.StopModeratorQuestionsDataPolling),
    ]);
  }
}

// Chart

function* getEventStatistic(action) {
  while (true) {
    try {
      const response = yield call(eventManagementApi.getStatisticEvent, action.payload);
      yield put(setEventStatistic(response.data));
      yield ApexCharts.exec(
        'realtime-statistics',
        'updateSeries',
        [
          {
            data: response.data.online,
          },
          {
            data: response.data.joined_count,
          },
          {
            data: response.data.left_count,
          },
        ],
        false,
      );
      yield delay(GET_EVENT_STATISTIC_DELAY);
    } catch (error) {
      console.log(error);
      return;
    }
  }
}

export function* watchGetEventStatistic() {
  while (true) {
    const payload = yield take(eventManagementActions.StartEventStatisticPolling);
    yield race([
      call(getEventStatistic, payload),
      take(eventManagementActions.StopEventStatisticPolling),
    ]);
  }
}

// Q&A

function* patchApproveQuestionSaga(action) {
  try {
    yield call(
      eventManagementApi.patchModeratorQuestion,
      action.payload.event_pk,
      action.payload.id,
      action.payload.data,
    );
  } catch (error) {
    console.log(error);
  }
}

function* patchRejectQuestionSaga(action) {
  try {
    yield call(
      eventManagementApi.patchModeratorQuestion,
      action.payload.event_pk,
      action.payload.id,
      action.payload.data,
    );
  } catch (error) {
    console.log(error);
  }
}

function* rejectedAllQuestionSaga(action) {
  try {
    yield put(rejectedAllQuestionLoader(true));
    yield call(eventManagementApi.patchRejectAllQuestions, action.payload);
  } catch (error) {
    console.log(error);
  } finally {
    yield getModeratorQuestions(action);
    yield put(rejectedAllQuestionLoader(false));
  }
}

function* clearAllQuestionSaga(action) {
  try {
    yield put(clearAllQuestionLoader(true));
    yield call(eventManagementApi.patchClearAllQuestions, action.payload);
  } catch (error) {
    console.log(error);
  } finally {
    yield getModeratorQuestions(action);
    yield put(clearAllQuestionLoader(false));
  }
}

function* patchRestoreQuestionSaga(action) {
  try {
    yield call(
      eventManagementApi.patchModeratorQuestion,
      action.payload.event_pk,
      action.payload.id,
      action.payload.data,
    );
  } catch (error) {
    console.log(error);
  }
}

export function* watchChangeStatusQuestion() {
  yield takeEvery(eventManagementActions.ApproveQuestion, patchApproveQuestionSaga);
  yield takeEvery(eventManagementActions.RejectQuestion, patchRejectQuestionSaga);
  yield takeEvery(eventManagementActions.RejectAllQuestion, rejectedAllQuestionSaga);
  yield takeEvery(eventManagementActions.ClearAllQuestion, clearAllQuestionSaga);
  yield takeEvery(eventManagementActions.RestoreQuestion, patchRestoreQuestionSaga);
}

// stream status saga

function* getStreamStatusSaga(action) {
  try {
    yield put(streamStatusLoader(true));
    const response = yield call(
      eventManagementApi.patchStreamStatus,
      action.payload.event_pk,
      action.payload.data,
    );
    yield put(setStreamStatus(response.data.stream_active));
  } catch (error) {
    console.log(error);
    return;
  } finally {
    yield put(streamStatusLoader(false));
  }
}

function* patchSessionStatusSaga(action) {
  try {
    yield call(
      eventManagementApi.patchSessionStatus,
      action.payload.day_pk,
      action.payload.id,
      action.payload.data,
    );
  } catch (error) {
    console.log(error);
  } finally {
    const response = yield call(sessionsAPI.getLiveSession, action.payload.event_pk);
    yield put(setLiveSession(response.data));
  }
}

export function* watchStreamStatusSaga() {
  yield takeEvery(eventManagementActions.GetStreamStatus, getStreamStatusSaga);
  yield takeEvery(eventManagementActions.PatchSessionStatus, patchSessionStatusSaga);
}

//broadcast message saga

function* getModeratorMessageSaga(action) {
  try {
    yield put(loadingLatestBroadcast(true));
    const response = yield call(eventManagementApi.getModeratorMessage, action.payload);
    yield put(setLatestBroadcast(response.data));
  } catch (error) {
    console.log(error);
    return;
  } finally {
    yield put(loadingLatestBroadcast(false));
  }
}

function* postBroadcastMessageSaga(action) {
  try {
    yield call(
      eventManagementApi.postBroadcastMessage,
      action.payload.event_pk,
      action.payload.data,
    );
  } catch (error) {
    console.log(error);
  } finally {
    const response = yield call(eventManagementApi.getModeratorMessage, action.payload.event_pk);
    yield put(setLatestBroadcast(response.data));
  }
}

function* patchBroadcastMessageSaga(action) {
  try {
    yield put(loadingPatchBroadcast(true));
    yield call(
      eventManagementApi.patchBroadcastMessage,
      action.payload.event_pk,
      action.payload.id,
      action.payload.data,
    );
  } catch (error) {
    console.log(error);
  } finally {
    const response = yield call(eventManagementApi.getModeratorMessage, action.payload.event_pk);
    yield put(setLatestBroadcast(response.data));
    yield put(loadingPatchBroadcast(false));
  }
}

export function* watchBroadcastMessageSaga() {
  yield takeEvery(eventManagementActions.GetModeratorMessage, getModeratorMessageSaga);
  yield takeEvery(eventManagementActions.PostBroadcastMessage, postBroadcastMessageSaga);
  yield takeEvery(eventManagementActions.PatchBroadcastMessage, patchBroadcastMessageSaga);
}

//moderator polls

function* getModeratorPollsSaga(action) {
  while (true) {
    try {
      const response = yield call(eventManagementApi.getModeratorPolls, action.payload);
      yield put(setModeratorPolls(response.data));
      yield delay(GET_ADMIN_POLLS_DELAY);
    } catch (error) {
      console.log(error);
      return;
    }
  }
}

function* patchModeratorPollSaga(action) {
  try {
    yield call(
      eventManagementApi.patchModeratorPoll,
      action.payload.session_pk,
      action.payload.id,
      action.payload.data,
    );
  } catch (error) {
    console.log(error);
  } finally {
    const response = yield call(eventManagementApi.getModeratorPolls, action.payload.session_pk);
    yield put(setModeratorPolls(response.data));
  }
}

export function* watchModeratorPollSaga() {
  yield takeEvery(eventManagementActions.PatchModeratorPoll, patchModeratorPollSaga);
  while (true) {
    const session_pk = yield take(eventManagementActions.StartModeratorPollsDataPolling);
    yield race([
      call(getModeratorPollsSaga, session_pk),
      take(eventManagementActions.StopModeratorPollsDataPolling),
    ]);
  }
}
