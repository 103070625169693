import React from 'react';
import { isEqual } from 'deep-equal';
import { InputContainer, Input, Label } from './styles';

const TextInput = ({ name, onChange, value, placeholder, disabled, onBlur, label }) => {
  return (
    <InputContainer>
      <Label>{label}</Label>
      <Input
        id={name}
        onBlur={onBlur}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={disabled}
      ></Input>
    </InputContainer>
  );
};

export default React.memo(TextInput, isEqual);
