import axios from 'axios';
import { baseURL } from '@/constants/baseURL';
import { responseHandlerInterceptor, sendTokenInterceptor } from '../helpers/interceptors';

const instance = axios.create({
  baseURL,
});

instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

instance.interceptors.request.use(request => {
  return sendTokenInterceptor(request);
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return responseHandlerInterceptor(error, instance);
  },
);

export const dashboardApi = {
  getDelegates(event_pk) {
    return instance.get(`/events/event/${event_pk}/networking/`);
  },
  getBookmarkedDelegates(event_pk) {
    return instance.get(`/events/event/${event_pk}/favorite-delegate/`);
  },
  invitationDelegates(data, event_pk) {
    return instance.post(`/events/event/${event_pk}/invitation/`, data);
  },
  getExibitorData(id) {
    return instance.get(`/events/exhibitor/exhibitor-dashboard/${id}/`);
  },
  getAcceptedUsers(event_pk, invitation_pk) {
    return instance.get(`/events/event/${event_pk}/invitation/accepted/${invitation_pk}/`);
  },
  getRejectedUsers(event_pk, invitation_pk) {
    return instance.get(`/events/event/${event_pk}/invitation/rejected/${invitation_pk}/`);
  },
  getExhibitorStandCsv(event_pk, urlTag) {
    return instance.get(`/events/exhibitor/${event_pk}/exhibitor-stand-csv/${urlTag}/`);
  },
};
