import React, { useMemo } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    paddingLeft: 0,
    '&:hover': {
      background: 'none',
    },
  },
  container: {
    marginLeft: '0',
  },
  label: {
    fontFamily: 'Inter',
    fontSize: '15px',
    lineHeight: '20px',
    color: '#52595E',
    fontWeight: 'normal',
  },
  colorSecondary: {
    '&$checked': {
      '&:hover': {
        background: 'none',
      },
    },
  },
  checked: {},
  icon: {
    width: 18,
    height: 18,
    background: '#FFFFFF',
    border: '1px solid #CAD4DC',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  checkedIcon: {
    position: 'relative',
    width: 18,
    height: 18,
    boxSizing: 'border-box',
    borderRadius: '4px',
    background: '#257BDE',
    '&:before': {
      position: 'absolute',
      left: -2,
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M8.60002 10.9251L5.45002 7.7751L4.40002 8.8251L8.60002 13.0251L17.6 4.0251L16.55 2.9751L8.60002 10.9251Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0.400024' y='0.975098' width='21.2' height='18.05' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='2'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E")`,
      content: '""',
    },
  },
});

const useItemStyles = makeStyles({
  root: {
    padding: '2px 0px',
    '&:hover': {
      background: 'none',
    },
  },
  item: {
    height: '40px',
    width: '100%',

    margin: 0,
    padding: '10px 15px 8px',

    background: '#FFFFFF',
    border: '1px solid #CAD4DC',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 4px #F2F4FA',
    borderRadius: '4px',
  },
  itemChecked: {
    height: '40px',
    width: '100%',

    margin: 0,
    padding: '10px 15px 8px',

    background: '#257BDE',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  label: {
    paddingLeft: '15px',
    fontFamily: 'Inter',
    fontSize: '15px',
    lineHeight: '20px',
    color: '#52595E',
    fontWeight: 'normal',
    userSelect: 'none',
  },
  labelChecked: {
    paddingLeft: '15px',
    fontFamily: 'Inter',
    fontSize: '15px',
    lineHeight: '20px',
    color: '#F2F4FA',
    fontWeight: 'normal',
    userSelect: 'none',
  },
  colorSecondary: {
    '&$checked': {
      '&:hover': {
        background: 'none',
      },
    },
  },
  checked: {},
  icon: {
    width: 14,
    height: 14,
    background: '#FFFFFF',
    border: '1px solid #CAD4DC',
    boxSizing: 'border-box',
    borderRadius: '2px',
  },
  checkedIcon: {
    position: 'relative',
    width: 14,
    height: 14,
    boxSizing: 'border-box',
    borderRadius: '2px',
    background: '#257BDE',
    '&:before': {
      position: 'absolute',
      display: 'block',
      width: 14,
      height: 14,
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='4 1 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M8.60002 10.9251L5.45002 7.7751L4.40002 8.8251L8.60002 13.0251L17.6 4.0251L16.55 2.9751L8.60002 10.9251Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0.400024' y='0.975098' width='21.2' height='18.05' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='2'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E")`,
      content: '""',
    },
  },
});

export const CustomCheckbox = ({ label, value, onChange, name, item: isItem, checked, form }) => {
  const regularClasses = useStyles();
  const itemClasses = useItemStyles();

  const { setFieldError } = form;

  const classes = useMemo(() => (isItem ? itemClasses : regularClasses), [
    isItem,
    itemClasses,
    regularClasses,
  ]);

  return (
    <FormControlLabel
      classes={{
        root: isItem ? (checked ? classes.itemChecked : classes.item) : classes.container,
        label: isItem ? (checked ? classes.labelChecked : classes.label) : classes.label,
      }}
      label={label}
      control={
        <Checkbox
          id={String(value)}
          checked={checked}
          onChange={onChange}
          classes={{
            root: classes.root,
            checked: classes.checked,
            colorSecondary: classes.colorSecondary,
          }}
          icon={<span className={classes.icon} />}
          checkedIcon={<span className={classes.checkedIcon} />}
          onFocus={() => setFieldError(name, '')}
          disableRipple
        />
      }
    />
  );
};
