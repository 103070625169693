import React from 'react';
import { isEqual } from 'deep-equal';
import styles from './navbar.module.css';

const Navbar = ({ config, activeIdx, spacing }) => {
  return (
    <div className={styles.container}>
      {Object.values(config).map(
        (item, idx) =>
          item.showNavbar && (
            <div
              key={idx}
              className={`${styles.item} ${spacing} ${
                idx === activeIdx ? styles['item_active'] : ''
              }`}
            >
              <span className={styles['item__text']}>{item.header}</span>
            </div>
          ),
      )}
    </div>
  );
};

export default React.memo(Navbar, isEqual);
