import React from 'react';
import { isEqual } from 'deep-equal';

import { StyledButton, Label, StyledImg } from './styles';
import preloader from './buttonPreloader.svg';

const Button = ({ label, type, styleType, color = 'green', onClick, disabled = false, isLoading }) => {
  return (
    <StyledButton
      type={type}
      styleType={styleType}
      onClick={onClick}
      color={color}
      disabled={isLoading ? true : disabled}
    >
        {isLoading ? <StyledImg src={preloader} alt="button_preloader" /> : <Label>{label}</Label>}
    </StyledButton>
  );
};

export default React.memo(Button, isEqual);
