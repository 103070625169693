import { call, put, takeEvery, select } from 'redux-saga/effects';
import { eventRegistrationAPI } from '@/api/eventRegistrationApi';
import { validationErrorsMapper } from '@/helpers/validationErrors';
import { setRegistrationStep, setErrorMsg } from '@/store/registration/actions';
import { setIsLoading } from './actions';
import { CheckFormValidationActions } from '@/constants/actions/checkFormValidationActions';
import {
  addDetailsInfo,
  getInterestsInfo,
  getProfileData,
  getUserDetails,
} from '../registration';

function* checkFormValidation(action) {
  const interests = yield select(getInterestsInfo);
  const profile = yield select(getProfileData);
  const userDetails = yield select(getUserDetails);
  try {
    yield put(setIsLoading(true));
    if (action.payload.next === 'summary' && Object.keys(userDetails).length === 0) {
      const response = yield call(eventRegistrationAPI.userInfo);
      yield put(addDetailsInfo(response.data));
    }
    yield call(eventRegistrationAPI.formValidation, action.payload.values, action.payload.event_pk);
    if (Object.keys(profile).length > 0 && Object.keys(interests).length > 0) {
      yield put(setRegistrationStep('summary'));
    } else if (Object.keys(interests).length > 0) {
      yield put(setRegistrationStep('summary'));
    } else {
      yield put(setRegistrationStep(action.payload.next));
      if (action.payload.resetForm !== undefined) yield call(action.payload.resetForm)
    }
    yield put(action.payload.putFunc(action.payload.values));
    yield put(setErrorMsg(''));
  } catch (error) {
    const errors = yield validationErrorsMapper(error.response.data);
    yield put(setErrorMsg(errors));
  } finally {
    yield put(setIsLoading(false));
  }
}

export function* watchFormValidation() {
  yield takeEvery(CheckFormValidationActions.checkFormValidation, checkFormValidation);
}
