import { profilePageActions } from '@/constants/actions/profilePageActions';

export const setProfile = data => ({
  type: profilePageActions.SetProfile,
  payload: data,
});

export const setBiographyErrorMsg = data => ({
  type: profilePageActions.SetBiographyErrorMsg,
  payload: data,
});

export const setIsFetchingProfile = data => ({
  type: profilePageActions.SetIsFetchingProfile,
  payload: data,
});

export const setIsActiveEditBiography = data => ({
  type: profilePageActions.SetIsActiveEditBiography,
  payload: data,
});

export const setIsActiveEditInterests = data => ({
  type: profilePageActions.SetIsActiveEditInterests,
  payload: data,
});


// Social Links Section

export const updateSocialLinks = data => ({
  type: profilePageActions.UpdateSocialLinks,
  payload: data,
});

export const setUpdateSocialLinksLoading = isLoading => ({
  type: profilePageActions.SetUpdateSocialLinksLoading,
  payload: isLoading,
});

export const setUpdateSocialLinksError = errorMessage => ({
  type: profilePageActions.SetUpdateSocialLinksError,
  payload: errorMessage,
});

export const setSocialLinks = (linkedinURL, twitterURL) => ({
  type: profilePageActions.SetSocialLinks,
  linkedinURL,
  twitterURL,
});

export const setIsActiveEditSocialLinks = data => ({
  type: profilePageActions.SetIsActiveEditSocialLinks,
  payload: data,
});


// ...

export const setBiography = data => ({
  type: profilePageActions.SetBiography,
  payload: data,
});

export const setEventInterests = data => ({
  type: profilePageActions.SetEventInterests,
  payload: data,
});

export const setInterests = data => ({
  type: profilePageActions.SetInterests,
  payload: data,
});

export const setIsMarketingAllowed = data => ({
  type: profilePageActions.SetIsMarketingAllowed,
  payload: data,
});

export const setIsAnonymous = data => ({
  type: profilePageActions.SetIsAnonymous,
  payload: data,
});

export const setIsBookmark = data => ({
  type: profilePageActions.SetIsBookmarkDelegate,
  payload: data,
});

export const setIsBookmarkSession = (sessionId, isBookmark) => ({
  type: profilePageActions.SetIsBookmarkSession,
  sessionId,
  isBookmark,
});

export const setIsBookmarkProfileExhibitor = data => ({
  type: profilePageActions.SetIsBookmarkExhibitor,
  payload: data,
});

//saga actions
export const getProfile = data => ({
  type: profilePageActions.GetProfile,
  payload: data,
});

export const getDelegateProfile = data => ({
  type: profilePageActions.GetDelegateProfile,
  payload: data,
});

export const updateBiography = data => ({
  type: profilePageActions.UpdateBiography,
  payload: data,
});

export const updateInterests = data => ({
  type: profilePageActions.UpdateInterests,
  payload: data,
});

export const updateIsMarketingAllowed = data => ({
  type: profilePageActions.UpdateIsMarketingAllowed,
  payload: data,
});

export const updateIsAnonymous = data => ({
  type: profilePageActions.UpdateIsAnonymous,
  payload: data,
});

export const getEventInterests = data => ({
  type: profilePageActions.GetEventInterests,
  payload: data,
});

export const bookmarkDelegate = data => ({
  type: profilePageActions.BookmarkDelegate,
  payload: data,
});

export const unBookmarkDelegate = data => ({
  type: profilePageActions.UnBookmarkDelegate,
  payload: data,
});
