import React from 'react';
import { debounce } from '@/helpers';

export const useWindowSize = resizeDelay => {
  const [size, setSize] = React.useState([0, 0]);
  React.useLayoutEffect(() => {
    function onResize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    onResize();
    const debouncedOnResize = debounce(onResize, resizeDelay);
    window.addEventListener('resize', debouncedOnResize);
    return () => window.removeEventListener('resize', debouncedOnResize);
  }, []);
  return size;
};
