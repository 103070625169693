import {expoPageActions} from "@/constants/actions/expoPageActions";

const initialState = {
  exhibitors: [],
  isFetchingExhibitors: true
};

export const expoPage = (state = initialState, action) => {
  switch (action.type) {
    case expoPageActions.SetExhibitors:
      return {
        ...state,
        exhibitors: action.payload,
      };
    case expoPageActions.SetIsFetchingExhibitors:
      return {
        ...state,
        isFetchingExhibitors: action.payload,
      };
    case expoPageActions.SetIsBookmark:
      return {
        ...state,
        exhibitors: state.exhibitors.map(ex => {
          if(ex.id === action.exhibitorId){
            return {...ex, is_bookmark: action.isBookmark}
          }
          return ex
        }),
      };
    default:
      return state;
  }
};
