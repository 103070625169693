import React, { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { generateForgotPasswordUrl } from '@/constants/routes'
import { isEqual } from 'deep-equal';
import styles from './styles.module.css';
import HelpText from '@/components/controls/HelpText';
import { ReactComponent as VisibleIcon } from './icons/visible.svg';
import { ReactComponent as InvisibleIcon } from './icons/invisible.svg';

const PasswordInput = ({ name, onChange, value, helperText, isSignInForm, onBlur, form, label = 'Password' }) => {
  const { id } = useParams()
  const forgotPasswordLink = generateForgotPasswordUrl(id)
  const [hidden, setHidden] = useState(true);
  const { errors, touched, setFieldError } = form;
  const helper = useMemo(() => (helperText ? <HelpText>{helperText}</HelpText> : null), [
    helperText,
  ]);

  const handleVisibleClick = () => setHidden(!hidden);

  const handleVisibleKey = e => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      setHidden(!hidden);
    }
  };

  const _onFocus = () => {
    //setErrors({})
    setFieldError(name, '')
    //setTouched({})
  }

  const showInvalid = useMemo(() => {
    return { display: errors[name] && touched[name] ? 'block' : 'none' };
  }, [errors]);

  return (
    <div className={styles['text-input-container']}>
      <div className={styles['input-label-with-link']}>
        <label htmlFor={name} className={styles['input-label']}>
          {label}
        </label>
        {isSignInForm && (
          <Link to={forgotPasswordLink} className={styles['input-link']}>
            Forgot your password?
          </Link>
        )}
      </div>
      <div className={styles['input-with-icon-container']}>
        <input
          id={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          type={hidden ? 'password' : 'text'}
          className={`${styles['text-input']} ${
            errors[name] && touched[name] ? styles['text-input_error'] : ''
            }`}
          onFocus={_onFocus}
        />
        <div
          className={styles['visibility-icon']}
          role="button"
          unselectable="on"
          tabIndex="0"
          onClick={handleVisibleClick}
          onKeyDown={handleVisibleKey}
        >
          {hidden ? <VisibleIcon /> : <InvisibleIcon />}
        </div>
      </div>
      <small className={styles['error-text']} style={showInvalid}>
        {errors[name]}
      </small>
      {helper}
    </div>
  );
};

export default React.memo(PasswordInput, isEqual);
