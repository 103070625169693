import styled from 'styled-components';

export const InformationCardContainer = styled.div``;

export const Header = styled.h5`
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
`;

export const Text = styled.span`
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.regularText};
`;
