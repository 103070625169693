import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash.isequal';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getNetworkingPageValues = state => state.networkingPage;

export const getSearchMsg = createSelector([getNetworkingPageValues], state => {
  return state.search;
});

export const getFilters = createSelector([getNetworkingPageValues], state => {
  return state.filters;
});

export const getSort = createSelector([getNetworkingPageValues], state => {
  return state.sort;
});

export const getSelectedSort = createSelector([getNetworkingPageValues], state => {
  return state.selectedSort;
});

export const getCheckedFilters = createSelector([getNetworkingPageValues], state => {
  return state.checkedFilters;
});

export const getDelegatesInfo = createDeepEqualSelector([getNetworkingPageValues], state => {
  return state.delegates;
});

export const getBookmarkedDelegates = createDeepEqualSelector([getNetworkingPageValues], state => {
  return state.delegates.filter(delegate => delegate.is_bookmark);
});

export const getIsFetchingFilters = createSelector([getNetworkingPageValues], state => {
  return state.isFetchingFilters;
});

export const getIsFetchingDelegates = createSelector([getNetworkingPageValues], state => {
  return state.isFetchingDelegates;
});

export const getNextDelegatesUrl = createSelector([getNetworkingPageValues], state => {
  return state.nextDelegatesUrl;
});

export const getIsLoadingNextDelegateList = createSelector([getNetworkingPageValues], state => {
  return state.isLoadingNextDelegateList;
});
