import styled from 'styled-components';

export const Badge = styled.div`
  height: 20px;
  width: 25px;
  background: ${({ color, theme }) =>
    (color === 'green' && theme.colors.greenHaze) ||
    (color === 'red' && theme.colors.mandy) ||
    (color === 'blue' && theme.colors.sanMarino)};
  border-radius: 99px;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.originWhite};
  display: flex;
  justify-content: center;
  align-items: center;
`;
