import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEqual } from 'deep-equal';
import { Header, User, Name } from './styles';
import { history } from '@/store/rootReducer';
import { generateLandingUrl } from '@/constants/routes';
import { localStorageServices } from '@/helpers/localStorageServices';
import { Popover } from 'antd';
import UserMenu from './components/UserMenu';
import { getDelegateInfo } from '@/store/platformInfo';
import { mystic, white } from '@/constants/colors';
import Avatar from 'react-avatar';

const CommonHeader = ({ children }) => {
  const { id: event_pk } = useParams();
  const { first_name, last_name, headshot } = useSelector(getDelegateInfo);
  const handleLogout = useCallback(() => {
    history.push(generateLandingUrl(event_pk));
    localStorageServices.clearAllTokens();
  }, []);

  const menuContent = useMemo(() => UserMenu(handleLogout), []);

  return (
    <Header>
      {children}
      <Popover placement="bottomRight" content={menuContent} trigger="click">
        <User>
          <Name>{`${first_name} ${last_name}`}</Name>
          <Avatar
            name={`${first_name} ${last_name}`}
            maxInitials={1}
            src={headshot}
            size="40"
            round="50%"
            textSizeRatio={1.5}
            color={mystic}
            fgColor={white}
          />
        </User>
      </Popover>
    </Header>
  );
};

export default React.memo(CommonHeader, isEqual);
