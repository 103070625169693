import { CheckFormValidationActions } from '@/constants/actions/checkFormValidationActions';

const initialState = {
  isLoading: false
};

export const checkFormValidation = (state = initialState, action) => {
  switch (action.type) {
    case CheckFormValidationActions.SetIsLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};
