import React, { useEffect } from 'react';
import { isEqual } from 'deep-equal';
import Preloader from '../../blocks/Preloader';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { linkedinUp } from '@/store/linkedinUp';

const LinkedinAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const linkedinToken = location.search.split('=')[1];
  const clientId = process.env.REACT_APP_LINKEDIN_APP_CLIENT_ID;
  useEffect(() => {
    dispatch(linkedinUp({ linkedinToken, clientId }));
  }, []);
  return (
    <>
      <Preloader label="Checking Linkedin profile..." />
    </>
  );
};

export default React.memo(LinkedinAuth, isEqual);
