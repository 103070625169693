import React, { useCallback } from 'react';
import { InputDatePicker, InputLabel, Container, DataPickerError } from './styles';
import { dateIcon } from '../icons';

export const DatePicker = ({ placeholder, label, value, form, name, onBlur }) => {
  const { errors, touched, setFieldError } = form;
  const setDate = useCallback(
    date => {
      form.setFieldValue(name, date);
    },
    [name, form],
  );

  const showInvalid = { display: errors[name] && touched[name] ? 'block' : 'none' };

  const onFocusInput = useCallback(() => {
    setFieldError(name, '');
  }, [name]);

  return (
    <>
      <Container>
        <InputLabel>{label}</InputLabel>
        <InputDatePicker
          id={name}
          onBlur={onBlur}
          selected={(value && new Date(value)) || null}
          onChange={date => setDate(date)}
          disabledKeyboardNavigation
          placeholderText={placeholder}
          dateFormat="d.MM.yyyy"
          value={value}
          minDate={new Date()}
          maxDate={
            new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())
          }
          onFocus={onFocusInput}
          error={errors[name]}
          touched={touched[name]}
        />
        {dateIcon}
      </Container>
      <DataPickerError style={showInvalid}>{errors[name]}</DataPickerError>
    </>
  );
};
