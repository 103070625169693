import React from 'react';
import { isEqual } from 'deep-equal';

import { IconSection } from './styles';

const BookmarkIcon = ({ onClick, isBookmark }) => {
  return (
    <>
      {isBookmark ? (
          <IconSection xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18" onClick={onClick}>
              <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 0H2C0.9 0 0.01 0.9 0.01 2L0 18L7 15L14 18V2C14 0.9 13.1 0 12 0Z" fill="#257BDE"
              />
          </IconSection>
      ) : (
        <IconSection xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18" onClick={onClick}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 0H12C13.1 0 14 0.9 14 2V18L7 15L0 18L0.01 2C0.01 0.9 0.9 0 2 0ZM7 12.82L12 15V2H2V15L7 12.82Z"
          />
        </IconSection>
      )}
    </>
  );
};

export default React.memo(BookmarkIcon, isEqual);
