import styled from 'styled-components';

export const LiveStreamPage = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MainSectionWrapper = styled.div`
  padding-bottom: 15px;
  width: 100%;
`;

export const MainSection = styled.div`
  margin: auto;
  width: 100%;
  overflow-x: hidden;
  padding-left: 30px;
  padding-right: 30px;
`;

export const VideoPlaceholder = styled.div`
  background: ${({ theme }) => theme.colors.agenda.liveBackground};
  width: 100%;
  padding-top: ${({ isValidationEventVideo }) =>
    isValidationEventVideo ? '56.25%' : '0'}; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
`;

export const StreamSession = styled.div``;

export const AgendaSection = styled.div`
  margin: 30px 0;
`;

export const AgendaTitle = styled.div`
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  margin-bottom: 15px;
`;

export const AgendaButton = styled.div`
  margin-top: 15px;
`;

export const EventPartnersSection = styled.div`
  margin-top: 30px;
  margin-bottom: 25px;
`;

export const EventPartnersSpan = styled.div`
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
`;
