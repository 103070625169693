import axios from 'axios';
import { baseURL } from '@/constants/baseURL';
import { responseHandlerInterceptor, sendTokenInterceptor } from '../helpers/interceptors';

const instance = axios.create({
  baseURL,
});

const instanceAws = axios.create({
  baseURL: 'https://rx15l5jip9.execute-api.eu-west-2.amazonaws.com/default/',
});

instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

instance.interceptors.request.use(request => {
  return sendTokenInterceptor(request);
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return responseHandlerInterceptor(error, instance);
  },
);

export const eventManagementApi = {
  getModeratorQuestions(event_pk) {
    return instance.get(`/events/event/${event_pk}/moderator-questions/`);
  },
  patchModeratorQuestion(event_pk, id, data) {
    return instance.patch(`/events/event/${event_pk}/moderator-questions/post/${id}/`, data);
  },
  getStatisticEvent(event_pk) {
    return instanceAws.get(`return_user_stat?event_id=${event_pk}`);
  },
  patchRejectAllQuestions(event_pk) {
    return instance.patch(`/events/event/${event_pk}/moderator-questions/reject/`);
  },
  patchClearAllQuestions(event_pk) {
    return instance.patch(`/events/event/${event_pk}/moderator-questions/hide/`);
  },
  patchStreamStatus(event_pk, data) {
    return instance.patch(`events/event/${event_pk}/stream-status/`, data);
  },
  getModeratorMessage(event_pk) {
    return instance.get(`events/event/${event_pk}/moderator-message/`);
  },
  postBroadcastMessage(event_pk, data) {
    return instance.post(`/events/event/${event_pk}/moderator-message/post/`, data);
  },
  patchBroadcastMessage(event_pk, id, data) {
    return instance.patch(`/events/event/${event_pk}/moderator-message/patch/${id}/`, data);
  },
  getModeratorPolls(session_pk) {
    return instance.get(`/events/session/${session_pk}/moderator-polls/`);
  },
  patchModeratorPoll(session_pk, id, data) {
    return instance.patch(`/events/session/${session_pk}/moderator-polls/post/${id}/`, data);
  },
  patchSessionStatus(day_pk, id, data) {
    return instance.patch(`/events/session/${day_pk}/session-status/${id}/`, data);
  },
};
