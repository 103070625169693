import { RegistrationActions } from '@/constants/actions/registrationActions'

const initialState = {
  isFree: null,
  ticketPrice: '',
  step: '',
  details: {},
  profile: {},
  bio: {},
  interests: {},
  interestsIn: {},
  errorMsg: '',
  isLoading: false
}

export const registration = (state = initialState, action) => {
  switch (action.type) {
    case RegistrationActions.AddDetailsInfoData:
      return {
        ...state,
        details: action.payload
      }
    case RegistrationActions.AddProfileInfoData:
      return {
        ...state,
        profile: action.payload
      }
    case RegistrationActions.AddBioInfoData:
      return {
        ...state,
        bio: action.payload
      }
    case RegistrationActions.AddInterestsInfoData:
      return {
        ...state,
        interests: action.payload
      }
    case RegistrationActions.AddInterestsInInfoData:
      return {
        ...state,
        interestsIn: action.payload
      }
    case RegistrationActions.SetRegistrationStep:
      return {
        ...state,
        step: action.payload
      }
    case RegistrationActions.SetErrorMsg:
      return {
        ...state,
        errorMsg: action.payload
      }
    case RegistrationActions.SetIsLoading:
      return {
        ...state,
        isLoading: action.payload
      }
    case RegistrationActions.SetTicketType:
      return {
        ...state,
        isFree: action.payload
      }
    case RegistrationActions.OnCheckTicketPrice:
      return {
        ...state,
        ticketPrice: action.payload
      }
    default:
      return state
  }
}