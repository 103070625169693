import styled from 'styled-components';

export const IconWrapper = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  margin-top: 15px;
`;

export const Icon = styled.svg`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  fill: #808b94;
  &:hover {
    fill: #df3541;
  }
`;
