import { RegistrationActions } from '@/constants/actions/registrationActions'

export const setDelegate = data => ({
  type: RegistrationActions.SetRegistrationDelegate,
  payload: data,
})

export const setIsLoadingSummaryForm = data => ({
  type: RegistrationActions.SetIsLoadingSummaryForm,
  payload: data,
})

export const setIsShowSummaryModal = data => ({
  type: RegistrationActions.SetIsShowSummaryModal,
  payload: data,
})