import React from 'react';
import { isEqual } from 'deep-equal';
import { Divider } from '@/components/controls/Divider';

import { Wrapper, Date, LineContainer } from './styles';

const Delimiter = ({ name }) => {
  return (
    <Wrapper>
      <Date>{name}</Date>
      <LineContainer>
        <Divider base />
      </LineContainer>
    </Wrapper>
  );
};

export default React.memo(Delimiter, isEqual);
