import { createSelector } from 'reselect';

export const getNotificationsSelector = (state) => state.notifications;

export const getSessionSet = createSelector(
    [getNotificationsSelector],
    (state) => {
    return state.notifications;
  });

