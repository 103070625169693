import styled from 'styled-components'

export const StreamingContainer = styled.div`
  width: 100%;
  margin-top: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    padding: 0px 20px 0px 20px;
    margin-top: 75px;
  }
`

export const Title = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 45px;
  line-height: 54px;
  display: flex;
  justify-content: center;
  color: #17191A;
  margin-bottom: 35px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    font-size: 30px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
`

export const StreamingInfo = styled.div`
  width: 750px;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #52595E;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
  }
`
