import { createSelector } from 'reselect';

const getAgenda = state => state.agenda;

export const getAgendaData = createSelector([getAgenda], state => {
  return state.agendaData;
});

export const getBookmarkedAgenda = createSelector([getAgenda], state => {
  return [...state.agendaData.map(day => day.sessions.filter(session => session.is_bookmark))].flat(
    2,
  );
});

export const getShowLoader = createSelector([getAgenda], state => {
  return state.showAgendaLoader;
});
