import React from 'react'
import { isEqual } from 'deep-equal'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import CallIcon from '@material-ui/icons/Call'
import InsertLinkIcon from '@material-ui/icons/InsertLink'
import climateAction from '../../images/climateAction.png'
import twitter from '../../images/twitter.png'
import linked from '../../images/linked.png'
import facebook from '../../images/facebook.png'
import {
  FooterContainer,
  FooterMenu,
  MenuLink,
  FooterSecondColumn,
  Title,
  EventDates,
  ShareImages,
  Image,
  GetInTouchColumn,
  GetInTouchTitle,
  GetInTouchRow,
  GetInTouchRowIcon,
  GetInTouchRowText,
  FooterEnd,
  FooterLinks,
  FooterLink,
  PoweredBy,
  FooterLogo,
} from './styles'


const Footer = props => {
  return (
    <>
      <FooterContainer>
        <FooterMenu>
          <FooterLogo src={climateAction} alt="" />
          <MenuLink>
            About
        </MenuLink>
          <MenuLink>
            Our mission
        </MenuLink>
          <MenuLink>
            News & resources
        </MenuLink>
          <MenuLink>
            Feedback
        </MenuLink>
        </FooterMenu>
        <FooterSecondColumn>
          <Title>
            Sustainable Innovation Forum 2020
        </Title>
          <EventDates>
            16 - 20 November, 2020
            14:00 - 16:00 GMT
        </EventDates>
          <Title>
            Share this event
        </Title>
          <ShareImages>
            <Image src={twitter} alt="" />
            <Image src={linked} alt="" />
            <Image src={facebook} alt="" />
          </ShareImages>
        </FooterSecondColumn>
        <GetInTouchColumn>
          <GetInTouchTitle>
            Get in touch
        </GetInTouchTitle>
          <GetInTouchRow>
            <GetInTouchRowIcon>
              <CallIcon />
            </GetInTouchRowIcon>
            <GetInTouchRowText>
              +44 (0)20 7871 0173
          </GetInTouchRowText>
          </GetInTouchRow>
          <GetInTouchRow>
            <GetInTouchRowIcon>
              <InsertLinkIcon />
            </GetInTouchRowIcon>
            <GetInTouchRowText>
              www.climateaction.org
          </GetInTouchRowText>
          </GetInTouchRow>
          <GetInTouchRow>
            <GetInTouchRowIcon>
              <MailOutlineIcon />
            </GetInTouchRowIcon>
            <GetInTouchRowText>
              events@climateaction.org
          </GetInTouchRowText>
          </GetInTouchRow>
          <ShareImages>
            <Image src={twitter} alt="" />
            <Image src={linked} alt="" />
          </ShareImages>
        </GetInTouchColumn>
      </FooterContainer>
      <FooterEnd>
        <FooterLinks>
          <FooterLink>
            Terms & Conditions
      </FooterLink>
          <FooterLink>
            Privacy Policy
      </FooterLink>
          <FooterLink>
            Cookies
      </FooterLink>
        </FooterLinks>
        <PoweredBy>
          Powered by CAVE
    </PoweredBy>
      </FooterEnd>
    </>
  )
}

export default React.memo(Footer, isEqual)
