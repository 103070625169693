import { createSelector } from 'reselect';

export const getProfilePageValues = state => state.profilePage;

export const getProfileInfo = createSelector([getProfilePageValues], state => {
    return state.profile;
});

export const getBiographyErrMsg = createSelector([getProfilePageValues], state => {
    return state.biographyErrMsg;
});

export const getIsEditSocialLinksLoading = createSelector([getProfilePageValues], state => {
    return state.isEditSocialLinksLoading;
});

export const getEditSocialLinksError = createSelector([getProfilePageValues], state => {
    return state.editSocialLinksError;
});

export const getIsFetchingProfile = createSelector([getProfilePageValues], state => {
    return state.isFetchingProfile;
});

export const getIsActiveEditSocialLinks = createSelector([getProfilePageValues], state => {
    return state.isActiveEditSocialLinks;
});

export const getIsActiveEditBiography = createSelector([getProfilePageValues], state => {
    return state.isActiveEditBiography;
});

export const getIsActiveEditInterests = createSelector([getProfilePageValues], state => {
    return state.isActiveEditInterests;
});

export const getEventInterestsInfo = createSelector([getProfilePageValues], state => {
    return state.eventInterests;
});