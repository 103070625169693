import React from 'react';
import { isEqual } from 'deep-equal';
import DropDown from '../DropDown/component';
import { useSelector } from 'react-redux';
import { getFilters } from '@/store/networkingPage';
import {NetworkingFiltersContainer} from "./styles";

const NetworkingFilters = () => {
  const filters = useSelector(getFilters);
  const data = Object.keys(filters).map(k => {
    return {
      title: k,
      subMenu: filters[k],
    };
  });
  return (
    <NetworkingFiltersContainer>
      {data.map((item, idx) => (
        <div key={idx}>
          <DropDown title={item.title} subMenu={item.subMenu} />
        </div>
      ))}
    </NetworkingFiltersContainer>
  );
};
export default React.memo(NetworkingFilters, isEqual);
