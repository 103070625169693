import { notificationsActions } from '@/constants/actions/notificationsActions';

const initialState = {
  notifications: {
    session_set: [],
    invitation_set: [],
  },
};

export const notifications = (state = initialState, action) => {
  switch (action.type) {
    case notificationsActions.SetNotifications:
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
};
