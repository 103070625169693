import { call, put, takeEvery, select } from 'redux-saga/effects';
import { eventRegistrationAPI } from '@/api/eventRegistrationApi';
import { setRegistrationFieldsValues } from './actions'
import { registrationFieldaDataMapper } from '@/helpers/parseRegistrationFieldsData'
import { setRegistrationStep } from '@/store/registration/actions';
import {
  getInterestsInfo,
  getProfileData,
  getRegistrationStep,
} from '../registration'

function* getFieldsValues(action) {
  const interests = yield select(getInterestsInfo)
  const profile = yield select(getProfileData)
  const step = yield select(getRegistrationStep)

  try {
    const fieldsValuesData = yield call(eventRegistrationAPI.fieldsValues, action.payload)
    const parseData = yield registrationFieldaDataMapper(fieldsValuesData.data)
    yield put(setRegistrationFieldsValues(parseData))
    if (Object.keys(profile).length > 0 && Object.keys(interests).length > 0 && step !== 'profile') {
      yield put(setRegistrationStep('summary'))
    } else {
      yield put(setRegistrationStep('profile'));
    }
  } catch (error) {
    yield put(setRegistrationFieldsValues(null))
    yield put(setRegistrationStep('signUp'))
    console.log(error);
  }
}

export function* watchRegistrationFormFieldsValues() {
  yield takeEvery('registrationFieldsValues/GET_FIELDS_VALUES', getFieldsValues);
}
