import { createSelector } from 'reselect';

const getDemand = state => state.demand;

export const getSessionInfo = createSelector([getDemand], state => {
  return state.session;
});

export const getEventPartnersInfo = createSelector([getDemand], state => {
  return state.eventPartners;
});

export const getIsFetchingDemand = createSelector([getDemand], state => {
  return state.isFetchingDemand;
});
