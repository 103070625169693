import React from 'react';
import { useParams } from 'react-router-dom';
import { Divider } from '@/components/controls/Divider';
import checkCircle from './icons/check-circle.svg';
import TextInput from '@/components/controls/TextInput';
import HelpText from '@/components/controls/HelpText';
import GenericButton from '@/components/controls/buttons/GenericButton';
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setRegistrationStep, setTicketType } from '@/store/registration';
import { generateRegisterUrl } from '@/constants/routes';
import { getPromocodeError } from '@/store/promocodeValidation/selectors';
import { history } from '@/store/rootReducer';
import {
  getInterestsInfo,
  getInterestsInInfo,
  getProfileData,
} from '@/store/registration'
import styles from './styles.module.css';

export const TicketChoice = ({ label, options, isPromotionalCode, isFree }) => {
  const dispatch = useDispatch();
  const errorMsg = useSelector(getPromocodeError)
  const interests = useSelector(getInterestsInfo)
  const interestsIn = useSelector(getInterestsInInfo)
  const profile = useSelector(getProfileData)
  const { id } = useParams();
  const RegisterRoute = generateRegisterUrl(id);

  const onClickBtn = () => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      if (Object.keys(profile).length > 0
        && Object.keys(interests).length > 0
        && Object.keys(interestsIn).length > 0) {
        dispatch(setRegistrationStep('summary'))
      } else {
        dispatch(setRegistrationStep('profile'));
      }
    }
    dispatch(setTicketType(isFree))
    sessionStorage.setItem('ticketType', true)
    sessionStorage.setItem('promocodeStatus', false)
    sessionStorage.setItem('promocode', '')
    history.push(RegisterRoute);
  };

  return (
    <div className={styles['container']}>
      <div>
        <div className={styles['container-header']}>
          <h2>{label}</h2>
          <span>Choose this option if your are:</span>
        </div>
        <Divider />
        <div className={styles['container-main']}>
          {options.map(opt => (
            <div key={opt.id}>
              <img src={checkCircle} alt="check-circle" />
              <span>{opt.label}</span>
            </div>
          ))}
        </div>
      </div>
      <div>
        {isPromotionalCode && (
          <div>
            <Field name="promotionalCode">
              {({ field, form }) => <TextInput {...field} form={form} label="Promotional code" />}
            </Field>
            <div className={styles['error-text']}>{errorMsg}</div>
            <div className={styles['help-text']}>
              <HelpText>If you have a promotional code, please add it here.</HelpText>
            </div>
          </div>
        )}
        {isPromotionalCode
          ? <GenericButton
            label="Apply"
            styleType="primary"
            type='submit'
          />
          : <GenericButton
            label="Apply"
            styleType="primary"
            type='button'
            onClick={onClickBtn}
          />}
      </div>
    </div>
  );
};
