import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.agenda.textDescriptionGrey};
`;

export const OptionContainer = styled.div`
  margin-top: 20px;
`;

export const InputCheckbox = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked + label {
    &:before {
      border-color: #0b76ef;
      background-color: #0b76ef;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M8.60002 10.9251L5.45002 7.7751L4.40002 8.8251L8.60002 13.0251L17.6 4.0251L16.55 2.9751L8.60002 10.9251Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0.400024' y='0.975098' width='21.2' height='18.05' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='2'/%3E%3CfeGaussianBlur stdDeviation='2'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
    }
  }
`;

export const Text = styled.label`
  display: inline-flex;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  &:before {
    content: '';
    margin-right: 10px;
    margin-top: 2px;
    display: inline-block;
    border: 1px solid #cad4dc;
    box-sizing: border-box;
    border-radius: 4px;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    background-position: center center;
  }
`;
