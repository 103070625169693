import React from 'react';
import { isEqual } from 'deep-equal';
import { Route, Switch, useHistory } from 'react-router-dom';
import Theme from '@/Theme';

import { PAGES_PREFIX, LINKEDIN_AUTH_URL, CHANGE_PASSWORD } from '@/constants/routes';
import EventRouter from '../wrappers/EventRouter/component';
import LinkedinAuth from '../pages/LinkedinAuth/component';
import ChangePassword from "../pages/ChangePasswordPage/component";

function App() {
  const { location } = useHistory();
  return (
    <Theme>
      <Switch>
        {location.pathname === '/linkedin-auth' && (
          <Route path={LINKEDIN_AUTH_URL} component={LinkedinAuth} />
        )}
        {location.pathname === '/change-password/' && (
          <Route path={CHANGE_PASSWORD} component={ChangePassword} />
        )}
        <Route path={PAGES_PREFIX} component={EventRouter} />
      </Switch>
    </Theme>
  );
}

export default React.memo(App, isEqual);
