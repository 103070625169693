import { localStorageServices } from './localStorageServices';
import { generateLandingUrl } from '../constants/routes';
import { history } from '../store/rootReducer';

export const sendTokenInterceptor = request => {
  const accessToken = localStorageServices.getAccessToken();
  if (accessToken) request.headers.Authorization = `Bearer ${accessToken}`;
  return request;
};

export const responseHandlerInterceptor = async (error, instance) => {
  const originalRequest = error.config;
  if (error.response.status === 400 && originalRequest.url === '/users/refresh/') {
    localStorageServices.clearAllTokens();
    const eventId = localStorage.getItem('eventId')
    eventId && history.push(generateLandingUrl(eventId));
    localStorage.removeItem('eventId');
    return Promise.reject(error);
  }
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const eventId = error.config.url.match(/\d+/);
    eventId && localStorage.setItem('eventId', eventId.join());
    const response = await instance.post('/users/refresh/', {
      refresh: localStorageServices.getRefreshToken(),
    });
    localStorage.removeItem('eventId');
    localStorageServices.setAccessToken(response.data.access);
    instance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
    return instance(originalRequest);
  }
  return Promise.reject(error);
};
