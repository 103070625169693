import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { isEqual } from 'deep-equal'
import { history } from '@/store/rootReducer'
import Agenda from '@/components/blocks/Agenda'
import { generateAgendaDayId, isDayValid, getDate } from '@/helpers'
import { generateChooseTicketUrl } from '@/constants/routes'
import { days, months } from '@/constants/date';
import {
  Container,
  AgendaContainer,
  Title,
  TitleLineText,
  TitleLine,
  ScrollSpySections,
  OneScrollSpySections,
  DatesContainer,
  AgendaData,
  SecondContainer,
} from './styles'
import styles from './styles.module.css';

const LandingAgenda = ({ agenda }) => {
  const { id } = useParams()

  const chooseTicketRoute = generateChooseTicketUrl(id)

  const goToRegistration = useCallback(() => {
    history.push(chooseTicketRoute)
  }, [chooseTicketRoute])

  return (
    <AgendaContainer id="agenda">
      <DatesContainer>
        <Container>
          <Title>
            <TitleLine />
            <TitleLineText>
              Agenda
            </TitleLineText>
            <TitleLine />
          </Title>
          {
            agenda && <ScrollSpySections
              items={agenda.map(day => (
                isDayValid(day) && generateAgendaDayId(day.id)
              ))}
              currentClassName={styles['current']}
            >
              {
                agenda.map(day => isDayValid(day) && (
                    <OneScrollSpySections href={`#${generateAgendaDayId(day.id)}`}>
                      {days[getDate(day.date).getUTCDay()]}
                      <br />
                      {
                        `${getDate(day.date).getUTCDate()} 
                        ${months[getDate(day.date).getUTCMonth()]}, 
                        ${getDate(day.date).getUTCFullYear()}`
                      }
                    </OneScrollSpySections>
                ))
              }
            </ScrollSpySections>
          }
        </Container>
      </DatesContainer>
      <SecondContainer />
      <AgendaData>
        {
          agenda && <Agenda agenda={agenda} registerCallback={goToRegistration} />
        }
      </AgendaData>
    </AgendaContainer>
  )
}

export default React.memo(LandingAgenda, isEqual)
