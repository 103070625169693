import styled from 'styled-components';

export const PlatformLogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const EventName = styled.div`
  margin-left: 30px;
  font-weight: bold;
  font-size: 17px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.regularText};
`;
