import React from 'react';
import { isEqual } from 'deep-equal';

import { Container, Message, Wrapper } from './styles';
import { CONTACT_EMAIl } from '@/constants/contactEmail';

const NotApprovedPage = () => {
  return (
    <Container>
      <Wrapper>
        <Message>
          Sorry, the application is either under review or has been rejected. Please write to&nbsp;
          <a href={`mailto: ${CONTACT_EMAIl}`}>{CONTACT_EMAIl}&nbsp;</a>
        </Message>
      </Wrapper>
    </Container>
  );
};

export default React.memo(NotApprovedPage, isEqual);
