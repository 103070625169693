import React, { useEffect } from 'react';
import { isEqual } from 'deep-equal';
import { useParams } from 'react-router-dom';
import PlatformRequestsWrapper from '@/components/wrappers/PlatformRequest';
import Preloader from '@/components/blocks/Preloader';
import { useDispatch, useSelector } from 'react-redux';
import { isDelegateCheckWatch } from '@/store/isDelegateCheck';
import { hasAccess } from '@/store/isDelegateCheck';

const PlatformDelegateCheck = props => {
  const { id: eventId } = useParams();
  const dispatch = useDispatch();
  const hasAccessValue = useSelector(hasAccess);
  useEffect(() => {
    dispatch(isDelegateCheckWatch(eventId));
  }, []);
  return hasAccessValue ? (
    <PlatformRequestsWrapper {...props} />
  ) : (
    <Preloader label="Checking delegate status" />
  );
};

export default React.memo(PlatformDelegateCheck, isEqual);
