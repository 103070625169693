import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEqual } from 'deep-equal';
import { history } from '@/store/rootReducer';
import DehazeIcon from '@material-ui/icons/Dehaze'
import { generateChooseTicketUrl, generateSignInUrl } from '@/constants/routes';
import {
  HeaderContainer,
  HeaderContent,
  Logo,
  MenuContainer,
  MenuLink,
  ButtonsContainer,
  RegButton,
  ButtonText,
  SmallMenuContainer,
  SmallMenuButton,
  SmallMenuIten,
} from './styles';
import styles from './styles.module.css';

const Header = ({ event }) => {
  const { id } = useParams();

  const signInRoute = generateSignInUrl(id);
  const chooseTicketRoute = generateChooseTicketUrl(id);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = link => () => {
    setIsMenuOpen(false);
    window.location.href = link
  };

  const goToSignIn = useCallback(() => {
    history.push(signInRoute)
  }, [signInRoute])

  const goToRegistration = useCallback(() => {
    history.push(chooseTicketRoute)
  }, [chooseTicketRoute])

  return (
    <HeaderContainer>
      <HeaderContent>
        <Logo src={event.logo} />
        <SmallMenuButton
          aria-controls="simple-menu"
          onClick={handleOpenMenu}
          aria-haspopup="true"
        >
          <DehazeIcon />
        </SmallMenuButton>
        <MenuContainer
          items={['sponsors', 'speakers', 'agenda', 'features']}
          currentClassName={styles['current']}
        >
          <MenuLink href="#sponsors" >Sponsors & Partners</MenuLink>
          <MenuLink href="#speakers">Speakers</MenuLink>
          <MenuLink href="#agenda">Agenda</MenuLink>
          <MenuLink href="#features">Features</MenuLink>
        </MenuContainer>
        {isMenuOpen && <SmallMenuContainer
          items={['sponsors', 'speakers', 'agenda', 'features']}
          currentClassName={styles['current']}
        >
          <SmallMenuIten onClick={handleCloseMenu('#sponsors')} href="#sponsors">Sponsors & Partners</SmallMenuIten>
          <SmallMenuIten onClick={handleCloseMenu('#speakers')} href="#speakers">Speakers</SmallMenuIten>
          <SmallMenuIten onClick={handleCloseMenu('#agenda')} href="#agenda">Agenda</SmallMenuIten>
          <SmallMenuIten onClick={handleCloseMenu('#features')} href="#features">Features</SmallMenuIten>
        </SmallMenuContainer>}
        <ButtonsContainer>
          <RegButton type="login" onClick={goToSignIn}>
            <ButtonText>
              Login
          </ButtonText>
          </RegButton>
          <RegButton type="register" onClick={goToRegistration}>
            <ButtonText>
              Register
          </ButtonText>
          </RegButton>
        </ButtonsContainer>
      </HeaderContent>
    </HeaderContainer >
  );
};

export default React.memo(Header, isEqual);
