import React, { useMemo } from 'react';
import { isEqual } from 'deep-equal';
import {
  CardContainer,
  UserName,
  Corporation,
  UserInformation,
  IconsSection,
  CustomAvatar,
  StyledLink,
} from './styles';
import { white, mystic } from '@/constants/colors';
import bookmarkIcon from './icons/boormarkIcon.svg';
import activeBookmarkIcon from './icons/activeBookmarkIcon.svg';
import communicationIcon from './icons/communicationIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bookmarkDelegate, unBookmarkDelegate } from '@/store/networkingPage';
import { generateUserUrl } from '@/constants/routes';
import { getDelegateInfo } from '@/store/platformInfo';
import { bookmarkDashboardDelegate, unBookmarkDashboardDelegate } from '@/store/dashboardPage';

const ContactCard = ({
  delegateId,
  photo,
  name,
  organisation,
  jobRole,
  handleOpenNewDialog,
  isBookmarkedIcon,
  isBookmark,
  colorSchema,
  isOnline,
  isOnDashboardPage,
  delegate,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { id: currentUserId } = useSelector(getDelegateInfo);

  const onOpenNewDialog = () => {
    handleOpenNewDialog && handleOpenNewDialog(delegateId);
  };

  const onIsBookmark = () => {
    if (isBookmark) {
      isOnDashboardPage
        ? dispatch(unBookmarkDashboardDelegate({ id, delegateId, isBookmark: false, delegate }))
        : dispatch(unBookmarkDelegate({ id, delegateId, isBookmark: false, delegate }));
    } else {
      isOnDashboardPage
        ? dispatch(bookmarkDashboardDelegate({ id, delegateId, isBookmark: true, delegate }))
        : dispatch(bookmarkDelegate({ id, delegateId, isBookmark: true, delegate }));
    }
  };

  const checkedParameters = useMemo(() => {
    if (!!organisation && !!jobRole) {
      return ',';
    } else {
      return '';
    }
  }, [organisation, jobRole]);

  return (
    <CardContainer>
      <StyledLink to={generateUserUrl(id, delegateId)} isonline={isOnline}>
        <CustomAvatar
          name={name}
          maxInitials={1}
          src={photo}
          size="36"
          round="50%"
          textSizeRatio={1.5}
          color={mystic}
          fgColor={white}
          style={{ position: 'absolute' }}
        />
      </StyledLink>
      <UserInformation withIcons={handleOpenNewDialog || isBookmarkedIcon}>
        <div>
          <UserName colorSchema={colorSchema}>{name}</UserName>
          <Corporation
            colorSchema={colorSchema}
          >{`${organisation}${checkedParameters} ${jobRole}`}</Corporation>
        </div>
        {currentUserId !== delegateId && (
          <IconsSection>
            {isBookmarkedIcon && (
              <img
                src={`${isBookmark ? activeBookmarkIcon : bookmarkIcon}`}
                onClick={onIsBookmark}
                alt="path_icon"
              />
            )}
            {handleOpenNewDialog && (
              <img src={communicationIcon} alt="communication_icon" onClick={onOpenNewDialog} />
            )}
          </IconsSection>
        )}
      </UserInformation>
    </CardContainer>
  );
};
export default React.memo(ContactCard, isEqual);
