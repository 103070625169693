import { createSelector } from 'reselect';

const getRegisterDelegate = state => state.registerDelegate;

export const getIsLoadingInfo = createSelector([getRegisterDelegate], state => {
  return state.isLoading;
});

export const getIsShowSummaryModalInfo = createSelector([getRegisterDelegate], state => {
  return state.isShowSummaryModal;
});