import React from 'react';
import { IconWrapper, Icon } from '../../styles';

export const DashboardIcon = () => {
  return (
    <IconWrapper style={ { paddingBottom: '87.5%' } }>
      {/* 
        paddingBottom is calculated from the view port aspect ratio  
        to maintain responsiveness of the svg
      */}
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 28" >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 26h32v4h-32zM4 18h4v6h-4zM10 10h4v14h-4zM16 16h4v8h-4zM22 4h4v20h-4z"
        />
      </Icon>
    </IconWrapper>
  );
};
