import React, { useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonPrimary } from '@/components/controls/buttons/ButtonPrimary';
import { Divider } from '@/components/controls/Divider';
import GenericButton from '@/components/controls/buttons/GenericButton';
import { Field, Form, Formik } from 'formik';
import TextInput from '@/components/controls/TextInput';
import PasswordInput from '@/components/controls/PasswordInput';
import styles from './styles.module.css';
import { Recaptcha } from '@/components/controls/Recaptcha';
import { signUpSchema } from '@/components/controls/validationSchemas/signUpSchema';
import { getRegistrationError, getRegistrationData, getIsLoadingInfo } from '@/store/registration';
import { setSpinnerValue } from '@/store/spinner/actions';
import { LINKEDIN_URL } from '@/constants/linkedinURL';
import { useParams } from 'react-router-dom';

export const SignUpForm = ({ submitAction }) => {
  const dispatch = useDispatch();
  const errorMsg = useSelector(getRegistrationError);
  const formRef = useRef();
  const { details } = useSelector(getRegistrationData);
  const isLoading = useSelector(getIsLoadingInfo);
  const { id } = useParams();

  const onSubmit = useCallback(
    values => {
      dispatch(submitAction(values));
      dispatch(setSpinnerValue(true));
      localStorage.setItem('isRegistrationInProgress', 'true')
    },
    [dispatch, submitAction],
  );

  const onLinkedinRedirect = () => {
    localStorage.setItem('event_id', id);
    window.location.href = LINKEDIN_URL;
  };

  useEffect(() => {
    const { current } = formRef;

    if (details.email) {
      for (const key in details) {
        current.setFieldValue(key, details[key]);
      }
    }
  }, [details, formRef]);

  return (
    <div>
      <ButtonPrimary text="Sign-up with LinkedIn" color="#0E76A8" onClick={onLinkedinRedirect} />
      <Divider isSignUp placeholder="Or" />
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          recaptcha: '',
        }}
        validationSchema={signUpSchema}
        validateOnChange={false}
        innerRef={formRef}
      >
        {() => (
          <Form className={styles['sign-up-form']}>
            <div className={styles['name-inputs']}>
              <Field name="first_name">
                {({ field, form }) => (
                  <TextInput {...field} form={form} placeholder="e.g James..." label="First name" />
                )}
              </Field>
              <Field name="last_name">
                {({ field, form }) => (
                  <TextInput {...field} form={form} placeholder="e.g Smith..." label="Last name" />
                )}
              </Field>
            </div>
            <Field name="email">
              {({ field, form }) => (
                <TextInput
                  {...field}
                  form={form}
                  placeholder="e.g j.smith@example.com..."
                  label="Email address"
                />
              )}
            </Field>
            <Field name="password">
              {({ field, form }) => (
                <PasswordInput
                  {...field}
                  form={form}
                  helperText="The password must be at least 8 characters long, maximum 30 characters long and contain at least one number"
                />
              )}
            </Field>
            <Field name="recaptcha">
              {({ field, form: { errors, setFieldValue, setFieldError } }) => (
                <div>
                  <Recaptcha
                    {...field}
                    validate={value => {
                      setFieldValue(field.name, value || '');
                      setFieldError(field.name, '');
                    }}
                  />
                  {errors?.recaptcha && (
                    <span className={styles['error-text']}>{errors.recaptcha}</span>
                  )}
                </div>
              )}
            </Field>
            <div className={styles['error-text']}>{errorMsg}</div>
            <GenericButton
              label="Continue"
              styleType="primary"
              type="submit"
              isLoading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
