import { pollsActions } from '@/constants/actions/pollsActions';

export const setPolls = data => ({
  type: pollsActions.SetPolls,
  payload: data,
});

export const postOptionVote = (session_pk, option_pk) => ({
  type: pollsActions.PostOptionVote,
  payload: { session_pk, option_pk },
});

//saga actions

export const startGetPollsPolling = () => ({
  type: pollsActions.StartGetPollsPolling,
});

export const stopGetPollsPolling = () => ({
  type: pollsActions.StopGetPollsPolling,
});
