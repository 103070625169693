import { inactiveUserPageActions } from '@/constants/actions/inactiveUserPageActions';

const initialState = {
  isLoadingResend: false,
  isEmailResend: false,
};

export const inactiveUserPage = (state = initialState, action) => {
  switch (action.type) {
    case inactiveUserPageActions.SetIsEmailResend:
      return {
        ...state,
        isEmailResend: action.payload,
      };
    case inactiveUserPageActions.SetIsLoadingResend:
      return {
        ...state,
        isLoadingResend: action.payload,
      };
    default:
      return state;
  }
};
