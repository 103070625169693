import React from 'react';
import { IconWrapper, Icon } from '../../styles';

export const EventNoteIcon = () => {
  return (
    <IconWrapper style={ { paddingBottom: '105%' } }>
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="4 0 23 29">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.3333 5.33329H24V2.66663H21.3333V5.33329H10.6667V2.66663H8V5.33329H6.66667C5.2 5.33329 4 6.53329 4 7.99996V26.6666C4 28.1333 5.2 29.3333 6.66667 29.3333H25.3333C26.8 29.3333 28 28.1333 28 26.6666V7.99996C28 6.53329 26.8 5.33329 25.3333 5.33329ZM25.3333 26.6666H6.66667V13.3333H25.3333V26.6666ZM6.66667 7.99996V10.6666H25.3333V7.99996H6.66667ZM9.33333 16H22.6667V18.6666H9.33333V16ZM18.6667 21.3333H9.33333V24H18.6667V21.3333Z"
        />
      </Icon>
    </IconWrapper>
  );
};
