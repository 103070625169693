import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
`;

export const Message = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;

  text-align: center;

  /* Grey 800 */

  color: #17191a;
`;

export const ButtonSection = styled.div`
  margin-top: 30px;
  width: 15%;
  height: 40px;
`;

export const EmailResend = styled.div`
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.primaryGreen};
  text-align: center;
`;
