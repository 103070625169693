import { call, takeEvery, put } from 'redux-saga/effects';
import { authAPI } from '@/api/authAPI';
import { emailApproveActions } from '@/constants/actions/emailApproveActions';
import { setIsEmailApprove, setEmailApproveErrMsg } from './actions';
import {localStorageServices} from "../../helpers";

function* emailApprove({payload : {eventId, token}}) {
  try {
    const response = yield call(authAPI.emailApprove, eventId, token);
    localStorageServices.setTokens(response.data);
    yield put(setIsEmailApprove(true));
  } catch (error) {
    yield put(setEmailApproveErrMsg('Failed to activate your account. Please, try again.'));
    yield put(setIsEmailApprove(true));
  }
}

export function* watchEmailApprovePage() {
  yield takeEvery(emailApproveActions.EmailApprove, emailApprove);
}
