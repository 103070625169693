import styled from 'styled-components';

export const AgendaTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.originBlack};
  margin-bottom: 10px;
  margin-left: 15px;
`;
export const AgendaSection = styled.div`
  padding-top: 15px;
`;
export const AgendaInfo = styled.div`
  position: relative;
  height: 300px;
  overflow-y: auto;
  display: ${({ message }) => !message && 'flex'};
  flex-direction: ${({ message }) => !message && 'column'};
  justify-content: ${({ message }) => !message && 'center'};
`;

export const Message = styled.div`
  color: ${({ theme }) => theme.colors.ieldsSummaryCardTitleGrey};
  text-align: center;
  font-weight: 700;
`;
