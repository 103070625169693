import { call, put, takeEvery, take, race, delay, select } from 'redux-saga/effects';
import { interactionPanelApi } from '@/api/interactionPanelApi';
import {
  setDialogs,
  setDialogsFail,
  setAreDialogsLoading,
  setCurrentChatData,
  setCurrentChatDataFail,
  setAreMessagesLoading,
  setSendingMessage,
  removeSendingMessage,
  putFavoriteDelegates,
  putFavoriteSessions,
  putFavoriteExhibitors,
  putCurrentChat,
  setCurrentDelegate,
  setIsDialogOpen,
  putMessageToCurrentReceivedList,
  putAllQuestions,
  putAreQuestionsLoading,
  setSendingQuestion,
  removeSendingQuestion,
  putQuestionToCurrentReceivedList,
  putUserNonActiveQuestions,
  putProcessedInvitationId,
  removeProcessedInvitationId,
  putIsChatBeingCreated,
  putBlockPuttingChatData,
  pollAllQuestions,
  stopQuestionsPolling,
} from './actions';
import {
  getCurrentChatSelector,
  getIsChatBeingCreated,
  getIsBlockPuttingChatData,
} from '@/store/interactionPanel/selectors';
import { interactionPanelActions } from '@/constants/actions/interactionPanelActions';
import { GET_CHAT_LIST_DELAY, GET_CHAT_DATA_DELAY, GET_QA_DATA_DELAY } from '@/constants/longPollingDelay';

// Chats tab

function* pollCurrentChatDataSaga({ payload }) {
  const { id: event_pk, currentDialogId } = payload;
  const isBlockingPuttinChatData = yield select(getIsBlockPuttingChatData);
  const isChatBeingCreated = yield select(getIsChatBeingCreated);

  if (isChatBeingCreated) {
    yield put(setAreMessagesLoading(false));
  } else {
    yield put(setAreMessagesLoading(true));
  }

  while (true) {
    try {
      const response = yield call(
        interactionPanelApi.getAllMessagesByDialogId,
        event_pk,
        currentDialogId,
      );
      yield put(setCurrentChatData(response.data));
      yield put(putIsChatBeingCreated(false));
      yield put(setAreMessagesLoading(false));
      yield delay(GET_CHAT_DATA_DELAY);
    } catch (error) {
      if (!error.message === 'Request failed with status code 404') {
        yield put(setCurrentChatDataFail(error));
      }
      return;
    }
  }
}

export function* watchPollCurrentChatDataSaga() {
  while (true) {
    const currentDialog = yield take(interactionPanelActions.StartMessagesPolling);
    yield race([
      call(pollCurrentChatDataSaga, currentDialog),
      take(interactionPanelActions.StopMessagesPolling),
    ]);
  }
}

function* getCurrentChatDataSaga(payload) {
  const { event_pk, currentChatId } = payload;
  const isBlockingPuttinChatData = yield select(getIsBlockPuttingChatData);
  if (!isBlockingPuttinChatData) {
    try {
      const response = yield call(
        interactionPanelApi.getAllMessagesByDialogId,
        event_pk,
        currentChatId,
      );
      yield put(setCurrentChatData(response.data));
    } catch (error) {
      if (!error.message === 'Request failed with status code 404') {
        yield put(setCurrentChatDataFail(error));
      }
      return;
    }
  }
}

function* pollDialogsSaga({ payload }) {
  yield put(putBlockPuttingChatData(true));

  while (true) {
    try {
      const response = yield call(interactionPanelApi.getAllDialogs, payload);
      yield put(setDialogs(response.data));
      yield put(setAreDialogsLoading(false));
      yield delay(GET_CHAT_LIST_DELAY);
    } catch (error) {
      yield put(setDialogsFail(error));
      yield put(setAreDialogsLoading(false));
      return;
    } 
  }
}

export function* watchPollDialogsSaga() {
  while (true) {
    const event_pk = yield take(interactionPanelActions.StartDialogsPolling);
    yield race([call(pollDialogsSaga, event_pk), take(interactionPanelActions.StopDialogsPolling)]);
  }
}

function* sendMessageSaga({ payload }) {
  const { id: event_pk, message } = payload;
  try {
    yield put(setSendingMessage(message));
    const response = yield call(interactionPanelApi.sendMessage, event_pk, message);
    const currentChat = yield select(getCurrentChatSelector);
    if (!Object.keys(currentChat).length) {
      yield put(putIsChatBeingCreated(true));
      yield put(putCurrentChat(response.data));
    }
    yield put(removeSendingMessage(message));
    yield put(putMessageToCurrentReceivedList(message));
  } catch (error) {
    console.log(error);
  }
}


function* sendInvitationStatusSaga({ payload }) {
  const { id: event_pk, invitation_pk, status } = payload;
  const { id: currentChatId } = yield select(getCurrentChatSelector);
  yield put(putProcessedInvitationId(invitation_pk));
  try {
    yield call(interactionPanelApi.sendInvitationStatus, event_pk, invitation_pk, status);
    yield call(getCurrentChatDataSaga, { event_pk, currentChatId });
    yield put(removeProcessedInvitationId(invitation_pk));
  } catch (error) {
    console.log(error);
  }
}

function* createOrGetChatSaga({ payload }) {
  const { event_pk, recipient } = payload;
  try {
    yield put(putBlockPuttingChatData(true));
    yield put(setAreMessagesLoading(true));
    yield put(putCurrentChat({}));
    yield put(setCurrentChatData({}));
    const response = yield call(
      interactionPanelApi.createOrGetChat,
      event_pk,
      recipient.id || recipient.delegateId,
    );
    yield put(setCurrentDelegate(recipient));
    yield put(putBlockPuttingChatData(false));
    yield put(putCurrentChat(response.data));
    yield put(setIsDialogOpen(true));
    yield put(setAreMessagesLoading(false));
  } catch (error) {
    console.log(error);
    yield put(setAreMessagesLoading(false));
  }
}


// --- Bookmarks - Chats

function* setFavoriteDelegatesSaga({ payload }) {
  try {
    const response = yield call(interactionPanelApi.getAllFavoriteDelegates, payload);
    yield put(putFavoriteDelegates(response.data));
  } catch (error) {
    console.log(error);
  }
}

// Bookmarks tab

function* setFavoriteSessionsSaga({ payload: event_pk }) {
  try {
    const response = yield call(interactionPanelApi.getAllFavoriteSessions, event_pk);
    yield put(putFavoriteSessions(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* setFavoriteExhibitorsSaga({ payload: event_pk }) {
  try {
    const response = yield call(interactionPanelApi.getAllFavoriteExhibitors, event_pk);
    yield put(putFavoriteExhibitors(response.data));
  } catch (error) {
    console.log(error);
  }
}

// Q&A tab

function* getUserNonActiveQuestionsSaga({ payload: event_pk }) {
  try {
    const response = yield call(interactionPanelApi.getUserNonActiveQuestions, event_pk);
    yield put(putUserNonActiveQuestions(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* pollAllQuestionsSaga({ payload: event_pk }) {
  while (true) {
    try {
      const response = yield call(interactionPanelApi.getAllQuestions, event_pk);
      yield call(getUserNonActiveQuestionsSaga, { payload: event_pk });
      yield put(putAllQuestions(response.data));
      yield put(putAreQuestionsLoading(false));
      yield delay(GET_QA_DATA_DELAY);
    } catch (error) {
      console.log(error);
    }
  }
}

export function* watchPollAllQuestionsSaga() {
  while (true) {
    const event_pk = yield take(interactionPanelActions.PollAllQuestions);
    yield race([
      call(pollAllQuestionsSaga, event_pk),
      take(interactionPanelActions.StopQuestionsPolling),
    ]);
  }
}

function* sendQuestionSaga({ payload }) {
  const { id: event_pk, question } = payload;
  try {
    yield put(stopQuestionsPolling());
    yield put(setSendingQuestion(question));
    const response = yield call(interactionPanelApi.sendQuestion, event_pk, question);
    yield put(removeSendingQuestion(question));
    yield put(putQuestionToCurrentReceivedList(response.data));
    yield put(pollAllQuestions(event_pk));
  } catch (error) {
    console.log(error);
  }
}

export function* watchInteractionPanelStuffSaga() {
  yield takeEvery(interactionPanelActions.SendMessage, sendMessageSaga);
  yield takeEvery(interactionPanelActions.SetFavoriteDelegates, setFavoriteDelegatesSaga);
  yield takeEvery(interactionPanelActions.SetFavoriteSessions, setFavoriteSessionsSaga);
  yield takeEvery(interactionPanelActions.CreateOrGetChat, createOrGetChatSaga);
  yield takeEvery(interactionPanelActions.SetFavoriteExhibitors, setFavoriteExhibitorsSaga);
  yield takeEvery(interactionPanelActions.GetUserNonActiveQuestions, getUserNonActiveQuestionsSaga);
  yield takeEvery(interactionPanelActions.SendQuestion, sendQuestionSaga);
  yield takeEvery(interactionPanelActions.SendInvitationStatus, sendInvitationStatusSaga);
}
