import { sessionsActions } from '@/constants/actions/sessionsActions';

export const setLiveSession = data => ({
  type: sessionsActions.SetLiveSession,
  payload: data,
});

export const setIsBookmarkRemainingSession = (sessionId, isBookmark) => ({
  type: sessionsActions.SetIsBookmarkRemainingSession,
  sessionId,
  isBookmark
});

//saga actions
export const startGetLiveSessionPolling = data => ({
  type: sessionsActions.StartGetLiveSessionPolling,
  payload: data,
});

export const stopGetLiveSessionPolling = data => ({
  type: sessionsActions.StopGetLiveSessionPolling,
  payload: data,
});

export const bookmarkRemainingSession = data => ({
  type: sessionsActions.BookmarkRemainingSession,
  payload: data,
});

export const unBookmarkRemainingSession = data => ({
  type: sessionsActions.UnBookmarkRemainingSession,
  payload: data,
});

