import styled from 'styled-components'
import Scrollspy from 'react-scrollspy'

export const AgendaContainer = styled.div`
  width: 1260px;
  background: #ffffff;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 1024px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
  }
`

export const DatesContainer = styled.div`
  z-index: 11;
  padding-top: 134px;
  position: sticky;
  top: 0px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    padding-top: 120px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    padding-top: 180px;
  }
`

export const SecondContainer = styled.div`
  z-index: 9;
  padding-top: 150px;
  position: sticky;
  top: 384px;
  width: 100%;
  height: 250px;
  background: #17191A;
  border: 1px solid #17191A;
  border-radius: 0px 0px 8px 8px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    top: 370px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    border-radius: 0px;
    top: 330px;
    height: 150px;
  }
`

export const Container = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #17191A;
  border: 1px solid #17191A;
  border-radius: 8px 8px 0px 0px;
  padding: 0px 60px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    padding: 0px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    border-radius: 0px;
    padding: 0px 30px;
    height: 150px;
  }
`

export const Title = styled.div`
  width: 100%;
  height: 42px;
  margin-bottom: 20px;
  margin-top: 55px;
  display: flex;
  align-items: center;
`

export const TitleLineText = styled.div`
  margin: 0px 15px;
  display: block;
  word-wrap: normal!important;
  font-weight: bold;
  font-size: 35px;
  line-height: 42px;
  color: #FFFFFF;
`

export const TitleLine = styled.div`
  flex: 1;
  height: 1px;
  background: #373A3C;
`

export const ScrollSpySections = styled(Scrollspy)`
  width: 100%;
  margin-top: 45px;
  display: flex;
  justify-content: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    display: none;
  }
`

export const OneScrollSpySections = styled.div`
  width: 200px;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #808B94;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 150px;
    font-size: 15px;
    line-height: 20px;
  }
`

export const AgendaData = styled.div`
  z-index: 10;
  position: sticky;
  top: 400px;
  border-radius: 4px 4px 0px 0px;
  padding: 0px 60px;
  margin-top: -240px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    padding: 0px 50px;
  }
`
