import React from 'react';
import { IconWrapper, Icon } from '../../styles';

export const ViewQuiltIcon = () => {
  return (
    <IconWrapper style={{ paddingBottom: '100%' }}>
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 15">
      <path 
        fillRule="evenodd"
        clipRule="evenodd" 
        d="M0.083374 0.416626V15.5833H19.9167V0.416626H0.083374ZM2.41671 13.25V2.74996H5.91671V13.25H2.41671ZM8.25004 9.16663V13.25H11.75V9.16663H8.25004ZM17.5834 13.25H14.0834V9.16663H17.5834V13.25ZM8.25004 2.74996V6.83329H17.5834V2.74996H8.25004Z"
      />
      </Icon>
    </IconWrapper>
  );
};
