import React from 'react';
import { isEqual } from 'deep-equal';
import { Divider } from '@/components/controls/Divider';
import Info from './components/Info';
import SignInPrompt from './components/SignInPrompt';
import styles from './register.module.css';
import Navbar from './components/Navbar';

const RegistrationWrapper = ({ stepData: data, steps, children, event }) => {

  return (
    <div className={styles['container']}>
      <div className={styles['layout-container']}>
        <div className={styles['sidebar-container']}>
          <div className={styles['logo-container_sidebar']}>
            <img src={event.logo} className={styles['logo_sidebar']} alt="Branding" />
          </div>
          <div className={styles['divider-container_sidebar']}>
            <Divider base />
          </div>
          <header className={styles['header-container_sidebar']}>
            <h1 className={`${styles.header} ${styles['header_sidebar']}`}>{data.header}</h1>
            <Info
              linkConfig={data.prompt?.link}
              className={`${styles['info']} ${styles['info_sidebar']}`}
            >
              {data.info}
            </Info>
          </header>
        </div>

        <div className={styles['body-container']}>
          <div className={styles['logo-container_body']}>
            <img src={event.logo} className={styles['logo_body']} alt="Branding" />
          </div>
          <div className={styles.navbar}>
            {data.showNavbar ? (
              <Navbar config={steps} activeIdx={data.stepIdx} spacing={styles['navbar_spacing']} />
            ) : (
                <div className={styles['empty-header']}></div>
              )}
          </div>
          <div
            className={`${styles['divider-container_body']} ${
              data.showNavbar ? '' : styles['divider-container_body_hidden']
              }`}
          >
            <Divider base />
          </div>
          <header className={styles['header-container_body']}>
            <h1 className={`${styles.header} ${styles['header_body']}`}>{data.header}</h1>
            <Info
              linkConfig={data.prompt?.link}
              className={`${styles['info']} ${styles['info_body']}`}
            >
              {data.info}{' '}
              {data.stepIdx === 0 && (
                <SignInPrompt config={data.prompt} className={styles['sign-in-prompt_inline']} />
              )}
            </Info>
          </header>
          <div className={styles['form-container']}>{children}</div>
          <div className={styles['sign-in-prompt_separate']}>
            {data.stepIdx === 0 && <SignInPrompt config={data.prompt} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RegistrationWrapper, isEqual);
