import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HelpText from '@/components/controls/HelpText';
import { Field, FieldArray, Form, Formik, ErrorMessage } from 'formik';
import { CustomCheckbox } from '@/components/controls/Checkbox';
import GenericButton from '@/components/controls/buttons/GenericButton';
import { multiSelectSchema } from '@/components/controls/validationSchemas/multiSelectSchema';
import { getRegistrationFieldsValues } from '@/store/registrationFieldsValues/selector';
import { getRegistrationData } from '@/store/registration/selectors';
import styles from './styles.module.css';
import { getIsLoadingInfo } from '@/store/checkFormValidation';
import { getRegistrationStep } from "@/store/registration";

export const MultiSelectForm = ({ submitAction, addStoreData, next }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const fieldValues = useSelector(getRegistrationFieldsValues);
  const [allInterests, setInterests] = useState(fieldValues.interests);
  const formRef = useRef();
  const { interests, interestsIn } = useSelector(getRegistrationData);
  const isLoading = useSelector(getIsLoadingInfo);
  const step = useSelector(getRegistrationStep)

  useEffect(() => {
    fieldValues.interests && setInterests(fieldValues.interests);
  }, [fieldValues]);

  const resetForm = () => {
    const { current } = formRef;
    current.resetForm();
  }

  const onSubmit = values => {
    dispatch(submitAction({ values, next, putFunc: addStoreData, event_pk: id, resetForm: resetForm }));
  };

  useEffect(() => {
    const { current } = formRef;

    if (step === 'interests' && interests.selectInterests !== undefined) {
      current.setFieldValue('selectInterests', interests.selectInterests);
    }
    if (step === 'interestedIn' && interestsIn.selectInterests !== undefined) {
      current.setFieldValue('selectInterests', interestsIn.selectInterests);
    }
  }, [interests, formRef, step, interestsIn.selectInterests]);

  return (
    <div>
      <div className={styles['form__help-text']}>
        <HelpText>Please select at least 2 interests</HelpText>
      </div>
      <Formik
        initialValues={{ selectInterests: [] }}
        onSubmit={onSubmit}
        validationSchema={multiSelectSchema}
        validateOnChange={false}
        innerRef={formRef}
      >
        {({ values }) => (
          <Form className={styles['form-container']}>
            <FieldArray
              name="selectInterests"
              render={arrayHelpers => (
                <div>
                  <div className={styles.form}>
                    {allInterests &&
                      allInterests.map((interest, index) => (
                        <div key={interest.id} className={styles['form__field']}>
                          <Field name={'selectInterests'}>
                            {({ field, form }) => (
                              <CustomCheckbox
                                {...field}
                                form={form}
                                value={interest.id}
                                checked={values.selectInterests.some(
                                  selectInt => selectInt.id === interest.id,
                                )}
                                item
                                label={interest.value}
                                onChange={e => {
                                  if (e.target.checked)
                                    arrayHelpers.push({ id: interest.id, value: interest.value });
                                  else {
                                    const idx = values.selectInterests.findIndex(
                                      selectInt => selectInt.id === interest.id,
                                    );
                                    arrayHelpers.remove(idx);
                                  }
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      ))}
                  </div>
                  <ErrorMessage name="selectInterests">
                    {msg => <div className={styles['error-text']}>{msg}</div>}
                  </ErrorMessage>
                </div>
              )}
            />
            <GenericButton
              label="Continue"
              styleType="primary"
              type="submit"
              isLoading={isLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
