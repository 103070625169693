import React from 'react';
import { isEqual } from 'deep-equal';
import { Interest, InterestCardContainer } from './styles';

const InterestCard = ({ name }) => {
  return (
    <InterestCardContainer>
      <Interest>{name}</Interest>
    </InterestCardContainer>
  );
};

export default React.memo(InterestCard, isEqual);
