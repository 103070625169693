import { demandActions } from '@/constants/actions/demandActions';

export const setSession = data => ({
  type: demandActions.SetSession,
  payload: data,
});

export const setEventPartners = data => ({
  type: demandActions.SetEventPartners,
  payload: data,
});

export const setIsFetchingDemand = data => ({
  type: demandActions.SetIsFetchingDemand,
  payload: data,
});

//saga actions
export const getSession = data => ({
  type: demandActions.GetSession,
  payload: data,
});

export const getEventPartners = data => ({
  type: demandActions.GetEventPartners,
  payload: data,
});

