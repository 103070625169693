import React, { useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'deep-equal';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChatInput from './components/ChatInput';
import Messages from './components/Messages';
import {
  startMessagesPolling,
  stopMessagesPolling,
  setIsDialogOpen,
  sendMessage,
} from '@/store/interactionPanel/actions';
import {
  getCurrentChatSelector,
  getCurrentChatDataSelector,
  getAreMessagesLoadingSelector,
  getCurrentDelegateSelector,
} from '@/store/interactionPanel/selectors';
import ContactCard from '@/components/blocks/ContactCard';
import {
  Container,
  DialogHeader,
  DialogBody,
  MessageAuthor,
  ArrowLeftOutlinedIcon,
} from './styles';
import { EmptyContainer } from '../InteractionPanel/styles';

const Chat = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const messagesRef = useRef(null);
  const currentDelegate = useSelector(getCurrentDelegateSelector);
  const currentChat = useSelector(getCurrentChatSelector);
  const currentChatData = useSelector(getCurrentChatDataSelector);
  const isLoading = useSelector(getAreMessagesLoadingSelector);

  const handleCloseDialog = () => {
    dispatch(setIsDialogOpen(false));
  };

  useLayoutEffect(() => {
    if (currentChat.id) {
      dispatch(startMessagesPolling({ currentDialogId: currentChat.id, id }));
    }
    return () => {
      dispatch(stopMessagesPolling());
    };
  }, [id, currentChat, dispatch]);

  const handleSendMessage = val => {
    const message = {
      recipient: currentDelegate.id || currentDelegate.delegateId,
      text: val,
      key: Math.random().toString(36).substring(7),
    };
    dispatch(sendMessage({ message, id }));
  };

  return (
    <Container>
      {!isLoading && Object.values(currentDelegate).length > 0 ? (
        <>
          <DialogHeader>
            <ArrowLeftOutlinedIcon onClick={handleCloseDialog} />
            <MessageAuthor>
              <ContactCard
                delegateId={currentDelegate.id || currentDelegate.delegateId}
                name={`${currentDelegate.first_name} ${currentDelegate.last_name}`}
                organisation={currentDelegate.organisation}
                jobRole={currentDelegate.role}
                photo={currentDelegate.headshot}
                isOnline={currentDelegate.online}
              />
            </MessageAuthor>
          </DialogHeader>
          <DialogBody>
            {currentChatData && Object.keys(currentChatData).length > 0 ? (
              <Messages
                messages={currentChatData.messages_set ? currentChatData.messages_set : []}
                invitations={currentChatData.invitations_set ? currentChatData.invitations_set : []}
                messagesRef={messagesRef}
              />
            ) : (
              <EmptyContainer>
                <span>You don't have any messages yet</span>
              </EmptyContainer>
            )}
            <ChatInput handleSendMessage={handleSendMessage} />
          </DialogBody>
        </>
      ) : (
        <EmptyContainer>
          <CircularProgress />
        </EmptyContainer>
      )}
    </Container>
  );
};

export default React.memo(Chat, isEqual);
