export const registrationFieldaDataMapper = data => {
  let countries = data.data.countries_set
  let interests = data.data.interests
  let role = data.data.job_role
  let organisation = data.data.organisation_type

  interests = interests.map(item => ({
    id: item.id,
    value: item.name,
  }));

  role = role.map(item => ({
    id: item.id,
    label: item.name,
  }));

  organisation = organisation.map(item => ({
    id: item.id,
    label: item.name,
  }));

  countries = countries.map(item => ({
    id: item.id,
    label: item.name,
  }));

  for (let country in data.countries) {
    countries.push({
      id: country,
      label: data.countries[country],
    });
  }

  return {
    countries,
    interests,
    role,
    organisation,
  };
};
