import { createSelector } from 'reselect';

export const getPromocodeData = state => state.promocode;

export const getPromocodeStatus = createSelector([getPromocodeData], state => {
  return state.promocodeStatus;
});

export const getPromocode = createSelector([getPromocodeData], state => {
  return state.promocode;
});

export const getPromocodeError = createSelector([getPromocodeData], state => {
  return state.error;
});