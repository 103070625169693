import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 200px);
  width: 400px;
  height: 400px;
  padding: 10px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.regularText};
`;

export const Details = styled.details`
  max-height: 300px;
  overflow-y: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  margin-top: 10px;
  padding: 10px;
  &::-webkit-scrollbar-track {
    background: #f2f4fa;
    border-radius: 16px;
  }
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background: #f2f4fa;
    border-radius: 16px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c9cbd0;
    border-radius: 16px;
  }
`;

export const BtnWrapper = styled.div`
  width: 200px;
  margin-top: 15px;
`;
