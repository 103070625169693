import React from 'react';
import Avatar from 'react-avatar';
import { mystic, white } from '@/constants/colors';
import { useSelector } from 'react-redux';
import { getEventInfo } from '@/store/platformInfo';
import {EventName, PlatformLogoContainer} from "./styles";

const PlatformLogo = () => {
  const { logo, name } = useSelector(getEventInfo);
  return (
    <PlatformLogoContainer>
      <Avatar
        name={`logo`}
        maxInitials={1}
        src={logo}
        size="40"
        round="50%"
        textSizeRatio={1.5}
        color={mystic}
        fgColor={white}
      />
      <EventName>
        {name}
      </EventName>
    </PlatformLogoContainer>
  );
};

export default PlatformLogo;
