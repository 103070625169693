import React, { useEffect, useState } from 'react';
import { isEqual } from 'deep-equal';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getRegistrationStep } from '@/store/registration/selectors';
import Preloader from '@/components/blocks/Preloader';
import { getEvent } from '@/store/landing/selectors'
import RegistrationWrapper from '@/components/wrappers/Registration';
import { getEventData } from '@/store/landing/actions'
import { getTicketType, setTicketType } from '@/store/registration'
import { setPromocodeStatus, onCheckPromocode } from '@/store/promocodeValidation/actions'
import { onGetRegistrationValues } from '@/store/registrationFieldsValues/actions'
import config from './config';

/* 
signUp
profile
bio
interests
interestedIn
summary
checkout
confirmation
*/

const RegistrationPage = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const event = useSelector(getEvent)

  const regStep = useSelector(getRegistrationStep)
  const [step, setStep] = useState(regStep)
  const [data, setData] = useState(undefined)
  const ticketType = useSelector(getTicketType)

  useEffect(() => {
    setStep(regStep)
  }, [regStep])

  useEffect(() => {
    step !== '' && setData(config[step])
  }, [step])

  useEffect(() => {
    dispatch(getEventData(id))
    dispatch(onGetRegistrationValues(id))
    if (ticketType === null) {
      let type
      if (sessionStorage.getItem('ticketType') === 'false') {
        type = false
      } else {
        type = true
      }
      dispatch(setTicketType(type))
    }
    let promocodeStatus
    if (sessionStorage.getItem('promocodeStatus') === 'false') {
      promocodeStatus = false
    } else {
      promocodeStatus = true
    }
    dispatch(onCheckPromocode(sessionStorage.getItem('promocode')))
    dispatch(setPromocodeStatus(promocodeStatus))
  }, [id, dispatch, ticketType])

  return (
    <>
      {
        (!event.name || data === undefined || step === '') ? (
          <Preloader />
        )
          : (
            <RegistrationWrapper stepData={data} steps={config} event={event}>
              {data.content}
            </RegistrationWrapper>
          )
      }
    </>
  );
};

export default React.memo(RegistrationPage, isEqual);
