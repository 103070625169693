import React from 'react';
import { isEqual } from 'deep-equal';
import { Container, Title, OptionContainer, InputCheckbox, Text } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileInfo, updateIsMarketingAllowed, updateIsAnonymous } from '@/store/profilePage';
import { useParams } from 'react-router-dom';

const PrivacySettings = ({ delegateId }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { is_marketing_allowed, is_anonymous } = useSelector(getProfileInfo);

  const onChangeIsMarketingAllowed = e => {
    dispatch(updateIsMarketingAllowed({ id, delegateId, isMarketingAllowed: e.target.checked }));
  };

  const onChangeIsAnonymous = e => {
    dispatch(updateIsAnonymous({ id, delegateId, isAnonymous: e.target.checked }));
  };

  return (
    <Container>
      <Title>Privacy settings</Title>
      <OptionContainer>
        <InputCheckbox
          type="checkbox"
          id="isMarketingAllowedId"
          name="isMarketingAllowed"
          onChange={onChangeIsMarketingAllowed}
          checked={is_marketing_allowed}
        />
        <Text htmlFor="isMarketingAllowedId">
          Check this box to allow the event organizer to email you marketing materials
        </Text>
      </OptionContainer>
      <OptionContainer>
        <InputCheckbox
          type="checkbox"
          id="isAnonymousId"
          name="isAnonymous"
          onChange={onChangeIsAnonymous}
          checked={is_anonymous}
        />
        <Text htmlFor="isAnonymousId">
          Please check this box if you agree to share your personal and contact information with
          exhibitors and sponsors
        </Text>
      </OptionContainer>
    </Container>
  );
};
export default React.memo(PrivacySettings, isEqual);
