import styled from 'styled-components';

export const CardWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.agenda.break.background};
  padding: 30px;
`;

export const CardHeaderSection = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;
`;

export const CardBodySection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.agenda.break.title};
`;

export const Time = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.agenda.break.timeLabel};
  flex-grow: 1;
  display: flex;
  align-items: center;
`;
