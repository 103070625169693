import {eventExistsActions} from "@/constants/actions/eventExists";

const initialValue = {
  eventStatus: '',
  eventChecked: false
};

export const eventExistsReducer = (state = initialValue, {type, payload}) => {
  switch (type) {
    case eventExistsActions.SetValue:
      return {
        ...state,
        eventStatus: payload
      }
    case eventExistsActions.SetChecked:
      return {
        ...state,
        eventChecked: payload
      }
    default:
      return state;
  }
}
