import React from 'react';
import { IconWrapper, Icon} from '../../styles';

export const ForumIcon = () => {
  return (
    <IconWrapper style={ { paddingBottom: '100%' } }>
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="3 3 22 22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.6667 2.33337H3.50004C2.85837 2.33337 2.33337 2.85837 2.33337 3.50004V19.8334L7.00004 15.1667H18.6667C19.3084 15.1667 19.8334 14.6417 19.8334 14V3.50004C19.8334 2.85837 19.3084 2.33337 18.6667 2.33337ZM17.5 4.66671V12.8334H6.03171L4.66671 14.1984V4.66671H17.5ZM22.1667 7.00004H24.5C25.1417 7.00004 25.6667 7.52504 25.6667 8.16671V25.6667L21 21H8.16671C7.52504 21 7.00004 20.475 7.00004 19.8334V17.5H22.1667V7.00004Z"
        />
      </Icon>
   </IconWrapper>
  );
};
