import { call, put, takeEvery } from 'redux-saga/effects'
import { LandingActions } from '@/constants/actions/landingActions'
import {
  onGetEventData,
  onGetEventSpeakersData,
  putSponsorsData,
  onGetEventAgendaData
} from './actions'
import { landingAPI } from '../../api/landingApi'
import { exibitorsMapper } from '@/helpers/sponsorsMapper'

function* getEventData(action) {
  try {
    const response = yield call(landingAPI.eventData, action.payload)
    yield put(onGetEventData(response.data))
    const sponsors = yield exibitorsMapper(response.data.exhibitors)
    yield put(putSponsorsData(sponsors))
  } catch (error) {
    console.log(error);
  }
}

function* getEventSpeakersData(action) {
  try {
    const response = yield call(landingAPI.eventSpeakersData, action.payload);
    yield put(onGetEventSpeakersData(response.data))
  } catch (error) {
    console.log(error);
  }
}

function* getEventAgendaData(action) {
  try {
    const response = yield call(landingAPI.fetchAgenda, action.payload);
    yield put(onGetEventAgendaData(response.data))
  } catch (error) {
    console.log(error);
  }
}

export function* watchEventData() {
  yield takeEvery(LandingActions.GetEventData, getEventData);
  yield takeEvery(LandingActions.GetEventAgendaData, getEventAgendaData);
  yield takeEvery(LandingActions.GetEventSpeakersData, getEventSpeakersData);
}