import React from 'react'
import { isEqual } from 'deep-equal'
import PartnersGroup from './components/PartnersGroup'
import {
  SponsorsContainer,
  SponsorsTitle,
} from './styles'


const SponsorsAndPartners = ({ partners }) => {

  return (
    <SponsorsContainer>
      <SponsorsTitle>
        Sponsors & Partners
      </SponsorsTitle>
      {
        partners?.map(partnersGroup => (
          <PartnersGroup partnersGroup={partnersGroup} key={partnersGroup.id} />
        ))
      }
    </SponsorsContainer>
  )
}

export default React.memo(SponsorsAndPartners, isEqual)
