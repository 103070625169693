import React, { useCallback, useState } from 'react';
import { isEqual } from 'deep-equal';
import { Menu, MenuBody, MenuHeader, MenuItem, Navigation, SubMenu, SubMenuItem } from './styles';
import arrow from './icons/arrow.svg';
import doneIcon from './icons/doneIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSomeCheckedFilter,
  getCheckedFilters,
  removeCheckedFilters,
  setCheckedFilters,
} from '@/store/networkingPage';

const DropDown = ({ title, subMenu }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const networkingCheckedFilters = useSelector(getCheckedFilters);

  const handleOpen = useCallback(() => {
    setShow(prev => !prev);
  }, []);

  const onChange = (e, item) => {
    if (e.target.checked) {
      dispatch(setCheckedFilters(title, { id: item.id, name: item.name }));
    } else {
      dispatch(removeCheckedFilters(title, { id: item.id, name: item.name }));
    }
  };

  const isChecked = useCallback(
    item => {
      return networkingCheckedFilters[title].some(i => i.id === item.id);
    },
    [networkingCheckedFilters],
  );

  const onResetDropDown = () => {
    dispatch(clearSomeCheckedFilter(title));
  };

  return (
    <Navigation>
      <Menu>
        <MenuItem active={show}>
          <div onClick={handleOpen}>
            <span>{title.replace('_', ' ')}</span>
            {<img src={arrow} alt="arrow_icon" />}
          </div>
          <SubMenu show={show}>
            <MenuHeader>
              <div>{networkingCheckedFilters[title].length} selected</div>
              {networkingCheckedFilters[title].length ? <div onClick={() => onResetDropDown()}>Reset</div> : ''}
            </MenuHeader>
            <MenuBody>
              {subMenu.map(item => (
                <SubMenuItem key={`${title}_${item.id}`} check={isChecked(item)}>
                  <label htmlFor={`${title}_${item.id}`}>{item.name}</label>
                  <img src={doneIcon} alt="done_icon" />
                  <input
                    checked={isChecked(item)}
                    type="checkbox"
                    id={`${title}_${item.id}`}
                    name={item.name}
                    value={item.name}
                    onChange={e => onChange(e, item)}
                  />
                </SubMenuItem>
              ))}
            </MenuBody>
          </SubMenu>
        </MenuItem>
      </Menu>
    </Navigation>
  );
};
export default React.memo(DropDown, isEqual);
