import React from 'react';
import { isEqual } from 'deep-equal';
import styles from './styles.module.css';
import { ReactComponent as InfoIcon } from './icons/info.svg';

const HelpText = props => {
  const content = props.children;
  return (
    <div className={styles['help-component']}>
      <InfoIcon className={styles['help-icons']} />
      <small className={styles['help-text']}>{content}</small>
    </div>
  );
};

export default React.memo(HelpText, isEqual);
