import styled from 'styled-components';
import { Input } from 'antd';
import {
  SmileOutlined,
  CameraOutlined,
  AudioOutlined,
  SendOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';

export const Container = styled.div`
  position: relative;
  height: 80px;
  display: flex;
  border-top: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  outline: none;
`;

export const SmileOutlinedIcon = styled(SmileOutlined)`
  position: absolute;
  z-index: 1;
  font-size: 18px;
  cursor: pointer;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CameraOutlinedIcon = styled(CameraOutlined)`
  font-size: 18px;
  cursor: pointer;
`;

export const PaperClipOutlinedIcon = styled(PaperClipOutlined)`
  margin-left: 12px;
  font-size: 18px;
  cursor: pointer;
`;

export const AudioOutlinedIcon = styled(AudioOutlined)`
  margin-left: 12px;
  font-size: 18px;
  cursor: pointer;
`;

export const SendOutlinedIcon = styled(SendOutlined)`
  position: relative;
  margin: 0 30px 0 12px;
  font-size: 28px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.sendMessageIcon};
`;

export const DialogInput = styled(Input)`
  padding-left: 30px;
  border: none;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  flex-grow: 1;

  &:focus {
    box-shadow: 0;
  }
`;

export const PickerWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  z-index: 1;
`;

export const Report = styled.span`
  position: relative;
  top: -15px;
  margin-right: 30px;
  align-self: flex-end;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => `1px solid ${theme.colors.textDescriptionGrey}`};
  text-transform: capitalize;
  text-decoration: underline;
  cursor: pointer;
`;

export const SendButtonWrapper = styled.button`
  border: none;
  outline: none;
  background: transparent;

  & > span {
    transition: ${({ disabled }) => !disabled && `color 0.3s ease 0s`};
  }

  & > span:hover {
    color: ${({ disabled }) => !disabled && `#000000`};
  }
`;
