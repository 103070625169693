import React, { useCallback, useEffect } from 'react';
import { isEqual } from 'deep-equal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RadioButtonRow from './components/RadioButtonRow';
import PollSection from './components/PollSection';
import QASection from './components/QASection';
import { getStreamStatus } from '@/store/eventManagementPage/actions';
import EventManagementChart from './components/EventManagementChart/component';
import {
  getStreamStatusInfo,
  getStreamStatusLoaderInfo,
} from '@/store/eventManagementPage/selectors';

import { Container, NetworkingSection, StatisticSection, Text, Title, Wrapper } from './styles';

const EventManagement = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const streamStatus = useSelector(getStreamStatusInfo);
  const streamStatusLoader = useSelector(getStreamStatusLoaderInfo);
  const btnLoader = streamStatusLoader || streamStatus === null;

  useEffect(() => {
    dispatch(getStreamStatus(id));
  }, [id, dispatch]);

  const changeStatusHandler = useCallback(
    () =>
      dispatch(
        getStreamStatus(id, {
          stream_active: !streamStatus,
        }),
      ),
    [streamStatus, id, dispatch],
  );

  return (
    <Container>
      <Title>
        <Text>Event Management</Text>
        <RadioButtonRow
          onClick={changeStatusHandler}
          textActive="Streaming on"
          textInactive="Streaming off"
          isActive={streamStatus}
          isLoading={btnLoader}
        />
      </Title>
      <StatisticSection>
        <EventManagementChart />
      </StatisticSection>
      <NetworkingSection>
        <Wrapper>
          <QASection />
        </Wrapper>
        <Wrapper>
          <PollSection />
        </Wrapper>
      </NetworkingSection>
    </Container>
  );
};

export default React.memo(EventManagement, isEqual);
