import React from 'react';
import ReactDom from 'react-dom';
import { isEqual } from 'deep-equal';

import { Overlay, ModalWindow } from './styles';

const Modal = ({ children, adaptiveSize }) => {
  return ReactDom.createPortal(
    <Overlay>
      <ModalWindow adaptiveSize={adaptiveSize}>{children}</ModalWindow>
    </Overlay>,
    document.querySelector('#global-modal'),
  );
};
export default React.memo(Modal, isEqual);
