import styled from 'styled-components';

export const Container = styled.div`
  width: 710px;
  height: 480px;
  box-sizing: border-box;
  padding: 30px 35px;
`;
export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
`;

export const CloseIcon = styled.div`
  align-self: flex-start;
  cursor: pointer;
`;
