import { inactiveUserPageActions } from '@/constants/actions/inactiveUserPageActions';

export const setIsLoadingResend = data => ({
  type: inactiveUserPageActions.SetIsLoadingResend,
  payload: data,
});

export const setIsEmailResend = data => ({
  type: inactiveUserPageActions.SetIsEmailResend,
  payload: data,
});


//saga actions
export const resendTicket = data => ({
  type: inactiveUserPageActions.ResendTicket,
  payload: data,
});

