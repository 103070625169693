import { RegistrationActions } from '@/constants/actions/registrationActions'

export const onUserRegistration = (data) => ({
  type: RegistrationActions.OnUserRegistration,
  payload: data,
})

export const addDetailsInfo = (data) => ({
  type: RegistrationActions.AddDetailsInfoData,
  payload: data,
})

export const addProfileInfo = (data) => ({
  type: RegistrationActions.AddProfileInfoData,
  payload: data,
})

export const addBioInfo = (data) => ({
  type: RegistrationActions.AddBioInfoData,
  payload: data,
})

export const addInterestsInfo = (data) => ({
  type: RegistrationActions.AddInterestsInfoData,
  payload: data,
})

export const addInterestsInInfo = (data) => ({
  type: RegistrationActions.AddInterestsInInfoData,
  payload: data,
})

export const setRegistrationStep = (data) => ({
  type: RegistrationActions.SetRegistrationStep,
  payload: data,
})

export const setErrorMsg = (data) => ({
  type: RegistrationActions.SetErrorMsg,
  payload: data,
})

export const setIsLoading = (data) => ({
  type: RegistrationActions.SetIsLoading,
  payload: data,
})

export const setTicketType = (data) => ({
  type: RegistrationActions.SetTicketType,
  payload: data,
})

export const checkTicketPrice = (data) => ({
  type: RegistrationActions.CheckTicketPrice,
  payload: data,
})

export const setTicketPrice = (data) => ({
  type: RegistrationActions.OnCheckTicketPrice,
  payload: data,
})
