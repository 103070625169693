import React, { useEffect } from 'react';
import isEqual from 'deep-equal';
import { Wrapper } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPlatformInfo,
  startIsUserOnlinePolling,
  stopIsUserOnlinePolling,
  getIsFetchingDataInfo,
  setLocationPathName,
} from '@/store/platformInfo';
import { useParams, useLocation } from 'react-router-dom';
import { startGetLiveSessionPolling, stopGetLiveSessionPolling } from '@/store/sessions';
import { startGetPollsPolling, stopGetPollsPolling } from '@/store/polls';
import Preloader from '../../blocks/Preloader';
import {
  startBroadcastMessagesPolling,
  stopBroadcastMessagesPolling,
} from '@/store/broadcastMessages';
import { startGetNotificationsPolling, stopGetNotificationsPolling } from '@/store/notifications';
import PlatformPage from '../../pages/Platform/component';
import { stopEventStatisticPolling } from '@/store/eventManagementPage';
import { generateUniqueId } from '@/helpers';
import { getUserStatus, setUserStatus } from '@/store/isDelegateCheck';
import { generateBlockedUserPage } from '@/constants/routes';
import { history } from '@/store/rootReducer';
import { localStorageServices } from '@/helpers';

const PlatformRequestWrapper = () => {
  const dispatch = useDispatch();
  const isFetchingDataInfo = useSelector(getIsFetchingDataInfo);
  const userStatus = useSelector(getUserStatus);
  const { id } = useParams();
  const location = useLocation();

  if (userStatus === 'blocked') {
    history.replace(generateBlockedUserPage(id));
    localStorageServices.clearAllTokens();
    dispatch(setUserStatus(''))
  }

  useEffect(() => {
    dispatch(getPlatformInfo(id));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(setLocationPathName(location.pathname));
  }, [id, location.pathname]);

  useEffect(() => {
    dispatch(startIsUserOnlinePolling({ eventId: id, sessionId: generateUniqueId(id) }));
    dispatch(startGetLiveSessionPolling(id));
    dispatch(startGetPollsPolling());
    dispatch(startBroadcastMessagesPolling(id));
    dispatch(startGetNotificationsPolling(id));

    return () => {
      dispatch(stopIsUserOnlinePolling({ eventId: id, sessionId: generateUniqueId(id) }));
      dispatch(stopEventStatisticPolling(id));
      dispatch(stopGetLiveSessionPolling(id));
      dispatch(stopGetPollsPolling());
      dispatch(stopBroadcastMessagesPolling(id));
      dispatch(stopGetNotificationsPolling(id));
    };
  }, [id]);

  return (
    <>
      {isFetchingDataInfo ? (
        <Preloader label="Preparing the platform" />
      ) : (
        <Wrapper>
          <PlatformPage />
        </Wrapper>
      )}
    </>
  );
};

export default React.memo(PlatformRequestWrapper, isEqual);
