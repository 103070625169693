import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getTimeString, getDateTimeString } from '@/helpers/date';
import IconButton from '../IconButton';
import { sendInvitationStatus } from '@/store/interactionPanel/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  InvitationCardConainer,
  Body,
  Content,
  Controls,
  EventTitle,
  EventDate,
  Description,
  EventLink,
  ControlsSection,
  IvatationStatus,
  Author,
  LoaderWrapper,
} from './styles';

const InvitationCard = ({
  invitation_pk,
  title,
  date,
  description,
  link,
  status,
  isCurrentUserInvitation,
  chatRecipientName,
  created_date,
  isProcessedInvitation,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const handleSendNewStatus = status => {
    dispatch(sendInvitationStatus({ id, invitation_pk, status }));
  };

  return (
    <InvitationCardConainer>
      <Author isCurrentUserMessage={isCurrentUserInvitation}>
        {isCurrentUserInvitation ? 'You' : chatRecipientName}
        {created_date && ` - ${getTimeString(created_date)}`}
      </Author>
      <Body isCurrentUserInvitation>
        <Content isProcessedInvitation>
          {!isProcessedInvitation ? (
            <>
              <EventTitle>{title}</EventTitle>
              <EventDate>{getDateTimeString(date)}</EventDate>
              <Description>{description}</Description>
              <EventLink href={link} target="_blank" rel="noreferrer noopener">{link}</EventLink>
            </>
          ) : (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )}
        </Content>
        {!isCurrentUserInvitation && (
          <Controls>
            <ControlsSection>
              <IconButton
                text="accept"
                icon={<CheckOutlined fill="black" />}
                onClick={() => handleSendNewStatus('accepted')}
                disabled={status === 'accepted' || isProcessedInvitation}
                color="#2AC262"
              />
            </ControlsSection>
            <ControlsSection>
              <IconButton
                text="reject"
                icon={<CloseOutlined />}
                onClick={() => handleSendNewStatus('rejected')}
                disabled={status === 'rejected' || isProcessedInvitation}
                color="#DF3541"
              />
            </ControlsSection>
          </Controls>
        )}
      </Body>
      {status !== 'pending' && !isProcessedInvitation && (
        <IvatationStatus isAccepted={status}>
          {status === 'accepted' && 'Invitation accepted'}
          {status === 'rejected' && 'Invitation rejected'}
        </IvatationStatus>
      )}
    </InvitationCardConainer>
  );
};

export default InvitationCard;
