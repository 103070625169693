import styled from 'styled-components';
import { ButtonOutline } from '@/components/controls/buttons/ButtonOutline';

export const InteractionContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.originWhite};

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    position: fixed;
    width: 100%;
    height: calc(100% - 88px);
    top: 0;
    left: 0;
    z-index: 1;
  }
`;

export const TabsContainer = styled.div`
  height: 56px;
  display: flex;
`;

export const Tab = styled.button`
  width: calc(100% / 3);
  margin: 0px;
  font-size: 15px;
  font-weight: 700;
  background: ${({ theme }) => theme.colors.originWhite};
  color: ${({ theme, active }) =>
    active ? theme.colors.regularText : theme.colors.fieldsSummaryCardTitleGrey};
  border: none;
  border-left: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  border-bottom: ${({ theme, active }) =>
    active ? 'none' : `1px solid ${theme.colors.tabBorder}`};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:first-child {
    border-left: none;
  }
`;

export const BodyContainer = styled.div`
  height: calc(100% - 56px);
  max-height: calc(100% - 56px);
`;

export const CustomButtonOutline = styled(ButtonOutline).attrs(({ theme }) => ({
  color: theme.colors.primaryGreen,
}))``;

export const EmptyContainer = styled.div`
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  font-size: 15px;
  font-weight: 400;
  text-align: center;
`;

export const EmptyContainerButtonWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
`;
