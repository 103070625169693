import { isDelegateCheckActions } from '../../constants/actions/isDelegateCheck';

export const setError = error => ({
  type: isDelegateCheckActions.SetError,
  payload: error,
});

export const isDelegateCheckWatch = event_pk => ({
  type: isDelegateCheckActions.IsDelegateCheckWatch,
  payload: event_pk,
});

export const setAccess = isAcessAllowed => ({
  type: isDelegateCheckActions.SetAccess,
  payload: isAcessAllowed,
});

export const setUserStatus = (data) => ({
  type: isDelegateCheckActions.SetUserStatus,
  payload: data,
});

