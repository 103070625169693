import { networkingActions } from '../../constants/actions/networkingActions';

export const setSearchMsg = data => ({
  type: networkingActions.SetSearch,
  payload: data,
});

export const setSortInfo = data => ({
  type: networkingActions.SetSort,
  payload: data,
});

export const setFilters = (data) => ({
  type: networkingActions.SetFilters,
  payload: data
})

export const setCheckedFilters = (title, item) => {
  return {type: networkingActions.SetCheckedFilters,
  title,
  item,}
};

export const removeCheckedFilters = (title, item) => ({
  type: networkingActions.RemoveCheckedFilters,
  title,
  item,
});

export const clearSomeCheckedFilter = data => ({
  type: networkingActions.ClearSomeCheckedFilter,
  payload: data
});

export const clearAllFilters = () => ({
  type: networkingActions.ClearAllFilters,
});

export const addCheckedFilterProperties = (data) => ({
  type: networkingActions.AddCheckedFilterProperties,
  payload: data
});

export const setDelegates = (data) => ({
  type: networkingActions.SetDelegates,
  payload: data
});

export const setIsFetchingFilters = (data) => ({
  type: networkingActions.SetIsFetchingFilters,
  payload: data
});

export const setIsFetchingDelegates = (data) => ({
  type: networkingActions.SetIsFetchingDelegates,
  payload: data
});

export const setIsBookmarkDelegates = (delegateId, isBookmark) => ({
  type: networkingActions.SetIsBookmark,
  delegateId,
  isBookmark
});

export const setNextDelegatesUrl = (data) => ({
  type: networkingActions.SetNextDelegatesUrl,
  payload: data
});

export const setNextDelegatesList = (data) => ({
  type: networkingActions.SetNextDelegatesList,
  payload: data
});

export const setIsLoadingNextDelegateList = (data) => ({
  type: networkingActions.SetIsLoadingNextDelegateList,
  payload: data
});
//saga actions
export const getNetworkingFilters = (data) => ({
  type: networkingActions.GetNetworkingFilters,
  payload: data
});

export const onFilteringDelegates = (data) => {
  return {type: networkingActions.OnFilteringDelegates,
  payload: data
}};

export const getDelegates = (data) => ({
  type: networkingActions.GetDelegates,
  payload: data
});

export const setSelectedSort = (data) => ({
  type: networkingActions.SetSelectedSort,
  payload: data
});

export const bookmarkDelegate = (data) => ({
  type: networkingActions.BookmarkDelegate,
  payload: data
});

export const unBookmarkDelegate = (data) => ({
  type: networkingActions.UnBookmarkDelegate,
  payload: data
});

export const getNextDelegatesList = (data) => ({
  type: networkingActions.GetNextDelegatesList,
  payload: data
});