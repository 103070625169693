import { LandingActions } from '@/constants/actions/landingActions'

export const getEventData = data => ({
  type: LandingActions.GetEventData,
  payload: data,
})

export const onGetEventData = data => ({
  type: LandingActions.OnGetEventData,
  payload: data,
})

export const getEventSpeakersData = data => ({
  type: LandingActions.GetEventSpeakersData,
  payload: data,
})

export const onGetEventSpeakersData = data => ({
  type: LandingActions.OnGetEventSpeakersData,
  payload: data,
})

export const putSponsorsData = data => ({
  type: LandingActions.putEventSponsorsData,
  payload: data,
})

export const getEventAgendaData = data => ({
  type: LandingActions.GetEventAgendaData,
  payload: data,
})

export const onGetEventAgendaData = data => ({
  type: LandingActions.OnGetEventAgendaData,
  payload: data,
})