import React, { useCallback } from 'react';
import { isEqual } from 'deep-equal';
import { format } from 'date-fns';
import QACard from '../QACard';

const ApprovedSection = ({ moderatorQuestions }) => {
  const dateParcer = useCallback(date => {
    let parseDate = new Date(JSON.parse(`"${date}"`));
    return format(parseDate, 'HH:mm');
  }, []);

  const sortById = useCallback(arr => arr.sort((a, b) => (a.id > b.id ? 1 : -1)), []);

  return (
    <>
      {sortById(moderatorQuestions).map((item, index) => (
        <QACard
          key={index}
          id={item.id}
          time={dateParcer(item.created_date)}
          text={item.question}
          reject={true}
          escalate={true}
          isEscalated={item.is_escalated}
          delegate={item.delegate}
        />
      ))}
    </>
  );
};
export default React.memo(ApprovedSection, isEqual);
