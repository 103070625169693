import { interactionPanelActions } from '../../constants/actions/interactionPanelActions';

export const setCurrentPanelTab = payload => ({
  type: interactionPanelActions.SetCurrentPanelTab,
  payload,
});

export const startDialogsPolling = payload => ({
  type: interactionPanelActions.StartDialogsPolling,
  payload,
});

export const stopDialogsPolling = payload => ({
  type: interactionPanelActions.StopDialogsPolling,
  payload,
});

export const setDialogs = payload => ({
  type: interactionPanelActions.SetDialogs,
  payload,
});

export const setDialogsFail = () => ({
  type: interactionPanelActions.SetDialogs,
});

export const setIsDialogOpen = payload => ({
  type: interactionPanelActions.SetIsDialogOpen,
  payload,
});

export const setAreDialogsLoading = payload => ({
  type: interactionPanelActions.SetAreDialogsLoading,
  payload,
});

export const startMessagesPolling = currentChatId => ({
  type: interactionPanelActions.StartMessagesPolling,
  payload: currentChatId,
});

export const stopMessagesPolling = () => ({
  type: interactionPanelActions.StopMessagesPolling,
});

export const setCurrentChatData = payload => ({
  type: interactionPanelActions.SetCurrentChatData,
  payload,
});

export const setCurrentChatDataFail = () => ({
  type: interactionPanelActions.SetMessagesFail,
});

export const setAreMessagesLoading = payload => ({
  type: interactionPanelActions.SetAreMessagesLoading,
  payload,
});

export const sendMessage = payload => ({
  type: interactionPanelActions.SendMessage,
  payload,
});

export const setSendingMessage = payload => ({
  type: interactionPanelActions.SetSendingMessage,
  payload,
});

export const removeSendingMessage = payload => ({
  type: interactionPanelActions.RemoveSendingMessage,
  payload,
});

export const setFavoriteDelegates = payload => ({
  type: interactionPanelActions.SetFavoriteDelegates,
  payload,
});

export const putFavoriteDelegates = payload => ({
  type: interactionPanelActions.PutFavoriteDelegates,
  payload,
});

export const addFavoriteDelegate = payload => ({
  type: interactionPanelActions.AddFavoriteDelegate,
  payload,
});

export const removeFavoriteDelegate = payload => ({
  type: interactionPanelActions.RemoveFavoriteDelegate,
  payload,
});

export const setCurrentDelegate = payload => ({
  type: interactionPanelActions.SetCurrentDelegate,
  payload,
});

export const setFavoriteSessions = payload => ({
  type: interactionPanelActions.SetFavoriteSessions,
  payload,
});

export const putFavoriteSessions = payload => ({
  type: interactionPanelActions.PutFavoriteSessions,
  payload,
});

export const setFavoriteExhibitors = payload => ({
  type: interactionPanelActions.SetFavoriteExhibitors,
  payload,
});

export const putFavoriteExhibitors = payload => ({
  type: interactionPanelActions.PutFavoriteExhibitors,
  payload,
});

export const setAreBookmarksLoading = payload => ({
  type: interactionPanelActions.SetAreBookmarksLoading,
  payload,
});

export const createOrGetChat = payload => ({
  type: interactionPanelActions.CreateOrGetChat,
  payload,
});

export const putCurrentChat = payload => ({
  type: interactionPanelActions.PutCurrentChat,
  payload,
});

export const putMessageToCurrentReceivedList = payload => ({
  type: interactionPanelActions.PutMessageToCurrentReceivedList,
  payload,
});

export const pollAllQuestions = payload => ({
  type: interactionPanelActions.PollAllQuestions,
  payload,
});

export const stopQuestionsPolling = payload => ({
  type: interactionPanelActions.StopQuestionsPolling,
  payload,
});

export const putAllQuestions = payload => ({
  type: interactionPanelActions.PutAllQuestions,
  payload,
});

export const putAreQuestionsLoading = payload => ({
  type: interactionPanelActions.PutAreQuestionsLoading,
  payload,
});

export const sendQuestion = payload => ({
  type: interactionPanelActions.SendQuestion,
  payload,
});

export const setSendingQuestion = payload => ({
  type: interactionPanelActions.SetSendingQuestion,
  payload,
});

export const removeSendingQuestion = payload => ({
  type: interactionPanelActions.RemoveSendingQuestion,
  payload,
});

export const putQuestionToCurrentReceivedList = payload => ({
  type: interactionPanelActions.PutQuestionToCurrentReceivedList,
  payload,
});

export const getUserNonActiveQuestions = payload => ({
  type: interactionPanelActions.GetUserNonActiveQuestions,
  payload,
});

export const putUserNonActiveQuestions = payload => ({
  type: interactionPanelActions.PutUserNonActiveQuestions,
  payload,
});

export const sendInvitationStatus = payload => ({
  type: interactionPanelActions.SendInvitationStatus,
  payload,
});

export const putProcessedInvitationId = payload => ({
  type: interactionPanelActions.PutProcessedInvitationId,
  payload,
});

export const removeProcessedInvitationId = payload => ({
  type: interactionPanelActions.RemoveProcessedInvitationId,
  payload,
});

export const putIsChatBeingCreated = payload => ({
  type: interactionPanelActions.PutIsChatBeingCreated,
  payload,
});

export const putBlockPuttingChatData = payload => ({
  type: interactionPanelActions.PutBlockPuttingChatData,
  payload,
});
