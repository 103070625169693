import styled from 'styled-components';

export const Container = styled.div``;

export const MenuItem = styled.p`
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0px;

  &:hover {
    cursor: pointer;
    background-color: #f2f4fa;
    border-radius: 4px;
  }
`;
