export const profilePageActions = {
    SetProfile: 'profile/SET_PROFILE',
    GetProfile: 'profile/GET_PROFILE',
    GetDelegateProfile: 'profile/GET_DELEGATE_PROFILE',
    SetIsFetchingProfile: 'profile/SET_IS_FETCHING_PROFILE',
    SetIsActiveEditSocialLinks: 'profile/SET_IS_ACTIVE_EDIT_SOCIAL_LINKS',
    SetIsActiveEditBiography: 'profile/SET_IS_ACTIVE_EDIT_BIOGRAPHY',
    SetIsActiveEditInterests: 'profile/SET_IS_ACTIVE_EDIT_INTERESTS',
    SetSocialLinks: 'profile/SET_SOCIAL_LINKS',
    SetBiography: 'profile/SET_BIOGRAPHY',
    SetInterests: 'profile/SET_INTERESTS',
    SetEventInterests: 'profile/SET_EVENT_INTERESTS',
    SetIsMarketingAllowed: 'profile/SET_IS_MARKETING_ALLOWED',
    SetIsAnonymous: 'profile/SET_IS_ANONYMOUS',
    GetEventInterests: 'profile/GET_EVENT_INTERESTS',
    UpdateSocialLinks: 'profile/UPDATE_SOCIAL_LINKS',
    SetUpdateSocialLinksLoading: 'profile/SET_UPDATE_SOCIAL_LINKS_LOADING',
    SetUpdateSocialLinksError: 'profile/SET_UPDATE_SOCIAL_LINKS_ERROR',
    UpdateBiography: 'profile/UPDATE_BIOGRAPHY',
    UpdateInterests: 'profile/UPDATE_INTERESTS',
    UpdateIsMarketingAllowed: 'profile/UPDATE_IS_MARKETING_ALLOWED',
    UpdateIsAnonymous: 'profile/UPDATE_IS_ANONYMOUS',
    SetIsBookmarkDelegate: 'profile/SET_IS_BOOKMARK_DELEGATE',
    SetIsBookmarkSession: 'profile/SET_IS_BOOKMARK_SESSION',
    SetIsBookmarkExhibitor: 'profile/SET_IS_BOOKMARK_EXHIBITOR',
    BookmarkDelegate: 'profile/BOOKMARK_DELEGATE',
    UnBookmarkDelegate: 'profile/UN_BOOKMARK_DELEGATE',
    SetBiographyErrorMsg: 'profile/SET_BIOGRAPHY_ERROR_MSG'
}