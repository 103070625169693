import styled from 'styled-components';

export const EditHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  height: 250px;
  border: ${({ theme }) => `1px solid ${theme.colors.logoBorder}`};
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 30px 0;
`;

export const AddVideoSection = styled.div`
  display: flex;
  flex-basis: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  border-right: ${({ theme }) => `1px solid ${theme.colors.logoBorder}`};
  cursor: pointer;

  > div {
    display: flex;
    justify-content: center;
  }
`;

export const AddVideoSectionSpan = styled.span`
  pointer-events: ${({ downloadImage }) => (downloadImage ? 'none' : 'auto')};
  margin-top: 20px;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  text-align: center;
`;

export const AddVideoInput = styled.div`
  margin-top: 15px;
`;

export const AddImageSection = styled.div`
  display: flex;
  flex-basis: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

export const AddImageSectionLabel = styled.label`
  pointer-events: ${({ isVideoUrlInput }) => (isVideoUrlInput ? 'none' : 'auto')};
  margin-top: 20px;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  text-align: center;

  > input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
`;

export const AddImageSectionSpan = styled.span`
  display: ${({ downloadImage }) => (downloadImage ? 'block' : 'none')};
  margin-top: 15px;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  text-align: center;
`;

export const ImageErrorMsg = styled.span`
  margin-top: 15px;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.alert};
  text-align: center;
`;

export const ButtonsSection = styled.div`
  margin-bottom: 45px;
  display: flex;

  > button:nth-child(1) {
    width: 100px;
    margin-right: 15px;
  }

  > button:nth-child(2) {
    width: 115px;
  }
`;
