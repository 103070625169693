import styled from 'styled-components';

export const AgendaContainer = styled.div`
  height: 100%;

  overflow: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    height: calc(100% + 20px);
  }
`;

export const MessagesContainer = styled.div``;

export const Body = styled.div`
  height: 100%;
`;

export const Controls = styled.div`
  margin: 15px 30px;
  height: 70px;
  padding: 0 30px;
  box-sizing: border-box;
`;

export const AgendaSection = styled.div`
  padding-top: 15px;
`;

export const SectionTitle = styled.div`
  margin: 0 0 15px 30px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;
