import React from 'react'
import { isEqual } from 'deep-equal'
import streaming from '../../images/streaming.png'
import {
  StreamingContainer,
  Title,
  StreamingInfo,
  Image,
} from './styles'


const Streaming = props => {
  return (
    <StreamingContainer>
      <Title>
        Streaming or on-demand.
      </Title>
      <Image src={streaming} />
      <StreamingInfo>
        How far have European financial centres, including central banks and
        supervisory authorities taken the UN- backed Green Climate Fund agenda:
        The impact of COVID-19 on sustainable investing and global resilience financing
      </StreamingInfo>
    </StreamingContainer>
  )
}

export default React.memo(Streaming, isEqual)
