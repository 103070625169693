import styled from 'styled-components';
import { Form } from 'formik';

export const StyledChangePasswordForm = styled(Form)`
  > div {
    margin-bottom: 30px;
  }
`;

export const ChangePasswordFormErr = styled.div`
  color: ${({ theme }) => theme.colors.alert};
`;

export const SuccessSpan = styled.span`
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.primaryGreen};
  text-align: center;
`;
