import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export const Recaptcha = ({ validate }) => {
  return (
    <ReCAPTCHA
      sitekey="6Lds98kZAAAAAPED9S_HnKh67nrmNwckGgJ4zOey"
      hl="en"
      onChange={value => validate(value)}
    />
  );
};
