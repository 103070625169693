import styled from 'styled-components'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 165px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    padding-top: 75px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    flex-direction: column;
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContentPlace = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #52595E;
  margin-top: 20px;
`

export const ContentTitle = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 140%;
  color: #17191A;
  margin-top: 30px;
`

export const ContentDate = styled.div`
  color: #52595E;
  font-weight: normal;
  font-size: 25px;
  line-height: 140%;
  margin-top: 30px;
`

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    margin-top: 25px;
  }
`

export const ContentImage = styled.img`
  border-radius: 8px; 
  object-fit: cover;
  height: 400px;
  width: 680px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 400px;
    height: 260px;
    margin-top: 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    margin-top: 25px;
    width: 100%;
    height: auto;
  }
`

export const Button = styled.button`
  color:${(props) => props.type === 'login' ? '#00806C' : '#FFFFFF'};
  background:${(props) => props.type === 'login' ? '#E6FBF8' : '#00967F'};
  margin-left: ${(props) => props.type === 'login' ? '0px' : '15px'};
  height: 40px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`
export const ButtonText = styled.p`
  font-size: 15px;
  line-height: 30px;
  margin: 0 30px;
`
