import styled from 'styled-components';
import { Empty, Spin } from 'antd';

export const Container = styled.div`
  padding: 0 30px 25px 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  word-break: break-word;
`;

export const EmptyAntd = styled(Empty)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SpinAntd = styled(Spin)`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
