import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { isEqual } from 'deep-equal';
import { StyledSelect, StyledOption, StyledLabel, StyledSmall } from './styles';

const CustomSelect = ({ name, options, form, label, onBlur }) => {
  const { errors, touched, setFieldValue, values, setFieldError } = form;
  const [value, setValue] = useState('');

  const handleSelect = useCallback(
    id => {
      setFieldValue(name, id);
    },
    [name, setFieldValue],
  );

  const showInvalid = useMemo(() => {
    return { display: errors[name] && touched[name] ? 'block' : 'none' };
  }, [errors, name, touched]);

  const onFocusSelect = useCallback(() => {
    setFieldError(name, '');
  }, [name, setFieldError]);

  useEffect(() => {
    values[name] !== undefined && setValue(values[name]);
  }, [values, name]);

  return (
    <div>
      <StyledLabel>{label}</StyledLabel>
      <StyledSelect
        id={name}
        onBlur={onBlur}
        onFocus={onFocusSelect}
        value={value ? value : undefined}
        onChange={handleSelect}
        isError={!!(errors[name] && touched[name])}
        placeholder="-- Select option --"
      >
        {options.map(item => (
          <StyledOption key={item.id} value={item.id}>{item.label}</StyledOption>
        ))}
      </StyledSelect>
      <StyledSmall style={showInvalid}>{errors[name]}</StyledSmall>
    </div>
  );
};

export default React.memo(CustomSelect, isEqual);
