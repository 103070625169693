import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'deep-equal';
import { EditBiographyButtons, EditBiographyContainer, EditBiographyTextarea } from './styles';
import Button from '@/components/controls/buttons/GenericButton/component';
import {
  setIsActiveEditBiography,
  getProfileInfo,
  updateBiography,
  getBiographyErrMsg,
} from '@/store/profilePage';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ErrorMsg } from '../../../ExhibitorPage/styles';

const EditBiography = () => {
  const dispatch = useDispatch();
  const { biography, id } = useSelector(getProfileInfo);
  const biographyErr = useSelector(getBiographyErrMsg);
  const [textareaValue, setTextareaValue] = useState(biography);
  const params = useParams();

  const textareaEl = useRef(null);

  useEffect(() => {
    textareaEl.current.focus();
  }, []);

  const onChangeTextarea = e => {
    setTextareaValue(e.target.value);
  };

  const onSave = () => {
    dispatch(updateBiography({ id: params.id, delegateId: id, biography: textareaValue }));
  };

  return (
    <EditBiographyContainer>
      <EditBiographyTextarea
        value={textareaValue}
        onChange={onChangeTextarea}
        ref={textareaEl}
        onFocus={e => {
          let val = e.target.value;
          e.target.value = '';
          e.target.value = val;
          e.target.scrollTop = e.target.scrollHeight;
        }}
      />
      {biographyErr && <ErrorMsg>{biographyErr}</ErrorMsg>}
      <EditBiographyButtons>
        <Button label="Save" onClick={onSave} />
        <Button
          label="Cancel"
          styleType="outline"
          onClick={() => dispatch(setIsActiveEditBiography(false))}
        />
      </EditBiographyButtons>
    </EditBiographyContainer>
  );
};
export default React.memo(EditBiography, isEqual);
