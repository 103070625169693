import styled from 'styled-components';

export const AgendaWrapper = styled.div`
  ${({ theme, showBorder }) =>
    showBorder ? `border: 1px solid ${theme.colors.agenda.border};` : null}
  box-sizing: border-box;
  border-radius: 4px;
  padding-bottom: 15px;
  background: #fff;
`;

export const AgendaDaySection = styled.div``;

export const AgendaDaySessions = styled.div`
  ${({ theme, showBorder }) =>
    showBorder ? `border: 1px solid ${theme.colors.agenda.border};` : null}
  box-sizing: border-box;
  border-radius: 4px;

  & > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.agenda.border};
    box-sizing: border-box;
  }

  ${({ showBorder }) => (showBorder ? `& :last-child {border: none;}` : null)}
`;
