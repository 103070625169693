import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
`;

export const LoadingSpinner = styled.div`
  margin-right: 10px;
`;
