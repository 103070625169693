import React, { useLayoutEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import {
  BLOCKED_USER_PAGE_URL,
  CHOOSE_TICKET_URL,
  EMAIL_APPROVE_PAGE_URL,
  EVENT_NOT_FOUND_URL,
  FORGOT_PASSWORD_URL,
  INACTIVE_USER_PAGE_URL,
  PLATFORM_URL,
  REGISTER_DELEGATE_PROMPT_URL,
  REGISTER_URL,
  SIGN_IN_URL,
  TICKETS_URL,
  ESCALATED_QUESTIONS_PAGE_URL,
  ESCALATED_QUESTIONS_SIGN_IN_URL,
  NOT_APPROVED_DELEGATE_PAGE_URL
} from '../../../constants/routes';
import ChooseTicketPage from '../../pages/ChooseTicket';
import Tickets from '../../pages/Tickets';
import SignIn from '../../pages/Sign-in';
import Registration from '@/components/pages/Registration';
import Landing from '../../pages/Landing';
import { useDispatch, useSelector } from 'react-redux';
import { checkEvent, getEventCheckedValue } from '../../../store/eventExists';
import Preloader from '../../blocks/Preloader';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage';
import RegisterDelegatePrompt from '../../pages/RegisterDelegatePrompt';
import PlatformDelegateCheckWrapper from '../PlatformDelegateCheck';
import BlockedUserPage from '../../pages/BlockedUserPage/component';
import InactiveUserPage from '../../pages/InactiveUserPage/component';
import EventNotFound from '../../pages/EventNotFound/component';
import EmailApprovePage from '../../pages/EmailApprovePage/component';
import EscalatedQuestionsPage from "../../pages/EsclatedQuestionsPage/component";
import SignInChair from "../../pages/EsclatedQuestionsPage/SignInChair/component";
import NotApprovedPage from "../../pages/NotApprovedDelegatePage/component";

const EventRouter = () => {
  const eventChecked = useSelector(getEventCheckedValue);
  const dispatch = useDispatch();
  const { id } = useParams();
  useLayoutEffect(() => {
    dispatch(checkEvent(id));
  }, []);
  return (
    <>
      {eventChecked ? (
        <Preloader />
      ) : (
        <Switch>
          <Route path={SIGN_IN_URL} exact component={SignIn} />
          <Route path={REGISTER_URL} exact component={Registration} />
          <Route path={TICKETS_URL} exact component={Tickets} />
          <Route path={CHOOSE_TICKET_URL} exact component={ChooseTicketPage} />
          <Route path={PLATFORM_URL} component={PlatformDelegateCheckWrapper} />
          <Route path={FORGOT_PASSWORD_URL} exact component={ForgotPasswordPage} />
          <Route path={REGISTER_DELEGATE_PROMPT_URL} exact component={RegisterDelegatePrompt} />
          <Route path={BLOCKED_USER_PAGE_URL} exact component={BlockedUserPage} />
          <Route path={INACTIVE_USER_PAGE_URL} exact component={InactiveUserPage} />
          <Route path={EVENT_NOT_FOUND_URL} exact component={EventNotFound} />
          <Route path={EMAIL_APPROVE_PAGE_URL} exact component={EmailApprovePage} />
          <Route path={NOT_APPROVED_DELEGATE_PAGE_URL} exact component={NotApprovedPage} />
          <Route path={ESCALATED_QUESTIONS_PAGE_URL} exact component={EscalatedQuestionsPage} />
          <Route path={ESCALATED_QUESTIONS_SIGN_IN_URL} exact component={SignInChair} />
          <Route path="/:id/" component={Landing} />
        </Switch>
      )}
    </>
  );
};

export default EventRouter;
