import { call, delay, put, race, take, select, takeEvery } from 'redux-saga/effects';
// import { pollsAPI } from '@/api/pollsAPI';
import { pollsAPI } from '../../api/pollsAPI';
import { GET_POLLS_DELAY, GET_LIVE_SESSIONS_DELAY } from '@/constants/longPollingDelay';
import { pollsActions } from '@/constants/actions/pollsActions';
import { getLiveSessionsInfo } from '../sessions';
import { setPolls } from './actions';

function* getPolls() {
  while (true) {
    try {
      const liveSession = yield select(getLiveSessionsInfo);
      if (Object.keys(liveSession).length > 0) {
        if (liveSession.current_sessions) {
          const response = yield call(pollsAPI.getPolls, liveSession.current_sessions.id);
          yield put(setPolls(response.data));
          yield delay(GET_POLLS_DELAY);
        } else {
          yield delay(GET_LIVE_SESSIONS_DELAY);
        }
      } else {
        yield delay(1000);
      }
    } catch (error) {
      console.log(error);
    }
  }
}

function* postOptionVote(action) {
  try {
    yield call(
      pollsAPI.postOptionVote,
      action.payload.session_pk,
      action.payload.option_pk,
    );
  } catch (error) {
    console.log(error);
  } 
}

export function* watchGetPolls() {
  while (true) {
    const payload = yield take(pollsActions.StartGetPollsPolling);
    yield takeEvery(pollsActions.PostOptionVote, postOptionVote);
    yield race([call(getPolls, payload), take(pollsActions.StopGetPollsPolling)]);
  }
}
