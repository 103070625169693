import React from 'react';
import { isEqual } from 'deep-equal';
import { ResponsiveContainer, ResponsiveIframe } from './styles';

const YoutubeVideo = ({ idVideo }) => {
  return (
    <ResponsiveContainer>
      <ResponsiveIframe
        src={`https://www.youtube.com/embed/${idVideo}`}
        frameBorder="0"
        gesture="media"
        allowFullScreen
      />
    </ResponsiveContainer>
  );
};

export default React.memo(YoutubeVideo, isEqual);
