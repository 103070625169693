import { dashboardActions } from '../../constants/actions/dashboardActions';

const initialState = {
  delegates: [],
  selectedDelegates: [],
  bookmarked: [],
  acceptedUsers: [],
  rejectedUsers: [],
  exhibitorData: {},
  standCsv: null,
  statisticVisitors: null,
  exibitorDataLoading: false,
  isFetchingDelegates: true,
  isFetchingBookmarked: true,
  acceptUsersModal: false,
  rejectUsersModal: false,
  isFetchingInvitation: false,
  isSuccessInvitation: false,
  errorMsg: ''
};

export const dashboardPage = (state = initialState, action) => {
  switch (action.type) {
    case dashboardActions.SetDelegates:
      return {
        ...state,
        delegates: action.payload,
      };

    case dashboardActions.SetErrorMsg:
      return {
        ...state,
        errorMsg: action.payload,
      };

    case dashboardActions.SetIsFetchingInvitation:
      return {
        ...state,
        isFetchingInvitation: action.payload,
      };

    case dashboardActions.SetIsSuccessInvitation:
      return {
        ...state,
        isSuccessInvitation: action.payload,
      };

    case dashboardActions.AddDelegate:
      return {
        ...state,
        selectedDelegates: [...state.selectedDelegates, action.payload],
        delegates: state.delegates.filter(i => i.id !== action.payload.id),
        bookmarked: state.bookmarked.filter(b => b.id !== action.payload.id),
      };

    case dashboardActions.RemoveDelegate:
      return {
        ...state,
        delegates: [...state.delegates, action.payload],
        selectedDelegates: state.selectedDelegates.filter(i => i.id !== action.payload.id),
        bookmarked: [...state.bookmarked, action.payload],
      };

    case dashboardActions.ClearSelectedDelegates:
      return {
        ...state,
        delegates: [...state.delegates, ...state.selectedDelegates],
        selectedDelegates: [],
      };

    case dashboardActions.SetBookmarked:
      return {
        ...state,
        bookmarked: action.payload,
      };

    case dashboardActions.AddBookmarkeds:
      //need check AddBookmarkeds
      return {
        ...state,
        selectedDelegates: [...state.selectedDelegates, ...state.bookmarked],
        delegates: state.delegates.filter(d => !state.bookmarked.some(b => d.id === b.id)),
        bookmarked: [],
      };

    case dashboardActions.SetExibitorDataLoading:
      return {
        ...state,
        exibitorDataLoading: action.payload,
      };

    case dashboardActions.SetExibitorData:
      return {
        ...state,
        exhibitorData: action.payload,
      };

    case dashboardActions.SetAcceptedUsers:
      return {
        ...state,
        acceptedUsers: action.payload,
      };

    case dashboardActions.SetRejectedUsers:
      return {
        ...state,
        rejectedUsers: action.payload,
      };

    case dashboardActions.SetStatisticVisitors:
      return {
        ...state,
        statisticVisitors: action.payload,
      };

    case dashboardActions.SetExhibitorStandCsv:
      return {
        ...state,
        standCsv: action.payload,
      };

    case dashboardActions.SetIsBookmarkAcceptedUsers:
      return {
        ...state,
        acceptedUsers: state.acceptedUsers.map(d => {
          if (d.recipient.id === action.delegateId) {
            return { ...d, is_bookmark: action.isBookmark };
          }
          return d;
        }),
      };

    case dashboardActions.SetIsBookmarkRejectedUsers:
      return {
        ...state,
        rejectedUsers: state.rejectedUsers.map(d => {
          if (d.recipient.id === action.delegateId) {
            return { ...d, is_bookmark: action.isBookmark };
          }
          return d;
        }),
      };

    case dashboardActions.SetIsAcceptUsersModal:
      return {
        ...state,
        acceptUsersModal: !state.acceptUsersModal,
      };

    case dashboardActions.SetIsRejectUsersModal:
      return {
        ...state,
        rejectUsersModal: !state.rejectUsersModal,
      };
    default:
      return state;
  }
};
