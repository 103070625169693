import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  overflow: auto;
  flex-basis: 100%;
  padding: 20px 70px 0px 30px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    flex-direction: column;
  }
`;

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 45px;
  line-height: 54px;
  color: ${({ theme }) => theme.colors.originBlack};
`;

export const VisitorsCardWrapper = styled.div`
  width: 400px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    margin-top: 60px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  justify-content: ${({ rights }) => (rights ? 'center' : 'space-between')};
  margin-top: 65px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    flex-direction: column;
  }
`;

export const Resource = styled.div`
  flex-basis: 40%;
  margin-right: 80px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

export const ResourceTitle = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  margin-bottom: 15px;
`;

export const DashboardCardWrapper = styled.div`
  margin-bottom: 15px;
  :last-child {
    margin-bottom: 0px;
  }
`;
