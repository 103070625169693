import React from 'react';
import { IconWrapper, Icon } from '../../styles';

export const HomeIcon = () => {
  return (
    <IconWrapper style={ { paddingBottom: '100%' } }>
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="3 3 26 22">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.66675 16.6666L16.0001 4.66663L29.3334 16.6666H25.3334V27.3333H17.3334V19.3333H14.6667V27.3333H6.66675V16.6666H2.66675ZM22.6667 14.2533L16.0001 8.25327L9.33341 14.2533V24.6666H12.0001V16.6666H20.0001V24.6666H22.6667V14.2533Z"
        />
      </Icon>
   </IconWrapper>
  );
};
