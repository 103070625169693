import axios from 'axios';
import { baseURL } from '@/constants/baseURL';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

export const signUpAPI = {
  signUp(data) {
    return instance.post('users/registration/', data);
  },
};
