import React from 'react';
import { isEqual } from 'deep-equal';
import {
  SpeakersBlockContainer,
  Title,
  SpeakersContainer,
  OneSpeaker,
  SpeakerImage,
  SpeakerName,
  SpeakerInfo,
} from './styles';

const Speakers = ({ speakers }) => {
  const orderSpeakers = speakers.sort(
    (a, b) => a.landing_sequence_order - b.landing_sequence_order,
  );

  return (
    <SpeakersBlockContainer id="speakers">
      <Title>Featured speakers</Title>
      <SpeakersContainer>
        {orderSpeakers.map(speaker => (
          <OneSpeaker>
            <SpeakerImage round="50%" src={speaker.headshot} name={speaker.first_name + ' ' + speaker.last_name} />
            <SpeakerName>{speaker.first_name + ' ' + speaker.last_name}</SpeakerName>
            <SpeakerInfo>{speaker.biography}</SpeakerInfo>
          </OneSpeaker>
        ))}
      </SpeakersContainer>
    </SpeakersBlockContainer>
  );
};

export default React.memo(Speakers, isEqual);
