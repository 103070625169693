import { escalatedQuestionsActions } from '@/constants/actions/escalatedQuestionsActions';

const initialState = {
  escalatedQuestions: [],
  isFetchingQuestions: true,
  escalatedSignInErrMsg: '',
  isLoadingSignIn: false
};

export const escalatedQuestions = (state = initialState, action) => {
  switch (action.type) {
    case escalatedQuestionsActions.SetEscalatedQuestions:
      return {
        ...state,
        escalatedQuestions: action.payload,
      };
    case escalatedQuestionsActions.SetIsFetchingQuestions:
      return {
        ...state,
        isFetchingQuestions: action.payload,
      };
    case escalatedQuestionsActions.SetEscalatedSignInErrMsg:
      return {
        ...state,
        escalatedSignInErrMsg: action.payload,
      };
    case escalatedQuestionsActions.SetIsLoadingSignIn:
      return {
        ...state,
        isLoadingSignIn: action.payload,
      };
    default:
      return state;
  }
};
