import styled from 'styled-components';

export const WrapperSection = styled.div`
  &.ant-popover-title {
    border-bottom: none;
  }
`;
export const IconSection = styled.svg`
  display: block;
  width: 21px;
  height: 18px;
  margin-right: ${({status}) => status === 'scheduled' ? '19px' : '0'};

  &.ant-popover-open {
    fill: #257bde;
  }
`;
