import { call, put, takeEvery } from 'redux-saga/effects';
import { isDelegateCheckActions } from '../../constants/actions/isDelegateCheck';
import { authAPI } from '../../api/authAPI';
import { history } from '@/store/rootReducer';
import { setAccess, setError } from './actions';
import {
  generateRegisterDelegatePromptUrl,
  generateBlockedUserPage,
  generateInactiveUserPage,
  generateNotApprovedDelegatePageUrl,
  generateRegisterUrl,
} from '@/constants/routes';
import { allowAccess, blockedUser, inactiveUser } from '@/helpers/userAndDelegateStatus';
import { platformInfoAPI } from '../../api/platformInfoAPI';

function* isDelegate(action) {
  try {
    yield put(setAccess(false));
    const response = yield call(platformInfoAPI.delegateCheck, action.payload);
    localStorage.removeItem('isRegistrationInProgress');
    yield allowAccess(response.data)
      ? put(setAccess(true))
      : blockedUser(response.data)
      ? history.replace(generateBlockedUserPage(action.payload))
      : inactiveUser(response.data)
      ? history.replace(generateInactiveUserPage(action.payload))
      : history.replace(generateNotApprovedDelegatePageUrl(action.payload));
  } catch (err) {
    const isRegistrationInProgress = localStorage.getItem('isRegistrationInProgress');
    if (isRegistrationInProgress) {
      history.replace(generateRegisterUrl(action.payload));
    } else {
      yield put(setError(err));
      history.replace(generateRegisterDelegatePromptUrl(action.payload));
    }
  }
}

function* fetchUserInfo(action) {
  try {
    yield call(authAPI.userInfo);
    yield call(() => isDelegate(action));
  } catch (err) {
    yield put(setError(err));
    history.replace(`/${action.payload}`);
  }
}

export function* watchIsDelegateCheck() {
  yield takeEvery(isDelegateCheckActions.IsDelegateCheckWatch, fetchUserInfo);
}
