export const RegistrationActions = {
  AddDetailsInfoData: 'register/ADD_DETAILS_INFO_DATA',
  AddProfileInfoData: 'register/ADD_PROFILE_INFO_DATA',
  AddBioInfoData: 'register/ADD_BIO_INFO_DATA',
  AddInterestsInfoData: 'register/ADD_INTERESTS_INFO_DATA',
  AddInterestsInInfoData: 'register/ADD_INTERESTS_IN_INFO_DATA',
  GetDetailsInfoData: 'register/GET_DETAILS_INFO_DATA',
  GetProfileInfoData: 'register/GET_PROFILE_INFO_DATA',
  GetBioInfoData: 'register/GET_BIO_INFO_DATA',
  GetInterestsInfoData: 'register/ET_INTERESTS_INFO_DATA',
  OnUserRegistration: 'register/ON_USER_REGISTRATION',
  SetRegistrationStep: 'register/SET_REGISTRATION_STEP',
  SetRegistrationDelegate: 'register/SET_REGISTRATION_DELEGATE',
  SetErrorMsg: 'register/SET_ERROR_MSG',
  SetIsLoading: 'register/SET_IS_LOADING',
  SetIsLoadingSummaryForm: 'register/SET_IS_LOADING_SUMMARY_FORM',
  SetIsShowSummaryModal: 'register/SET_IS_SHOW_SUMMARY_MODAL',
  SetTicketType: 'register/SET_TICKET_TYPE',
  CheckTicketPrice: 'stripeActions/CHECK_TICKET_PRICE',
  OnCheckTicketPrice: 'stripeActions/ON_CHECK_TICKET_PRICE',
};
