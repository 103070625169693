import React from 'react';
import { MenuItem, MainItemText } from './styles';

export const ShareContent = ({icon, content}) => {
    return (

        <>
            <MenuItem>     
                {icon}
                <MainItemText>{content}</MainItemText>
            </MenuItem>
        </>
    );
}


