import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { ButtonStyle } from './buttonStyle';

export const ButtonSecondary = ({ text, onClick }) => {
  const StyledButton = withStyles({
    root: {
      '&:hover': {
        boxShadow: 'none',
      },
    },
    label: {
      color: '#6B7178',
      fontWeight: 'bold',
    },
  })(ButtonStyle);
  return <StyledButton disableRipple onClick={onClick}>{text}</StyledButton>;
};
