import React, { useCallback, useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import GenericButton from '@/components/controls/buttons/GenericButton';
import { isEqual } from 'deep-equal';
import TicketCard from '@/components/blocks/TicketCard';
import { setRegistrationStep } from '@/store/registration/actions';
import { getRegistrationData } from '@/store/registration/selectors';
import { generateChooseTicketUrl } from '@/constants/routes';
import { getTicketType, checkTicketPrice, getTicketPrice } from '@/store/registration';
import { setDelegate, getIsLoadingInfo, getIsShowSummaryModalInfo } from '@/store/registerDelegate';
import { getRegistrationFieldsValues } from '@/store/registrationFieldsValues/selector';
import { getPromocode, getPromocodeStatus } from '@/store/promocodeValidation/selectors';
import { history } from '@/store/rootReducer';
import SummaryModal from "./components/SummaryModal/component";

import {
  SummaryApplication,
  FormSection,
  FieldName,
  Header,
  CustomRow,
  Title,
  TitleLink,
  Fields,
  TicketTypeDescription,
  MarginTopWrapper,
} from './styles';

const SummaryForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [blob, setBlob] = useState('');
  const [country, setCountries] = useState('');
  const [role, setRole] = useState('');
  const [organisation, setOrganisation] = useState('');
  const { details, profile, interests, interestsIn, bio } = useSelector(getRegistrationData);
  const ticketType = useSelector(getTicketType)
  const ticketPrice = useSelector(getTicketPrice)
  const ChooseTicketRoute = generateChooseTicketUrl(id);
  const fieldValues = useSelector(getRegistrationFieldsValues);
  const isLoading = useSelector(getIsLoadingInfo);
  const isShowSummaryModal = useSelector(getIsShowSummaryModalInfo);
  const promocode = useSelector(getPromocode)
  const promocodeStatus = useSelector(getPromocodeStatus)

  let delegateDetails = Object.assign(profile, bio);

  useEffect(() => {
    dispatch(checkTicketPrice(id))
  }, [dispatch, id])

  useEffect(() => {
    fieldValues.countries &&
      setCountries(fieldValues.countries.find(item => item.id === profile.country).label);
    fieldValues.role && setRole(fieldValues.role.find(item => item.id === profile.role).label);
    fieldValues.organisation &&
      setOrganisation(
        fieldValues.organisation.find(item => item.id === profile.organisation_type).label,
      );
  }, [fieldValues, profile.country, profile.organisation_type, profile.role]);

  useLayoutEffect(() => {
    const blob = async () => {
      const response = await fetch(delegateDetails['image']);
      const image = await response.blob();
      let fileT = new File([image], 'headshot.png', { type: "image/png" });
      setBlob(fileT);
    }
    delegateDetails['image'] && blob();
  }, [delegateDetails])

  const onSubmit = () => {
    let formData = new FormData();

    for (const key in delegateDetails) {
      if (key === 'image' && blob) {
        formData.append('headshot', blob);
      } else {
        formData.append(key, delegateDetails[key])
      }
    }

    promocode !== '' && formData.append('promo_code', promocode)

    dispatch(setDelegate({
      delegate: formData,
      event_pk: id,
      isFree: ticketType,
      promocode: promocodeStatus,
    }))
  }

  const onChangeStep = step => () => {
    dispatch(setRegistrationStep(step));
  };

  const onSelectTicketType = useCallback(() => {
    history.push(ChooseTicketRoute);
  }, [ChooseTicketRoute]);

  return (
    <>
      {
        ticketPrice.id && (
          <SummaryApplication>
            {isShowSummaryModal && <SummaryModal />}
            <FormSection>
              <Header>
                <Title>Your details</Title>
                <TitleLink onClick={onChangeStep('profile')}>Edit your details</TitleLink>
              </Header>
              <Fields cols={2}>
                <CustomRow marginBottom={30}>
                  <FieldName>Name</FieldName>
                  <div>{`${details.first_name} ${details.last_name}`}</div>
                </CustomRow>
                <CustomRow marginBottom={30}>
                  <FieldName>Email adress</FieldName>
                  <div>{details.email}</div>
                </CustomRow>
                <CustomRow marginBottom={30}>
                  <FieldName>Phone number</FieldName>
                  <div>{profile.phone}</div>
                </CustomRow>
                <CustomRow marginBottom={30}>
                  <FieldName>Country</FieldName>
                  <div>{country}</div>
                </CustomRow>
                <CustomRow marginBottom={30}>
                  <FieldName>Job title</FieldName>
                  <div>{profile.job_title}</div>
                </CustomRow>
                <CustomRow marginBottom={30}>
                  <FieldName>Job role</FieldName>
                  <div>{role}</div>
                </CustomRow>
                <CustomRow marginBottom={30}>
                  <FieldName>Organisation</FieldName>
                  <div>{profile.organisation}</div>
                </CustomRow>
                <CustomRow marginBottom={30}>
                  <FieldName>Organisation type</FieldName>
                  <div>{organisation}</div>
                </CustomRow>
              </Fields>
            </FormSection>
            <FormSection>
              <Header>
                <Title>Ticket type</Title>
                <TitleLink onClick={onSelectTicketType}>change your ticket type</TitleLink>
              </Header>
              <MarginTopWrapper>
                <TicketCard price={ticketPrice} isFree={ticketType} promotionCode={promocode} currency="GBP" />
              </MarginTopWrapper>
              <TicketTypeDescription>
                You confirm you are a student, employee of an NGO, public sector employee or employee of a
                non-profit organisation.
        </TicketTypeDescription>
            </FormSection>
            <FormSection>
              <Header>
                <Title>I'm interested in</Title>
                <TitleLink onClick={onChangeStep('interests')}>Edit your interests</TitleLink>
              </Header>
              <Fields cols={3}>
                {interests.selectInterests.map(({ value }) => (
                  <CustomRow marginBottom={15} key={value}>
                    <div>{value}</div>
                  </CustomRow>
                ))}
              </Fields>
            </FormSection>
            <FormSection>
              <Header>
                <Title>I’d like to meet</Title>
                <TitleLink onClick={onChangeStep('interestedIn')}>Edit your interests</TitleLink>
              </Header>
              <Fields cols={3}>
                {interestsIn.selectInterests.map(({ value }) => (
                  <CustomRow key={value} marginBottom={15}>
                    <div>{value}</div>
                  </CustomRow>
                ))}
              </Fields>
            </FormSection>
            <MarginTopWrapper>
              <GenericButton
                label="Submit application"
                styleType="primary"
                type="submit"
                onClick={onSubmit}
                isLoading={isLoading}
              />
            </MarginTopWrapper>
          </SummaryApplication>
        )
      }
    </>
  );
};

export default React.memo(SummaryForm, isEqual);
