import { RegistrationActions } from '@/constants/actions/registrationActions'

const initialState = {
  isLoading: false,
  isShowSummaryModal: false
};

export const registerDelegate = (state = initialState, action) => {
  switch (action.type) {
    case RegistrationActions.SetIsLoadingSummaryForm:
      return {
        ...state,
        isLoading: action.payload,
      };
    case RegistrationActions.SetIsShowSummaryModal:
      return {
        ...state,
        isShowSummaryModal: action.payload,
      };
    default:
      return state;
  }
};
