import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'deep-equal';
import { icoFacebook, icoLinkedin, icoPhone, icoTwitter, icoYotube } from './icons';
import { bookmarkExhibitor, getExhibitorInfo, unBookmarkExhibitor } from '@/store/exhibitorPage';
import { setCurrentPanelTab, createOrGetChat } from '@/store/interactionPanel/actions';
import { getDelegateInfo } from '@/store/platformInfo';
import { BackgroundGradient, BackgroundSection } from '../ExpoCard/styles';
import { VectorIcon } from '../ExpoCard/icons/VectorIcon';
import Button from '../../controls/buttons/GenericButton/component';
import iconSite from './icons/iconSite.svg';
import iconEmail from './icons/emailIcon.svg';

import {
  ButtonContainer,
  CardContainer,
  ContactsContainer,
  Container,
  Email,
  ExhibitorBadge,
  Logo,
  Phone,
  Site,
  Social,
  SocialIco,
  Title,
} from './styles';

const ExhibitorCard = props => {
  const dispatch = useDispatch();
  const exhibitor = useSelector(getExhibitorInfo);
  const { id: event_pk} = useParams();
  const { id: currentUserId } = useSelector(getDelegateInfo);
  const {
    id,
    name,
    phone,
    website,
    email,
    label,
    logo,
    color,
    youtube_url,
    facebook_url,
    linkedin_url,
    twitter_url,
    is_bookmark,
    contact_person,
  } = exhibitor;
  const social = [
    {
      id: 1,
      icon: icoTwitter,
      url: twitter_url,
    },
    {
      id: 2,
      icon: icoLinkedin,
      url: linkedin_url,
    },
    {
      id: 3,
      icon: icoFacebook,
      url: facebook_url,
    },
    {
      id: 4,
      icon: icoYotube,
      url: youtube_url,
    },
  ];

  const handleOpenNewDialog = (event_pk, delegate) => {
    dispatch(setCurrentPanelTab('messages'));
    dispatch(createOrGetChat({ event_pk, recipient: delegate }));
  };

  const onIsBookmark = () => {
    if (is_bookmark) {
      dispatch(unBookmarkExhibitor({ exhibitorId: id, isBookmark: false }));
    } else {
      dispatch(bookmarkExhibitor({ exhibitorId: id, isBookmark: true }));
    }
  };

  return (
    <Container>
      <CardContainer backgroundColor={color}>
        <BackgroundGradient />
        <BackgroundSection>
          <VectorIcon color={color} />
        </BackgroundSection>
        <Logo>
          <img src={`${logo}`} alt="exhibitor_logo" />
        </Logo>
        <Title>{name}</Title>
        <Social>
          <div>
            {social.map(i => {
              if (i.url) {
                return (
                  <SocialIco key={i.id} href={i.url} target="_blank">
                    {i.icon}
                  </SocialIco>
                );
              }
            })}
          </div>
        </Social>
      </CardContainer>
      <ContactsContainer label={label}>
        <div>
          {label && (
            <ExhibitorBadge background={label.color} color={label.text_color}>
              {label.text}
            </ExhibitorBadge>
          )}
        </div>
        <div>
          {phone && (
            <Phone>
              {icoPhone}
              {phone}
            </Phone>
          )}
          {website && (
            <Site>
              <img src={iconSite} alt="icon_site" />
              <a href={website} target="_blank" rel="noopener noreferrer">
                {website}
              </a>
            </Site>
          )}
          {email && (
            <Email>
              <img src={iconEmail} alt="icon_email" />
              <a href={`mailto:${email}`}>{email}</a>
            </Email>
          )}
          <ButtonContainer label={phone || website || email}>
            {!!contact_person?.id && currentUserId !== contact_person?.id && (
              <Button
                label="Send message"
                styleType="outline"
                onClick={() => handleOpenNewDialog(event_pk, contact_person)}
              />
            )}
            <Button
              label={`${is_bookmark ? 'Unfollow' : 'Bookmark'}`}
              styleType="outline"
              onClick={onIsBookmark}
            />
          </ButtonContainer>
        </div>
      </ContactsContainer>
    </Container>
  );
};
export default React.memo(ExhibitorCard, isEqual);
