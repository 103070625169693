import { createSelector } from 'reselect';

const getResetPasswordData = state => state.resetPassword;

export const getResetPasswordEmail = createSelector([getResetPasswordData], state => {
  return state.email;
});

export const getResetPassworError = createSelector([getResetPasswordData], state => {
  return state.error;
});

export const getResetPassworSpinner = createSelector([getResetPasswordData], state => {
  return state.spinner;
});

export const getIsSuccessChangePassword = createSelector([getResetPasswordData], state => {
  return state.isSuccessChangePassword;
});

export const getIsLoadingChangePassword = createSelector([getResetPasswordData], state => {
  return state.isLoadingChangePassword;
});