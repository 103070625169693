import styled from 'styled-components';
import { ArrowLeftOutlined } from '@ant-design/icons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`;

export const DialogHeader = styled.div`
  min-height: 80px;
  max-height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.tabBorder};
  box-sizing: border-box;
`;

export const DialogBody = styled.div`
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
`;

export const MessageAuthor = styled.div`
  height: 70px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  box-sizing: 'border-box';
  border-top: ${({ theme, withBorderTop }) =>
  withBorderTop && `1px solid ${theme.colors.tabBorder}`};
`;

export const Avatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: black;
`;

export const AuthorData = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

export const Name = styled.span`
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => `1px solid ${theme.colors.regularText}`};
`;

export const Position = styled.span`
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
`;

export const ArrowLeftOutlinedIcon = styled(ArrowLeftOutlined)`
  margin-left: 12px;
  font-size: 18px;
  cursor: pointer;
`;
