import styled from 'styled-components';

export const Container = styled.div`
  min-width: 177px;
  min-height: 32px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
`;

export const MenuTitle = styled.div`
  padding: 10px 0px;
  font-size: 12px;
  line-height: 15px;
  color: #808b94;
  text-transform: uppercase;
`;

export const MenuContent = styled.div`
  padding: 5px 0px;
  display: flex;
  flex-direction: column;

  button {
    &:active,
    &:focus {
      outline: none;
    }

    &:last-child {
      a {
        margin-bottom: 0;
      }
    }
  }
`;

export const MenuItem = styled.a`
  display: flex;
  font-size: 15px;
  line-height: 20px;
  color: #6b7178;
  margin-bottom: 8px;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    background-color: #f2f4fa;
    border-radius: 4px;
    color: #17191a;
  }
`;

export const MainItemText = styled.span`
  margin-left: 10px;
  display: flex;
  align-items: flex-end;
`;
