import styled from 'styled-components';

export const StyledButton = styled.button`
  position: relative;
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: 40px;

  transition: all 0.2s;

  background-color: ${({ styleType, theme, color }) =>
    theme.buttons[color]?.[styleType]?.backgroundColor ||
    theme.buttons[color]?.['primary'].backgroundColor};
  border: ${({ styleType, theme, color }) =>
    theme.buttons[color]?.[styleType]?.border || theme.buttons[color]?.['primary'].border};
  border-radius: 4px;

  color: ${({ styleType, theme, color }) =>
    theme.buttons[color]?.[styleType]?.textColor || theme.buttons[color]?.['primary'].textColor};

  &:focus {
    outline: none;
    background-color: ${({ styleType, theme, color }) =>
      theme.buttons[color]?.[styleType]?.backgroundColorHover ||
      theme.buttons[color]?.['primary'].backgroundColorHover};
    border: ${({ styleType, theme, color }) =>
      theme.buttons[color]?.[styleType]?.border || theme.buttons[color]?.['primary'].border};
    color: ${({ styleType, theme, color }) =>
      theme.buttons[color]?.[styleType]?.textColorHover ||
      theme.buttons[color]?.['primary'].textColorHover};
    box-shadow: ${({ styleType, theme, color }) =>
      theme.buttons[color]?.[styleType]?.shadowFocus ||
      theme.buttons[color]?.['primary'].shadowFocus};
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ styleType, theme, color }) =>
      theme.buttons[color]?.[styleType]?.backgroundColorHover ||
      theme.buttons[color]?.['primary'].backgroundColorHover};
    border: ${({ styleType, theme, color }) =>
      theme.buttons[color]?.[styleType]?.border || theme.buttons[color]?.['primary'].border};
    color: ${({ styleType, theme, color }) =>
      theme.buttons[color]?.[styleType]?.textColorHover ||
      theme.buttons[color]?.['primary'].textColorHover};
  }

  &:active {
    background-color: ${({ styleType, theme, color }) =>
      theme.buttons[color]?.[styleType]?.backgroundColorActive ||
      theme.buttons[color]?.['primary'].backgroundColorActive};
    border: ${({ styleType, theme, color }) =>
      theme.buttons[color]?.[styleType]?.border || theme.buttons[color]?.['primary'].border};
    color: ${({ styleType, theme, color }) =>
      theme.buttons[color]?.[styleType]?.textColorActive ||
      theme.buttons[color]?.['primary'].textColorActive};
    transition: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Label = styled.span`
  font-family: Inter;
  font-weight: bold;
  font-size: 15px;
  line-height: 30px;
`;

export const StyledImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
`;
