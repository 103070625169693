import React from 'react';
import { isEqual } from 'deep-equal';
import { SignInForm } from '@/components/forms/SignInForm';
import Logo from '@/components/controls/Logo';

import { Wrapper, Content, Title, LogoContainer } from './styles';

const SignIn = props => {
  return (
    <Wrapper>
      <Content>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Title>Sign-in to your account</Title>
        <SignInForm />
      </Content>
    </Wrapper>
  );
};

export default React.memo(SignIn, isEqual);
