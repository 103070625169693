import styled from 'styled-components';

export const Navigation = styled.nav`
  display: flex;
  flex-direction: column;
`;
export const Menu = styled.ul``;

export const MenuItem = styled.li`
  list-style: none;
  margin-top: 15px;
  > div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  > div span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-align: left;    
    text-transform: uppercase;
    color: ${({ active }) => (active ? '#17191A' : '#52595e')};
  }
  > div img {
    margin-left: 5px;
    transform: rotate(${({ active }) => (active ? '180deg' : '0deg')});
  }
`;

export const SubMenu = styled.ul`
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: 100%;
  border-radius: 4px;
  background: #f2f4fa;
  margin-top: 15px;
  max-height: 335px;
  overflow: auto;
`;

export const MenuHeader = styled.div`
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cad4dc;
  box-sizing: border-box;
  padding: 15px 13px;
  &:first-child {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #808b94;
  }
  > div:nth-child(2) {
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    color: #17191a;
    text-decoration: underline;
  }
`;

export const MenuBody = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0px 15px 15px 15px;
`;

export const SubMenuItem = styled.li`
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background: ${({ check }) => (check ? '#257BDE' : '#ffffff')};
  box-shadow: 0px 2px 4px #f2f4fa;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  margin: 15px 0px;
  :last-child {
    margin-bottom: 0;
  }

  input {
    display: ${({ check }) => (check ? 'none' : 'block')};
    border: 1px solid #cad4dc;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 0 15px;
  }

  img {
    display: ${({ check }) => (check ? 'block' : 'none')};
    margin: 0 10px;
  }
  label {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 15px;
    line-height: 20px;
    color: ${({ check }) => (check ? '#ffffff' : '#52595e')};
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;
