import {createSelector} from "reselect";

const eventExistsState = state => {
  return state.eventExistsReducer;
};

export const getEventStatus = createSelector(
    [eventExistsState],
    (state) => state.eventStatus
);

export const getEventCheckedValue = createSelector(
    [eventExistsState],
    (state) => state.eventChecked
)
