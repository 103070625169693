import { SignInActions } from '@/constants/actions/signInActions';

export const onUserSignIn = data => ({
  type: SignInActions.OnSignInUser,
  payload: data,
});

export const setIsLoading = data => ({
  type: SignInActions.SetIsLoading,
  payload: data,
});

export const addUserData = data => ({
  type: SignInActions.AddUserData,
  payload: data,
});

export const setErrorMsg = data => ({
  type: SignInActions.SetErrorMsg,
  payload: data,
});
