import React from 'react';
import { StyledToastContainer } from './styles';
const  Toaster = () => {

    return (
        <div>
            <StyledToastContainer />
        </div>
    )
}

export default Toaster;