const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const timeParserToString = (timeStart, timeEnd, isOnLivestream) => {
  const parseTimeStart = new Date(timeStart);
  const parseTimeEnd = new Date(timeEnd);
  const startMinutes =
    parseTimeStart.getMinutes() < 10
      ? `0${parseTimeStart.getMinutes()}`
      : parseTimeStart.getMinutes();
  const endMinutes =
    parseTimeEnd.getMinutes() < 10 ? `0${parseTimeEnd.getMinutes()}` : parseTimeEnd.getMinutes();
  return `${days[parseTimeStart.getDay()]} ${parseTimeStart.getDate()} ${
        months[parseTimeStart.getMonth()]
      }, ${parseTimeStart.getFullYear()}, ${parseTimeStart.getHours()}:${startMinutes} - ${parseTimeEnd.getHours()}:${endMinutes}`;
};
