import React, { useMemo, useState } from 'react';
import { isEqual } from 'deep-equal';
import {
  AddImageSection,
  AddImageSectionLabel,
  AddImageSectionSpan,
  AddVideoInput,
  AddVideoSection,
  AddVideoSectionSpan,
  ButtonsSection,
  EditHeaderContainer,
  ImageErrorMsg,
} from './styles';
import Button from '../../../controls/buttons/GenericButton/component';
import addVideoIcon from './icons/addVideoIcon.svg';
import addImageIcon from './icons/addimageIcon.svg';
import { setIsActiveEditHeader, updateImage, updateVideo } from '@/store/exhibitorPage';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import TextInput from '@/components/controls/TextInput';
import { editHeaderExhibitorSchema } from '@/components/controls/validationSchemas/editHeaderExhibitorSchema';
import { getIsLoading } from '@/store/exhibitorPage';

const EditHeaderSection = () => {
  const MAX_FILE_SIZE = useMemo(() => 10e6, []);
  const [downloadImageName, setDownloadImageName] = useState('');
  const [downloadImage, setDownloadImage] = useState('');
  const [isVideoUrlInput, setIsVideoUrlInput] = useState(false);
  const [imageError, setImageError] = useState('');
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const { exhibitorId } = useParams();

  const onChooseImage = e => {
    const file = e.target.files && e.target.files[0];
    setImageError('');
    if (file && file.size <= MAX_FILE_SIZE) {
      setDownloadImageName(e.target.files[0].name);
      setDownloadImage(e.target.files[0]);
    } else if (file && file.size > MAX_FILE_SIZE) {
      setDownloadImageName('');
      setImageError('Size of the file can not be more than 10 MB');
    }
  };

  const onSaveChanges = ({ videoUrlInput }) => {
    if (isVideoUrlInput) {
      dispatch(updateVideo({ exhibitorId, standVideo: videoUrlInput }));
    } else {
      dispatch(updateImage({ exhibitorId, standImage: downloadImage }));
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          videoUrlInput: '',
        }}
        validationSchema={editHeaderExhibitorSchema}
        onSubmit={onSaveChanges}
      >
        {() => (
          <Form>
            <EditHeaderContainer>
              <AddVideoSection>
                <img src={addVideoIcon} alt="add_video_icon" />
                <AddVideoSectionSpan
                  onClick={() => setIsVideoUrlInput(true)}
                  downloadImage={downloadImage}
                >
                  Add video
                </AddVideoSectionSpan>
                {isVideoUrlInput && (
                  <div>
                    <AddVideoInput>
                      <Field name="videoUrlInput">
                        {({ field, form }) => (
                          <TextInput
                            {...field}
                            form={form}
                            placeholder="youtube video url"
                            withoutIcon
                          />
                        )}
                      </Field>
                    </AddVideoInput>
                  </div>
                )}
              </AddVideoSection>
              <AddImageSection>
                <img src={addImageIcon} alt="add_image_icon" />
                <AddImageSectionLabel isVideoUrlInput={isVideoUrlInput}>
                  Add image
                  <input type="file" accept=".jpg, .gif, .png" onChange={onChooseImage} />
                </AddImageSectionLabel>
                <AddImageSectionSpan downloadImage={downloadImageName}>
                  {downloadImageName}
                </AddImageSectionSpan>
                {imageError && <ImageErrorMsg>{imageError}</ImageErrorMsg>}
              </AddImageSection>
            </EditHeaderContainer>
            <ButtonsSection>
              <Button label="Save" type="submit" isLoading={isLoading}/>
              <Button
                label="Cancel"
                styleType="outline"
                onClick={() => dispatch(setIsActiveEditHeader(false))}
              />
            </ButtonsSection>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default React.memo(EditHeaderSection, isEqual);
