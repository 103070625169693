import React, { useCallback, useEffect } from 'react';
import { isEqual } from 'deep-equal';
import { useDispatch, useSelector } from 'react-redux';
import { getLiveSessionsInfo } from '@/store/sessions';
import SessionCard from '../../blocks/Agenda/components/SessionCard/component';
import { AgendaDaySessions } from '../../blocks/Agenda/styles';
import BreakCard from '../../blocks/Agenda/components/BreakCard';
import Button from '../../controls/buttons/GenericButton/component';
import { history } from '@/store/rootReducer';
import { generateAgendaUrl } from '@/constants/routes';
import { useParams } from 'react-router-dom';
import {
  getEventPartners,
  getEventPartnersInfo,
  getIsFetchingLiveStream,
} from '@/store/livestream';
import Preloader from '../../blocks/Preloader';
import PartnersGroup from '../Landing/components/SponsorsAndPartners/components/PartnersGroup';

import {
  AgendaButton,
  AgendaSection,
  AgendaTitle,
  EventPartnersSection,
  EventPartnersSpan,
  LiveStreamPage,
  MainSection,
  MainSectionWrapper,
  StreamSession,
  VideoPlaceholder,
} from './styles';

const LiveStream = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { current_sessions: currentSession, remaining_sessions: remainingSession } = useSelector(
    getLiveSessionsInfo,
  );
  const eventPartners = useSelector(getEventPartnersInfo);
  const isFetchingLivestream = useSelector(getIsFetchingLiveStream);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getEventPartners(id));
  }, []);

  const onRedirectToAgenda = useCallback(() => {
    history.push(generateAgendaUrl(id));
  }, []);
  return (
    <>
      {isFetchingLivestream ? (
        <Preloader />
      ) : (
        <LiveStreamPage>
          <MainSectionWrapper>
            <MainSection>
              <VideoPlaceholder ref={ref} isValidationEventVideo={props.isValidEventVideo}/>
              <StreamSession>
                {currentSession && <SessionCard session={currentSession} isLive isOnLivestream />}
              </StreamSession>
              {remainingSession?.length > 0 && (
                <AgendaSection>
                  <AgendaTitle>Up next</AgendaTitle>
                  <AgendaDaySessions showBorder>
                    {remainingSession
                      .slice()
                      .reverse()
                      .map(session =>
                        session.is_break ? (
                          <BreakCard key={session.id} session={session} />
                        ) : (
                          <SessionCard
                            key={session.id}
                            session={session}
                            speakers={session.speakers}
                            allowBookmarking
                            allowSharing
                            remainingSession
                            id={session.id}
                          />
                        ),
                      )}
                  </AgendaDaySessions>
                  <AgendaButton>
                    <Button
                      label="View full agenda"
                      styleType="outline"
                      onClick={onRedirectToAgenda}
                    />
                  </AgendaButton>
                </AgendaSection>
              )}
              <EventPartnersSection>
                <EventPartnersSpan>Event Partners</EventPartnersSpan>
                {eventPartners?.map(partnersGroup => (
                  <PartnersGroup partnersGroup={partnersGroup} isOnLivestream />
                ))}
              </EventPartnersSection>
            </MainSection>
          </MainSectionWrapper>
        </LiveStreamPage>
      )}
    </>
  );
});

export default React.memo(LiveStream, isEqual);
