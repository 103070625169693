import * as Yup from 'yup';

export const invitedUsersSchema = Yup.object().shape({
  meetingDescription: Yup.string()
    .required('This is a required field')
    .min(10, 'The title must be in between 10 and 50 characters')
    .max(50, 'The title must be in between 10 and 50 characters'),
  description: Yup.string()
    .required('This is a required field')
    .min(10, 'The description must be in between 10 and 100 characters')
    .max(100, 'The description must be in between 10 and 100 characters'),
  meetingLink: Yup.string()
    .required('This is a required field')
    .matches(
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
      'Not a valid URL',
    ),
  date: Yup.string().required('This is a required field'),
  time: Yup.string().required('This is a required field'),
});
