import axios from 'axios';
import { baseURL } from '@/constants/baseURL';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

export const landingAPI = {
  eventData(id) {
    return instance.get(`events/event/${id}/`);
  },
  eventSpeakersData(id) {
    return instance.get(`events/event/${id}/speaker/`);
  },
  eventExists(event_pk) {
    return instance.get(`/events/event/${event_pk}`);
  },
  fetchAgenda(event_pk) {
    return instance.get(`events/event/${event_pk}/agenda/`);
  },
};
