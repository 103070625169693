import styled from 'styled-components';

export const InvitationCardConainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${({ isCurrentUserInvitation }) => (isCurrentUserInvitation ? '195px' : '150px')};
  border: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  border-radius: 4px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  padding: 10px 15px 20px 15px;
  display: flex;
  flex-direction: column;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  flex: 1;
`;

export const LoaderWrapper = styled.div`
  align-self: center;
  margin: auto;
`;

export const EventTitle = styled.div`
  width: 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 40px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const EventDate = styled.div`
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
`;

export const Description = styled.p`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
`;

export const EventLink = styled.a`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.primaryGreen};
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

export const Controls = styled.div`
  display: flex;
  height: 45px;
`;

export const ControlsSection = styled.div`
  width: 50%;

  &:not(:last-child) {
    border-right: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  }
`;

export const IvatationStatus = styled.div`
  position: relative;
  margin-top: 15px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: ${({ theme, isAccepted }) =>
    isAccepted === 'accepted' ? theme.colors.success : theme.colors.alert};
  background: ${({ theme, isAccepted }) =>
    isAccepted === 'accepted' ? theme.colors.successBackground : theme.colors.alertBackground};
  font-size: 15px;
  line-height: 20px;
`;

export const Author = styled.div`
  padding-bottom: 5px;
  align-self: ${({ isCurrentUserInvitation }) => isCurrentUserInvitation && 'flex-end'};
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  font-size: 13px;
  line-height: 16px;
`;
