import styled from 'styled-components';
import tinycolor from 'tinycolor2';

export const ExpoCardApplication = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const BackgroundGradient = styled.div`
  position: absolute;
  border-radius: 8px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  z-index: 1;
`;

export const ExpoCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0 30px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 280px;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    height: 315px;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  z-index: 2;
`;

export const LogoSection = styled.div`
  position: relative;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.originWhite};
  width: 100px;
  height: 100px;
  border: 1px solid ${({ theme }) => theme.colors.cards.border};
  box-sizing: border-box;
  margin-right: 50px auto;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100px;
    max-height: 40px;
  }
`;

export const ExpoLabelSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;

  img {
    z-index: 3;
    padding: 5px 7px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
`;

export const ExpoLabel = styled.span`
  display: flex;
  width: max-content;
  align-items: center;
  height: 25px;
  border-radius: 4px;
  padding: 1px 15px;
  margin-right: 15px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: ${({ labelColor }) => labelColor};
  color: ${({ labelTextColor }) => labelTextColor};

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    margin-bottom: 10px;
  }
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  max-height: 95px;
  word-break: break-all;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    max-height: 130px;
  }
`;

export const MainSectionTitle = styled.span`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.originWhite};
`;

export const MainSectionDescription = styled.span`
  display: -webkit-box;
  overflow: hidden;
  font-size: 15px;
  line-height: 20px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.originWhite};
`;

export const BackgroundSection = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const VectorIconSection = styled.svg`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  fill: ${({ color }) => tinycolor(color).darken(5).toString()};
`;
