import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'deep-equal';
import PollCard from './components/PollCard';
import { getLiveSessionsInfo } from '@/store/sessions/selectors';
import { getModeratorPollsInfo } from '@/store/eventManagementPage/selectors';
import {
  startModeratorPollsDataPolling,
  stopModeratorPollsDataPolling,
} from '@/store/eventManagementPage/actions';
import { Container, PollTitle, PollCardWrapper } from './styles';

const PollSection = () => {
  const dispatch = useDispatch();
  const { current_sessions } = useSelector(getLiveSessionsInfo);
  const moderatorPolls = useSelector(getModeratorPollsInfo);
  const eventPk = current_sessions ? current_sessions.id : false;

  useEffect(() => {
    if (eventPk) {
      dispatch(startModeratorPollsDataPolling(eventPk));
      return () => dispatch(stopModeratorPollsDataPolling(eventPk));
    }
  }, [eventPk]);

  const sortById = useCallback(arr => arr.sort((a, b) => (a.id > b.id ? 1 : -1)), []);

  return (
    <Container>
      <PollTitle>Polls</PollTitle>
      <PollCardWrapper>
        {sortById(moderatorPolls).map(poll => (
          <PollCard key={poll.id} data={poll} session={eventPk} />
        ))}
      </PollCardWrapper>
    </Container>
  );
};
export default React.memo(PollSection, isEqual);
