import React from 'react';
import { isEqual } from 'deep-equal';
import { Tab, TabsContainer } from './styles';

const Tabs = ({ handleSetCurrentTab, currentTab }) => {
  return (
    <TabsContainer>
      <Tab onClick={() => handleSetCurrentTab('filters')} active={currentTab === 'filters'}>
        Filters (1)
      </Tab>
      <Tab onClick={() => handleSetCurrentTab('sort')} active={currentTab === 'sort'}>
        Sort
      </Tab>
    </TabsContainer>
  );
};

export default React.memo(Tabs, isEqual);
