export const exhibitorPageActions = {
  SetExhibitor: 'exhibitor/SET_EXHIBITOR',
  GetExhibitor: 'exhibitor/GET_EXHIBITOR',
  SetIsFetchingExhibitor: 'exhibitor/SET_IS_FETCHING_EXHIBITOR',
  SetIsInactiveStand: 'exhibitor/SET_IS_INACTIVE_STAND',
  SetDescriptionVideo: 'exhibitor/SET_DESCRIPTION_VIDEO',
  updateDescriptionVideo: 'exhibitor/UPDATE_DESCRIPTION_VIDEO',
  UpdateVideo: 'exhibitor/UPDATE_VIDEO',
  UpdateImage: 'exhibitor/UPDATE_IMAGE',
  SetIsActiveEditHeader: 'exhibitor/SET_IS_ACTIVE_EDIT_HEADER',
  SetIsActiveEditDescription: 'exhibitor/SET_IS_ACTIVE_EDIT_DESCRIPTION',
  SetStandVideo: 'exhibitor/SET_STAND_VIDEO',
  SetStandImage: 'exhibitor/SET_STAND_IMAGE',
  SetIsBookmarkSession: 'exhibitor/SET_IS_BOOKMARK_SESSION',
  SetIsBookmarkExhibitor: 'exhibitor/SET_IS_BOOKMARK_EXHIBITOR',
  BookmarkSession: 'exhibitor/BOOKMARK_SESSION',
  UnBookmarkSession: 'exhibitor/UN_BOOKMARK_SESSION',
  BookmarkExhibitor: 'exhibitor/BOOKMARK_EXHIBITOR',
  UnBookmarkExhibitor: 'exhibitor/UN_BOOKMARK_EXHIBITOR',
  SetErrorDescriptionMsg: 'exhibitor/SET_ERROR_DESCRIPTION_MSG',
  AddResources: 'exhibitor/ADD_RESOURCES',
  RemoveResources: 'exhibitor/REMOVE_RESOURCES',
  DownloadExhibitorDoc: 'exhibitor/DOWNLOAD_EXHIBITOR_DOC',
  SetResourcesErrorMsg: 'exhibitor/SET_RESOURCES_ERROR_MSG',
  SetIsLoading: 'exhibitor/SET_IS_LOADING',
};
