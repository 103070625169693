import { createSelector } from 'reselect';

const isDelegateCheck = state => {
  return state.isDelegateReducer;
};

export const hasAccess = createSelector([isDelegateCheck], state => {
  return state.hasAccess;
});

export const getUserStatus = createSelector([isDelegateCheck], state => {
  return state.userStatus;
});