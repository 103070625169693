import React, { useMemo } from 'react';
import { isEqual } from 'deep-equal';
import { getTimeIntervalString } from '@/helpers/date';

import { CardWrapper, CardHeaderSection, CardBodySection, Time, Title } from './styles';

const BreakCard = ({ session }) => {
  const { start_time, end_time } = useMemo(() => session, [session]);
  return (
    <CardWrapper>
      <CardHeaderSection>
        <Time>{start_time && end_time && getTimeIntervalString(start_time, end_time)}</Time>
      </CardHeaderSection>
      <CardBodySection>
        <Title>{session.title}</Title>
      </CardBodySection>
    </CardWrapper>
  );
};

export default React.memo(BreakCard, isEqual);
