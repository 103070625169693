import styled from 'styled-components';

export const QACardContainer = styled.div`
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.backgroundDownloadCard};
  border-radius: 8px;
  padding: 5px 20px 20px 20px;
  margin-bottom: 15px;
`;

export const Time = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
`;

export const InfoBlock = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tabBorder};
  padding-bottom: 30px;
  margin-bottom: 15px;
  div {
    :first-child {
      flex-basis: 70%;
    }
    :last-child {
      flex-basis: 30%;
    }
  }
`;

export const Text = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.regularText};
`;

export const ButtonsContainer = styled.div`
  min-height: 80px;
  position: relative;
  align-self: flex-end;
  button {
    margin-bottom: 15px;
  }
`;

export const ContactCardWrapper = styled.div`
  width: 100%;
`;

export const Escalated = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
