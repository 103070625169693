import React from 'react';
import { isEqual } from 'deep-equal';
import Preloader from '@/components/blocks/Preloader';
import { Container, ButtonContainer, LabelContainer, Btn, Input, Label } from './styles';

const RadioButtonColumn = ({ textActive, textInactive, onClick, isActive, isLoading }) => {
  return (
    <Container>
      <ButtonContainer onClick={onClick}>
        <Btn active={isActive}>
          <Input type="checkbox"></Input>
        </Btn>
      </ButtonContainer>
      <LabelContainer>
        {isLoading ? (
          <Preloader />
        ) : (
          <Label active={isActive}>{isActive ? <p>{textActive}</p> : <p>{textInactive}</p>}</Label>
        )}
      </LabelContainer>
    </Container>
  );
};
export default React.memo(RadioButtonColumn, isEqual);
