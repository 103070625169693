import styled from 'styled-components';

export const NetworkingContainer = styled.div`
  position: relative;
  height: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

`;
export const InputsContainer = styled.div`
  display: flex;
  padding: 0 250px 30px 30px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    padding-right: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    padding-bottom: 0;
    margin-top: 10px;
  }
  > div:nth-child(1) {
    flex-basis: 70%;
    margin-right: 45px;
    @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
      flex-basis: 100%;
      margin-right: 0;
    }
  }
  > div:nth-child(2) {
    flex-basis: 30%;

    @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
      display: none;
    }
  }
`;
export const Layout = styled.div`
  height: 100%;
  padding: 0 250px 70px 30px;
  display: flex;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    padding-right: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    padding: 0 30px 0 30px;
    margin-top: 10px;
    flex: 1;
    overflow: auto;
  }
`;

export const FiltersSection = styled.div`
  height: 100%;
  flex-basis: 35%;
  margin-right: 30px;
  > div {
    height: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    display: none;
  }
`;

export const FilterButton = styled.div`
  margin-bottom: 15px;
`;

export const FiltersSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  > span {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  }
  > span:nth-child(2) {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.colors.regularText};
    cursor: pointer;
  }
`;

export const NetworkingResults = styled.div`
  height: 100%;
  overflow: auto;
  flex-basis: 65%;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    flex-basis: 100%;
  }
`;
export const CardContainer = styled.div`
  height: 50px;
  margin-bottom: 15px;
  display: flex;
`;

export const ResponsiveTabsSection = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    display: block;
    box-sizing: border-box;
    height: 50px;
  }
`;

export const ResponsiveFiltersSection = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    display: block;
    background: ${({ theme }) => theme.colors.originWhite};
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}){
    top: 60px;
  }
`;
export const LoadingDelegateList = styled.div`
  padding: 0 0 20px 20px;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.speakerDetails};
`;
