import React, { useState, useEffect } from 'react';
import { throttle } from '@/helpers';

export const useIsVideoScrolled = (videoPlaceRef, containerRef, delay) => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () =>
      videoPlaceRef.current?.getBoundingClientRect().bottom <= 0
        ? setIsScrolled(true)
        : setIsScrolled(false);
    const throttledHandleScroll = throttle(handleScroll, delay, {});
    containerRef.current && containerRef.current.addEventListener('scroll', throttledHandleScroll);
    return () => containerRef.current.removeEventListener('scroll', throttledHandleScroll);
  }, [containerRef, videoPlaceRef]);
  return isScrolled;
};
