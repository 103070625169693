import { CheckFormValidationActions } from '@/constants/actions/checkFormValidationActions'

export const checkFormValidation = (data) => ({
  type: CheckFormValidationActions.checkFormValidation,
  payload: data,
})

export const setIsLoading = (data) => ({
  type: CheckFormValidationActions.SetIsLoading,
  payload: data,
})