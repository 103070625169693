import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEqual } from 'deep-equal';
import { Field, Form, Formik } from 'formik';

import Button from '@/components/controls/buttons/GenericButton/component';

import { editSocialLinksSchema } from '@/components/controls/validationSchemas/editSocialLinksSchema';
import { getProfileInfo, updateSocialLinks, setIsActiveEditSocialLinks, setUpdateSocialLinksError, getIsEditSocialLinksLoading, getEditSocialLinksError } from '@/store/profilePage';
import {
  EditSocialLinksButtons,
  EditSocialLinksContainer,
  EditSocialLinksInput,
  LinkedinSection,
  TwitterSection,
  ErrorMessage,
} from './styles';

const EditSocialLinks = () => {
  const dispatch = useDispatch();
  const { linkedin_url, twitter_url, id } = useSelector(getProfileInfo);
  const params = useParams();
  const isLoading = useSelector(getIsEditSocialLinksLoading);
  const errorMessage = useSelector(getEditSocialLinksError);

  const onSubmit = useCallback(
    event => {
      dispatch(
        updateSocialLinks({
          id: params.id,
          delegateId: id,
          linkedinUrl: event.linkedin_url,
          twitterUrl: event.twitter_url,
        }),
      );
    },
    [dispatch],
  );

  const onCancel = useCallback(() => {
      dispatch(setIsActiveEditSocialLinks(false))
      dispatch(setUpdateSocialLinksError(''))
    }, [dispatch],
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ linkedin_url, twitter_url }}
      validationSchema={editSocialLinksSchema}
      validateOnChange={false}
    >
      {() => (
        <Form>
          <EditSocialLinksContainer>
            <LinkedinSection>
              <Field name="linkedin_url">
                {({ field, form }) => (
                  <EditSocialLinksInput
                    {...field}
                    form={form}
                    placeholder="e.g https://linkedin.com/in/john-smith-497832..."
                    label="LinkedIn profile URL"
                  />
                )}
              </Field>
            </LinkedinSection>
            <TwitterSection>
              <Field name="twitter_url">
                {({ field, form }) => (
                  <EditSocialLinksInput
                    {...field}
                    form={form}
                    placeholder="e.g https://twitter.com/jsmith3798..."
                    label="Twitter profile URL"
                  />
                )}
              </Field>
            </TwitterSection>
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <EditSocialLinksButtons>
              <Button label="Save" type="submit" isLoading={isLoading}/>
              <Button
                label="Cancel"
                styleType="outline"
                onClick={onCancel}
              />
            </EditSocialLinksButtons>
          </EditSocialLinksContainer>
        </Form>
      )}
    </Formik>
  );
};
export default React.memo(EditSocialLinks, isEqual);
