import { SignInActions } from '@/constants/actions/signInActions'

const initialState = {
  email: '',
  error: '',
  spinner: false,
  isSuccessChangePassword: false,
  isLoadingChangePassword: false
}

export const resetPassword = (state = initialState, action) => {
  switch (action.type) {
    case SignInActions.AddResetPasswordEmail:
      return {
        ...state,
        email: action.payload
      }
    case SignInActions.SetResetPasswordError:
      return {
        ...state,
        error: action.payload
      }
    case SignInActions.SetResetPasswordSpinner:
      return {
        ...state,
        spinner: action.payload
      }
    case SignInActions.SetIsLoadingChangePassword:
      return {
        ...state,
        isLoadingChangePassword: action.payload
      }
    case SignInActions.SetIsSuccessChangePassword:
      return {
        ...state,
        isSuccessChangePassword: action.payload
      }
    default:
      return state;
  }
};