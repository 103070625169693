import styled from 'styled-components';

export const ExhibitorResourcesContainer = styled.div`
  > span {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  }
`;

export const ExhibitorResourcesLabel = styled.div`
  position: relative;

  align-items: center;
  div:first-child {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  }
  div:last-child {
    > input {
      opacity: 1;
      left: 150px;
      cursor: pointer;
    }
  }
`;

export const ExhibitorResources = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const ExhibitorResource = styled.div`
  margin-bottom: 15px;
`;

export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div:last-child {
    cursor: ${({ active }) => active && 'pointer '};
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textDescriptionGrey};
  }
`;

export const FormWrapper = styled.div`
  margin: 20px 0px 30px 0px;
  input {
    margin-top: 10px;
    padding: 5px;
    overflow: hidden;
    font-size: 15px;
    line-height: 20px;
    text-overflow: ellipsis;
  }
  button {
    width: 100px;
    margin-top: 10px;
    margin-right: 15px;
  }

  small {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
  }
`;

export const ErrorMsg = styled.div`
  margin-top: 10px;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.alert};
`;
