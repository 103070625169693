import React, { useState, useEffect, useCallback, useRef } from 'react';
import { isEqual } from 'deep-equal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TextInput from '../TextInput';
import ContactCard from '@/components/blocks/ContactCard';
import {
  getDelegates,
  getDelegatesInfo,
  getSelectedDelegates,
  addDelegates,
  removeDelegates,
  getBookmarkedInfo,
  getBookmarked,
  addBookmarkeds,
} from '@/store/dashboardPage';
import {
  SelectedDelegates,
  ListTitle,
  ListContainer,
  ContactCardContainer,
  Delegates,
  AddDelegates,
  SearchDelegate,
  DeletePerson,
  Person,
} from './styles';
import { closeIcon } from '../icons';

const SelectDelegates = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const { id } = useParams();
  const [list, setList] = useState({ show: false, value: '' });
  const delegates = useSelector(getDelegatesInfo);
  const selectedDelegates = useSelector(getSelectedDelegates);
  const bookmarked = useSelector(getBookmarkedInfo);

  useEffect(() => {
    id && dispatch(getDelegates(id));
  }, [id, dispatch]);

  useEffect(() => {
    id && dispatch(getBookmarked(id));
  }, [id, dispatch]);

  const handleChange = useCallback(e => {
    setList({ show: !!e.target.value, value: e.target.value });
  }, []);

  const addDelegateHandler = useCallback(
    delegate => () => {
      dispatch(addDelegates(delegate));
      setList({ value: '', show: false });
    },
    [],
  );

  const removeDelegateHandler = useCallback(
    delegate => () => {
      dispatch(removeDelegates(delegate));
    },
    [],
  );

  const addBookmarkedsHandler = useCallback(
    bookmarked => () => {
      dispatch(addBookmarkeds(bookmarked));
    },
    [],
  );

  // custom hook
  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, []);
  };

  useOnClickOutside(ref, () => setList({ value: '', show: false }));

  return (
    <>
      <TextInput
        placeholder="Search a delegate by name, role or affiliation"
        label="Select people to invite"
        onChange={handleChange}
        value={list.value}
      />
      <Delegates>
        {!list.show && (
          <AddDelegates onClick={addBookmarkedsHandler(bookmarked)}>
            Add all bookmarked delegates ({bookmarked.length})
          </AddDelegates>
        )}
        {list.show && (
          <SearchDelegate ref={ref}>
            {delegates
              .filter(
                i =>
                  i.first_name.toLowerCase().includes(list.value.toLowerCase()) ||
                  i.last_name.toLowerCase().includes(list.value.toLowerCase()) ||
                  i.organisation.toLowerCase().includes(list.value.toLowerCase()),
              )
              .map(i => (
                <Person
                  key={i.id}
                  onClick={addDelegateHandler(i)}
                >{`${i.first_name} ${i.last_name} | ${i.organisation}`}</Person>
              ))}
          </SearchDelegate>
        )}
      </Delegates>
      <SelectedDelegates>
        <ListTitle>
          <span>RSVP LIST ({selectedDelegates.length})</span>
        </ListTitle>
        <ListContainer>
          {selectedDelegates.map((i, index) => (
            <ContactCardContainer key={index}>
              <ContactCard
                name={`${i.first_name} ${i.last_name}`}
                organisation={i.organisation}
                jobRole={i.role}
              />
              <DeletePerson onClick={removeDelegateHandler(i)}>{closeIcon}</DeletePerson>
            </ContactCardContainer>
          ))}
        </ListContainer>
      </SelectedDelegates>
    </>
  );
};

export default React.memo(SelectDelegates, isEqual);
