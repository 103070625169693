import styled from 'styled-components';

export const DownloadCardSection = styled.a.attrs(() => ({
  tabIndex: 0,
}))`
  display: flex;
  justify-content: space-between;
  border: 1px solid #dbe2eb;
  box-sizing: border-box;
  border-radius: 4px;
  &:hover {
    border: 1px solid #257bde;
  }
  &:focus {
    border: 1px solid #257bde;
    outline: none;
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 3px rgba(37, 123, 222, 0.2);
  }
`;

export const DownloadCardMain = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 10px 15px 17px;
`;

export const DownloadCardInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileCopyImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 22px;
`;

export const DownloadCardSpan = styled.span`
  display: -webkit-box;
  font-size: 15px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};

  &:nth-child(2) {
    font-size: 13px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  }
`;

export const DownloadImg = styled.div`
  display: ${({ hideDownload }) => (hideDownload ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  min-width: 43px;
  background: ${({ theme }) => theme.colors.backgroundDownloadCard};
`;
