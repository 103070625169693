import { notificationsActions } from '@/constants/actions/notificationsActions';

export const setNotifications = data => ({
  type: notificationsActions.SetNotifications,
  payload: data,
});

//saga actions
export const startGetNotificationsPolling = data => ({
  type: notificationsActions.StartGetNotificationsPolling,
  payload: data,
});

export const stopGetNotificationsPolling = data => ({
  type: notificationsActions.StopGetNotificationsPolling,
  payload: data,
});
