import React from 'react';
import { useParams } from 'react-router-dom';
import { isEqual } from 'deep-equal';
import styles from './prompt.module.css';
import { Link } from 'react-router-dom';

const SignInPrompt = ({ config, className }) => {
  const { id } = useParams()
  const url = `/${id}/sign-in`

  return (
    <span className={className}>
      {config.text}{' '}
      <Link to={url} className={styles.link}>
        {config.link.text}
      </Link>
    </span>
  );
};

export default React.memo(SignInPrompt, isEqual);
