import { StripeActions } from '@/constants/actions/stripe'

const initialState = {
  clientSecret: '',
  ticketConfirmation: '',
}

export const stripe = (state = initialState, action) => {
  switch (action.type) {
    case StripeActions.AddClientSecret:
      return {
        ...state,
        clientSecret: action.payload
      }
    case StripeActions.OnCheckTicket:
      return {
        ...state,
        ticketConfirmation: action.payload
      }
    default:
      return state;
  }
};

