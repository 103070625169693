import {eventExistsActions} from "../../constants/actions/eventExists";

export const setEventStatus = (value) => ({
  type: eventExistsActions.SetValue,
  payload: value
});

export const setEventCheckedValue = (value) => ({
  type: eventExistsActions.SetChecked,
  payload: value
});

export const checkEvent = (eventId) => ({
  type: eventExistsActions.CheckEvent,
  payload: eventId
})
