import React from 'react';
import { isEqual } from 'deep-equal';

import { ResponsiveContainer, ResponsiveIframe } from './styles';
import { useSelector } from 'react-redux';
import { youtubeRegExp } from '@/constants/regExp';
import { getLiveSessionsInfo } from '@/store/sessions';

const YouTubeStream = ({ preEventVideo, streamingLink }) => {
  const { stream_active: streamActive } = useSelector(getLiveSessionsInfo);
  const streamingLinkId = streamingLink.match(youtubeRegExp)[1];
  const preEventVideoId = preEventVideo.match(youtubeRegExp)[1];
  return (
    <ResponsiveContainer>
      <ResponsiveIframe
        autoPlay="1"
        src={`https://www.youtube.com/embed/${streamActive ? streamingLinkId : preEventVideoId}`}
        frameBorder="0"
        gesture="media"
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
    </ResponsiveContainer>
  );
};
export default React.memo(YouTubeStream, isEqual);
