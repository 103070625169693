import styled from 'styled-components';
import TextInput from '@/components/controls/TextInput';

export const EditSocialLinksContainer = styled.div`
  width: 100%;
`;

export const LinkedinSection = styled.div`
  margin-bottom: 15px;
`;
export const TwitterSection = styled(LinkedinSection)``;

export const EditSocialLinksInput = styled(TextInput)`
  width: 100%;
  padding-left: 15px;
  height: 40px;
  border: 1px solid #cad4dc;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px #f2f4fa;
  border-radius: 4px;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  input {
    margin-bottom: 15px;
  }
  &::placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  }
`;

export const EditSocialLinksButtons = styled.div`
  display: flex;
  margin-bottom: 30px;

  > button:nth-child(1) {
    width: 100px;
    margin-right: 15px;
  }

  > button:nth-child(2) {
    width: 115px;
  }
`;

export const ErrorMessage = styled.small`
  display: inline-block;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 14px;
  color: #df3541;
  margin-bottom: 15px;
`;