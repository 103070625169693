import React from 'react';

export const download = (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="17" stroke="#808B94" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 16H21V10H15V16H11L18 23L25 16ZM17 18V12H19V18H20.17L18 20.17L15.83 18H17ZM25 27V25H11V27H25Z"
      fill="#808B94"
    />
  </svg>
);
