import React, { useMemo } from 'react';
import { isEqual } from 'deep-equal';
import styles from './styles.module.css';

const TextArea = props => {
  const { id, label, disabled, name, onChange, value, onBlur, form } = props;
  const { errors, touched, setFieldError } = form;
  const showInvalid = useMemo(() => {
    return { display: errors[name] && touched[name] ? 'block' : 'none' };
  }, [errors]);
  return (
    <div className={styles['textarea-container']}>
      <label className={styles['input-label']}>{label}</label>
      <textarea
        id={`textarea-${id}`}
        id={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        className={`${styles['textarea']} ${
          errors[name] && touched[name] ? styles['textarea_error'] : ''
        }`}
        disabled={disabled}
        onFocus={() => setFieldError(name, '')}
      ></textarea>
      <small className={styles['error-text']} style={showInvalid}>
        {errors[name]}
      </small>
    </div>
  );
};

export default React.memo(TextArea, isEqual);
