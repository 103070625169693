import React, { useEffect } from 'react';
import { isEqual } from 'deep-equal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import Header from './components/Header';
import ContentBlock from './components/ContentBlock';
import SecondContentBlock from './components/SecondContentBlock';
import SponsorsAndPartners from './components/SponsorsAndPartners';
import OrginisedBy from './components/OrginisedBy';
import RegisterBlock from './components/RegisterBlock';
import Speakers from './components/Speakers';
import Features from './components/Features';
import Footer from './components/Footer';
import LandingAgenda from './components/Agenda';
import Streaming from './components/Streaming';
import Preloader from '@/components/blocks/Preloader';
import {
  getEvent,
  getEventSpeakers,
  getEventAgenda,
  getEventSponsor,
} from '@/store/landing/selectors';
import { getEventData, getEventSpeakersData, getEventAgendaData } from '@/store/landing/actions';
import { LandingContainer, LandingPage } from './styles';

const Landing = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const agenda = useSelector(getEventAgenda);
  const event = useSelector(getEvent);
  const speakers = useSelector(getEventSpeakers);
  const partners = useSelector(getEventSponsor);

  useEffect(() => {
    dispatch(getEventData(id));
    dispatch(getEventSpeakersData(id));
    dispatch(getEventAgendaData(id));
  }, [id, dispatch]);

  return (
    <LandingPage>
      {event.id && speakers !== null && agenda !== null && partners !== null ? (
        <>
          <MetaTags>
            <meta name="title" content={event.meta_title} />
            <meta name="description" content={event.meta_description} />
            <meta name="keywords" content={event.meta_keywords} />
          </MetaTags>
          <LandingContainer>
            <Header event={event} />
            <ContentBlock event={event} />
            {!!partners.length && <SponsorsAndPartners partners={partners} />}
            <SecondContentBlock event={event} />
            <OrginisedBy event={event} />
            {!!speakers.length && <Speakers speakers={speakers} />}
            {!!agenda.length && <LandingAgenda agenda={agenda} />}
            <RegisterBlock />
            <Features />
            <Streaming />
            <Footer />
          </LandingContainer>
        </>
      ) : (
        <Preloader label="Preparing the event page" />
      )}
    </LandingPage>
  );
};

export default React.memo(Landing, isEqual);
