import { call, put, takeEvery } from 'redux-saga/effects';
import { authAPI } from '@/api/authAPI';
import { addUserData } from './actions';
import { history } from '@/store/rootReducer';
import { SignInActions } from '@/constants/actions/signInActions';
import { setErrorMsg, setIsLoading } from './actions';
import { localStorageServices } from '@/helpers';
import {setIsEmailResend} from "../inactiveUserPage";

function* signInUser(action) {
  try {
    yield put(setIsEmailResend(false));
    yield put(setIsLoading(true));
    const response = yield call(authAPI.signIn, {
      email: action.payload.email,
      password: action.payload.password,
    });
    localStorageServices.setTokens(response.data);
    yield put(
      addUserData({
        email: action.payload.email,
        password: action.payload.password,
      }),
    );
    history.push(action.payload.PlatformRoute);
    yield put(setErrorMsg(''));
  } catch (error) {
    yield put(setErrorMsg(error.response.data.detail));
  } finally {
    yield put(setIsLoading(false));
  }
}

export function* watchSignIn() {
  yield takeEvery(SignInActions.OnSignInUser, signInUser);
}
