import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
`;

export const Message = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;

  text-align: center;

  /* Grey 800 */

  color: #17191a;
`;

export const ButtonWrapper = styled.div`
  min-width: 200px;
  margin-top: 30px;
`;

export const Or = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;

  text-align: center;

  /* Grey 800 */

  color: #17191a;
  margin-top: 15px;
`;

export const LinkWrapper = styled.div`
  min-width: 200px;
  margin-top: 15px;

  text-align: center;
`;
