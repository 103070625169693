import { call, delay, put, race, select, take } from 'redux-saga/effects';
import differenceBy from 'lodash.differenceby';
import { toast } from 'react-toastify';
import { GET_NOTIFICATIONS_DELAY } from '@/constants/longPollingDelay';
import { notificationsActions } from '@/constants/actions/notificationsActions';
import { setNotifications } from './actions';
import { notificationsAPI } from '@/api/notificationsAPI';
import {getSessionSet} from './selectors';

function* compareAndShoot(prevState, newData) {
  const newSessions = yield call(() => differenceBy(newData.session_set, prevState.session_set, 'id'));
  const newInvitations = yield call(() => differenceBy(newData.invitation_set, prevState.invitation_set, 'id'));
  newSessions.length &&  (
    newSessions.map(session => toast(`The session "${session.title}" will start in 5 minutes`, { autoClose: 10000 }))
  );
  newInvitations.length &&  (
    newInvitations.map(event => toast.warn(`The event "${event.description}" will start in 5 minutes`, { autoClose: 10000 }))
  );
}

function* getNotifications(action) {
  while (true) {
    try {
      const response = yield call(notificationsAPI.getNotifications, action.payload);
      const prevData = yield select(getSessionSet);
      yield call(() => compareAndShoot(prevData, response.data));
      yield put(setNotifications(response.data));
      yield delay(GET_NOTIFICATIONS_DELAY);
    } catch (error) {
      console.log(error);
    }
  }
}

export function* watchNotifications() {
  while (true) {
    const payload = yield take(notificationsActions.StartGetNotificationsPolling);
    yield race([
      call(getNotifications, payload),
      take(notificationsActions.StopGetNotificationsPolling),
    ]);
  }
}
