import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik';
import TextInput from '@/components/controls/TextInput';
import { ButtonPrimary } from '@/components/controls/buttons/ButtonPrimary';
import stripeLogo from './icons/stripe.svg';
import SummaryModal from '../SummaryForm/components/SummaryModal/component';
import CustomSelect from '@/components/controls/CustomSelect';
import { onBuyTicket, onCheckTicket } from '@/store/stripe/actions';
import { getTicketType, getTicketPrice } from '@/store/registration';
import { getClientSecret } from '@/store/stripe/selector';
import { getPromocode } from '@/store/promocodeValidation/selectors';
import TicketCard from '@/components/blocks/TicketCard';
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import styles from './styles.module.css';

export const PaymentForm = () => {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const clientSecret = useSelector(getClientSecret)
  const { id } = useParams()
  const elements = useElements()
  const ticketType = useSelector(getTicketType)
  const ticketPrice = useSelector(getTicketPrice)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isCard, setIsCard] = useState(false)
  const promocode = useSelector(getPromocode)

  const currencyArray = [
    {
      id: 'USD',
      label: 'USD',
    },
    {
      id: 'EUR',
      label: 'EUR',
    },
    {
      id: 'GBP',
      label: 'GBP',
    },
  ]

  const showCard = (currency) => () => {
    dispatch(onBuyTicket({ event_pk: id, currency: currency }))
    setIsCard(true)
  }

  const handleSubmit = async ev => {
    ev.preventDefault();

    const cardElement = elements.getElement("card");

    const paymentMethodReq = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    const confirm = await stripe.confirmCardPayment(clientSecret.client_secret, {
      payment_method: paymentMethodReq.paymentMethod.id
    });

    await dispatch(onCheckTicket({ pi_token: clientSecret.id, event_pk: id }))

    if (confirm.paymentIntent.status === "succeeded") setIsOpenModal(true)
  };

  return (
    <>
      {isOpenModal && <SummaryModal />}
      <Formik
        initialValues={{
          fullName: '',
          email: '',
          phone: '',
          billingAddress: '',
          city: '',
          postCode: '',
          cardNumber: '',
          validityDate: '',
          currency: 'GBP',
        }}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <>
            <div className={styles['ticket-container']}>
              <TicketCard price={ticketPrice} isFree={ticketType} promotionCode={promocode} currency={values.currency} />
            </div>
            <Form className={styles['form-container']}>
              <Field name="fullName">
                {({ field, form }) => (
                  <TextInput {...field} form={form} placeholder="e.g James..." label="Full name" />
                )}
              </Field>
              <div className={styles['email-phone']}>
                <Field name="email">
                  {({ field, form }) => (
                    <TextInput
                      {...field}
                      form={form}
                      placeholder="e.g j.smith@example.com..."
                      label="Email address"
                    />
                  )}
                </Field>
                <Field name="phone">
                  {({ field, form }) => (
                    <TextInput
                      {...field}
                      form={form}
                      placeholder="e.g +44207 123 4321..."
                      label="Phone number"
                    />
                  )}
                </Field>
              </div>
              <Field name="billingAddress">
                {({ field, form }) => (
                  <TextInput
                    {...field}
                    form={form}
                    placeholder="Address..."
                    label="Billing address"
                  />
                )}
              </Field>
              <div className={styles['city-postCode']}>
                <Field name="city">
                  {({ field, form }) => <TextInput {...field} form={form} placeholder="City.." />}
                </Field>
                <Field name="postCode">
                  {({ field, form }) => (
                    <TextInput {...field} form={form} placeholder="Postcode/ZIP..." />
                  )}
                </Field>
              </div>
              <div className={styles['currency-selection']}>
                <Field name="currency">
                  {({ field, form }) => (
                    <CustomSelect options={currencyArray} {...field} form={form} label="Currency" />
                  )}
                </Field>
              </div>
              {
                isCard
                  ? (<>
                    <div className={styles['card-info-block']}>
                      <div className={styles['card-info-title']}>Card info</div>
                      <div className={styles['date-cvc-container']}>
                        <CardElement
                          id="card-element"
                          className={styles['cardInfo']}
                        />
                        <img src={stripeLogo} alt="stripe-logo" className={styles['stripe-logo']} />
                      </div>
                    </div>
                    <ButtonPrimary text="Purchase ticket" color="#00967F" onClick={handleSubmit} />
                  </>)
                  : (<ButtonPrimary text="Show card" color="#00967F" onClick={showCard(values.currency)} />)
              }
            </Form>
          </>
        )
        }
      </Formik >
    </>
  )
};
