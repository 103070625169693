import axios from 'axios';
import { baseURL } from '@/constants/baseURL';
import { responseHandlerInterceptor, sendTokenInterceptor } from '../helpers/interceptors';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

instance.interceptors.request.use(request => {
  return sendTokenInterceptor(request);
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return responseHandlerInterceptor(error, instance);
  },
);

export const sessionsAPI = {
  bookmarkSession(event_pk, sessionId) {
    return instance.post(`/events/event/${event_pk}/favorite-session/`, {
      session: sessionId,
    });
  },
  unBookmarkSession(event_pk, sessionId) {
    return instance.delete(`/events/event/${event_pk}/favorite-session/${sessionId}/`);
  },
  getLiveSession(event_pk) {
    return instance.get(`/events/event/${event_pk}/live-session/`);
  },
  getSession(sessionId) {
    return instance.get(`/events/session/${sessionId}/`);
  },
};
