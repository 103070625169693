import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'deep-equal';
import InputSearch from '@/components/controls/InputSearch';
import InputSort from '@/components/controls/InputSort';
import {
  CardContainer,
  FilterButton,
  FiltersSection,
  FiltersSectionHeader,
  InputsContainer,
  Layout,
  LoadingDelegateList,
  NetworkingContainer,
  NetworkingResults,
  ResponsiveFiltersSection,
  ResponsiveTabsSection,
} from './styles';
import NetworkingFilters from '../../blocks/NetworkingFilters/component';
import ContactCard from '../../blocks/ContactCard/component';
import ResponsiveFilters from './ResponsiveFilters/components';
import Tabs from './ResponsiveFilters/Tabs/components';
import { useWindowSize } from '@/helpers/hooks';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAllFilters,
  getCheckedFilters,
  getDelegates,
  getDelegatesInfo,
  getIsFetchingDelegates,
  getIsFetchingFilters,
  getNetworkingFilters,
  getSearchMsg,
  getSelectedSort,
  onFilteringDelegates,
  getNextDelegatesList,
  getIsLoadingNextDelegateList,
} from '@/store/networkingPage';
import { useParams } from 'react-router-dom';
import Preloader from '../../blocks/Preloader';
import Button from '../../controls/buttons/GenericButton/component';
import { setCurrentPanelTab, createOrGetChat } from '@/store/interactionPanel/actions';

const Networking = props => {
  const [currentTab, setCurrentTab] = useState('');
  const [responsiveFilters, setResponsiveFilters] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const delegates = useSelector(getDelegatesInfo);
  const checkedFilters = useSelector(getCheckedFilters);
  const isFetchingFilters = useSelector(getIsFetchingFilters);
  const isFetchingDelegates = useSelector(getIsFetchingDelegates);
  const searchMsg = useSelector(getSearchMsg);
  const selectedSort = useSelector(getSelectedSort);
  const isLoadingNextDelegateList = useSelector(getIsLoadingNextDelegateList);

  useEffect(() => {
    dispatch(getNetworkingFilters(id));
    dispatch(getDelegates(id));
  }, []);

  const { breakpoints } = useContext(ThemeContext);
  const [windowWidth, _] = useWindowSize(0);

  const handleSetCurrentTab = useCallback(
    tab => {
      setCurrentTab(tab);
    },
    [currentTab],
  );

  const onClickTabs = useCallback(() => {
    setResponsiveFilters(true);
  }, [responsiveFilters]);

  if (responsiveFilters) {
    document.querySelector('body').classList.add('lock');
  } else {
    document.querySelector('body').classList.remove('lock');
  }

  const onClearAllFilters = useCallback(() => {
    dispatch(clearAllFilters());
  }, [dispatch]);

  const visibleFilters = useMemo(() => {
    if (windowWidth > parseInt(breakpoints.minWidth.landscape.tablet.replace(/\D/g, ''))) {
      return true;
    } else {
      return undefined;
    }
  }, [windowWidth]);

  const onFilteringDelegate = () => {
    dispatch(onFilteringDelegates({ id, searchMsg, selectedSort, checkedFilters }));
  };

  const allCheckedFilters = useMemo(() => {
    return Object.values(checkedFilters).reduce((acc, el) => acc + el.length, 0);
  }, [checkedFilters]);

  const handleOpenNewDialog = (id, delegate) => {
    dispatch(setCurrentPanelTab('messages'));
    dispatch(createOrGetChat({ event_pk: id, recipient: delegate }));
  };

  const handleScroll = useCallback(
    e => {
      const bottom =
        Math.round(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
      if (bottom) {
        dispatch(getNextDelegatesList(id));
      }
    },
    [id, dispatch],
  );

  return (
    <>
      {isFetchingFilters && isFetchingDelegates ? (
        <Preloader />
      ) : (
        <NetworkingContainer>
          <InputsContainer>
            <InputSearch
              placeholder="search people & organisations..."
              value={searchMsg}
              onFilteringDelegate={onFilteringDelegate}
            />
            <InputSort label="Sort" />
          </InputsContainer>
          <Layout>
            <FiltersSection>
              {isFetchingFilters ? (
                <Preloader />
              ) : (
                <div>
                  <FilterButton>
                    <Button label="Filter" styleType="outline" onClick={onFilteringDelegate} />
                  </FilterButton>
                  <FiltersSectionHeader>
                    <span>Filters ({allCheckedFilters})</span>
                    <span onClick={onClearAllFilters}>Clear filters</span>
                  </FiltersSectionHeader>
                  {visibleFilters && <NetworkingFilters />}
                </div>
              )}
            </FiltersSection>
            <NetworkingResults onScroll={handleScroll}>
              {isFetchingDelegates ? (
                <Preloader />
              ) : (
                <div>
                  {delegates.map(delegate => (
                    <CardContainer key={delegate.id}>
                      <ContactCard
                        delegateId={delegate.id}
                        name={`${delegate.first_name} ${delegate.last_name}`}
                        organisation={delegate.organisation}
                        jobRole={delegate.role}
                        photo={delegate.headshot}
                        isBookmark={delegate.is_bookmark}
                        handleOpenNewDialog={() => handleOpenNewDialog(id, delegate)}
                        isOnline={delegate.online}
                        isBookmarkedIcon
                        delegate={delegate}
                      />
                    </CardContainer>
                  ))}
                  {isLoadingNextDelegateList && (
                    <LoadingDelegateList>Loading...</LoadingDelegateList>
                  )}
                </div>
              )}
            </NetworkingResults>
          </Layout>
          {!responsiveFilters && (
            <ResponsiveTabsSection onClick={onClickTabs}>
              <Tabs currentTab={currentTab} handleSetCurrentTab={handleSetCurrentTab} />
            </ResponsiveTabsSection>
          )}
          {responsiveFilters && (
            <ResponsiveFiltersSection>
              <ResponsiveFilters
                currentTab={currentTab}
                handleSetCurrentTab={handleSetCurrentTab}
                setResponsiveFilters={setResponsiveFilters}
                setCurrentTab={setCurrentTab}
              />
            </ResponsiveFiltersSection>
          )}
        </NetworkingContainer>
      )}
    </>
  );
};

export default React.memo(Networking, isEqual);
