import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.cards.border};
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    height: 353px;
    flex-direction: row;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    height: auto;
    flex-direction: column;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
  }
`;

export const CardContainer = styled.div`
  position: relative;
  border-radius: 8px;
  background: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    flex-basis: 50%;
    padding-bottom: 25px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    flex-basis: 100%;
  }
`;

export const Logo = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  background: ${({ theme }) => theme.colors.originWhite};
  margin-top: 45px;
  border: 1px solid ${({ theme }) => theme.colors.cards.border};
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 2;

  > img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100px;
    max-height: 100px;
  }
`;

export const Title = styled.div`
  margin: 30px 0px;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  z-index: 2;
  color: ${({ theme }) => theme.colors.originWhite};
  padding: 0 10px;
`;

export const Social = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > div {
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: center;
    height: 20px;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
      height: auto;
    }
  }
`;

export const SocialIco = styled.a`
  background: ${({ theme }) => theme.colors.originWhite};
  border: 1px solid ${({ theme }) => theme.colors.cards.border};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    margin-left: 0px;
  }
`;

export const ContactsContainer = styled.div`
  margin-top: 35px;
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.originWhite};
  display: flex;
  flex-direction: column;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    padding-top: 0px;
    margin-top: 0;
    flex-basis: 50%;
    padding: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    margin-top: 35px;
    flex-basis: 100%;
    padding: 0;
  }
  svg {
    margin-right: 17px;
  }
  > div:first-child {
    display: flex;
    justify-content: center;
    height: 13px;
    background: ${({ theme }) => theme.colors.originWhite};
    border-radius: 0px 0px 8px 8px;
    @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
      justify-content: start;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
      justify-content: center;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    margin: ${({ label }) => (label ? '45px 30px 30px 30px' : '0 30px 30px 30px')};
    @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
      margin: 0;
      margin-top: 35px;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
      margin: ${({ label }) => (label ? '45px 30px 30px 30px' : '0 30px 30px 30px')};
    }
  }
`;

export const ExhibitorBadge = styled.span`
  display: flex;
  width: max-content;
  align-items: center;
  height: 25px;
  border-radius: 4px;
  padding: 1px 15px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
`;

export const Phone = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  svg {
    margin-right: 17px;
  }
`;

export const Site = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    height: 23px;
    width: 24px;
    margin-right: 13px;
  }
  a {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: ${({ theme }) => theme.colors.textDescriptionGrey};
    &:hover {
      color: ${({ theme }) => theme.colors.textDescriptionGrey};
    }
  }
`;

export const Email = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    height: 23px;
    width: 24px;
    margin-right: 13px;
  }
  a {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: ${({ theme }) => theme.colors.textDescriptionGrey};
    &:hover {
      color: ${({ theme }) => theme.colors.textDescriptionGrey};
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-top: ${({ label }) => (label ? '30px' : '0')};
  button {
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.primaryGreen};
    border: 1px solid ${({ theme }) => theme.colors.primaryGreen};
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
