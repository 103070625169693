import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEqual } from 'deep-equal';
import { reduceDelegateData } from '@/helpers';
import { icoLinkedin, icoTwitter } from './icons';
import { mystic, white } from '@/constants/colors';
import { bookmarkDelegate, getProfileInfo, unBookmarkDelegate } from '@/store/profilePage';
import { setCurrentPanelTab, createOrGetChat } from '@/store/interactionPanel/actions';
import Button from '../../../../controls/buttons/GenericButton/component';

import {
  ButtonContainer,
  CardContainer,
  ContactsContainer,
  Container,
  ProfileAvatar,
  Social,
  SocialIco,
  UserCountry,
  UserJob,
  UserName,
} from './styles';

const UserCardProfile = ({ paramsDelegateId, showButtons=true }) => {
  const dispatch = useDispatch();
  const delegate = useSelector(getProfileInfo);
  const {
    headshot,
    job_title,
    linkedin_url,
    twitter_url,
    first_name,
    last_name,
    organisation,
    country_name,
    is_bookmark,
    online
  } = delegate;
  const { id, delegateId } = useParams();

  const twitterRegExp = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/;
  const linkedinRegExp = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;

  const social = [
    { id: 1, icon: icoTwitter, url: twitterRegExp.test(twitter_url) ? twitter_url : '' },
    { id: 2, icon: icoLinkedin, url: linkedinRegExp.test(linkedin_url) ? linkedin_url : '' },
  ];

  const onIsBookmarkDelegate = () =>
    delegate.is_bookmark 
      ? dispatch(unBookmarkDelegate({  delegate: reduceDelegateData(delegate), event_pk: id, delegateId: delegate.id, })) 
      : dispatch(bookmarkDelegate({ delegate: reduceDelegateData(delegate), event_pk: id, delegateId: delegate.id }));

  const handleOpenNewChat = (event_pk, delegate) => {
    dispatch(setCurrentPanelTab('messages'));
    dispatch(createOrGetChat({ event_pk, recipient: delegate}));
  };

  const checkedParameters = useMemo(() => {
    if (!!organisation && !!job_title) {
      return ',';
    } else {
      return '';
    }
  }, [organisation, job_title]);

  return (
    <Container paramsDelegateId={paramsDelegateId}>
      <CardContainer paramsDelegateId={paramsDelegateId}>
        <ProfileAvatar
          src={`${headshot}`}
          name={`${first_name} ${last_name}`}
          maxInitials={1}
          size="150"
          round="50%"
          textSizeRatio={1.5}
          color={mystic}
          fgColor={white}
          isOnline={online}
        />
        <UserName>{`${first_name} ${last_name}`}</UserName>
        <UserJob>{`${organisation}${checkedParameters} ${job_title}`}</UserJob>
        <UserCountry>{country_name}</UserCountry>
        <Social paramsDelegateId={paramsDelegateId}>
          <div>
            {social.map(i => {
              if (i.url) {
                return (
                  <SocialIco key={i.id} href={i.url} target="_blank">
                    {i.icon}
                  </SocialIco>
                );
              }
            })}
          </div>
        </Social>
      </CardContainer>
      <ContactsContainer>
        <div></div>
        {showButtons && paramsDelegateId && (
          <div>
            <ButtonContainer>
              <Button label="Send message" styleType="outline" onClick={() => handleOpenNewChat(id, delegate)}/>
              <Button
                label={`${is_bookmark ? 'Unfollow' : 'Bookmark'}`}
                styleType="outline"
                onClick={() => onIsBookmarkDelegate()}
              />
            </ButtonContainer>
          </div>
        )}
      </ContactsContainer>
    </Container>
  );
};
export default React.memo(UserCardProfile, isEqual);
