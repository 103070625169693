import { isDelegateCheckActions } from '../../constants/actions/isDelegateCheck';

const initialState = {
  error: null,
  hasAccess: false,
  userStatus: ''
};

export const isDelegateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case isDelegateCheckActions.SetError:
      return {
        ...state,
        error: payload,
      };
    case isDelegateCheckActions.SetAccess:
      return {
        ...state,
        hasAccess: payload,
      };
    case isDelegateCheckActions.SetUserStatus:
      return {
        ...state,
        userStatus: payload,
      };
    default:
      return state;
  }
};
