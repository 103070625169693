import { createSelector } from 'reselect';

const getLivestream = state => state.livestream;

export const getEventPartnersInfo = createSelector([getLivestream], state => {
  return state.eventPartners;
});

export const getIsFetchingLiveStream = createSelector([getLivestream], state => {
  return state.isFetchingLivestream;
});
