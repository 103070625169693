const IS_POLLING = process.env.REACT_APP_NEEDS_FREQUENT_POLLING === 'true';

export const PING_IS_USER_ONLINE_DELAY = IS_POLLING ? 60000 : 3600e3; // 1 hour delay

// interaction panel
export const GET_CHAT_LIST_DELAY = IS_POLLING ? 5000 : 500000;
export const GET_CHAT_DATA_DELAY = IS_POLLING ? 5000 : 550000;
export const GET_QA_DATA_DELAY = IS_POLLING ? 5000 : 600000;

// platform
export const GET_LIVE_SESSIONS_DELAY = IS_POLLING ? 15000 : 650000;
export const GET_POLLS_DELAY = IS_POLLING ? 30000 : 1000000;
export const GET_BROADCAST_MESSAGES_DELAY = IS_POLLING ? 3000 : 1000000;
export const GET_NOTIFICATIONS_DELAY = IS_POLLING ? 30000 : 3000000;

// admin polling
export const GET_EVENT_STATISTIC_DELAY = IS_POLLING ? 60000 : 600000;
export const GET_ADMIN_POLLS_DELAY = IS_POLLING ? 10000 : 600000;
export const GET_ADMIN_QA_DELAY = IS_POLLING ? 10000 : 600000;

// chair - escalated questions polling
export const GET_ESCALATED_QUESTIONS_DELAY = IS_POLLING ? 3000 : 300000;

// dashboard polling
export const GET_STATISTIC_VISITORS_DELAY = IS_POLLING ? 60000 : 600000;
export const GET_EXIBITOR_DATA_DELAY = IS_POLLING ? 60000 : 600000;
