import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ButtonStyle } from './buttonStyle';

export const ButtonPrimary = ({ classes, text, color, type, onClick }) => {
  const StyledButton = withStyles({
    root: {
      display: 'block',
      background: `${color}`,
      '&:hover': {
        background: `${color}`,
      },
    },
    label: {
      fontWeight: 'bold',
      textTransform: 'none',
    },
  })(ButtonStyle);
  return (
    <StyledButton variant="contained" color="primary" type={type} onClick={onClick} disableRipple>
      {text}
    </StyledButton>
  );
};
