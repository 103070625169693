export const allowAccess = check =>
  check.user_status === 'active' && check.delegate_status === 'approved';

export const blockedUser = check =>
    check.user_status === 'blocked'

export const inactiveUser = check =>
    check.user_status === 'inactive'

export const notApproved = check =>
    check.user_status === 'active' && check.delegate_status !== 'approved';