import styled from 'styled-components';

export const BroadcastSection = styled.div`
  margin-top: 80px;
  position: relative;
`;

export const BroadcastTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.originBlack};
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

export const RadioBtnContainer = styled.div`
  display: flex;
  align-items: center;
  label {
    :last-child {
      margin: 0;
    }
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      &:first-child {
      }
    }
  }
`;

export const Message = styled.div`
  margin-top: 20px;
  position: relative;
`;

export const MessageInputField = styled.div`
  box-sizing: border-box;
  min-height: 120px;
  border: 1px solid ${({ theme }) => theme.colors.logoBorder};
  box-shadow: 0px 2px 4px ${({ theme }) => theme.colors.backgroundDownloadCard};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  textarea {
    width: 100%;
    height: 80px;
    border: none;
    background: ${({ theme }) => theme.colors.originWhite};
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.textDescriptionGrey};
    resize: none;
    box-shadow: none;
    padding: 10px;
    margin: 0px;
    :focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
  small {
    position: absolute;
    top: 80px;
  }
`;

export const MessageOutputField = styled.div`
  min-height: 120px;
  box-sizing: border-box;
  padding: 15px 20px;
  background: ${({ theme }) => theme.colors.backgroundDownloadCard};
  box-shadow: 0px 2px 4px ${({ theme }) => theme.colors.backgroundDownloadCard};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Text = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
`;

export const BtnEdit = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const BtnSave = styled(BtnEdit)`
  padding: 5px 20px 15px 0px;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  position: relative;
  > span {
    position: absolute;
    top: 40px;
  }
`;

export const BtnWrapper = styled.div`
  width: 200px;
`;

export const RadioBtnWrapper = styled.div`
  > input {
    height: 20px;
    width: 20px;
  }
  display: flex;
  align-items: center;
`;

export const LabelBtn = styled.label`
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
  padding: 0px 10px 0px 5px;
`;
