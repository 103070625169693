import React from 'react';
import { Container, Title, Details, BtnWrapper, Section } from './styles';
import GenericButton from '@/components/controls/buttons/GenericButton';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Container>
          <Section>
            <Title>An error occured.</Title>
            <Details>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </Details>
            <BtnWrapper>
              <GenericButton
                label="Reload"
                styleType="outline"
                color="green"
                onClick={() => this.setState({ error: null, errorInfo: null })}
              />
            </BtnWrapper>
          </Section>
        </Container>
      );
    }
    return this.props.children;
  }
}
