import styled from 'styled-components';

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    height: 100%;
  }
`;

export const BodyContainer = styled.div`
  padding: 15px 30px 0 30px;
  flex: 1;
  overflow: auto;
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  border-top: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  height: 70px;
  background: ${({ theme }) => theme.colors.originWhite};
  padding: 0 30px;
`;
