import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const StyledToastContainer = styled(ToastContainer).attrs({
    className: 'toast-container',
    toastClassName: 'toast',
    bodyClassName: 'body',
    progressClassName: 'progress',
    position:'bottom-left'    
})

`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    left: 2em;
    padding-right: 50px;
 
    @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
        bottom: 7rem;
    }

    .Toastify__toast {
        display: inline;
        position: relative;
        min-height: 55px;
        background: ${({ theme }) => theme.colors.originBlack};
        color: ${({ theme }) => theme.colors.originWhite};
        padding: 15px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        max-height: 800px;
        align-items: center;
        overflow: unset;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;

        @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
             margin-bottom: 10px;
        }
    }

    .Toastify__toast-container {
        max-width: 98vw;
    }
    
    .Toastify__toast-body {
        margin-left:15px;
    }
    
    .Toastify__toast::before {
        content: "";
        position: absolute;
        left: -9px;
        width: 20px;
        height: 20px;
        border-radius: 50%;  
    }

    .Toastify__toast--default::before {
        background-color: ${({ theme }) => theme.colors.alert}; 
    }  
     
    .Toastify__toast--warning::before {
        background-color: #7688DC; 
    }
    
    .Toastify__close-button:hover{
        opacity: 1;
    }

    .Toastify__close-button {
        color: ${({ theme }) => theme.colors.agenda.break.background};
        background: transparent;
        opacity: 0.7;
        margin-left: 40px;

        svg {
            padding-top: 4px;
        }
    }

    .Toastify__close-button > svg {
        height: 22px;
        width: 22px;
    }

    .Toastify__progress-bar {
        background: none;
    }
`;
