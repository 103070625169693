import React from 'react';
import { isEqual } from 'deep-equal';
import { InterestsApp, InterestsContainer, InterestSection, InterestTitle } from './styles';
import InterestCard from '@/components/blocks/InterestCard/component';
import { useSelector } from 'react-redux';
import { getProfileInfo } from '@/store/profilePage';

const Interests = () => {
  const { interests_set } = useSelector(getProfileInfo);
  return (
    <InterestsApp>
      <InterestTitle>Interests</InterestTitle>
      <InterestsContainer>
        {interests_set.map(i => (
          <InterestSection key={i.id}>
            <InterestCard name={i.name} />
          </InterestSection>
        ))}
      </InterestsContainer>
    </InterestsApp>
  );
};
export default React.memo(Interests, isEqual);
