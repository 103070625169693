export const interactionPanelActions = {
  SetCurrentPanelTab: 'interactionPanel/SET_CURRENT_PANEL_TAB',

  SetCurrentChatData: 'interactionPanel/SET_CURRENT_CHAT_DATA',
  SetCurrentChatDataFail: 'interactionPanel/SET_CURRENT_CHAT_DATA_FAIL',
  StartMessagesPolling: 'interactionPanel/START_MESSAGES_POLLING',
  StopMessagesPolling: 'interactionPanel/STOP_MESSAGES_POLLING',
  SetAreMessagesLoading: 'interactionPanel/SET_ARE_MESSAGES_LOADING',
  SendMessage: 'interactionPanel/SEND_MESSAGE',
  SetSendingMessage: 'interactionPanel/SET_SENDING_MESSAGE',
  RemoveSendingMessage: 'interactionPanel/REMOVE_SENDING_MESSAGE',
  PutMessageToCurrentReceivedList: 'interactionPanel/PUT_MESSAGE_TO_CURRENT_RECEIVED_LIST',
  SendInvitationStatus: 'interactionPanel/SEND_INVITATION_STATUS',
  PutProcessedInvitationId: 'interactionPanel/PUT_PROCESSED_INVITATION_ID',
  RemoveProcessedInvitationId: 'interactionPanel/REMOVE_PROCESSED_INVITATION_ID',
  PutIsChatBeingCreated: 'interactionPanel/PUT_IS_CHAT_IS_BEING_CREATED',
  PutBlockPuttingChatData: 'interactionPanel/PUT_BLOCK_PUTTING_CHAT_DATA',

  SetDialogs: 'interactionPanel/SET_DIALOGS',
  SetDialogsFail: 'interactionPanel/SET_DIALOGS_FAIL',
  SetIsDialogOpen: 'interactionPanel/SET_IS_DIALOG_OPEN',
  StartDialogsPolling: 'interactionPanel/START_DIALOGS_POLLING',
  StopDialogsPolling: 'interactionPanel/STOP_DIALOGS_POLLING',
  SetAreDialogsLoading: 'interactionPanel/SET_ARE_DIALOGS_LOADING',

  SetCurrentDelegate: 'interactionPanel/SET_CURRENT_DELEGATE',
  SetFavoriteDelegates: 'interactionPanel/SET_FAVORITE_DELEGATES',
  PutFavoriteDelegates: 'interactionPanel/PUT_FAVORITE_DELEGATES',
  AddFavoriteDelegate: 'interactionPanel/ADD_FAVORITE_DELEGATE',
  RemoveFavoriteDelegate: 'interactionPanel/REMOVE_FAVORITE_DELEGATE',
  PollAllQuestions: 'interactionPanel/POLL_ALL_QUESTIONS',
  StopQuestionsPolling: 'interactionPanel/STOP_QUESTIONS_POLLING',
  PutAllQuestions: 'interactionPanel/PUT_ALL_QUESTIONS',
  PutAreQuestionsLoading: 'interactionPanel/PUT_ARE_QUESTIONS_LOADING',
  SendQuestion: 'interactionPanel/SEND_QUESTION',
  SetSendingQuestion: 'interactionPanel/SET_SENDING_QUESTION',
  RemoveSendingQuestion: 'interactionPanel/REMOVE_SENDING_QUESTION',
  PutQuestionToCurrentReceivedList: 'interactionPanel/PUT_QUESTION_TO_CURRENT_RECEIVED_LIST',
  GetUserNonActiveQuestions: 'interactionPanel/GET_ALL_USER_NON_ACTIVE_QUESTIONS',
  PutUserNonActiveQuestions: 'interactionPanel/PUT_ALL_USER_NON_ACTIVE_QUESTIONS',

  SetFavoriteSessions: 'interactionPanel/SET_FAVORITE_SESSIONS',
  PutFavoriteSessions: 'interactionPanel/PUT_FAVORITE_SESSIONS',
  SetFavoriteExhibitors: 'interactionPanel/SET_FAVORITE_EXHIBITORS',
  PutFavoriteExhibitors: 'interactionPanel/PUT_FAVORITE_EXHIBITORS',
  SetAreBookmarksLoading: 'interactionPanel/SET_ARE_BOOKMARKS_LOADING',
  CreateOrGetChat: 'interactionPanel/CREATE_OR_GET_CHAT',
  PutCurrentChat: 'interactionPanel/PUT_CURRENT_CHAT',
};
