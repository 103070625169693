import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px 30px 0px 30px;
  display: flex;
  height: 100%;
 
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    flex-direction: column;
    margin-top: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
     padding: 0px 9px 0px 9px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tablet}) {
      overflow: auto;
  }

`;

export const EditSocialLinksApp = styled.div`
  margin-top: 45px;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    margin-bottom: 35px;
  }
`;

export const PersonalSettings = styled.div`
  width: 30%;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 100%;
  }
`;

export const PersonalInfo = styled.div`
  width: 70%;
  margin-left: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const PrivacySettingsWrapper = styled.div`
  margin-top: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    display: none;
  }
`;

export const PrivacySettingsAdaptiveWrapper = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    display: block;
    margin: 30px 0px;
  }
`;

export const EditBiographyApp = styled.div``;

export const EditBiographySpan = styled.span`
  display: inline-block;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.textDescriptionDarkGrey};
  margin-bottom: 35px;
  word-break: break-all;
`;

export const EditInterestsApp = styled.div`
  margin-bottom: 20px;
`;

export const SessionContainer = styled.div``;

export const SessionTitle = styled.span`
  display: inline-block;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
`;

export const ExhibitorContainer = styled.div`
  margin: 30px 0;
  max-width: 410px;
`;

export const ExhibitorContainerTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  margin-bottom: 15px;
`;
