import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.portrait.phone}) {
    margin: 30px 30px 15px 30px;
  }
`;

export const Content = styled.div`
  width: 360px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 25px;
  margin-top: 65px;
  margin-bottom: 45px;
`;

export const LogoContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.portrait.phone}) {
    width: 150px;
  }
`;
