import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEqual } from 'deep-equal';
import Scrollspy from 'react-scrollspy';
import Agenda from '@/components/blocks/Agenda';
import { pullAgendaData } from '@/store/agenda/actions';
import { getAgendaData, getShowLoader } from '@/store/agenda';
import { getLiveSessionsInfo } from '@/store/sessions';
import Preloader from '../../blocks/Preloader';
import { generateAgendaDayId, getDateString, isDayValid } from '../../../helpers';
import ErrorBoundary from '@/components/blocks/ErrorBoundary';
import {
  AgendaPageWrapper,
  AgendaDateMenuSection,
  AgendaDateMenuItem,
  AgendaSection,
  StyledLink,
} from './styles';

const AgendaPage = () => {
  const dispatch = useDispatch();
  const { id: event_pk } = useParams();
  const isLoading = useSelector(getShowLoader);
  const agenda = useSelector(getAgendaData);
  const { current_sessions: currentSession } = useSelector(getLiveSessionsInfo);
  const menuCurrentClassName = useMemo(() => 'agenda-menu-active');
  const dateMenuItems = useMemo(
    () => agenda?.map(day => isDayValid(day) && generateAgendaDayId(day.id)),
    [agenda],
  );

  const liveSessionRef = useRef();
  const agendaRef = useRef();

  const checkAgendaData = useMemo(
    () => !!liveSessionRef.current && !!agendaRef.current && !!currentSession,
    [liveSessionRef.current, agendaRef.current, currentSession],
  );

  useEffect(() => {
    dispatch(pullAgendaData(event_pk));
  }, [event_pk, dispatch]);

  useEffect(() => {
    !isLoading &&
      checkAgendaData &&
      agendaRef.current.scrollTo(0, liveSessionRef.current.offsetTop - window.innerHeight / 3);
  }, [liveSessionRef, isLoading]);

  return (
    <ErrorBoundary>
      {isLoading ? (
        <Preloader />
      ) : (
        <AgendaPageWrapper>
          {agenda && (
            <>
              <AgendaDateMenuSection activeClassName={menuCurrentClassName}>
                <Scrollspy
                  items={dateMenuItems}
                  currentClassName={menuCurrentClassName}
                  rootEl={'#agenda_page-agenda_section'}
                >
                  {agenda.map(
                    day =>
                      isDayValid(day) && (
                        <AgendaDateMenuItem key={day.id}>
                          <StyledLink href={`#${generateAgendaDayId(day.id)}`}>
                            {getDateString(day.date, true)}
                          </StyledLink>
                        </AgendaDateMenuItem>
                      ),
                  )}
                </Scrollspy>
              </AgendaDateMenuSection>
              <AgendaSection id="agenda_page-agenda_section" ref={agendaRef}>
                <Agenda
                  agenda={agenda}
                  liveSession={currentSession}
                  liveSessionRef={liveSessionRef}
                  agendaRef={agendaRef}
                />
              </AgendaSection>
            </>
          )}
        </AgendaPageWrapper>
      )}
    </ErrorBoundary>
  );
};

export default React.memo(AgendaPage, isEqual);
