import { networkingActions } from '../../constants/actions/networkingActions';

const initialState = {
  delegates: [],
  search: '',
  sort: [],
  selectedSort: null,
  filters: {},
  checkedFilters: {},
  isFetchingFilters: true,
  isFetchingDelegates: true,
  nextDelegatesUrl: '',
  isLoadingNextDelegateList: false
};

export const networkingPage = (state = initialState, action) => {
  switch (action.type) {
    case networkingActions.SetSearch:
      return {
        ...state,
        search: action.payload,
      };
    case networkingActions.SetIsLoadingNextDelegateList:
      return {
        ...state,
        isLoadingNextDelegateList: action.payload,
      };
    case networkingActions.SetNextDelegatesUrl:
      return {
        ...state,
        nextDelegatesUrl: action.payload,
      };
    case networkingActions.SetSort:
      return {
        ...state,
        sort: action.payload,
      };
    case networkingActions.SetSelectedSort:
      return {
        ...state,
        selectedSort: action.payload,
      };
    case networkingActions.SetFilters:
      return {
        ...state,
        filters: action.payload,
      };
    case networkingActions.SetCheckedFilters:
      return {
        ...state,
        checkedFilters: {
          ...state.checkedFilters,
          [action.title]: [...state.checkedFilters[action.title], action.item],
        },
      };
    case networkingActions.ClearSomeCheckedFilter:
      return {
        ...state,
        checkedFilters: {
          ...state.checkedFilters,
          [action.payload]: [],
        },
      };
    case networkingActions.RemoveCheckedFilters:
      return {
        ...state,
        checkedFilters: {
          ...state.checkedFilters,
          [action.title]: state.checkedFilters[action.title].filter(i => i.id !== action.item.id),
        },
      };
    case networkingActions.ClearAllFilters:
      const newState = { ...state.checkedFilters };
      for (let key in newState) {
        newState[key] = [];
      }
      return {
        ...state,
        checkedFilters: { ...state.checkedFilters, ...newState },
      };
    case networkingActions.AddCheckedFilterProperties:
      return {
        ...state,
        checkedFilters: { ...state.checkedFilters, ...action.payload },
      };
    case networkingActions.SetDelegates:
      return {
        ...state,
        delegates: action.payload
      };
    case networkingActions.SetNextDelegatesList:
      return {
        ...state,
        delegates: [...state.delegates, ...action.payload]
      };
    case networkingActions.SetIsFetchingDelegates:
      return {
        ...state,
        isFetchingDelegates: action.payload,
      };
    case networkingActions.SetIsFetchingFilters:
      return {
        ...state,
        isFetchingFilters: action.payload,
      };
    case networkingActions.SetIsBookmark:
      return {
        ...state,
        delegates: state.delegates.map(d => {
          if(d.id === action.delegateId){
            return {...d, is_bookmark: action.isBookmark}
          }
          return d
        }),
      };
    default:
      return state;
  }
};
