export const localStorageServices = {
  setTokens(tokenObj) {
    localStorage.setItem('accessToken', tokenObj.access);
    localStorage.setItem('refreshToken', tokenObj.refresh);
  },
  getAccessToken() {
    return localStorage.getItem('accessToken');
  },
  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  },
  clearAllTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  },
  setAccessToken(accessToken) {
    localStorage.setItem('accessToken', accessToken);
  },
};
