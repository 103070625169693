import React from 'react';
import { isEqual } from 'deep-equal';

import { IconSection } from './styles';

const PlayIcon = () => {
  return (
    <IconSection xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333328 27C0.333328 12.28 12.28 0.333344 27 0.333344C41.72 0.333344 53.6667 12.28 53.6667 27C53.6667 41.72 41.72 53.6667 27 53.6667C12.28 53.6667 0.333328 41.72 0.333328 27ZM37.6667 27L21.6667 15V39L37.6667 27Z"
      />
    </IconSection>
  );
};

export default React.memo(PlayIcon, isEqual);
