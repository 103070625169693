import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

export const LabelWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const Dot = styled.span`
  height: 5px;
  width: 5px;
  background-color: #df3541;
  border-radius: 50%;
  display: inline-block;
  /* animation: ${blink} 2s infinite; */
`;

export const Text = styled.span`
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.agenda.nowLiveLabel};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-left: 5px;
`;
