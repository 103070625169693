import React, { useEffect } from 'react';
import { isEqual } from 'deep-equal';

import {Container, EmailApproveMessage} from './styles';
import Preloader from '../../blocks/Preloader';
import { useDispatch, useSelector } from 'react-redux';
import { getIsEmailApprove, emailApprove, getEmailApproveErrMsg } from '@/store/emailApprovePage';
import { useLocation, useParams } from 'react-router-dom';
import { history } from '@/store/rootReducer';
import { generatePlatformUrl } from '@/constants/routes';

const EmailApprovePage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const token = location.search.split('=')[1];
  const isEmailApprove = useSelector(getIsEmailApprove);
  const emailApproveErrMsg = useSelector(getEmailApproveErrMsg);

  useEffect(() => {
    dispatch(emailApprove({ eventId: id, token }));
  }, []);
  return (
    <Container>
      {!isEmailApprove ? (
        <Preloader label="Approving email..." />
      ) : emailApproveErrMsg ? <EmailApproveMessage>{emailApproveErrMsg}</EmailApproveMessage> : (
        history.push(generatePlatformUrl(id))
      )}
    </Container>
  );
};

export default React.memo(EmailApprovePage, isEqual);
