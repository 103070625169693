import { livestreamActions } from '@/constants/actions/livestreamActions';

const initialState = {
  eventPartners: [],
  isFetchingLivestream: true
};

export const livestream = (state = initialState, action) => {
  switch (action.type) {
    case livestreamActions.SetEventPartners:
      return {
        ...state,
        eventPartners: action.payload,
      };
    case livestreamActions.IsFetchingLivestream:
      return {
        ...state,
        isFetchingLivestream: action.payload,
      };
    default:
      return state;
  }
};
