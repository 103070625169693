import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
`;

export const Tab = styled.button`
  flex-basis: 50%;
  height: 50px;
  border: none;
  outline: none;
  color: ${({ theme, active }) =>
    active ? theme.colors.regularText : theme.colors.textDescriptionGrey};
  border-top: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  border-bottom: ${({ theme, active }) =>
    active ? 'none' : `1px solid ${theme.colors.tabBorder}`};
  cursor: pointer;
  background: ${({ theme }) => theme.colors.originWhite};
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;

  &:first-child {
    border-right: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  }
`;
