import { getDateString } from '@/helpers/date';
import { AGENDA_DAY_ID_PREFIX } from '@/constants/agenda';

export const isDayValid = day => !!day.sessions?.length;

export const generateAgendaDayId = dayId => `${AGENDA_DAY_ID_PREFIX}${dayId}`;

export const getDayDate = day => {
  const date = new Date(day.date);
  return date.toUTCString();
}

export const getDaySessionStartDate = day => {
  const start = day.sessions.slice().reverse()[0]?.start_time;
  return getDateString(start);
};
