import styled from 'styled-components';

export const SelectedDelegates = styled.div``;

export const ListTitle = styled.div`
  position: relative;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  overflow: hidden;
  span {
    position: relative;
    :before {
      display: block;
      width: 1000px;
      content: '';
      position: absolute;
      border-bottom: 1px solid ${({ theme }) => theme.colors.tabBorder};
      top: 50%;
      right: 150px;
    }
    :after {
      display: block;
      width: 1000px;
      content: '';
      position: absolute;
      border-bottom: 1px solid ${({ theme }) => theme.colors.tabBorder};
      top: 50%;
      left: 150px;
    }
  }
`;
export const ListContainer = styled.div`
  position: relative;
  height: 200px;
  overflow-y: auto;
  margin-bottom: 30px;
`;
export const ContactCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px;
  svg {
    margin-right: 20px;
  }
`;

export const Delegates = styled.div``;
export const AddDelegates = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.blueChill};
  margin-top: 10px;
  margin-bottom: 50px;
  cursor: pointer;
`;

export const SearchDelegate = styled.ul`
  width: 100%;
  max-height: 150px;
  margin-top: 2px;
  background: ${({ theme }) => theme.colors.originWhite};
  border: 1px solid ${({ theme }) => theme.colors.logoBorder};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px ${({ theme }) => theme.colors.backgroundDownloadCard};
  border-radius: 4px;
  overflow: auto;
`;

export const Person = styled.li`
  margin: 8px 0px 3px 10px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.originBlack};
  cursor: pointer;
`;
export const DeletePerson = styled.div`
  cursor: pointer;
`;
