import styled from 'styled-components';

export const EditDescriptionContainer = styled.div``;

export const EditDescriptionTextarea = styled.textarea`
  padding: 15px;
  border: ${({ theme }) => `1px solid ${theme.colors.logoBorder}`};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px #f2f4fa;
  border-radius: 4px;
  width: 100%;
  font-size: 15px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.textDescriptionDarkGrey};
  margin-bottom: 20px;
  resize: none;
`;
