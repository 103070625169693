import styled from 'styled-components';

export const DownloadCardSection = styled.div`
  display: flex;
  box-sizing: content-box;
  justify-content: space-between;
  border: 1px solid #dbe2eb;
  border-radius: 4px;
`;

export const DownloadCardMain = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 15px 30px 15px 17px;
  width: 80%;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
`;

export const DownloadCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const FileCopyImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 22px;
`;

export const DownloadCardSpan = styled.span`
  display: -webkit-box;
  font-size: 15px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};

  &:nth-child(2) {
    font-size: 13px;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  }
`;

export const DownloadImg = styled.a.attrs(() => ({
  tabIndex: 0,
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 43px;
  background: ${({ theme }) => theme.colors.backgroundDownloadCard};
`;

export const DeleteImg = styled.div`
  min-width: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    height: 20px;
    width: 20;
  }
`;

export const PopoverTitle = styled.div`
  height: 50px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BtnContainer = styled.div`
  display: flex;
  > button {
    margin-right: 5px;
  }
`;
