import React, {useCallback, useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { UploadImage } from '@/components/controls/UploadImage';
import TextArea from '@/components/controls/TextArea';
import TextInput from '@/components/controls/TextInput';
import GenericButton from '@/components/controls/buttons/GenericButton';
import { ButtonSecondary } from '@/components/controls/buttons/ButtonSecondary';
import HelpText from '@/components/controls/HelpText';
import { getRegistrationError, setRegistrationStep } from '@/store/registration';
import { getRegistrationData } from '@/store/registration/selectors';
import { uploadProfileSchema } from '@/components/controls/validationSchemas/uploadProfileSchema';
import style from './styles.module.css';
import { getIsLoadingInfo } from '@/store/checkFormValidation';

export const UploadProfileForm = ({ submitAction, addStoreData }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const { id } = useParams();
  const errorMsg = useSelector(getRegistrationError);
  const { bio } = useSelector(getRegistrationData);
  const isLoading = useSelector(getIsLoadingInfo);

  const onSubmit = values => {
    dispatch(submitAction({ values, next: 'interests', putFunc: addStoreData, event_pk: id }));
  };

  const onDoThisLater = useCallback(() => {
    dispatch(setRegistrationStep('interests'))
  }, [])

  useEffect(() => {
    const { current } = formRef;

    if (bio.biography) {
      for (const key in bio) {
        current.setFieldValue(key, bio[key]);
      }
    }
  }, [bio, formRef]);

  return (
    <Formik
      onSubmit={onSubmit}
      innerRef={formRef}
      initialValues={{ image: '', biography: '', linkedin_url: '', twitter_url: '' }}
      validationSchema={uploadProfileSchema}
      validateOnChange={false}
    >
      {() => (
        <Form className={style['upload-profile-form']}>
          <Field name="image">{({ field, form }) => <UploadImage {...field} form={form} />}</Field>
          <Field name="biography">
            {({ field, form }) => <TextArea {...field} form={form} label="About you" />}
          </Field>
          <div>
            <HelpText>
              Please add a brief description that will be displayed on your profile
            </HelpText>
          </div>
          <Field name="linkedin_url">
            {({ field, form }) => (
              <TextInput
                {...field}
                form={form}
                placeholder="e.g https://linkedin.com/in/john-smith-497832..."
                label="LinkedIn profile URL"
              />
            )}
          </Field>
          <Field name="twitter_url">
            {({ field, form }) => (
              <TextInput
                {...field}
                form={form}
                placeholder="e.g https://twitter.com/jsmith3798..."
                label="Twitter profile URL"
              />
            )}
          </Field>
          {errorMsg !== '' &&
            errorMsg.map(error => (
              <div key={error} className={style['error-text']}>
                {error}
              </div>
            ))}
          <div>
            <GenericButton label="Continue" styleType="primary" type="submit" isLoading={isLoading}/>
          </div>
          <ButtonSecondary text="Do this later" onClick={onDoThisLater}/>
        </Form>
      )}
    </Formik>
  );
};
