import React, { useState, useEffect } from 'react';
import { isEqual } from 'deep-equal';
import styles from './styles.module.css';
import receiptIcon from './icons/receipt.svg';

const TicketCard = ({ isFree, price, promotionCode, currency }) => {
  const [ticketPrice, setTicketPrice] = useState('£0.00')

  useEffect(() => {
    switch (currency) {
      case 'USD': {
        setTicketPrice(`$${price.price_in_usd}`)
        break
      }
      case 'EUR': {
        setTicketPrice(`€${price.price_in_eur}`)
        break
      }
      case 'GBP': {
        setTicketPrice(`£${price.price_in_gbp}`)
        break
      }
      default: setTicketPrice('£0.00')
    }
  }, [currency, price.price_in_eur, price.price_in_gbp, price.price_in_usd])

  return (
    <div className={styles['ticket-container']}>
      <div className={styles['ticket-receipt']}>
        <img src={receiptIcon} alt="receipt-icon" />
      </div>
      <div className={styles['ticket-main']}>
        {!!(price && !isFree) ? <span>Conference ticket</span> : <span>Free ticket</span>}
        <span>Includes full access to the virtual event.</span>
        {promotionCode && (
          <div>
            <span>Promotion code applied: {promotionCode}</span>
          </div>
        )}
      </div>
      <div className={styles['ticket-price-container']}>
        {!!(price && !isFree) ? (
          <span className={`${promotionCode ? styles['discount-price'] : styles['ticket-price']}`}>
            {ticketPrice}
          </span>
        ) : (
            <span className={styles['ticket-price']}>£0.00</span>
          )}
        {promotionCode && price && <span className={styles['ticket-price']}>{ticketPrice}</span>}
      </div>
    </div>
  );
};

export default React.memo(TicketCard, isEqual);
