import React, { useCallback, useMemo } from 'react';
import { InputTimePicker, InputLabel, Container, TimePickerError } from './styles';
import { timeIcon } from '../icons';
import moment from 'moment';

export const TimePicker = ({ placeholder, label, value, form, name, onBlur }) => {
  const { errors, touched, setFieldError } = form;
  const setTime = useCallback(date => {
    form.setFieldValue(name, date);
  }, []);

  const showInvalid = { display: errors[name] && touched[name] ? 'block' : 'none' };

  const onFocusInput = useCallback(() => {
    setFieldError(name, '');
  }, [name]);

  const nowDay = useMemo(() => {
    return form.values.date ? form.values.date.getDate() : '';
  }, [form.values.date]);

  const minTime = useMemo(() => {
    return nowDay === new Date().getDate() || !form.values.date
      ? moment().toDate()
      : new Date().setHours(0, 0, 0, 0);
  }, [form.values.date]);

  return (
    <>
      <Container>
        <InputLabel>{label}</InputLabel>
        <InputTimePicker
          id={name}
          onBlur={onBlur}
          selected={(value && new Date(value)) || null}
          onChange={date => setTime(date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          placeholderText={placeholder}
          value={value}
          minTime={minTime}
          maxTime={moment().endOf('day').toDate()}
          onFocus={onFocusInput}
          error={errors[name]}
          touched={touched[name]}
        />
        {timeIcon}
      </Container>
      <TimePickerError style={showInvalid}>{errors[name]}</TimePickerError>
    </>
  );
};
