import React from 'react'
import { isEqual } from 'deep-equal'
import circle from '../../images/circle.png'
import climateAction from '../../images/climateAction.png'
import {
  OrginisedByContainer,
  ContentContainer,
  ContentInfo,
  ContentImage,
  ContentImageContainer,
  ContentImageTitle,
} from './styles'


const OrginisedBy = ({ event }) => {
  const info = event.additional_info2

  return (
    <OrginisedByContainer>
      <ContentContainer>
        <ContentInfo dangerouslySetInnerHTML={{ __html: info }} />
        <ContentImageContainer>
          <ContentImageTitle>
            Organised by
          </ContentImageTitle>
          <ContentImage src={climateAction} />
          <ContentImage src={circle} />
        </ContentImageContainer>
      </ContentContainer>
    </OrginisedByContainer>
  )
}

export default React.memo(OrginisedBy, isEqual)
