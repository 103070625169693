import { eventManagementActions } from '@/constants/actions/eventManagementActions';

//moderator-questions
export const getModeratorQuestions = data => ({
  type: eventManagementActions.GetModeratorQuestions,
  payload: data,
});

export const setModeratorQuestionsData = data => ({
  type: eventManagementActions.SetModeratorQuestions,
  payload: data,
});

export const setEventStatistic = data => ({
  type: eventManagementActions.SetEventStatistic,
  payload: data,
});

export const startModeratorQuestionsDataPolling = data => ({
  type: eventManagementActions.StartModeratorQuestionsDataPolling,
  payload: data,
});

export const stopModeratorQuestionsDataPolling = data => ({
  type: eventManagementActions.StopModeratorQuestionsDataPolling,
  payload: data,
});

export const startEventStatisticPolling = data => ({
  type: eventManagementActions.StartEventStatisticPolling,
  payload: data,
});

export const stopEventStatisticPolling = data => ({
  type: eventManagementActions.StopEventStatisticPolling,
  payload: data,
});

//change approve reject status

export const approveQuestion = (event_pk, id, data) => ({
  type: eventManagementActions.ApproveQuestion,
  payload: { event_pk, id, data },
});

export const rejectQuestion = (event_pk, id, data) => ({
  type: eventManagementActions.RejectQuestion,
  payload: { event_pk, id, data },
});

export const restoreQuestion = (event_pk, id, data) => ({
  type: eventManagementActions.RestoreQuestion,
  payload: { event_pk, id, data },
});

export const rejectedAllQuestion = data => ({
  type: eventManagementActions.RejectAllQuestion,
  payload: data,
});

export const clearAllQuestions = data => ({
  type: eventManagementActions.ClearAllQuestion,
  payload: data,
});

export const clearAllQuestionLoader = data => ({
  type: eventManagementActions.ClearAllQuestionLoader,
  payload: data,
});

export const rejectedAllQuestionLoader = data => ({
  type: eventManagementActions.RejectedAllQuestionLoader,
  payload: data,
});

//stream status

export const getStreamStatus = (event_pk, data) => ({
  type: eventManagementActions.GetStreamStatus,
  payload: { event_pk, data },
});

export const setStreamStatus = data => ({
  type: eventManagementActions.SetStreamStatus,
  payload: data,
});

export const streamStatusLoader = data => ({
  type: eventManagementActions.StreamStatusLoader,
  payload: data,
});

//broadcast message

export const getModeratorMessage = data => ({
  type: eventManagementActions.GetModeratorMessage,
  payload: data,
});

export const setLatestBroadcast = data => ({
  type: eventManagementActions.SetLatestBroadcast,
  payload: data,
});

export const loadingLatestBroadcast = data => ({
  type: eventManagementActions.LoadingLatestBroadcast,
  payload: data,
});

export const postBroadcastMessage = (event_pk, data) => ({
  type: eventManagementActions.PostBroadcastMessage,
  payload: { event_pk, data },
});

export const patchBroadcastMessage = (event_pk, id, data) => ({
  type: eventManagementActions.PatchBroadcastMessage,
  payload: { event_pk, id, data },
});

export const loadingPatchBroadcast = data => ({
  type: eventManagementActions.LoadingPatchBroadcast,
  payload: data,
});

//moderator polls
export const startModeratorPollsDataPolling = data => ({
  type: eventManagementActions.StartModeratorPollsDataPolling,
  payload: data,
});

export const stopModeratorPollsDataPolling = data => ({
  type: eventManagementActions.StopModeratorPollsDataPolling,
  payload: data,
});

export const setModeratorPolls = data => ({
  type: eventManagementActions.SetModeratorPolls,
  payload: data,
});

export const patchModeratorPoll = (session_pk, id, data) => ({
  type: eventManagementActions.PatchModeratorPoll,
  payload: { session_pk, id, data },
});

//session status (agenda)
export const patchSessionStatus = (day_pk, id, data, event_pk) => ({
  type: eventManagementActions.PatchSessionStatus,
  payload: { day_pk, id, data, event_pk },
});
