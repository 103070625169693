import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
`;

export const Body = styled.div`
  height: calc(100% - 80px);
  padding: 15px 30px 0 30px;
  overflow: auto;
  box-sizing: border-box;
`;

export const Message = styled.div`
  margin-bottom: 15px;
  background: ${({ theme, isCurrentlyModerated }) =>
    isCurrentlyModerated ? '#f7f7f7' : theme.colors.backgroundDownloadCard};
  border-radius: 8px;
`;

export const MessageText = styled.div`
  padding: 20px;
  font-size: 15px;
  line-height: 24px;
  box-sizing: 'border-box';
`;

export const MessageAuthor = styled.div`
  height: 70px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  box-sizing: 'border-box';
  border-top: ${({ theme, withBorderTop }) =>
    withBorderTop && `1px solid ${theme.colors.tabBorder}`};
`;

export const CustomInputWrapper = styled.div`
  && input {
    border: none;
    outline: none;
    box-shadow: none;
    font-style: normal;
    margin: 0;
    padding-right: 0;
  }
`;

export const ModerationInfoNotification = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.currentUserMessageBackground};
  background: ${({ theme }) => theme.colors.moderatingNotification};
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
`;
