import axios from 'axios';
import { baseURL } from '@/constants/baseURL';
import { responseHandlerInterceptor, sendTokenInterceptor } from '../helpers/interceptors';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

instance.interceptors.request.use(request => {
  return sendTokenInterceptor(request);
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return responseHandlerInterceptor(error, instance);
  },
);

export const profileAPI = {
  getProfile(event_pk) {
    return instance.get(`/events/event/${event_pk}/delegate/profile/`);
  },
  getDelegateProfile(event_pk, delegateId) {
    return instance.get(`/events/event/${event_pk}/delegate/view/${delegateId}/`);
  },
  editSocialLinks(event_pk, delegateId, linkedinUrl, twitterUrl) {
    return instance.patch(`/events/event/${event_pk}/delegate/${delegateId}/`, {
      linkedin_url: linkedinUrl,
      twitter_url: twitterUrl,
    });
  },
  editBiography(event_pk, delegateId, biography) {
    return instance.patch(`/events/event/${event_pk}/delegate/${delegateId}/`, {
      biography: biography,
    });
  },
  editInterests(event_pk, interests) {
    return instance.post(`/events/event/${event_pk}/delegate/add-interests/`, interests);
  },
  editInInterests(event_pk, interests) {
    return instance.post(`/events/event/${event_pk}/delegate/add-interest-delegate/`, interests);
  },
  editIsMarketingAllowed(event_pk, delegateId, isMarketingAllowed) {
    return instance.patch(`/events/event/${event_pk}/delegate/${delegateId}/`, {
      is_marketing_allowed: isMarketingAllowed,
    });
  },
  editIsAnonymous(event_pk, delegateId, isAnonymous) {
    return instance.patch(`/events/event/${event_pk}/delegate/${delegateId}/`, {
      is_anonymous: isAnonymous,
    });
  },
};
