import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

export const ButtonStyle = withStyles({
  root: {
    borderRadius: '4px',
    padding: '5px 30px',
    width: '100%',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  label: {
    fontFamily: 'Inter',
    fontSize: '15px',
    lineHeight: '30px',
    textTransform: 'capitalize',
  },
})(Button);
