import styled from 'styled-components';

export const PartnerGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  padding: ${({isOnDemandPage}) => isOnDemandPage ? '0 30px' : '0'}
`;

export const PartnersTitle = styled.div`
  width: 100%;
  margin-bottom: ${({ isOnLivestream }) => (isOnLivestream ? '5px' : '30px')};
  display: flex;
  align-items: center;
`;

export const PartnersTitleLineText = styled.div`
  margin: ${({ isOnLivestream }) => (isOnLivestream ? '0 15px 0 0' : '0 15px 0 15px')};
  display: block;
  word-wrap: normal !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #52595e;
`;

export const PartnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ isOnLivestream }) => (isOnLivestream ? '130px' : 'auto')};
  flex-basis: ${({ isOnLivestream }) => (isOnLivestream ? '33.3%' : '20%')};

  &:hover {
    background-color: ${({ theme, isOnLivestream }) =>
      isOnLivestream ? theme.colors.backgroundDownloadCard : theme.colors.originWhite};
    border-radius: 4px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    flex-basis: ${({ isOnLivestream }) => (isOnLivestream ? '50%' : '20%')};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    flex-basis: ${({ isOnLivestream }) => (isOnLivestream ? '100%' : '20%')};
  }
`;

export const PartnersTitleLine = styled.div`
  flex: 1;
  border: 1px solid #dbe2eb;
`;

export const PartnersList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ isOnLivestream }) => (isOnLivestream ? 'flex-start' : 'center')};
  margin-right: ${({ isOnLivestream }) => (isOnLivestream ? '-15px' : '0')};
  margin-left: ${({ isOnLivestream }) => (isOnLivestream ? '-10px' : '0')};
`;
