import styled from 'styled-components';

export const PollContainer = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.originWhite};
  position: absolute;
  left: 0;
  top: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    position: fixed;
    width: 100%;
    height: calc(100% - 88px);
    top: 0;
    left: 0;
    z-index: 1;
  }
`;

export const PollWrapper = styled.div`
  margin: 20px;
`;

export const PollTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  margin-bottom: 5px;
  margin-left: 10px;
`;

export const PollSection = styled.div`
  h3 { 
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 150%;
    margin-bottom: 22px;
  }
  button {
    color: ${({ theme }) => theme.colors.primaryGreen} !important;
    border: 1px solid ${({ theme }) => theme.colors.primaryGreen} !important; 
    font-size: 15px !important;
    line-height: 30px !important;
    word-break: break-all;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
  }
  div {
    color: ${({ theme }) => theme.colors.primaryGreen} !important;
    border-color: ${({ theme }) => theme.colors.primaryGreen} !important;
    font-weight: normal;
    display: flex;
    width: 95%;
    
    div:first-of-type {      
      background-color: #DBE2EB !important;
    }
    span {
        font-size: 15px;
    }
  }
  p {
    display: none;
  }
`;

export const CloseButton = styled.div`
  margin: 0 15px;
`;