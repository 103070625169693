import styled from 'styled-components'

export const FeaturesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    margin-top: -50px;
    padding: 150px 20px 0px 20px;
  }
`

export const Title = styled.div`
  width: 100%;
  color: #17191A;
  font-weight: bold;
  font-style: normal;
  font-size: 45px;
  line-height: 54px;
`

export const OneFeature = styled.div`
  margin-top:50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    margin-top: 35px;
    flex-direction: ${({ reverse }) => reverse ? `column` : `column-reverse`};
    justify-content: center;
  }
`

export const OneFeatureInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const OneFeatureTitle = styled.div`
  font-weight: bold;
  font-size: 35px;
  line-height: 42px;
  display: flex;
  align-items: center;
  color: #17191A;
`

export const OneFeatureText = styled.div`
  margin-top:50px;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #52595E;
`

export const OneFeatureImage = styled.img`
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
    height: auto;
  }
`
