import React, { useState } from 'react';
import { Container, DialogInput, Actions, SendOutlinedIcon, SendButtonWrapper } from './styles';

const ChatInput = ({ handleSendMessage, disabledCheck, ...props }) => {
  const [inputValue, setInputValue] = useState('');

  const handleOnPressInput = e => {
    if (e.keyCode === 13 || e.key === 'Enter') {
      onSendMessage();
    }
  };

  const handleChangeInput = value => {
    setInputValue(value);
  };

  const onSendMessage = () => {
    if (inputValue) {
      handleSendMessage(inputValue);
      setInputValue('');
    }
  };

  return (
    <Container>
      <DialogInput
        size="large"
        placeholder="Write your message..."
        bordered={false}
        value={inputValue}
        onChange={e => handleChangeInput(e.target.value)}
        onKeyDown={e => handleOnPressInput(e)}
      />
      <Actions>
        <SendButtonWrapper
          disabled={disabledCheck && disabledCheck(inputValue)}
          onClick={onSendMessage}
        >
          <SendOutlinedIcon />
        </SendButtonWrapper>
      </Actions>
    </Container>
  );
};

export default ChatInput;
