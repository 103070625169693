import styled from 'styled-components';

export const OnePartnerContainer = styled.div`
  display: ${({ isOnLivestream }) => (isOnLivestream ? 'flex' : 'flex')};
  flex-direction: ${({ isOnLivestream }) => (isOnLivestream ? 'row' : 'column')};
  align-items: center;
  padding: ${({ isOnLivestream }) => (isOnLivestream ? '0 15px 0 10px' : '0 30px 0 0')};
  height: 100%;
  width: 100%;
`;

export const OnePartnerLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isOnLivestream }) => (isOnLivestream ? '100%' : '165px')};
  background-color: ${({ theme }) => theme.colors.originWhite};
  max-width: ${({ isOnLivestream }) => (isOnLivestream ? '100px' : '100%')};
  height: ${({ isOnLivestream }) => (isOnLivestream ? '100px' : '165px')};
  border: 1px solid #cad4dc;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const OnePartnerInfo = styled.div`
  display: flex;
  flex-direction: ${({ isOnLivestream }) => (isOnLivestream ? 'column' : 'row')};
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-left: ${({ isOnLivestream }) => (isOnLivestream ? '20px' : '0')};
  margin-top: ${({ isOnLivestream }) => (isOnLivestream ? '0' : '15px')};
  word-break: break-all;
`;

export const OnePartnerTitle = styled.div`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  text-align: ${({ isOnLivestream }) => (isOnLivestream ? 'left' : 'center')};
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: ${({ isOnLivestream }) => (isOnLivestream ? '5px' : '0')};
`;

export const OnePartnerDescription = styled.div`
  display: -webkit-box;
  overflow: hidden;
  font-size: 15px;
  line-height: 20px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
`;

export const OnePartnerLogo = styled.img`
  max-width: 100%;
  height: ${({ isOnLivestream }) => (isOnLivestream ? '40px' : '65px')};
`;
