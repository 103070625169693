import React from 'react';
import { isEqual } from 'deep-equal';

import { Header, Text } from './styles';

const InformationCard = ({ header, content }) => {
  return (
    <div>
      <Header>{header}</Header>
      <Text>{content}</Text>
    </div>
  );
};

export default React.memo(InformationCard, isEqual);
