import { livestreamActions } from '@/constants/actions/livestreamActions';

export const setEventPartners = data => ({
  type: livestreamActions.SetEventPartners,
  payload: data,
});

export const isFetchingLivestream = data => ({
  type: livestreamActions.IsFetchingLivestream,
  payload: data,
});

//saga actions
export const getEventPartners = data => ({
  type: livestreamActions.GetEventPartners,
  payload: data,
});

