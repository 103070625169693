import { interactionPanelActions } from '../../constants/actions/interactionPanelActions';

const initialState = {
  currentPanelTab: 'messages', // messages | agenda | qa
  dialogs: [],
  currentChatData: {},
  favoriteDelegates: [],
  favoriteSessions: [],
  favoriteExhibitors: [],
  questions: [],
  isDialogOpen: false,
  currentDialogId: '',
  currentDelegate: {},
  areDialogsLoading: true,
  areMessagesLoading: true,
  areBookmarksLoading: true,
  areQuestionsLoading: true,
  currentChat: {},
  sendingMessages: {},
  sendingQuestions: [],
  invitations: [],
  userNonActiveQuestions: [],
  processedInvitationIds: [],
  isChatBeingCreated: false,
  isBlockPuttingChatData: false,
};

export const interactionPanel = (state = initialState, { type, payload }) => {
  switch (type) {
    case interactionPanelActions.SetCurrentPanelTab: {
      return {
        ...state,
        currentPanelTab: payload,
      };
    }
    case interactionPanelActions.SetDialogs:
      return {
        ...state,
        dialogs: payload,
      };
    case interactionPanelActions.SetAreDialogsLoading:
      return {
        ...state,
        areDialogsLoading: payload,
      };
    case interactionPanelActions.SetIsDialogOpen:
      return {
        ...state,
        isDialogOpen: payload,
      };
    case interactionPanelActions.SetCurrentChatData:
      return {
        ...state,
        currentChatData: payload,
      };
    case interactionPanelActions.SetAreMessagesLoading:
      return {
        ...state,
        areMessagesLoading: payload,
      };
    case interactionPanelActions.SetSendingMessage: {
      return {
        ...state,
        sendingMessages: {
          ...state.sendingMessages,
          [payload.recipient]: state.sendingMessages[payload.recipient]
            ? [...state.sendingMessages[payload.recipient], payload]
            : [payload],
        },
      };
    }
    case interactionPanelActions.RemoveSendingMessage: {
      return {
        ...state,
        sendingMessages: {
          ...state.sendingMessages,
          [payload.recipient]: state.sendingMessages[payload.recipient].filter(
            item => item.key !== payload.key,
          ),
        },
      };
    }
    case interactionPanelActions.PutFavoriteDelegates: {
      return {
        ...state,
        favoriteDelegates: payload,
      };
    }
    case interactionPanelActions.AddFavoriteDelegate: {
      return {
        ...state,
        favoriteDelegates: [ payload, ...state.favoriteDelegates],
      };
    }
    case interactionPanelActions.RemoveFavoriteDelegate: {
      return {
        ...state,
        favoriteDelegates: [...state.favoriteDelegates.filter(delegate => `${delegate.id}` !== `${payload.id}`)],
      };
    }
    case interactionPanelActions.SetCurrentDelegate: {
      return {
        ...state,
        currentDelegate: payload,
      };
    }
    case interactionPanelActions.PutFavoriteSessions: {
      return {
        ...state,
        favoriteSessions: payload,
      };
    }
    case interactionPanelActions.PutFavoriteExhibitors: {
      return {
        ...state,
        favoriteExhibitors: payload,
      };
    }
    case interactionPanelActions.SetAreBookmarksLoading:
      return {
        ...state,
        areBookmarksLoading: payload,
      };
    case interactionPanelActions.PutCurrentChat: {
      return {
        ...state,
        currentChat: payload,
      };
    }
    case interactionPanelActions.PutMessageToCurrentReceivedList: {
      return {
        ...state,
        currentChatData: {
          ...state.currentChatData,
          messages_set:
            state.currentChatData.messages_set && state.currentChatData.messages_set.length
              ? [...state.currentChatData.messages_set, payload]
              : [payload],
        },
      };
    }
    case interactionPanelActions.PutAllQuestions: {
      return {
        ...state,
        questions: payload,
      };
    }
    case interactionPanelActions.PutAreQuestionsLoading: {
      return {
        ...state,
        areQuestionsLoading: payload,
      };
    }
    case interactionPanelActions.SetSendingQuestion: {
      return {
        ...state,
        sendingQuestions: [...state.sendingQuestions, payload],
      };
    }
    case interactionPanelActions.RemoveSendingQuestion: {
      return {
        ...state,
        sendingQuestions: [...state.sendingQuestions.filter(item => item !== payload)],
      };
    }
    case interactionPanelActions.PutQuestionToCurrentReceivedList: {
      return {
        ...state,
        userNonActiveQuestions: [...state.userNonActiveQuestions, payload],
      };
    }
    case interactionPanelActions.PutUserNonActiveQuestions: {
      return {
        ...state,
        userNonActiveQuestions: payload,
      };
    }
    case interactionPanelActions.PutProcessedInvitationId: {
      return {
        ...state,
        processedInvitationIds: [...state.processedInvitationIds, payload],
      };
    }
    case interactionPanelActions.RemoveProcessedInvitationId: {
      return {
        ...state,
        processedInvitationIds: state.processedInvitationIds.filter(id => id !== payload),
      };
    }
    case interactionPanelActions.PutIsChatBeingCreated: {
      return {
        ...state,
        isChatBeingCreated: payload,
      };
    }
    case interactionPanelActions.PutBlockPuttingChatData: {
      return {
        ...state,
        isBlockPuttingChatData: payload,
      };
    }
    default:
      return state;
  }
};
