import { call, put, takeEvery } from 'redux-saga/effects';
import { authAPI } from '@/api/authAPI';
import { onPutResetPasswordEmail } from './actions';
import { SignInActions } from '@/constants/actions/signInActions';
import {
  setResetPasswordError,
  setResetPasswordSpinner,
  setIsLoadingChangePassword,
  setIsSuccessChangePassword,
} from './actions';
import { localStorageServices } from '@/helpers';

function* resetPassword({ payload: { email, event } }) {
  try {
    yield put(setResetPasswordSpinner(true));
    const response = yield call(authAPI.resetPassword, {
      email,
      event,
    });
    yield put(onPutResetPasswordEmail(response.data));
    yield put(setResetPasswordSpinner(false));
    yield put(setResetPasswordError(''));
  } catch (error) {
    yield put(setResetPasswordError(error.response.data.detail));
    yield put(setResetPasswordSpinner(false));
  }
}

function* resetConfirm(action) {
  try {
    const response = yield call(authAPI.resetConfirm, action.payload);
    localStorageServices.setTokens(response.data);
  } catch (error) {}
}

function* changePassword({ payload: { password } }) {
  try {
    yield put(setIsLoadingChangePassword(true));
    yield call(authAPI.changePassword, {
      password,
    });
    yield put(setIsSuccessChangePassword(true));
    yield put(setResetPasswordError(''));
  } catch (error) {
    yield put(setResetPasswordError(error.response.data.detail));
  } finally {
    yield put(setIsLoadingChangePassword(false));
  }
}

export function* watchResetPassword() {
  yield takeEvery(SignInActions.ResetPassword, resetPassword);
  yield takeEvery(SignInActions.ChangePassword, changePassword);
  yield takeEvery(SignInActions.ResetConfirm, resetConfirm);
}
