import styled from 'styled-components';

export const SummaryApplication = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormSection = styled.section`
  margin-bottom: 15px;
`;

export const FieldName = styled.div`
  color: ${({ theme }) => theme.colors.originGrey};
  margin-bottom: 10px;
`;

export const Header = styled.div`
  margin-bottom: 15px;
`;

export const Title = styled.div`
  padding: 10px 0;
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
`;

export const TitleLink = styled.a`
  font-size: 15px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.primaryGreen};
  text-decoration: underline;
  cursor: pointer;
`;

export const Fields = styled.div`
  display: grid;
  grid-template-columns: ${({ cols }) =>
    (cols === 2 && '50% 50%') || (cols === 3 && '33% 33% 33%')};
`;

export const CustomRow = styled.div`
  margin-top: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
`;

export const TicketTypeDescription = styled.div`
  padding: 13px 0;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
`;

export const MarginTopWrapper = styled.div`
  margin-top: 30px;
`;
