import React, { useCallback, useState } from 'react';
import { isEqual } from 'deep-equal';
import { Formik, Field, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import DocumentCard from './components/DocumentCard';
import TextInput from '@/components/controls/TextInput';
import GenericButton from '@/components/controls/buttons/GenericButton';
import {
  addResources,
  getErrDescriptionMsgInfo,
  setErrorDescriptionMsg,
} from '@/store/exhibitorPage';
import { upLoadResourcesSchema } from '@/components/controls/validationSchemas/upLoadResourcesSchema';
import {
  ExhibitorResource,
  ExhibitorResources,
  ExhibitorResourcesContainer,
  ExhibitorResourcesLabel,
  Label,
  FormWrapper,
  ErrorMsg,
} from './styles';

const ExhibitorResourcesSection = ({ documents, exhibitor, id, isVisible }) => {
  const dispatch = useDispatch();
  const errDescriptionMsg = useSelector(getErrDescriptionMsgInfo);

  const onSubmit = useCallback(
    ({ file, fileName }) => {
      dispatch(addResources(id, exhibitor.id, { path: file, document_name: fileName }));
    },
    [exhibitor.id, id],
  );

  const [showUploader, setShowUploader] = useState(false);
  const showUploaderHandler = useCallback(() => {
    setShowUploader(prev => !prev);
    dispatch(setErrorDescriptionMsg(''));
  }, []);

  return (
    <ExhibitorResourcesContainer>
      <ExhibitorResourcesLabel>
        <Label active={documents.length < 10}>
          <div>Resources</div>
          {isVisible && (
            <div onClick={showUploaderHandler}>
              {documents.length < 10 ? 'Add file' : 'Files limit reached'}
            </div>
          )}
        </Label>
        {showUploader && (
          <FormWrapper>
            <Formik
              onSubmit={onSubmit}
              initialValues={{
                file: '',
                fileName: '',
              }}
              validationSchema={upLoadResourcesSchema}
              validateOnChange={false}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Field name="file">
                    {({ form, field }) => (
                      <TextInput
                        name={field.name}
                        onBlur={field.onBlur}
                        form={form}
                        onChange={event => {
                          setFieldValue('file', event.currentTarget.files[0]);
                        }}
                        type="file"
                      />
                    )}
                  </Field>
                  <Field name="fileName">
                    {({ field, form }) => (
                      <TextInput
                        {...field}
                        form={form}
                        placeholder="Please enter file name"
                        type="text"
                      />
                    )}
                  </Field>
                  {errDescriptionMsg !== '' && <ErrorMsg>{errDescriptionMsg}</ErrorMsg>}
                  <GenericButton label="Submit" styleType="primary" type="submit" />
                  <GenericButton label="Cancel" styleType="outline" onClick={showUploaderHandler} />
                </Form>
              )}
            </Formik>
          </FormWrapper>
        )}
      </ExhibitorResourcesLabel>
      <ExhibitorResources>
        {documents.map((d, i) => (
          <ExhibitorResource key={i}>
            <DocumentCard
              id={d.id}
              event_pk={id}
              exhib_pk={exhibitor.id}
              fileName={d.document_name}
              filePath={d.path.replace(/^.*[\\\/]/, '')}
              isVisible={isVisible}
            />
          </ExhibitorResource>
        ))}
      </ExhibitorResources>
    </ExhibitorResourcesContainer>
  );
};

export default React.memo(ExhibitorResourcesSection, isEqual);
