import React, { useMemo } from 'react';
import { isEqual } from 'deep-equal';
import styles from './styles.module.css';
import HelpText from '@/components/controls/HelpText';

const TextInput = ({
  name,
  label,
  onChange,
  value,
  placeholder,
  helperText,
  disabled,
  onBlur,
  form,
  type,
}) => {
  const { errors, touched, setFieldError } = form;
  const helper = useMemo(() => (helperText ? <HelpText>{helperText}</HelpText> : null), [
    helperText,
  ]);

  const showInvalid = { display: errors[name] && touched[name] ? 'block' : 'none' };

  return (
    <div className={styles['text-input-container']}>
      <label className={styles['input-label']}>{label}</label>
      <div className={styles['input-with-icon-container']}>
        <input
          id={name}
          onBlur={onBlur}
          type={type || 'text'}
          placeholder={placeholder}
          className={`${styles['text-input']} ${
            errors[name] && touched[name] ? styles['text-input_error'] : ''
          }`}
          onChange={onChange}
          value={value}
          disabled={disabled}
          onFocus={() => setFieldError(name, '')}
        ></input>
      </div>
      <small className={styles['error-text']} style={showInvalid}>
        {errors[name]}
      </small>
      {helper}
    </div>
  );
};

export default React.memo(TextInput, isEqual);
