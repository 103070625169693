import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { isEqual } from 'deep-equal';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { Tooltip } from 'antd';
import { HomeIcon } from './icons/HomeIcon';
import { ForumIcon } from './icons/ForumIcon';
import { EventNoteIcon } from './icons/EvenNoteIcon';
import { UserCircleIcon } from './icons/UserCircleIcon';
import { ViewQuiltIcon } from './icons/ViewQuiltIcon';
import { DashboardIcon } from './icons/DashboardIcon';
import { MyProfileIcon } from './icons/MyProfileIcon';
import { EventManagementIcon } from './icons/EventManagementIcon';
import { useWindowSize } from '@/helpers/hooks';
import {
  generatePlatformUrl,
  generateAgendaUrl,
  generateNetworkingUrl,
  generateExpoUrl,
  generateDashboardUrl,
  generateMyProfiledUrl,
  generateEventManagementUrl,
} from '@/constants/routes';
import { NavMenuItem, NavMenuSection, CustomLink, NavMenuButton } from './styles';
import { useSelector } from 'react-redux';
import { getPermissionsInfo } from '@/store/platformInfo';

const TooltipHOC = ({children, tooltipTitle, isTooltipNeeded}) => {
  return isTooltipNeeded 
    ? <Tooltip placement="right" title={tooltipTitle} mouseEnterDelay={1} >
      {children}
    </Tooltip>
    : <>
      {children}
    </>
}

const NavMenuLink = ({ children, to, activeOnlyWhenExact = true, name }) => {
  const RESIZE_DELAY = 10;
  const { breakpoints } = useContext(ThemeContext);
  const [windowWidth, _] = useWindowSize(RESIZE_DELAY);
  const isTabletOrSmaller = windowWidth <= parseInt(breakpoints.minWidth.landscape.tablet.replace(/\D/g, ''));

  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  
  return (
    <CustomLink match={match}>
      <TooltipHOC isTooltipNeeded={!isTabletOrSmaller} tooltipTitle={name}>
        <Link
          to={to}
          style={
            {
              position: 'relative',
              display: 'flex',
              justifyContent: 'space-around',
              justifyItems: 'center',
              width: '100%',
              height: '100%',

            }
          }
        >
          {children}
        </Link>
      </TooltipHOC>
    </CustomLink>
  );
};

const NavMenu = ({ isHorizontal, isInteractionPanelOpen, setIsInteractionPanelOpen }) => {
  const { id } = useParams();
  const platformHomeRoute = generatePlatformUrl(id);
  const agendaRoute = generateAgendaUrl(id);
  const networkingRoute = generateNetworkingUrl(id);
  const exhibitorRoute = generateExpoUrl(id);
  const dashboardRoute = generateDashboardUrl(id);
  const myProfileRoute = generateMyProfiledUrl(id);
  const eventManagementRoute = generateEventManagementUrl(id);
  
  const RESIZE_DELAY = 10;
  const { breakpoints } = useContext(ThemeContext);
  const [windowWidth, _] = useWindowSize(RESIZE_DELAY);

  const { is_assoc_with_exhib: isAssocWithExhib, is_admin: isAdmin } = useSelector(getPermissionsInfo);
  
  const handleSetIsInteractionPanelOpen = bool => {
    setIsInteractionPanelOpen(bool);
  };


  return (
    <NavMenuSection isHorizontal={isHorizontal}>
      <NavMenuItem marginTop={50} isHorizontal={isHorizontal}>
        <NavMenuLink to={platformHomeRoute} name="Home">
          <HomeIcon />
        </NavMenuLink>
      </NavMenuItem>
      <NavMenuItem isHorizontal={isHorizontal}>
        <NavMenuLink to={agendaRoute} name="Agenda">
          <EventNoteIcon />
        </NavMenuLink>
      </NavMenuItem>
      <NavMenuItem isHorizontal={isHorizontal}>
        <NavMenuLink to={networkingRoute} name="Networking">
          <UserCircleIcon />
        </NavMenuLink>
      </NavMenuItem>
      <NavMenuItem isHorizontal={isHorizontal}>
        <NavMenuLink to={exhibitorRoute} name="Expo">
          <ViewQuiltIcon />
        </NavMenuLink>
      </NavMenuItem>
      {isAssocWithExhib && (
        <NavMenuItem isHorizontal={isHorizontal}>
          <NavMenuLink to={dashboardRoute} name="Dashboard">
            <DashboardIcon />
          </NavMenuLink>
        </NavMenuItem>
      )}
      <NavMenuItem isHorizontal={isHorizontal}>
        <NavMenuLink to={myProfileRoute} name="My Profile">
          <MyProfileIcon />
        </NavMenuLink>
      </NavMenuItem>
      {isAdmin && <NavMenuItem isHorizontal={isHorizontal}>
        <NavMenuLink to={eventManagementRoute} name="Event Management">
          <EventManagementIcon />
        </NavMenuLink>
      </NavMenuItem>}
      {windowWidth <= parseInt(breakpoints.minWidth.portrait.tabletV.replace(/\D/g, '')) && (
        <NavMenuItem
          isHorizontal={isHorizontal}
          onClick={() => handleSetIsInteractionPanelOpen(!isInteractionPanelOpen)}
        >
          <NavMenuButton isActive={isInteractionPanelOpen}>
            <ForumIcon />
          </NavMenuButton>
        </NavMenuItem>
      )}
    </NavMenuSection>
  );
};

export default React.memo(NavMenu, isEqual);
