import { RegistrationFieldsValues } from '@/constants/actions/registrationFieldsValues'

const initialState = ''

export const registrationFieldValues = (state = initialState, action) => {
  switch (action.type) {
    case RegistrationFieldsValues.SetRegistrationFieldsValues:
      return action.payload
    default:
      return state;
  }
};
