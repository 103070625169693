import styled from 'styled-components'

export const OrginisedByContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const Title = styled.div`
`

export const ContentContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    flex-direction: column;
  }
`

export const ContentInfo = styled.div`
  display: block;
  width: 750px;
  h1, h2, h3, h4, h5, h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 54px;
    color: #17191A;
  }
  
  p {
    margin-top: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 150%;
    color: #373A3C;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
  }
`

export const ContentImageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    margin-top: 20px;
  }
`

export const ContentImageTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #52595E;
`

export const ContentImage = styled.img`
  margin-top: 10px;
`
