import styled from 'styled-components';

export const ModalContainer = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: 'border-box';
  border: 10px solid transparent;
`;

export const CropContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%; 
  
  .reactEasyCrop_Container {
    bottom: 70px;
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  bottom: 65px;
`;

export const SubContainer = styled.div`
  position: relative;
  margin-right: 100px;
  width: 100%;
  height: 100%;
  box-sizing: 'border-box';
`;

export const Avatar = styled.img`
  width: 87px;
  height: 87px;
  border-radius: 50px;
`;

export const ErrorMessage = styled.small`
  /* styles from formik error message */
  margin-top: 10px;
  font-size: 13px;
  line-height: 16px;
  color: #df3541;
`;