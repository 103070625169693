import { call, delay, put, race, take, takeEvery, select } from 'redux-saga/effects';
import { platformInfoActions } from '@/constants/actions/platformInfoActions';
import { platformInfoAPI } from '@/api/platformInfoAPI';
import { setDelegateInfo, setPermissions, setIsFetchingData, setEventInfo } from './actions';
import { PING_IS_USER_ONLINE_DELAY } from '@/constants/longPollingDelay';
import { setLiveSession } from '../sessions';
import { sessionsAPI } from '@/api/sessionsAPI';
import { awsAPI } from '@/api/awsAPI';
import { getDelegateInfo, getLocationPathname } from './selectors';

function* clearPlatformInfo(action) {
  try {
    yield put(setDelegateInfo({}));
    yield put(setPermissions({}));
    yield put(setEventInfo({}));
  } catch (error) {}
}

function* getDelegate(action) {
  try {
    const response = yield call(platformInfoAPI.getDelegateInfo, action.payload);
    yield put(setDelegateInfo(response.data));
  } catch (error) {}
}

function* getEvent(action) {
  try {
    const response = yield call(platformInfoAPI.getEventInfo, action.payload);
    yield put(
      setEventInfo({
        logo: response.data.logo,
        preEventVideo: response.data.pre_event_video,
        streamingLink: response.data.streaming_link,
        name: response.data.name,
      }),
    );
  } catch (error) {}
}

function* getPermissions(action) {
  try {
    const response = yield call(platformInfoAPI.getPermissions, action.payload);
    yield put(setPermissions(response.data));
  } catch (error) {}
}

function* getLiveSession(action) {
  try {
    const response = yield call(sessionsAPI.getLiveSession, action.payload);
    yield put(setLiveSession(response.data));
  } catch (error) {}
}

function* getPlatformInfo(action) {
  try {
    yield put(setIsFetchingData(true));
    yield call(() => clearPlatformInfo());
    yield call(() => getDelegate(action));
    yield call(() => getEvent(action));
    yield call(() => getPermissions(action));
    yield call(() => getLiveSession(action));
  } catch (error) {
  } finally {
    yield put(setIsFetchingData(false));
  }
}

function* pingIsUserOnline({ payload: { eventId, sessionId } }) {
  while (true) {
    try {
      const delegateInfo = yield select(getDelegateInfo);
      const locationPathname = yield select(getLocationPathname);
      if (Object.keys(delegateInfo).length > 0 && locationPathname) {
        yield call(awsAPI.pingIsUserOnline, eventId, delegateInfo.id, locationPathname, sessionId);
        yield delay(PING_IS_USER_ONLINE_DELAY);
      } else {
        yield delay(1000);
      }
    } catch (error) {
    }
  }
}

export function* watchPingIsUserOnline() {
  while (true) {
    const payload = yield take(platformInfoActions.StartIsUserOnlinePolling);
    yield race([
      call(pingIsUserOnline, payload),
      take(platformInfoActions.StopIsUserOnlinePolling),
    ]);
  }
}

export function* watchPlatformInfo() {
  yield takeEvery(platformInfoActions.GetPlatformInfo, getPlatformInfo);
}
