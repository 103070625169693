import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    originBlack: '#000000',
    originWhite: '#ffffff',
    originGrey: '#808080',
    regularText: '#17191A',
    textDescriptionGrey: '#52595E',
    textDescriptionDarkGrey: '#373A3C',
    fieldsSummaryCardTitleGrey: '#808B94',
    primaryGreen: '#00967F',
    backgroundDownloadCard: '#F2F4FA',
    tabBorder: '#DBE2EB',
    logoBorder: '#CAD4DC',
    transparent: 'transparent',
    currentUserMessageBackground: '#257BDE',
    sendMessageIcon: '#DADADA',
    success: '#00806C',
    alert: '#DF3541',
    successBackground: '#D4F7E1',
    alertBackground: '#FBEEEF',
    greenHaze: '#009789',
    mandy: '#DD4852',
    blue: '#388DED',
    blueChill: '#0E76A8',
    boulder: '#7c7c7c',
    sanMarino: '#3C64B1',
    moderatingNotification: '#E9F2FC',
    disabledStuff: '#CAD4DC',
    statusOnline:'#2AC262',
    cards: {
      border: '#DBE2EB',
      buttonBackground: '#F2F4FA',
    },
    managerAgenda: {
      whiteLilac: '#F2F4FA',
      osloGray: '#808b94',
      cinnabar: '#DF3541',
      observatory: '#00967F',
      scorpion: '#5E5E5E',
      persianGreen: '#00B397',
    },
    agenda: {
      liveBorder: '#17191A',
      liveBackground: '#17191A',
      arrowFocusBackground: '#b7bdc4',
      arrowHoverBackground: '#b7bdc4',
      onDemandLabel: '#52595E',
      nowLiveLabel: '#F2F4FA',
      border: '#dbe2eb',
      dayDelimiter: {
        date: '#808B94',
      },
      dayDelimiterLanding: {
        name: '#00806C',
        topic: '#52595E',
      },
      scrollSpy: {
        font: '#808B94',
        activeFont: '#17191A',
        delimiter: '#CAD4DC',
      },
      break: {
        background: '#F2F4FA',
        timeLabel: '#808B94',
        title: '#52595E',
      },
      regular: {
        background: '#FFFFFF',
        timeLabel: '#808B94',
        iconOutline: '#6B7178',
        title: '#17191A',
        description: '#52595E',
        speakerRole: '#52595E',
        speakerName: '#17191A',
        speakerDetails: '#808B94',
      },
      live: {
        background: '#17191A',
        timeLabel: '#CAD4DC',
        iconOutline: '#6B7178',
        title: '#FFFFFF',
        description: '#CAD4DC',
        speakerRole: '#DBE2EB',
        speakerName: '#FFFFFF',
        speakerDetails: '#808B94',
      },
    },
  },
  buttons: {
    green: {
      primary: {
        backgroundColor: '#00967F',
        backgroundColorHover: '#00806C',
        backgroundColorActive: '#00B397',
        border: 'none',
        borderHover: 'none',
        borderActive: 'none',
        textColor: '#FFFFFF',
        textColorHover: '#FFFFFF',
        textColorActive: '#FFFFFF',
        shadowFocus: 'none',
        shadowActive: 'none',
      },
      outline: {
        backgroundColor: '#FFFFFF',
        backgroundColorHover: '#FFFFFF',
        backgroundColorActive: '#FFFFFF',
        border: '1px solid #00B397',
        borderHover: '1px solid #00967F',
        borderActive: '1px solid #00B397',
        textColor: '#00967F',
        textColorHover: '#00806C',
        textColorActive: '#00B397',
        shadowFocus: '0px 0px 0px 3px rgba(0, 179, 151, 0.2)',
        shadowActive: '0px 0px 0px 3px rgba(0, 179, 151, 0.2)',
      },
    },
    red: {
      primary: {
        backgroundColor: '#DD4852',
        backgroundColorHover: '#b63c44',
        backgroundColorActive: '#ff5661',
        border: 'none',
        borderHover: 'none',
        borderActive: 'none',
        textColor: '#FFFFFF',
        textColorHover: '#FFFFFF',
        textColorActive: '#FFFFFF',
        shadowFocus: 'none',
        shadowActive: 'none',
      },
      outline: {
        backgroundColor: '#FFFFFF',
        backgroundColorHover: '#FFFFFF',
        backgroundColorActive: '#FFFFFF',
        border: '1px solid #ff5661',
        borderHover: '1px solid #DD4852',
        borderActive: '1px solid #ff5661',
        textColor: '#DD4852',
        textColorHover: '#b63c44',
        textColorActive: '#ff5661',
        shadowFocus: '0px 0px 0px 3px rgba(179, 0, 0, 0.2)',
        shadowActive: '0px 0px 0px 3px rgba(179, 0, 0, 0.2)',
      },
    },
    grey: {
      primary: {
        backgroundColor: '#C4C4C4',
        backgroundColorHover: '#9b9b9b',
        backgroundColorActive: '#dfdddd',
        border: 'none',
        borderHover: 'none',
        borderActive: 'none',
        textColor: '#FFFFFF',
        textColorHover: '#FFFFFF',
        textColorActive: '#FFFFFF',
        shadowFocus: 'none',
        shadowActive: 'none',
      },
      outline: {
        backgroundColor: '#FFFFFF',
        backgroundColorHover: '#FFFFFF',
        backgroundColorActive: '#FFFFFF',
        border: '1px solid #dfdddd',
        borderHover: '1px solid #C4C4C4',
        borderActive: '1px solid #dfdddd',
        textColor: '#C4C4C4',
        textColorHover: '#9b9b9b',
        textColorActive: '#dfdddd',
        shadowFocus: '0px 0px 0px 3px rgba(125, 128, 127, 0.2)',
        shadowActive: '0px 0px 0px 3px rgba(125, 128, 127, 0.2)',
      },
    },
  },
  boxShadows: {
    message: '0px 5px 5px rgba(54, 116, 255, 0.196733)',
  },
  fontSizes: {
    regular: '1rem',
  },
  breakpoints: {
    minWidth: {
      portrait: {
        tablet: '501px',
        tabletV: '768px',
      },
      landscape: {
        tablet: '1024px',
        desktopS: '1366px',
        desktopL: '1920px',
      },
    },
    maxWidth: {
      portrait: {
        phone: '500px',
        tablet: '1023px',
      },
      landscape: {
        phone: '1023px',
        tablet: '1365px',
        desktopS: '1919px',
      },
    },
  },
};

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default Theme;
