import styled from 'styled-components';

export const NavMenuSection = styled.ul`
  display: inline-flex;
  flex-direction: ${({ isHorizontal }) => (isHorizontal ? 'row' : 'column')};
  justify-content: ${({ isHorizontal }) => isHorizontal && 'center'};
  padding: ${({ isHorizontal }) => (!isHorizontal && '0 30px')};
  width: ${({ isHorizontal }) => !isHorizontal && '87px'};
  border-right: ${({ isHorizontal }) => !isHorizontal && '1px solid #dbe2eb'};
  /* border-bottom: ${({ isHorizontal }) => isHorizontal && '1px solid #dbe2eb'}; */
  height: ${({ isHorizontal }) => !isHorizontal && '100%'};
  background: ${({ theme }) => theme.colors.originWhite};
  z-index: 1; 
`;

export const NavMenuItem = styled.li`
  display: inline-block;
  margin-top: ${({ marginTop, isHorizontal }) => marginTop && !isHorizontal && `${marginTop}px`};
  margin-bottom: ${({ isHorizontal }) => !isHorizontal && '50px'};
  margin-right: ${({ isHorizontal }) => isHorizontal && '25px'};
  margin-left: ${({ isHorizontal }) => isHorizontal && '25px'};
  width: ${({ isHorizontal }) => isHorizontal ? '32px' : '100%'};
  height: ${({ isHorizontal }) => isHorizontal ? '100%' : '32px'};
`;

export const IconWrapper = styled.div`
  position: relative; 
  height: 0; 
  width: 100%; 
  padding: 0;
  padding-bottom: 100%; 
`;

export const Icon = styled.svg`
  position: absolute; 
  height: 100%; 
  width: 100%; 
  left: 0; 
  top: 0;
  fill: #808b94;
`;

export const CustomLink = styled.div`
  width: 100%;
  height: 100%;

  & svg {
    transition: fill .2s ease;
    fill: ${({ theme, match }) => match && theme.colors.managerAgenda.observatory};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${Icon} {
        fill: #4e7082;
      }
    }
  }
`;

export const NavMenuButton = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;

  & svg {
    transition: fill .2s ease;
    fill: ${({ theme, isActive }) => isActive && theme.colors.managerAgenda.observatory};
  }

  &:hover {
    ${Icon} {
      fill: #4e7082;
    }
  }
`;
