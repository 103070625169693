import React, { useState, useCallback, useMemo } from 'react';
import avatar from './icons/upload-image.svg';
import Modal from '@/components/blocks/Modal';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import styles from './styles.module.css';
import HelpText from '../HelpText';
import getCroppedImg from './cropImage';
import { ButtonPrimary } from '@/components/controls/buttons/ButtonPrimary';

import { Avatar, ModalContainer, SubContainer, CropContainer, SliderContainer, ErrorMessage } from './styles';

export const UploadImage = ({ form: { setFieldValue } }) => {
  const MAX_FILE_SIZE = useMemo(() => 10e6, []); // 10 MB
  const [imageError, setImageError] = useState('');
  const [isCropPicModalOpen, setIsCropPicModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onImageChange = event => {
    const file = event.target.files && event.target.files[0];
    setImageError('');
    if (file && file.size <= MAX_FILE_SIZE) {
      setCurrentImage(URL.createObjectURL(file));
      setIsCropPicModalOpen(true);
    } else if (file && file.size > MAX_FILE_SIZE) {
      setCurrentImage('');
      setImageError('Size of the file can not be more than 10 MB');
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(currentImage, croppedAreaPixels);
      setCurrentImage(croppedImage);
      setFieldValue('image', croppedImage);
      setIsCropPicModalOpen(false);
    } catch (e) {
      setImageError('Failed to load the file.\nWe only support JPG, GIF or PNG file formats and the size of the file can not exceed 10 MB.');
    }
  }, [currentImage, croppedAreaPixels, setFieldValue]);

  return (
    <div>
      <span className={styles['upload-label']}>Upload profile image</span>
      <div className={styles['upload-image']}>
        <Avatar src={currentImage || avatar} alt="avatar" />
        <div className={styles['upload-input']}>
          <label htmlFor="upload-photo">Choose file</label>
          <input type="file" accept=".jpg, .gif, .png" id="upload-photo" onChange={onImageChange} />
        </div>
      </div>
      {imageError && <ErrorMessage>{imageError}</ErrorMessage>}
      <div className={styles['upload-help-text']}>
        <HelpText>Please upload a JPG, GIF or PNG with a maximum size of 10Mb</HelpText>
      </div>
      {isCropPicModalOpen && (
        <Modal>
          <ModalContainer>
            <SubContainer>
              <CropContainer>
                <Cropper
                  image={currentImage}
                  crop={crop}
                  zoom={zoom}
                  cropShape="round"
                  aspect={1 / 1}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </CropContainer>
              <SliderContainer>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              <ButtonPrimary text="Upload" onClick={showCroppedImage} />

              </SliderContainer>
            </SubContainer>
          </ModalContainer>
        </Modal>
      )}
    </div>
  );
};
