import React, { useEffect, useLayoutEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEqual } from 'deep-equal';
import {
  createOrGetChat,
  startDialogsPolling,
  stopDialogsPolling,
  setFavoriteDelegates,
} from '@/store/interactionPanel/actions';
import { getDelegates } from '@/store/networkingPage/actions';
import {
  getAreDialogsLoadingSelector,
  getDialogsSelector,
} from '@/store/interactionPanel/selectors';
import { getFavoriteDelegates } from '@/store/interactionPanel/selectors';
import { getDelegatesInfo } from '@/store/networkingPage';
import ContactCard from '../../../ContactCard';
import { generateNetworkingUrl } from '@/constants/routes';
import {
  Body,
  Controls,
  MessagesContainer,
  SectionTitle,
  ContactCardWrapper,
  MessagesSection,
  UnreadMessagesCounter,
} from './styles';
import { EmptyContainer, EmptyContainerButtonWrapper, CustomButtonOutline } from '../../styles';

const Messages = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const networkingRoute = generateNetworkingUrl(id);
  const dialogs = useSelector(getDialogsSelector);
  const favoriteDelegates = useSelector(getFavoriteDelegates);
  const isLoading = useSelector(getAreDialogsLoadingSelector);
  const delegatesList = useSelector(getDelegatesInfo);

  useEffect(() => {
    dispatch(startDialogsPolling(id));
    !delegatesList.length && dispatch(getDelegates(id));

    return () => {
      dispatch(stopDialogsPolling(id));
    };
  }, [id, dispatch]);

  useLayoutEffect(() => {
    dispatch(setFavoriteDelegates(id));
  }, [id, dispatch])

  const handleSelectDialog = (id, delegate) => {
    dispatch(createOrGetChat({ event_pk: id, recipient: delegate }));
  };

  return (
    <MessagesContainer>
      {((dialogs && dialogs.length) || (favoriteDelegates && favoriteDelegates.length)) &&
      !isLoading ? (
        <>
          <Body>
            {dialogs && !!dialogs.length && (
              <MessagesSection>
                <SectionTitle>active conversations</SectionTitle>
                {dialogs.map(({ id: chatId, target, unread_count }) => (
                  <ContactCardWrapper key={chatId} onClick={() => handleSelectDialog(id, target)}>
                    <ContactCard
                      photo={target.headshot}
                      delegateId={target.id}
                      name={`${target.first_name} ${target.last_name}`}
                      organisation={target.organisation}
                      jobRole={target.role}
                      isOnline={target.online}
                    />
                    {unread_count > 0 && (
                      <UnreadMessagesCounter>{unread_count}</UnreadMessagesCounter>
                    )}
                  </ContactCardWrapper>
                ))}
              </MessagesSection>
            )}
            {favoriteDelegates && !!favoriteDelegates.length && (
              <MessagesSection>
                <SectionTitle>bookmarks</SectionTitle>
                {favoriteDelegates.map(
                  ({
                    id: delegateId,
                    email,
                    event,
                    first_name,
                    last_name,
                    organisation,
                    organisation_type,
                    role,
                    headshot,
                    biography,
                    job_title,
                    online,
                  }) => {
                    const delegate = {
                      delegateId,
                      email,
                      event,
                      first_name,
                      last_name,
                      organisation,
                      organisation_type,
                      role,
                      headshot,
                      biography,
                      job_title,
                      online,
                    };
                    return (
                      <ContactCardWrapper
                        key={delegateId}
                        onClick={() => handleSelectDialog(id, delegate)}
                      >
                        <ContactCard
                          delegateId={delegateId}
                          name={`${first_name} ${last_name}`}
                          organisation={organisation}
                          jobRole={role}
                          photo={headshot}
                        />
                      </ContactCardWrapper>
                    );
                  },
                )}
              </MessagesSection>
            )}
          </Body>
          <Controls>
            <Link to={networkingRoute}>
              <CustomButtonOutline text="Go to networking" />
            </Link>
          </Controls>
        </>
      ) : isLoading ? (
        <EmptyContainer>
          <CircularProgress />
        </EmptyContainer>
      ) : (
        <EmptyContainer>
          <span>You don't have any chats or bookmarks yet</span>
          <EmptyContainerButtonWrapper>
            <Link to={networkingRoute}>
              <CustomButtonOutline text="Start networking" />
            </Link>
          </EmptyContainerButtonWrapper>
        </EmptyContainer>
      )}
    </MessagesContainer>
  );
};

export default React.memo(Messages, isEqual);
