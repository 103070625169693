import React, { useEffect } from 'react';
import { isEqual } from 'deep-equal';
import {
  Container,
  EditBiographyApp,
  EditBiographySpan,
  EditInterestsApp,
  EditSocialLinksApp,
  ExhibitorContainer,
  ExhibitorContainerTitle,
  PersonalInfo,
  PersonalSettings,
  PrivacySettingsAdaptiveWrapper,
  PrivacySettingsWrapper,
  SessionContainer,
  SessionTitle,
} from './styles';
import UserCardPofile from './components/UserCardProfile';
import PrivacySettings from './components/PrivacySettings';
import EditHeader from '../ExhibitorPage/EditHeader/component';
import Interests from './components/Inerests/component';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsActiveEditBiography,
  getIsActiveEditInterests,
  getIsActiveEditSocialLinks,
  getIsFetchingProfile,
  getProfile,
  getProfileInfo,
  setIsActiveEditBiography,
  setIsActiveEditInterests,
  setIsActiveEditSocialLinks,
  getDelegateProfile,
} from '@/store/profilePage';
import Preloader from '../../blocks/Preloader';
import { useParams } from 'react-router-dom';
import { SessionSection } from '../ExhibitorPage/styles';
import MicroAgenda from '../../blocks/MicroAgendaCard';
import EditSocialLinks from './components/EditSocialLinks/component';
import ExpoCard from '../../blocks/ExpoCard/component';
import EditBiography from './components/EditBiography/component';
import EditInterests from './components/EditInterests/component';

const MyProfile = props => {
  const dispatch = useDispatch();
  const {
    biography,
    session_set,
    exhibitor_data,
    job_title,
    id,
    interests_set,
  } = useSelector(getProfileInfo);
  
  const isFetchingProfile = useSelector(getIsFetchingProfile);
  const isActiveEditSocialLinks = useSelector(getIsActiveEditSocialLinks);
  const isActiveEditBiography = useSelector(getIsActiveEditBiography);
  const isActiveEditInterests = useSelector(getIsActiveEditInterests);
  const params = useParams();

  useEffect(() => {
    if (params.delegateId) {
      dispatch(getDelegateProfile({ id: params.id, delegateId: params.delegateId }));
    } else {
      dispatch(getProfile(params.id));
    }
  }, [params.delegateId]);

  return (
    <>
      {isFetchingProfile ? (
        <Preloader />
      ) : (
        <Container>
          <PersonalSettings>
            <UserCardPofile paramsDelegateId={params.delegateId} showButtons={false}/>
            <EditSocialLinksApp>
              {!params.delegateId && (
                <EditHeader
                  label="Edit social links"
                  active={isActiveEditSocialLinks}
                  setActive={setIsActiveEditSocialLinks}
                />
              )}
              {isActiveEditSocialLinks && <EditSocialLinks />}
            </EditSocialLinksApp>

            {!params.delegateId && (
              <PrivacySettingsWrapper>
                <PrivacySettings delegateId={id} />
              </PrivacySettingsWrapper>
            )}
          </PersonalSettings>
          <PersonalInfo>
            <EditBiographyApp>
              {!params.delegateId && (
                <EditHeader
                  label="Edit biography"
                  active={isActiveEditBiography}
                  setActive={setIsActiveEditBiography}
                />
              )}
            </EditBiographyApp>
            {isActiveEditBiography ? (
              <EditBiography />
            ) : (
              biography && <EditBiographySpan>{biography}</EditBiographySpan>
            )}
            {interests_set.length > 0 && (
              <EditInterestsApp>
                {!params.delegateId && (
                  <EditHeader
                    label="Edit interests"
                    active={isActiveEditInterests}
                    setActive={setIsActiveEditInterests}
                  />
                )}
                {isActiveEditInterests ? <EditInterests delegateId={id} /> : <Interests />}
              </EditInterestsApp>
            )}
            {session_set.length > 0 && (
              <SessionContainer>
                <SessionTitle>Speaking at</SessionTitle>
                {session_set.map(session => (
                  <SessionSection key={session.id}>
                    <MicroAgenda
                      {...session}
                      eventId={params.id}
                      speakers={session.speakers_set}
                      isWithSpeakers
                      isOnLivestream
                    />
                  </SessionSection>
                ))}
              </SessionContainer>
            )}
            {Object.keys(exhibitor_data).length > 0 && (
              <ExhibitorContainer>
                <ExhibitorContainerTitle>{`${job_title ? job_title : 'Works'} at`}</ExhibitorContainerTitle>
                <ExpoCard {...exhibitor_data} eventId={params.id} isOnProfilePage/>
              </ExhibitorContainer>
            )}
            {!params.delegateId && (
              <PrivacySettingsAdaptiveWrapper>
                <PrivacySettings delegateId={id} />
              </PrivacySettingsAdaptiveWrapper>
            )}
          </PersonalInfo>
        </Container>
      )}
    </>
  );
};

export default React.memo(MyProfile, isEqual);
