import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ bgColor, theme }) =>
    bgColor === 'grey' && `1px solid ${theme.colors.fieldsSummaryCardTitleGrey}`};
  color: ${({ textColor, theme }) =>
    textColor ? theme.colors.originWhite : theme.colors.regularText};
  background: ${({ bgColor, theme }) => {
    if (bgColor === 'danger') {
      return 'rgba(221, 72, 82, 0.3)';
    } else if (bgColor === 'black') {
      return theme.colors.regularText;
    } else if (bgColor === 'grey') {
      return theme.colors.managerAgenda.whiteLilac;
    }
  }};
`;
export const InfoBlock = styled.div``;
export const Session = styled.div`
  width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-overflow: ellipsis;
`;
export const Time = styled.div`
  width: 100%;
  margin-bottom: 3px;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.managerAgenda.osloGray};
`;
export const Speakers = styled.div`
  width: 100%;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-overflow: ellipsis;
`;
export const StatusBlock = styled.div`
  position: relative;
  display: ${({ visible }) => (visible ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
`;
export const Status = styled.div`
  min-width: 95px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${({ online, theme }) =>
    online &&
    `:before{
    content:'';
    width:4px;
    height:4px;
    border-radius:50px;
    display:inline-block;
    background: ${theme.colors.managerAgenda.cinnabar};
    margin-right:5px;
  }`}
`;
