import { broadcastMessagesActions } from '@/constants/actions/broadcastMessagesActions';

const initialState = {
  broadcastMessages: [],
};

export const broadcastMessages = (state = initialState, action) => {
  switch (action.type) {
    case broadcastMessagesActions.SetBroadcastMessages:
      return {
        ...state,
        broadcastMessages: action.payload,
      };
    default:
      return state;
  }
};
