import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer, { history } from './store/rootReducer';
import rootSaga from './store/rootSaga';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { routerMiddleware } from 'connected-react-router'
import { ConnectedRouter } from 'connected-react-router'
import 'antd/dist/antd.css'

import { composeWithDevTools } from 'redux-devtools-extension';

import './index.css';
import App from './components/App';

const promise = loadStripe("pk_test_51HXSZHKqNwmIT58jJ56h1fvGhYyAeNmo4urfmj9z5By4Zp7uLLlKngETYspZDg3jwvWqBk9tAsZbwmh8bEuwFII900JeoE7VFH");

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware)));
sagaMiddleware.run(rootSaga)

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Elements stripe={promise}>
        <App />
      </Elements>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
