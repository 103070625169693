import React from 'react';
import { isEqual } from 'deep-equal';
import SessionCard from './components/SessionCard';
import BreakCard from './components/BreakCard';
import DayDelimiter from './components/DayDelimiter';
import DayDelimiterLanding from './components/DayDelimiterLanding';
import { generateAgendaDayId, isDayValid } from '@/helpers';
import { generateAgendaSessionID } from '../../../constants/elementsIDs';

import { AgendaWrapper, AgendaDaySection, AgendaDaySessions } from './styles';

const Agenda = ({ agenda, liveSession, registerCallback, liveSessionRef, agendaRef }) => {
  const isOnLanding = Boolean(registerCallback);

  return (
    <AgendaWrapper showBorder={isOnLanding}>
      {agenda.map(day => isDayValid(day) && (
        <AgendaDaySection key={generateAgendaDayId(day.id)} id={generateAgendaDayId(day.id)}>
          {isOnLanding ? (
            <DayDelimiterLanding
              name={`${day.name}`}
              topic={`${day.topic}`}
              handleRegister={registerCallback}
            />
          ) : (
            <DayDelimiter name={day.name} />
          )}
          <AgendaDaySessions key={day.id} showBorder={!isOnLanding}>
            {day.sessions
              .slice()
              .reverse()
              .map(session => {
                const isLive = session.id === liveSession?.id;
                return session.is_break ? (
                  <BreakCard key={session.id} session={session} />
                ) : (
                  <SessionCard
                    key={session.id}
                    dayID={day.id}
                    session={session}
                    speakers={session.speakers}
                    isLive={isLive}
                    liveSessionRef={isLive ? liveSessionRef : null}
                    allowBookmarking={!isOnLanding}
                    allowOnDemand={!isOnLanding}
                    allowSharing={!isOnLanding}
                    agendaRef={agendaRef}
                    id={generateAgendaSessionID(day.id, session.id)}
                  />
                );
              })}
          </AgendaDaySessions>
        </AgendaDaySection>
      ))}
    </AgendaWrapper>
  );
};

export default React.memo(Agenda, isEqual);
