export const PAGES_PREFIX = '/:id';

export const LANDING = '/';
export const EVENT_NOT_FOUND = '/event-not-found';
export const LINKEDIN_AUTH_URL = '/linkedin-auth';
export const REGISTER_DELEGATE_PROMPT = '/register-to-event-prompt';
export const SIGN_IN = '/sign-in';
export const FORGOT_PASSWORD = '/forgot-password';
export const TICKETS = '/tickets';
export const REGISTER = '/register';
export const CHOOSE_TICKET = '/choose-ticket';
export const PLATFORM = '/platform';
export const AGENDA = '/agenda';
export const NETWORKING = '/networking-page';
export const DASHBOARD = '/dashboard-page';
export const PROFILE = '/my-profile-page';
export const EXPO = '/expo';
export const EVENT_MANAGEMENT = '/event-management';
export const BLOCKED_USER_PAGE = '/blocked-user-page';
export const INACTIVE_USER_PAGE = '/inactive-user-page';
export const NOT_APPROVED_DELEGATE_PAGE = '/delegate-not-approved';
export const EMAIL_APPROVE_PAGE = '/email-approve';
export const ESCALATED_QUESTIONS_PAGE = '/escalated-questions';
export const ESCALATED_QUESTIONS_SIGN_IN = '/escalated-questions/sign-in';
export const CHANGE_PASSWORD = '/change-password';

export const LANDING_URL = `${PAGES_PREFIX}${LANDING}`;
export const EVENT_NOT_FOUND_URL = `${PAGES_PREFIX}${EVENT_NOT_FOUND}`;
export const REGISTER_DELEGATE_PROMPT_URL = `${PAGES_PREFIX}${REGISTER_DELEGATE_PROMPT}`;
export const SIGN_IN_URL = `${PAGES_PREFIX}${SIGN_IN}`;
export const FORGOT_PASSWORD_URL = `${PAGES_PREFIX}${FORGOT_PASSWORD}`;
export const TICKETS_URL = `${PAGES_PREFIX}${TICKETS}`;
export const REGISTER_URL = `${PAGES_PREFIX}${REGISTER}`;
export const CHOOSE_TICKET_URL = `${PAGES_PREFIX}${CHOOSE_TICKET}`;
export const PLATFORM_URL = `${PAGES_PREFIX}${PLATFORM}`;
export const BLOCKED_USER_PAGE_URL = `${PAGES_PREFIX}${BLOCKED_USER_PAGE}`;
export const INACTIVE_USER_PAGE_URL = `${PAGES_PREFIX}${INACTIVE_USER_PAGE}`;
export const NOT_APPROVED_DELEGATE_PAGE_URL = `${PAGES_PREFIX}${NOT_APPROVED_DELEGATE_PAGE}`;
export const EMAIL_APPROVE_PAGE_URL = `${PAGES_PREFIX}${EMAIL_APPROVE_PAGE}`;
export const ESCALATED_QUESTIONS_PAGE_URL = `${PAGES_PREFIX}${ESCALATED_QUESTIONS_PAGE}`;
export const ESCALATED_QUESTIONS_SIGN_IN_URL = `${PAGES_PREFIX}${ESCALATED_QUESTIONS_SIGN_IN}`;

export const AGENDA_URL = `${PAGES_PREFIX}${PLATFORM}${AGENDA}`;
export const NETWORKING_URL = `${PAGES_PREFIX}${PLATFORM}${NETWORKING}`;
export const DASHBOARD_URL = `${PAGES_PREFIX}${PLATFORM}${DASHBOARD}`;
export const PROFILE_URL = `${PAGES_PREFIX}${PLATFORM}${PROFILE}`;
export const EVENT_MANAGEMENT_URL = `${PAGES_PREFIX}${PLATFORM}${EVENT_MANAGEMENT}`;

export const EXPO_URL = `${PAGES_PREFIX}${PLATFORM}${EXPO}`;
export const EXHIBITOR_URL = `${EXPO_URL}/:exhibitorId`;

export const USER_URL = `${NETWORKING_URL}/user/:delegateId`;
export const DEMAND_URL = `${AGENDA_URL}/demand/:sessionId/:videoId`;

export const generateLandingUrl = id => `/${id}${LANDING}`;
export const generateEventNotFoundUrl = id => `/${id}${EVENT_NOT_FOUND}`;
export const generateRegisterDelegatePromptUrl = id => `/${id}${REGISTER_DELEGATE_PROMPT}`;
export const generateSignInUrl = id => `/${id}${SIGN_IN}`;
export const generateForgotPasswordUrl = id => `/${id}${FORGOT_PASSWORD}`;
export const generateTicketsUrl = id => `/${id}${TICKETS}`;
export const generateRegisterUrl = id => `/${id}${REGISTER}`;
export const generateChooseTicketUrl = id => `/${id}${CHOOSE_TICKET}`;
export const generatePlatformUrl = id => `/${id}${PLATFORM}`;
export const generateBlockedUserPage = id => `/${id}${BLOCKED_USER_PAGE}`;
export const generateInactiveUserPage = id => `/${id}${INACTIVE_USER_PAGE}`;
export const generateNotApprovedDelegatePageUrl = id => `/${id}${NOT_APPROVED_DELEGATE_PAGE}`;
export const generateEmailApproveUrl = id => `/${id}${EMAIL_APPROVE_PAGE}`;
export const generateEscalatedQuestionsUrl = id => `/${id}${ESCALATED_QUESTIONS_PAGE}`;
export const generateEscalatedQuestionsSignInUrl = id => `/${id}${ESCALATED_QUESTIONS_SIGN_IN}`;

export const generateAgendaUrl = id => `/${id}${PLATFORM}${AGENDA}`;
export const generateNetworkingUrl = id => `/${id}${PLATFORM}${NETWORKING}`;
export const generateDashboardUrl = id => `/${id}${PLATFORM}${DASHBOARD}`;
export const generateExpoUrl = id => `/${id}${PLATFORM}${EXPO}`;
export const generateMyProfiledUrl = id => `/${id}${PLATFORM}${PROFILE}`;
export const generateEventManagementUrl = id => `/${id}${PLATFORM}${EVENT_MANAGEMENT}`;
export const generateExhibitorUrl = (id, exhibitorTag) =>
  `/${id}${PLATFORM}${EXPO}/${exhibitorTag}`;
export const generateUserUrl = (id, delegateId) =>
  `/${id}${PLATFORM}${NETWORKING}/user/${delegateId}`;
export const generateDemandUrl = (id, sessionId, videoId) =>
  `/${id}${PLATFORM}${AGENDA}/demand/${sessionId}/${videoId}`;
