import React from 'react';
import { IconWrapper, Icon } from '../../styles';

export const UserCircleIcon = () => {
  return (
    <IconWrapper style={ { paddingBottom: '100%' } }>
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="2 2 28 28">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0001 2.66663C8.64008 2.66663 2.66675 8.63996 2.66675 16C2.66675 23.36 8.64008 29.3333 16.0001 29.3333C23.3601 29.3333 29.3334 23.36 29.3334 16C29.3334 8.63996 23.3601 2.66663 16.0001 2.66663ZM16.6801 13.32C16.6801 11.12 14.8801 9.31996 12.6801 9.31996C10.4801 9.31996 8.68008 11.12 8.68008 13.32C8.68008 15.52 10.4801 17.32 12.6801 17.32C14.8801 17.32 16.6801 15.52 16.6801 13.32ZM12.6801 14.6533C11.9468 14.6533 11.3468 14.0533 11.3468 13.32C11.3468 12.5866 11.9468 11.9866 12.6801 11.9866C13.4134 11.9866 14.0134 12.5866 14.0134 13.32C14.0134 14.0533 13.4134 14.6533 12.6801 14.6533ZM24.0134 14.6533C24.0134 16.1333 22.8267 17.32 21.3467 17.32C19.8667 17.32 18.6801 16.1333 18.6801 14.6533C18.6667 13.1733 19.8667 11.9866 21.3467 11.9866C22.8267 11.9866 24.0134 13.1733 24.0134 14.6533ZM12.6801 21.3333C10.8267 21.3333 8.70675 22.0933 7.80008 22.8133C9.62675 25.0133 12.3201 26.4533 15.3467 26.6266V22.92C15.3467 20.4 19.3201 19.32 21.3467 19.32C22.5201 19.32 24.3334 19.6933 25.6667 20.48C26.3067 19.1066 26.6667 17.5866 26.6667 15.9866C26.6667 10.1066 21.8801 5.31996 16.0001 5.31996C10.1201 5.31996 5.33341 10.1066 5.33341 15.9866C5.33341 17.6266 5.70675 19.1733 6.37341 20.56C8.16008 19.2533 10.9467 18.6533 12.6801 18.6533C13.2667 18.6533 13.9734 18.7333 14.7201 18.8666C13.8801 19.6266 13.3067 20.4933 12.9867 21.3466C12.9316 21.3466 12.8765 21.3431 12.8232 21.3396C12.7734 21.3364 12.7252 21.3333 12.6801 21.3333Z"
      />
      </Icon>
   </IconWrapper>
  );
};
