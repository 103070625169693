import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Poll from 'react-polls';
import Button from '../../../../controls/buttons/GenericButton/component';
import { PollContainer, PollWrapper, PollTitle, PollSection, CloseButton } from './styles';
import { postOptionVote, startGetPollsPolling, stopGetPollsPolling } from '@/store/polls/actions';
import { getLiveSessionsInfo } from '@/store/sessions/selectors';
import { getLastPoll } from '@/store/polls/selectors';

const pollStyles = {
  questionSeparator: false,
}

const PollPanel = ({ setIsPollPanelShown }) => {
  const dispatch = useDispatch();
  const pollsDataSelector = useSelector(getLastPoll);
  const { current_sessions } = useSelector(getLiveSessionsInfo);
  const pollQuestion = useMemo( () => {
    if(pollsDataSelector){
    return pollsDataSelector.question;
    } 
    return null;   
  }, [pollsDataSelector]);  
  const pollAnswers = useMemo( () => {
    if(pollsDataSelector){
    return pollsDataSelector.options_set.map(op => (
          {
            id: op.id,
            option: op.option,
            votes: op.votes_number,
          }
        ))
    } 
    
    return [];   
  }, [pollsDataSelector]);
  const [isButtonClose, setIsButtonClose] = useState(false);
  const [pollTitle, setPollTitle] = useState('Poll');
  const [pollAnswer, setPollAnswers] = useState([...pollAnswers]);

  const handleVote = voteAnswer => {
    let answerId = null;
    const newPollAnswers = pollAnswer.map(answer => {
      if (answer.option === voteAnswer) {
        answer.votes++;
        answerId = answer.id;
      }

      return answer;
    });
    
    setPollAnswers(newPollAnswers);
    setIsButtonClose(true);
    setPollTitle('Poll results');
    dispatch(stopGetPollsPolling());
    dispatch(postOptionVote(current_sessions.id, answerId));
  };

  const handleClosePoll = () => {
    setIsPollPanelShown(false);
    dispatch(startGetPollsPolling());
  };

  if(!pollsDataSelector || pollsDataSelector.length === 0) {
    setIsPollPanelShown(false);
  }

  return (
    <>    
      <PollContainer>
        <PollWrapper>
          <PollTitle>{pollTitle}</PollTitle>
          <PollSection>
            <Poll
              question={pollQuestion}
              answers={pollAnswer}
              onVote={handleVote}
              customStyles={pollStyles}
              noStorage
            />
          </PollSection>

          {isButtonClose &&
            <CloseButton>
              <Button label="Close" styleType="outline" onClick={handleClosePoll} />
            </CloseButton>
          }
        </PollWrapper>
      </PollContainer>
    </>
  )
}

export default PollPanel;