import { createSelector } from 'reselect';

const getLogin = state => state.loginPageReducer;

export const getSignInError = createSelector([getLogin], state => {
  return state.errorMsg;
});

export const isLoadingInfo = createSelector([getLogin], state => {
  return state.isLoading;
});