import axios from 'axios';
import { baseURL } from '@/constants/baseURL';
import { responseHandlerInterceptor, sendTokenInterceptor } from '../helpers/interceptors';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

instance.interceptors.request.use(request => {
  return sendTokenInterceptor(request);
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return responseHandlerInterceptor(error, instance);
  },
);

export const pollsAPI = {
  getPolls(session_pk) {
    return instance.get(`/events/session/${session_pk}/polls/`);
  },
  postOptionVote(session_pk, option_pk) {
    return instance.post(`/events/session/${session_pk}/option/${option_pk}/vote/`, {});
  },
};
