import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://2awhr2raf1.execute-api.eu-west-2.amazonaws.com/default/',
});

export const awsAPI = {
  pingIsUserOnline(event_pk, delegateId, pageUrl, sessionId) {
    return instance.get(
      `check_interval?&user_id=${delegateId}&event_id=${event_pk}&page_url=${pageUrl}&session_id=${sessionId}`,
    );
  },
};
