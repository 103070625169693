export const SignInActions = {
  OnSignInUser: 'signIn/N_SIGN_IN_USER',
  AddUserData: 'signIn/ADD_USER_DATA',
  SetErrorMsg: 'signIn/SET_ERROR_MSG',
  ResetPassword: 'signIn/RESET_PASSWORD',
  AddResetPasswordEmail: 'signIn/ADD_RESET_PASSWORD_EMAIL',
  SetIsLoading: 'signIn/SET_IS_LOADING',
  SetResetPasswordError: 'signIn/SET_RESET_PASSWORD_ERROR',
  SetResetPasswordSpinner: 'signIn/SET_RESET_PASSWORD_SPINNER',
  SetIsSuccessChangePassword: 'signIn/SET_IS_SUCCESS_CHANGE_PASSWORD',
  SetIsLoadingChangePassword: 'signIn/SET_IS_LOADING_CHANGE_PASSWORD',
  ChangePassword: 'signIn/CHANGE_PASSWORD',
  ResetConfirm: 'signIn/RESET_CONFIRM',
};
