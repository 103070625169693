import React from 'react';
import { isEqual } from 'deep-equal';
import { SortContainer, SortSpan } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getSort, setSelectedSort } from '@/store/networkingPage';

const Sort = () => {
  const dispatch = useDispatch();
  const sort = useSelector(getSort);
  const handleSpan = id => {
    dispatch(setSelectedSort(id));
  };
  return (
    <SortContainer>
      {sort.map(s => {
        return (
          <div key={s.id} onClick={() => handleSpan(s.id)}>
            <SortSpan>{s.name}</SortSpan>
          </div>
        );
      })}
    </SortContainer>
  );
};

export default React.memo(Sort, isEqual);
