import { dashboardActions } from '../../constants/actions/dashboardActions';

//Delegates
export const setIsFetchingDelegates = data => ({
  type: dashboardActions.SetIsFetchingDelegates,
  payload: data,
});

export const getDelegates = data => ({
  type: dashboardActions.GetDelegates,
  payload: data,
});

export const setDelegates = data => ({
  type: dashboardActions.SetDelegates,
  payload: data,
});

export const addDelegates = data => ({
  type: dashboardActions.AddDelegate,
  payload: data,
});

export const removeDelegates = data => ({
  type: dashboardActions.RemoveDelegate,
  payload: data,
});

export const setIsBookmarkAcceptedUsers = (delegateId, isBookmark) => ({
  type: dashboardActions.SetIsBookmarkAcceptedUsers,
  delegateId,
  isBookmark,
});

export const setIsBookmarkRejectedUsers = (delegateId, isBookmark) => ({
  type: dashboardActions.SetIsBookmarkRejectedUsers,
  delegateId,
  isBookmark,
});

export const bookmarkDashboardDelegate = data => ({
  type: dashboardActions.BookmarkDashboardDelegate,
  payload: data,
});

export const setErrorMsg = data => ({
  type: dashboardActions.SetErrorMsg,
  payload: data,
});

export const unBookmarkDashboardDelegate = data => ({
  type: dashboardActions.UnBookmarkDashboardDelegate,
  payload: data,
});

export const setIsFetchingInvitation = data => ({
  type: dashboardActions.SetIsFetchingInvitation,
  payload: data,
});

export const setIsSuccessInvitation = data => ({
  type: dashboardActions.SetIsSuccessInvitation,
  payload: data,
});

export const setIsAcceptUsersModal = () => ({
  type: dashboardActions.SetIsAcceptUsersModal,
});

export const setIsRejectUsersModal = () => ({
  type: dashboardActions.SetIsRejectUsersModal,
});

export const clearSelectedDelegates = () => ({
  type: dashboardActions.ClearSelectedDelegates,
});

//Bookmarked delegates
export const setIsFetchingBookmarked = data => ({
  type: dashboardActions.SetIsFetchingBookmarked,
  payload: data,
});

export const getBookmarked = data => ({
  type: dashboardActions.GetBookmarked,
  payload: data,
});

export const setBookmarked = data => ({
  type: dashboardActions.SetBookmarked,
  payload: data,
});

export const addBookmarkeds = data => ({
  type: dashboardActions.AddBookmarkeds,
  payload: data,
});

//Send invitation
export const sendInvitationDelegates = (invitation, event_pk) => ({
  type: dashboardActions.SendInvitationDelegates,
  payload: { invitation, event_pk },
});

//Get Exhibitor data\

export const startExibitorDataPolling = data => ({
  type: dashboardActions.StartExibitorDataPolling,
  payload: data,
});

export const stopExibitorDataPolling = data => ({
  type: dashboardActions.StopExibitorDataPolling,
  payload: data,
});

export const setExibitorDataLoading = data => ({
  type: dashboardActions.SetExibitorDataLoading,
  payload: data,
});

export const setExibitorData = data => ({
  type: dashboardActions.SetExibitorData,
  payload: data,
});

//Get AcceptedUsers

export const getAcceptedUsers = (event_pk, invitation_pk) => ({
  type: dashboardActions.GetAcceptedUsers,
  payload: { event_pk, invitation_pk },
});

export const setAcceptedUsers = data => ({
  type: dashboardActions.SetAcceptedUsers,
  payload: data,
});

//Get RejectedUsers

export const getRejectedUsers = (event_pk, invitation_pk) => ({
  type: dashboardActions.GetRejectedUsers,
  payload: { event_pk, invitation_pk },
});

export const setRejectedUsers = data => ({
  type: dashboardActions.SetRejectedUsers,
  payload: data,
});

//Get StatisticVisitors

export const startStatisticVisitorsPolling = (event_pk, urlTag) => ({
  type: dashboardActions.StartStatisticVisitorsPolling,
  payload: { event_pk, urlTag },
});

export const stopStatisticVisitorsPolling = (event_pk, urlTag) => ({
  type: dashboardActions.StopStatisticVisitorsPolling,
  payload: { event_pk, urlTag },
});

export const setStatisticVisitors = data => ({
  type: dashboardActions.SetStatisticVisitors,
  payload: data,
});

export const getExhibitorStandCsv = (event_pk, urlTag) => ({
  type: dashboardActions.GetExhibitorStandCsv,
  payload: { event_pk, urlTag },
});

export const setExhibitorStandCsv = data => ({
  type: dashboardActions.SetExhibitorStandCsv,
  payload: data,
});
