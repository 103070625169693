import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://gsn2rc8rok.execute-api.eu-west-2.amazonaws.com/default/',
});

export const awsApiStatistic = {
  getStatisticVisitors(event_pk, urlTag) {
    return instance.get(`return_page_visit?page_url=/${event_pk}/platform/expo/${urlTag}`);
  },
};
