import React from 'react';
import { isEqual } from 'deep-equal';
import { TicketChoiceForm } from '@/components/forms/TicketChoiceForm';
import Logo from '@/components/controls/Logo';
import styles from './styles.module.css';

const ChooseTicketPage = props => {
  return (
    <div className={styles['choose-ticket-form-wrapper']}>
      <div className={styles['choose-ticket-form-content']}>
        <div className={styles['logo-wrapper']}>
          <Logo />
        </div>
        <div className={styles['title-wrapper']}>
          <p className={styles['choose-ticket-title']}>
            Choose your ticket
            </p>
          <p className={styles['choose-ticket-subtitle']}>
            We review each application and aim to respond within 24 hours
            </p>
        </div>
        <TicketChoiceForm />
      </div>
    </div>
  );
};

export default React.memo(ChooseTicketPage, isEqual);
