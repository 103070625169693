import { SignInActions } from '@/constants/actions/signInActions'

export const onPutResetPasswordEmail = (data) => ({
  type: SignInActions.AddResetPasswordEmail,
  payload: data,
})

export const setResetPasswordError = (data) => ({
  type: SignInActions.SetResetPasswordError,
  payload: data,
})

export const setResetPasswordSpinner = (data) => ({
  type: SignInActions.SetResetPasswordSpinner,
  payload: data,
})

export const setIsSuccessChangePassword = (data) => ({
  type: SignInActions.SetIsSuccessChangePassword,
  payload: data,
})

export const setIsLoadingChangePassword = (data) => ({
  type: SignInActions.SetIsLoadingChangePassword,
  payload: data,
})

//saga actions
export const onResetPassword = (data) => ({
  type: SignInActions.ResetPassword,
  payload: data,
})

export const changePassword = (data) => ({
  type: SignInActions.ChangePassword,
  payload: data,
})

export const resetConfirm = (data) => ({
  type: SignInActions.ResetConfirm,
  payload: data,
})