import { call, put, takeEvery, delay, take, race, select } from 'redux-saga/effects';
import { dashboardActions } from '@/constants/actions/dashboardActions';
import {
  GET_STATISTIC_VISITORS_DELAY,
  GET_EXIBITOR_DATA_DELAY,
} from '@/constants/longPollingDelay';
import { awsApiStatistic } from '../../api/awsApiStatistic';
import { dashboardApi } from '../../api/dashboardApi';
import {
  setDelegates,
  setIsFetchingDelegates,
  setBookmarked,
  setIsFetchingBookmarked,
  setExibitorDataLoading,
  setExibitorData,
  setAcceptedUsers,
  setRejectedUsers,
  setStatisticVisitors,
  setExhibitorStandCsv,
  setIsBookmarkAcceptedUsers,
  setIsBookmarkRejectedUsers,
  setIsFetchingInvitation,
  setIsSuccessInvitation,
  setErrorMsg,
} from './actions';
import { networkingPageAPI } from '../../api/networkingPageApi';
import { getAcceptUsersModal, getRejectUsersModal } from './selectors';
import { addFavoriteDelegate, removeFavoriteDelegate } from '../interactionPanel/actions';

function* getDelegates(action) {
  try {
    const response = yield call(dashboardApi.getDelegates, action.payload);
    yield put(setDelegates(response.data));
    yield put(setIsFetchingDelegates(false));
  } catch (error) {
    console.log(error);
  }
}

function* bookmarkDashboardDelegate({ payload: { id, delegateId, isBookmark, delegate } }) {
  const acceptedUserModal = yield select(getAcceptUsersModal);
  const rejectedUserModal = yield select(getRejectUsersModal);
  try {
    yield acceptedUserModal && put(setIsBookmarkAcceptedUsers(delegateId, isBookmark));
    yield rejectedUserModal && put(setIsBookmarkRejectedUsers(delegateId, isBookmark));
    yield put(addFavoriteDelegate(delegate));
    yield call(networkingPageAPI.bookmarkDelegate, id, delegateId);
  } catch (error) {
    yield acceptedUserModal && put(setIsBookmarkAcceptedUsers(delegateId, false));
    yield rejectedUserModal && put(setIsBookmarkRejectedUsers(delegateId, false));
    yield put(removeFavoriteDelegate(delegate));
  }
}

function* unBookmarkDashboardDelegate({ payload: { id, delegateId, isBookmark, delegate } }) {
  const acceptedUserModal = yield select(getAcceptUsersModal);
  const rejectedUserModal = yield select(getRejectUsersModal);
  try {
    yield acceptedUserModal && put(setIsBookmarkAcceptedUsers(delegateId, isBookmark));
    yield rejectedUserModal && put(setIsBookmarkRejectedUsers(delegateId, isBookmark));
    yield put(removeFavoriteDelegate(delegate));
    yield call(networkingPageAPI.unBookmarkDelegate, id, delegateId);
  } catch (error) {
    yield acceptedUserModal && put(setIsBookmarkAcceptedUsers(delegateId, true));
    yield rejectedUserModal && put(setIsBookmarkRejectedUsers(delegateId, true));
    yield put(addFavoriteDelegate(delegate));
  }
}

//get BookmarkedDelegates
function* getBookmarkedDelegates(action) {
  try {
    const response = yield call(dashboardApi.getBookmarkedDelegates, action.payload);
    yield put(setBookmarked(response.data));
    yield put(setIsFetchingBookmarked(false));
  } catch (error) {
    console.log(error);
  }
}

function* setInvitation(action) {
  try {
    yield put(setIsFetchingInvitation(true));
    yield call(
      dashboardApi.invitationDelegates,
      action.payload.invitation,
      action.payload.event_pk,
    );
    yield put(setErrorMsg(''));
    yield put(setIsSuccessInvitation(true));
  } catch (error) {
    yield put(setErrorMsg(error.response.data.map(err => Object.values(err).join(''))));
  } finally {
    yield put(setIsFetchingInvitation(false));
  }
}

//Get AcceptedUsers
function* getAcceptedUsersSaga(action) {
  try {
    const response = yield call(
      dashboardApi.getAcceptedUsers,
      action.payload.event_pk,
      action.payload.invitation_pk,
    );
    yield put(setAcceptedUsers(response.data));
  } catch (error) {
    console.log(error);
  }
}

//Get RejectedUsers
function* getRejectedUsersSaga(action) {
  try {
    const response = yield call(
      dashboardApi.getRejectedUsers,
      action.payload.event_pk,
      action.payload.invitation_pk,
    );
    yield put(setRejectedUsers(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* watchAcceptedAndRejectedUsers() {
  yield takeEvery(dashboardActions.GetAcceptedUsers, getAcceptedUsersSaga);
  yield takeEvery(dashboardActions.GetRejectedUsers, getRejectedUsersSaga);
}

// get exhibitor
function* getExibitorDataSaga(action) {
  yield put(setExibitorDataLoading(true));
  while (true) {
    try {
      const response = yield call(dashboardApi.getExibitorData, action.payload);
      yield put(setExibitorData(response.data));
      yield put(setExibitorDataLoading(false));
      yield delay(GET_EXIBITOR_DATA_DELAY);
    } catch (error) {
      console.log(error);
      return;
    }
  }
}

export function* watchExibitorDataSaga() {
  while (true) {
    const event_pk = yield take(dashboardActions.StartExibitorDataPolling);
    yield race([
      call(getExibitorDataSaga, event_pk),
      take(dashboardActions.StopExibitorDataPolling),
    ]);
  }
}

// get statistic
function* getStatisticVisitorsSaga(event_pk, urlTag) {
  while (true) {
    try {
      const response = yield call(awsApiStatistic.getStatisticVisitors, event_pk, urlTag);
      yield put(setStatisticVisitors(response.data));
      yield delay(GET_STATISTIC_VISITORS_DELAY);
    } catch (error) {
      console.log(error);
      return;
    }
  }
}

function* getExhibitorStandCsvSaga(action) {
  try {
    const response = yield call(
      dashboardApi.getExhibitorStandCsv,
      action.payload.event_pk,
      action.payload.urlTag,
    );
    yield put(setExhibitorStandCsv(response.data));
  } catch (error) {
    console.log(error);
  }
}

export function* watchStatisticVisitorsSaga() {
  while (true) {
    const { payload } = yield take(dashboardActions.StartStatisticVisitorsPolling);
    yield race([
      call(getStatisticVisitorsSaga, payload.event_pk, payload.urlTag),
      take(dashboardActions.StopStatisticVisitorsPolling),
    ]);
  }
}

export function* watchDashboard() {
  yield takeEvery(dashboardActions.GetDelegates, getDelegates);
  yield takeEvery(dashboardActions.GetBookmarked, getBookmarkedDelegates);
  yield takeEvery(dashboardActions.SendInvitationDelegates, setInvitation);
  yield takeEvery(dashboardActions.GetExhibitorStandCsv, getExhibitorStandCsvSaga);
  yield takeEvery(dashboardActions.BookmarkDashboardDelegate, bookmarkDashboardDelegate);
  yield takeEvery(dashboardActions.UnBookmarkDashboardDelegate, unBookmarkDashboardDelegate);
}
