export const validationErrorsMapper = errors => {
  let errorsMesg = []

  for (const key in errors) {
    switch (key) {
      case 'job_title': {
        const error = `Job title: ${errors[key]}`

        errorsMesg.push(error)
        break
      }

      case 'role': {
        const error = `Role: ${errors[key]}`

        errorsMesg.push(error)
        break
      }

      case 'organisation': {
        const error = `Organisation: ${errors[key]}`

        errorsMesg.push(error)
        break
      }

      case 'country': {
        const error = `Country: ${errors[key]}`

        errorsMesg.push(error)
        break
      }

      case 'image': {
        const error = `Image: ${errors[key]}`

        errorsMesg.push(error)
        break
      }

      case 'linkedin_url': {
        const error = `Linkedin URL: ${errors[key]}`

        errorsMesg.push(error)
        break
      }

      case 'twitter_url': {
        const error = `Twitter URL: ${errors[key]}`

        errorsMesg.push(error)
        break
      }

      case 'biography': {
        const error = `Biography: ${errors[key]}`

        errorsMesg.push(error)
        break
      }

      case 'non_field_errors': {
        const error = 'This user has already been registered for this event'

        errorsMesg.push(error)
        break
      }

      default: {
        const error = `${key}: ${errors[key]}`

        errorsMesg.push(error)
        break
      }
    }
  }

  return errorsMesg
}