import { emailApproveActions } from '@/constants/actions/emailApproveActions';

export const setIsEmailApprove = data => ({
  type: emailApproveActions.SetIsEmailApprove,
  payload: data,
});

export const setEmailApproveErrMsg = data => ({
  type: emailApproveActions.SetEmailApproveErrMsg,
  payload: data,
});

//saga actions
export const emailApprove = data => ({
  type: emailApproveActions.EmailApprove,
  payload: data,
});

