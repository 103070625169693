import { call, put, select, takeEvery } from 'redux-saga/effects';
import { agendaAPI } from '@/api/agendaAPI';
import { AgendaActions } from '@/constants/actions/agendaActions';
import { setAgendaData, setShowAgendaLoader, setIsBookmarkAgendaSession } from './actions';
import { bookmarkExhibitorSession, unBookmarkExhibitorSession } from '@/store/exhibitorPage/saga';
import { getExhibitorInfo } from '@/store/exhibitorPage/selectors';
import { bookmarkProfileSession, unBookmarkProfileSession } from '@/store/profilePage/saga';
import { getProfileInfo } from '@/store/profilePage/selectors';
import { sessionsAPI } from '@/api/sessionsAPI';

function* updateAgenda(action) {
  try {
    const response = yield call(agendaAPI.fetchAgenda, action.payload);
    yield put(setAgendaData(response.data));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(setShowAgendaLoader(false));
  }
}

function* updateAgendaOnBookmarks(action) {
  try {
    const response = yield call(agendaAPI.fetchAgenda, action.payload);
    yield put(setAgendaData(response.data));
  } catch (error) {
    console.log(error);
  }
}

function* bookmarkAgendaSession({ payload: { eventId, sessionId, isBookmark } }) {
  const exhibitor = yield select(getExhibitorInfo);
  const { session_set } = yield select(getProfileInfo);
  try {
    yield put(setIsBookmarkAgendaSession(sessionId, isBookmark));
    yield Object.keys(exhibitor).length > 0 &&
      call(bookmarkExhibitorSession, { id: eventId, sessionId, isBookmark });
    yield session_set && call(bookmarkProfileSession, { id: eventId, sessionId, isBookmark });
    yield call(sessionsAPI.bookmarkSession, eventId, sessionId);
  } catch (error) {
    yield put(setIsBookmarkAgendaSession(sessionId, false));
    console.log(error);
  }
}

function* unBookmarkAgendaSession({ payload: { eventId, sessionId, isBookmark } }) {
  const exhibitor = yield select(getExhibitorInfo);
  const { session_set } = yield select(getProfileInfo);
  try {
    yield put(setIsBookmarkAgendaSession(sessionId, isBookmark));
    yield Object.keys(exhibitor).length > 0 &&
      call(unBookmarkExhibitorSession, { id: eventId, sessionId, isBookmark });
    yield yield session_set &&
      call(unBookmarkProfileSession, { id: eventId, sessionId, isBookmark });
    yield call(sessionsAPI.unBookmarkSession, eventId, sessionId);
  } catch (error) {
    yield put(setIsBookmarkAgendaSession(sessionId, true));
    console.log(error);
  }
}

export function* watchAgendaUpdate() {
  yield takeEvery(AgendaActions.OnPullAgendaData, updateAgenda);
  yield takeEvery(AgendaActions.PullAgendaDataOnBookmarks, updateAgendaOnBookmarks);
  yield takeEvery(AgendaActions.BookmarkSessions, bookmarkAgendaSession);
  yield takeEvery(AgendaActions.UnBookmarkSessions, unBookmarkAgendaSession);
}
