import axios from 'axios';
import { baseURL } from '@/constants/baseURL';
import { sendTokenInterceptor } from '../helpers/interceptors';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

instance.interceptors.request.use(request => {
  return sendTokenInterceptor(request);
});

export const authAPI = {
  signIn(data) {
    return instance.post('users/token/', data);
  },
  resetPassword(data) {
    return instance.post('/users/reset-password/', data);
  },
  changePassword(data) {
    return instance.patch('/users/change-password/', data);
  },
  resetConfirm(token) {
    return instance.get(`/users/reset-confirm/?token=${token}`);
  },
  userInfo() {
    return instance.get('users/info');
  },
  resendTicket(event_pk) {
    return instance.get(`/events/event/${event_pk}/resend-ticket/`);
  },
  emailApprove(event_pk, token) {
    return instance.get(`/events/event/${event_pk}/email-approve/?token=${token}`);
  },
};
