import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { isEqual } from 'deep-equal'
import { history } from '@/store/rootReducer'
import { generateChooseTicketUrl, generateSignInUrl } from '@/constants/routes'
import {
  ContentContainer,
  ContentInfo,
  ContentPlace,
  ContentTitle,
  ContentDate,
  ContentButtons,
  Button,
  ButtonText,
  ContentImage,
} from './styles'


const ContentBlock = ({ event }) => {
  const { id } = useParams()

  const signInRoute = generateSignInUrl(id)
  const chooseTicketRoute = generateChooseTicketUrl(id)

  const goToSignIn = useCallback(() => {
    history.push(signInRoute)
  }, [signInRoute])

  const goToRegistration = useCallback(() => {
    history.push(chooseTicketRoute)
  }, [chooseTicketRoute])

  return (
    <ContentContainer id="sponsors">
      <ContentInfo>
        <ContentPlace dangerouslySetInnerHTML={{ __html: event.strap_line }} />
        <ContentTitle>
          {event.summary}
        </ContentTitle>
        <ContentDate>
          {event.timings}
        </ContentDate>
        <ContentButtons>
          <Button type="login" onClick={goToSignIn}>
            <ButtonText>
              Login
          </ButtonText>
          </Button>
          <Button type="register" onClick={goToRegistration}>
            <ButtonText>
              Register
          </ButtonText>
          </Button>
        </ContentButtons>
      </ContentInfo>
      {event.feature_image && <ContentImage src={event.feature_image} />}
    </ContentContainer>
  )
}

export default React.memo(ContentBlock, isEqual)
