import React from 'react'
import { isEqual } from 'deep-equal'
import firstFeature from '../../images/firstFeature.png'
import secondFeature from '../../images/secondFeature.png'
import thirdFeature from '../../images/thirdFeature.png'
import {
  FeaturesContainer,
  Title,
  OneFeature,
  OneFeatureInfo,
  OneFeatureTitle,
  OneFeatureText,
  OneFeatureImage,
} from './styles'


const Features = props => {
  return (
    <FeaturesContainer id="features">
      <Title>
        Features
      </Title>
      <OneFeature>
        <OneFeatureImage src={firstFeature} alt="" styles={{ marginRight: '45px' }} />
        <OneFeatureInfo>
          <OneFeatureTitle>
            Discover.
        </OneFeatureTitle>
          <OneFeatureText>
            How far have European financial centres, including central
            banks and supervisory authorities taken the UN- backed Green Climate Fund agenda:
            The impact of COVID-19 on sustainable investing and global resilience financing
        </OneFeatureText>
        </OneFeatureInfo>
      </OneFeature>
      <OneFeature reverse>
        <OneFeatureInfo styles={{ marginRight: '45px' }}>
          <OneFeatureTitle>
            Network.
        </OneFeatureTitle>
          <OneFeatureText>
            How far have European financial centres, including central
            banks and supervisory authorities taken the UN- backed Green Climate Fund agenda:
            The impact of COVID-19 on sustainable investing and global resilience financing
        </OneFeatureText>
        </OneFeatureInfo>
        <OneFeatureImage src={secondFeature} alt="" />
      </OneFeature>
      <OneFeature>
        <OneFeatureImage src={thirdFeature} alt="" styles={{ marginRight: '45px' }} />
        <OneFeatureInfo>
          <OneFeatureTitle>
            Interact.
        </OneFeatureTitle>
          <OneFeatureText>
            How far have European financial centres, including central
            banks and supervisory authorities taken the UN- backed Green Climate Fund agenda:
            The impact of COVID-19 on sustainable investing and global resilience financing
        </OneFeatureText>
        </OneFeatureInfo>
      </OneFeature>
    </FeaturesContainer>
  )
}

export default React.memo(Features, isEqual)
