import React from 'react';
import { isEqual } from 'deep-equal';

import { Label } from './styles';

const OnDemandLabel = () => {
  return <Label>On Demand</Label>;
};

export default React.memo(OnDemandLabel, isEqual);
