import { platformInfoActions } from '@/constants/actions/platformInfoActions';

export const setDelegateInfo = data => ({
  type: platformInfoActions.SetUserInfo,
  payload: data,
});

export const setPermissions = data => ({
  type: platformInfoActions.SetPermissions,
  payload: data,
});

export const setIsFetchingData = data => ({
  type: platformInfoActions.SetIsFetchingData,
  payload: data,
});

export const setEventInfo = data => ({
  type: platformInfoActions.SetEventInfo,
  payload: data,
});

export const setLocationPathName = data => ({
  type: platformInfoActions.SetLocationPathName,
  payload: data,
});

//saga actions
export const getPlatformInfo = data => ({
  type: platformInfoActions.GetPlatformInfo,
  payload: data,
});

export const startIsUserOnlinePolling = data => ({
  type: platformInfoActions.StartIsUserOnlinePolling,
  payload: data,
});

export const stopIsUserOnlinePolling = data => ({
  type: platformInfoActions.StopIsUserOnlinePolling,
  payload: data,
});