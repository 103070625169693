import { all, fork } from 'redux-saga/effects';
import { watchRegistration } from './registration/saga';
import { watchSignIn } from './signIn/saga';
import { watchFormValidation } from './checkFormValidation/saga';
import { watchRegistrationDelegate } from './registerDelegate/saga';
import { watchBuyTicket } from './stripe/saga';
import { watchAgendaUpdate } from './agenda/saga';
import { watchRegistrationFormFieldsValues } from './registrationFieldsValues/saga';
import { watchNetworking } from './networkingPage';
import {
  watchAcceptedAndRejectedUsers,
  watchDashboard,
  watchExibitorDataSaga,
  watchStatisticVisitorsSaga,
} from './dashboardPage';
import { watchExpo } from './expoPage';
import { watchIsDelegateCheck } from './isDelegateCheck';
import { watchEventExists } from './eventExists/saga';
import {
  watchPollAllQuestionsSaga,
  watchPollCurrentChatDataSaga,
  watchPollDialogsSaga,
  watchInteractionPanelStuffSaga,
} from './interactionPanel/saga';
import { watchExhibitor } from './exhibitorPage';
import { watchResetPassword } from './resetPassword/saga';
import { watchEventData } from './landing/saga';
import { watchPingIsUserOnline, watchPlatformInfo } from './platformInfo';
import { watchProfilePage } from './profilePage';
import { watchGetLiveSession, watchSessionsInfo } from './sessions';
import { watchGetPolls } from './polls';
import { watchGetBroadcastMessages } from './broadcastMessages';
import { watchNotifications } from './notifications';
import {
  watchBroadcastMessageSaga,
  watchChangeStatusQuestion,
  watchGetEventStatistic,
  watchModeratorPollSaga,
  watchModeratorQuestionsSaga,
  watchStreamStatusSaga,
} from './eventManagementPage/saga';
import { watchDemandPage } from './demandPage';
import { watchLiveStreamPage } from './livestream';
import { watchInactiveUserPage } from './inactiveUserPage';
import { watchEmailApprovePage } from './emailApprovePage';
import { watchEscalatedQuestions, watchPollingEscalatedQuestions } from './escalatedQuestions';
import { watchLinkedinUp } from './linkedinUp';
import { watchTicketPrice } from './checkTicketPrice/saga'
import { watchCheckPromocode } from './promocodeValidation/saga'

export default function* rootSaga() {
  yield all([
    fork(watchRegistration),
    fork(watchBuyTicket),
    fork(watchSignIn),
    fork(watchRegistrationDelegate),
    fork(watchFormValidation),
    fork(watchCheckPromocode),
    fork(watchRegistrationFormFieldsValues),
    fork(watchNetworking),
    fork(watchAgendaUpdate),
    fork(watchDashboard),
    fork(watchExibitorDataSaga),
    fork(watchExpo),
    fork(watchExhibitor),
    fork(watchPollDialogsSaga),
    fork(watchPollCurrentChatDataSaga),
    fork(watchResetPassword),
    fork(watchEventData),
    fork(watchIsDelegateCheck),
    fork(watchEventExists),
    fork(watchStatisticVisitorsSaga),
    fork(watchAcceptedAndRejectedUsers),
    fork(watchPlatformInfo),
    fork(watchProfilePage),
    fork(watchPingIsUserOnline),
    fork(watchSessionsInfo),
    fork(watchGetLiveSession),
    fork(watchGetPolls),
    fork(watchGetBroadcastMessages),
    fork(watchNotifications),
    fork(watchModeratorQuestionsSaga),
    fork(watchChangeStatusQuestion),
    fork(watchGetEventStatistic),
    fork(watchStreamStatusSaga),
    fork(watchBroadcastMessageSaga),
    fork(watchModeratorPollSaga),
    fork(watchDemandPage),
    fork(watchLiveStreamPage),
    fork(watchPollAllQuestionsSaga),
    fork(watchInactiveUserPage),
    fork(watchEmailApprovePage),
    fork(watchInteractionPanelStuffSaga),
    fork(watchLinkedinUp),
    fork(watchEscalatedQuestions),
    fork(watchPollingEscalatedQuestions),
    fork(watchTicketPrice)
  ]);
}
