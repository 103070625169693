export const dashboardActions = {
  GetDelegates: 'dashboard/GET_DELEGATES',
  SetDelegates: 'dashboard/SET_DELEGATES',
  SetIsFetchingDelegates: 'dashboard/SET_IS_FETCHING_DELEGATES',
  AddDelegate: 'dashboard/ADD_DELEGATES',
  RemoveDelegate: 'dashboard/REMOVE_DELEGATES',
  ClearSelectedDelegates: 'dashboard/CLEAR_SELECTED_DELEGATES',
  SetIsBookmarkAcceptedUsers: 'dashboard/SET_IS_BOOKMARK_ACCEPTED_USERS',
  SetIsBookmarkRejectedUsers: 'dashboard/SET_IS_BOOKMARK_REJECTED_USERS',
  BookmarkDashboardDelegate: 'dashboard/BOOKMARK_DASHBOARD_DELEGATE',
  UnBookmarkDashboardDelegate: 'dashboard/UN_BOOKMARK_DASHBOARD_DELEGATE',
  SetIsAcceptUsersModal: 'dashboard/SET_IS_ACCEPT_USER_MODAL',
  SetIsRejectUsersModal: 'dashboard/SET_IS_REJECT_USER_MODAL',
  SetIsFetchingInvitation: 'dashboard/SET_IS_FETCHING_INVITATION',
  SetIsSuccessInvitation: 'dashboard/SET_IS_SUCCESS_INVITATION',
  SetErrorMsg: 'dashboard/SET_ERROR_MSG',

  GetBookmarked: 'dashboard/GET_BOOKMARKED',
  SetBookmarked: 'dashboard/SET_BOOKMARKED',
  SetIsFetchingBookmarked: 'dashboard/SET_IS_FETCHING_BOOKMARKED',
  AddBookmarkeds: 'dashboard/ADD_BOOKMARKEDS',

  SendInvitationDelegates: 'dashboard/SEND_INVITATION_DELEGATES',

  StartExibitorDataPolling: `dashboard/START_EXIBITOR_DATA_POLLING`,
  StopExibitorDataPolling: `dashboard/STOP_EXIBITOR_DATA_POLLING`,
  SetExibitorDataLoading: 'dashboard/SET_EXIBITOR_DATA_LOADING',
  SetExibitorData: `dashboard/SET_EXIBITOR_DATA`,

  GetAcceptedUsers: `dashboard/GET_ACCEPTED_USERS`,
  SetAcceptedUsers: `dashboard/SET_ACCEPTED_USERS`,

  GetRejectedUsers: `dashboard/GET_REJECTED_USERS`,
  SetRejectedUsers: `dashboard/SET_REJECTED_USERS`,

  StartStatisticVisitorsPolling: `dashboard/START_STATISTIC_VISITORS_POLLING`,
  StopStatisticVisitorsPolling: `dashboard/STOP_STATISTIC_VISITORS_POLLING`,
  SetStatisticVisitors: `dashboard/SET_STATISTIC_VISITORS`,

  GetExhibitorStandCsv: 'dashboard/GET_EXIBITOR_STAND_CSV',
  SetExhibitorStandCsv: 'dashboard/SET_EXIBITOR_STAND_CSV',
};
