import styled from 'styled-components';

export const EscalatedQuestionsPageContainer = styled.div`
  height: 100vh;
`;

export const EscalatedQuestionsHeader = styled.div`
  padding: 15px 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EscalatedQuestionsTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.regularText};
`;

export const LogOutButton = styled.div`
  width: 150px;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 110px;
  }
`;

export const EscalatedQuestionsContainer = styled.div`
  width: 100%;
  height: calc(100% - 70px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EscalatedQuestionSection = styled.div`
  width: 40%;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 60%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.portrait.phone}) {
    width: 90%;
  }
`;
