import React from 'react';
import { Author, MessageConainer, Content, ContentBuble, Text } from './styles';
import { getTimeString } from '@/helpers/date';

const Message = ({ text, created_date, isCurrentUserMessage, chatRecipientName }) => {
  return (
    <MessageConainer isCurrentUserMessage={isCurrentUserMessage}>
      <Content>
        <Author isCurrentUserMessage={isCurrentUserMessage}>
          {isCurrentUserMessage ? 'You' : chatRecipientName}
          {created_date && ` - ${getTimeString(created_date)}`}
        </Author>
        <ContentBuble isCurrentUserMessage={isCurrentUserMessage}>
          <Text isCurrentUserMessage={isCurrentUserMessage}>{text}</Text>
        </ContentBuble>
      </Content>
    </MessageConainer>
  );
};

export default Message;
