import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'deep-equal';
import orderBy from 'lodash.orderby';
import {
  getCurrentDelegateSelector,
  getSendingMessagesSelector,
  getProcessedInvitationIdsSelector,
} from '@/store/interactionPanel/selectors';
import Message from '../Message';
import InvitationCard from '../InvitationCard';
import LoadedMessage from '../LoadedMessage';
import { Container } from './styles';

const Messages = ({ messages, invitations, messagesRef }) => {
  const currentDelegate = useSelector(getCurrentDelegateSelector);
  const processedInvitationIds = useSelector(getProcessedInvitationIdsSelector);
  const recipientId = currentDelegate.id || currentDelegate.delegateId;
  const sendingMessages = useSelector(getSendingMessagesSelector);

  useEffect(() => {
    messagesRef.current && messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight);
  }, [sendingMessages, messagesRef]);

  console.log(sendingMessages);
  return (
    <Container ref={messagesRef}>
      {((messages && messages.length > 0) || (invitations && invitations.length > 0)) &&
        orderBy([...messages, ...invitations], ['created_date'], ['asc']).map(item => {
          return item.invitation_info ? (
            <InvitationCard
              key={`${item.invitation_info.id}-invitation`}
              invitation_pk={item.invitation_info.id}
              title="event"
              date={item.invitation_info.time}
              description={item.invitation_info.description}
              link={item.invitation_info.link}
              status={item.status}
              isCurrentUserInvitation={item.recipient === recipientId}
              chatRecipientName={`${currentDelegate.first_name} ${currentDelegate.last_name}`}
              isProcessedInvitation={processedInvitationIds.find(
                id => id === item.invitation_info.id,
              )}
              {...item}
            />
          ) : (
            <Message
              key={`${item.id}-message`}
              isCurrentUserMessage={item.delegate !== recipientId}
              chatRecipientName={`${currentDelegate.first_name} ${currentDelegate.last_name}`}
              {...item}
            />
          );
        })}
      {sendingMessages &&
        sendingMessages.length > 0 &&
        sendingMessages.map((item, i) => (
          <LoadedMessage key={i}>
            <Message isCurrentUserMessage {...item} />
          </LoadedMessage>
        ))}
    </Container>
  );
};

export default React.memo(Messages, isEqual);
