import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Wrapper, LoadingSpinner } from './styles';

const LoadedMessage = ({ children }) => {
  const [isLoadingSpinnerShown, setIsLoadingSpinnerShown] = useState(false);

  useEffect(() => {
    const lodaingTimeOut = setTimeout(() => {
      setIsLoadingSpinnerShown(true);
    }, 1000);

    return () => {
      clearTimeout(lodaingTimeOut);
    };
  }, []);

  return (
    <Wrapper>
      <LoadingSpinner>{isLoadingSpinnerShown && <CircularProgress size={20} />}</LoadingSpinner>
      {children}
    </Wrapper>
  );
};

export default LoadedMessage;
