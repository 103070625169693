import React from 'react';
import { isEqual } from 'deep-equal';
import Agenda from './components/Agenda';
import Broadcast from './components/Broadcast';

import { AdminContainer } from './styles';

const Admin = () => {
  return (
    <AdminContainer>
      <Agenda />
      <Broadcast />
    </AdminContainer>
  );
};

export default React.memo(Admin, isEqual);
