import styled from 'styled-components';

export const Table = styled.div`
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    margin-top: 50px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.originBlack};
  box-sizing: border-box;
  padding: 5px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tabBorder};
`;

export const HeaderItem = styled.div`
  width: 15%;
  margin-left: 5px;
  :first-child {
    width: 20%;
  }
  :nth-child(2) {
    width: 35%;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tabBorder};
  :last-child {
    border-bottom: none;
  }
`;

export const BodyItem = styled.div`
  width: 15%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid ${({ theme }) => theme.colors.tabBorder};
  box-sizing: content-box;
  padding: 5px;

  :first-child {
    border-left: none;
    width: 20%;
  }
  :nth-child(2) {
    width: 35%;
    justify-content: flex-start;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    position: relative;
    font-size: 15px;
    line-height: 1.42857;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }
  :nth-child(4) {
    cursor: pointer;
  }
  :nth-child(5) {
    cursor: pointer;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.portrait.tablet}) {
    box-sizing: border-box;
    overflow: hidden;
    height: 50px;
  }
`;

export const InvitationTitle = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 30px;
    cursor: pointer;
  }
`;
