import styled from 'styled-components';

export const AgendaPageWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const AgendaDateMenuSection = styled.div`
  width: 188px;
  padding-right: 48px;
  margin-top: 30px;
  margin-left: 30px;
  flex-shrink: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.agenda.scrollSpy.font};

  /* Adaptive styles */
  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.landscape.desktopS}) {
    font-size: 16px;
    line-height: 21px;
    width: 121px;
    padding-right: 15px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.maxWidth.landscape.tablet}) {
    display: none;
  }

  /* Active link highlighting */
  ${({ theme, activeClassName }) => `
      & .${activeClassName} {
        color: ${theme.colors.agenda.scrollSpy.activeFont};
      }
    `}

  /* Delimiter styles */
  & li {
    position: relative;
    padding-bottom: 30px;
  }

  & li:after {
    content: '';
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    height: 0;
    width: 20px;
    border-top: 1px solid ${({ theme }) => theme.colors.agenda.scrollSpy.delimiter};
    z-index: -1;
  }

  /* No delimiter under the last item */
  & ul :last-child:after {
    content: none;
  }
`;

export const AgendaDateMenuItem = styled.li``;

export const AgendaSection = styled.div`
  margin-left: 30px;
  padding-right: 30px;
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  position: relative;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;

  &:hover {
    color: inherit;
  }
  &:active {
      color: ${({ theme }) => theme.colors.regularText};
  }
`;
