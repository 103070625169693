import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPanelTab } from '@/store/interactionPanel/actions';
import {
  getcurrentPanelTabSelector,
  getIsDialogOpenSelector,
} from '@/store/interactionPanel/selectors';
import { isEqual } from 'deep-equal';
import { InteractionContainer, TabsContainer, BodyContainer, Tab } from './styles';

import Messages from './components/Messages';
import Bookmarks from './components/Bookmarks';
import Questions from './components/Questions';
import Chat from '@/components/blocks/Chat';
import Admin from './components/Admin';
import { getPermissionsInfo } from '@/store/platformInfo';

const InteractionPanel = () => {
  const dispatch = useDispatch();
  const isDialogOpen = useSelector(getIsDialogOpenSelector);
  const currentTab = useSelector(getcurrentPanelTabSelector);
  const { is_admin: isAdmin } = useSelector(getPermissionsInfo);

  const handleSetCurrentTab = tab => {
    dispatch(setCurrentPanelTab(tab));
  };

  return (
    <InteractionContainer>
      <TabsContainer>
        <Tab onClick={() => handleSetCurrentTab('messages')} active={currentTab === 'messages'}>
          Messages
        </Tab>
        <Tab onClick={() => handleSetCurrentTab('bookmarks')} active={currentTab === 'bookmarks'}>
          Bookmarks
        </Tab>
        <Tab onClick={() => handleSetCurrentTab('qa')} active={currentTab === 'qa'}>
          Q&amp;A
        </Tab>
        {isAdmin && (
          <Tab onClick={() => handleSetCurrentTab('admin')} active={currentTab === 'admin'}>
            Admin
          </Tab>
        )}
      </TabsContainer>
      <BodyContainer>
        {currentTab === 'messages' && (!isDialogOpen ? <Messages /> : <Chat />)}
        {currentTab === 'bookmarks' && <Bookmarks />}
        {currentTab === 'qa' && <Questions />}
        {currentTab === 'admin' && <Admin />}
      </BodyContainer>
    </InteractionContainer>
  );
};

export default React.memo(InteractionPanel, isEqual);
