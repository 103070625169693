import { emailApproveActions } from '@/constants/actions/emailApproveActions';

const initialState = {
  isEmailApprove: false,
  emailApproveErrMsg: '',
};

export const emailApprovePage = (state = initialState, action) => {
  switch (action.type) {
    case emailApproveActions.SetIsEmailApprove:
      return {
        ...state,
        isEmailApprove: action.payload,
      };
    case emailApproveActions.SetEmailApproveErrMsg:
      return {
        ...state,
        emailApproveErrMsg: action.payload,
      };
    default:
      return state;
  }
};
