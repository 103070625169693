import { createSelector } from 'reselect';

export const getExhibitorPageValues = state => state.exhibitorPage;

export const getExhibitorInfo = createSelector([getExhibitorPageValues], state => {
    return state.exhibitor;
});

export const getIsLoading = createSelector([getExhibitorPageValues], state => {
    return state.isLoading;
});

export const getErrDescriptionMsgInfo = createSelector([getExhibitorPageValues], state => {
    return state.errDescriptionMsg;
});

export const getIsFetchingExhibitor = createSelector([getExhibitorPageValues], state => {
    return state.isFetchingExhibitor;
});

export const getIsInactiveStand = createSelector([getExhibitorPageValues], state => {
    return state.isInactiveStand;
});

export const getIsActiveEditHeader = createSelector([getExhibitorPageValues], state => {
    return state.isActiveEditHeader;
});

export const getIsActiveEditDescription = createSelector([getExhibitorPageValues], state => {
    return state.isActiveEditDescription;
});