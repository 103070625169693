import { createSelector } from 'reselect';

export const getInactiveUserPageInfo = state => state.inactiveUserPage;

export const getIsLoadingResend = createSelector([getInactiveUserPageInfo], state => {
  return state.isLoadingResend;
});

export const getIsEmailResend = createSelector([getInactiveUserPageInfo], state => {
  return state.isEmailResend;
});
