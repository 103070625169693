import React from 'react';
import { isEqual } from 'deep-equal';
import { Popover } from 'antd';
import { IconSection, WrapperSection } from './styles';
import ShareMenu from './components/ShareMenu';

const ShareIcon = ({ dayID, sessionID, status, agendaRef }) => {
  const menuContent = ShareMenu({ dayID, sessionID });
  return (
    <>
      <WrapperSection>
        <Popover placement="bottomRight" content={menuContent} arrowPointAtCenter trigger="click" getPopupContainer={() => agendaRef.current}>
          <IconSection xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 19" status={status}>
            <path d="M5.5 13.75C7.57992 4.69242 15.5833 3.05525 15.5833 3.05525V0L22 6.08392L15.5833 12.2219V9.16667C15.5833 9.16667 9.9275 9.00992 5.5 13.75ZM16.5 13.8829V16.5H1.83333V5.5H7.8815C8.5855 4.77858 9.31242 4.17175 10.0256 3.66667H0V18.3333H18.3333V12.1284L16.5 13.8829Z" />
          </IconSection>
        </Popover>
      </WrapperSection>
    </>
  );
};

export default React.memo(ShareIcon, isEqual);
