import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash.isequal';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getInteractionPanelValues = state => state.interactionPanel;

export const getcurrentPanelTabSelector = createSelector([getInteractionPanelValues], state => {
  return state.currentPanelTab;
});

export const getDialogsSelector = createDeepEqualSelector([getInteractionPanelValues], state => {
  return state.dialogs;
});

export const getCurrentChatDataSelector = createDeepEqualSelector(
  [getInteractionPanelValues],
  state => {
    return state.currentChatData;
  },
);

export const getCurrentDialogIdSelector = createSelector([getInteractionPanelValues], state => {
  return state.currentDialogId;
});

export const getIsDialogOpenSelector = createSelector([getInteractionPanelValues], state => {
  return state.isDialogOpen;
});

export const getAreDialogsLoadingSelector = createSelector([getInteractionPanelValues], state => {
  return state.areDialogsLoading;
});

export const getAreMessagesLoadingSelector = createSelector([getInteractionPanelValues], state => {
  return state.areMessagesLoading;
});

export const getFavoriteDelegates = createSelector([getInteractionPanelValues], state => {
  return state.favoriteDelegates;
});

export const getCurrentDelegateSelector = createDeepEqualSelector(
  [getInteractionPanelValues],
  state => {
    return state.currentDelegate;
  },
);

export const getFavoriteDelegatesSelector = createSelector([getInteractionPanelValues], state => {
  return state.favoriteDelegates;
});

export const getFavoriteSessionsSelector = createSelector([getInteractionPanelValues], state => {
  return state.favoriteSessions;
});

export const getFavoriteExhibitorsSelector = createSelector([getInteractionPanelValues], state => {
  return state.favoriteExhibitors;
});

export const getAreBookmarksLoadingSelector = createSelector([getInteractionPanelValues], state => {
  return state.areBookmarksLoading;
});

export const getCurrentChatSelector = createDeepEqualSelector(
  [getInteractionPanelValues],
  state => {
    return state.currentChat;
  },
);

export const getSendingMessagesSelector = createDeepEqualSelector(
  [getInteractionPanelValues],
  state => {
    return state.sendingMessages[state.currentDelegate.id];
  },
);

export const getInvitationsSelectorSelector = createSelector([getInteractionPanelValues], state => {
  return state.invitations;
});

export const getAllQuestionsSelector = createSelector([getInteractionPanelValues], state => {
  return [
    ...state.questions,
    ...state.userNonActiveQuestions.map(item => {
      return { ...item, isCurrentlyModerated: true };
    }),
  ];
});

export const getAreQuestionsLoadingSelector = createSelector([getInteractionPanelValues], state => {
  return state.areQuestionsLoading;
});

export const getSendingQuestionsSelector = createSelector([getInteractionPanelValues], state => {
  return state.sendingQuestions;
});

export const getProcessedInvitationIdsSelector = createSelector(
  [getInteractionPanelValues],
  state => {
    return state.processedInvitationIds;
  },
);

export const getIsChatBeingCreated = createSelector([getInteractionPanelValues], state => {
  return state.isChatBeingCreated;
});

export const getIsBlockPuttingChatData = createSelector([getInteractionPanelValues], state => {
  return state.isBlockPuttingChatData;
});
