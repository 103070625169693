import { createSelector } from 'reselect';

export const getPlatformInfoValues = state => state.platformInfo;

export const getDelegateInfo = createSelector([getPlatformInfoValues], state => {
    return state.delegateInfo;
});

export const getEventInfo = createSelector([getPlatformInfoValues], state => {
    return state.eventInfo;
});

export const getPermissionsInfo = createSelector([getPlatformInfoValues], state => {
    return state.permissions;
});

export const getLocationPathname = createSelector([getPlatformInfoValues], state => {
    return state.locationPathname;
});

export const getIsFetchingDataInfo = createSelector([getPlatformInfoValues], state => {
    return state.isFetchingData;
});