import React, { useCallback } from 'react';
import { isEqual } from 'deep-equal';
import { format } from 'date-fns';
import QACard from '../QACard';

const PendingSection = ({ moderatorQuestions }) => {
  const dateParcer = useCallback(date => {
    let parseDate = new Date(JSON.parse(`"${date}"`));
    return format(parseDate, 'HH:mm');
  }, []);

  return (
    <>
      {moderatorQuestions.map((item, index) => (
        <QACard
          key={index}
          id={item.id}
          time={dateParcer(item.created_date)}
          text={item.question}
          approve={true}
          reject={true}
          delegate={item.delegate}
        />
      ))}
    </>
  );
};
export default React.memo(PendingSection, isEqual);
