import React, { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'deep-equal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import GenericButton from '@/components/controls/buttons/GenericButton';
import ContactCard from '@/components/blocks/ContactCard';
import Preloader from '@/components/blocks/Preloader';
import { escalatedIco, escalatedActiveIco } from './icons/index';
import {
  approveQuestion,
  rejectQuestion,
  restoreQuestion,
} from '@/store/eventManagementPage/actions';
import {
  QACardContainer,
  Time,
  InfoBlock,
  Text,
  ButtonsContainer,
  ContactCardWrapper,
  Escalated,
} from './styles';

const QACard = ({ time, text, approve, reject, cleared, escalate, isEscalated, delegate, id }) => {
  const createFullName = `${delegate.first_name} ${delegate.last_name}`;
  const approveData = { status: 'approved' };
  const rejectData = { status: 'rejected' };
  const restoreData = { invisible: false };

  const dispatch = useDispatch();
  const { id: eventPk } = useParams();

  const [approveLoading, setApproveLoading] = useState({ [id]: false });
  const [rejectLoading, setRejectLoading] = useState({ [id]: false });
  const [restoreLoading, setRestoreLoading] = useState({ [id]: false });
  const [escolatedSatte, isEscolatedState] = useState({ [id]: isEscalated });

  const approveHandler = useCallback(() => {
    setApproveLoading({ [id]: true });
    dispatch(approveQuestion(eventPk, id, approveData));
  }, [eventPk, id]);

  const rejectHandler = useCallback(() => {
    setRejectLoading({ [id]: true });
    dispatch(rejectQuestion(eventPk, id, rejectData));
  }, [eventPk, id]);

  const restoreHandler = useCallback(() => {
    setRestoreLoading({ [id]: true });
    dispatch(restoreQuestion(eventPk, id, restoreData));
  }, [eventPk, id]);

  const escalateHandler = useCallback(() => {
    setApproveLoading({ [id]: true });
    dispatch(approveQuestion(eventPk, id, { is_escalated: !escolatedSatte[id] }));
  }, [eventPk, id, escolatedSatte]);

  useEffect(() => {
    setApproveLoading({ [id]: false });
    isEscolatedState({ [id]: isEscalated });
  }, [isEscalated, eventPk, id]);

  return (
    <QACardContainer>
      <Time>{time}</Time>
      <InfoBlock>
        <Text>{text}</Text>
        <ButtonsContainer>
          {approveLoading[id] || rejectLoading[id] || restoreLoading[id] ? (
            <Preloader />
          ) : (
            <>
              {approve && (
                <GenericButton
                  label="Approve"
                  type="submit"
                  styleType="outline"
                  color="green"
                  onClick={approveHandler}
                />
              )}
              {reject && (
                <GenericButton
                  label="Reject"
                  type="submit"
                  styleType="outline"
                  color="red"
                  onClick={rejectHandler}
                />
              )}
              {cleared && (
                <GenericButton
                  label="Restore"
                  type="submit"
                  styleType="outline"
                  color="grey"
                  onClick={restoreHandler}
                />
              )}
              {escalate && (
                <Escalated onClick={escalateHandler}>
                  {isEscalated ? escalatedActiveIco : escalatedIco}
                </Escalated>
              )}
            </>
          )}
        </ButtonsContainer>
      </InfoBlock>
      <ContactCardWrapper>
        <ContactCard
          src={delegate.headshot}
          name={createFullName}
          organisation={delegate.organisation}
          jobRole={delegate.role}
        />
      </ContactCardWrapper>
    </QACardContainer>
  );
};
export default React.memo(QACard, isEqual);
