import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  margin-bottom: 15px;
  cursor: pointer;
`;
export const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 15px;
  cursor: pointer;
`;
export const Label = styled.div`
  position: relative;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  text-decoration-line: ${({ active }) => active ? 'none' : 'underline'};
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  :after {
    content: '';
    display: block;
    min-width: 1500px;
    position: absolute;
    border-bottom: 1px solid ${({ theme }) => theme.colors.tabBorder};
    top: 50%;
    left: 120%;
  }
`;
