import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Input = styled.input.attrs(({ placeholder }) => ({
  placeholder,
}))`
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  margin-top: 10px;
  background: ${({ theme }) => theme.colors.originWhite};
  border: 1px solid ${({ theme }) => theme.colors.logoBorder};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px ${({ theme }) => theme.colors.backgroundDownloadCard};
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
  caret-color: ${({ theme }) => theme.colors.boulder};

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.currentUserMessageBackground};
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 3px rgba(37, 123, 222, 0.2);
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.backgroundDownloadCard};
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
    cursor: not-allowed;
  }

  &::placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  }

  &:disabled::placeholder {
    font-style: normal;
  }
`;
export const Label = styled.label`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;

  color: ${({ theme }) => theme.colors.textDescriptionGrey};
`;
