import { combineReducers } from 'redux';
import { registration } from './registration/reducer';
import { registrationFieldValues } from './registrationFieldsValues/reducer';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { loginPageReducer } from './signIn/reducer';
import { spinner } from './spinner/reducer';
import { stripe } from './stripe/reducer';
import { agenda } from './agenda/reducer';
import { networkingPage } from './networkingPage/reducer';
import { dashboardPage } from './dashboardPage/reducer';
import { interactionPanel } from './interactionPanel/reducer';
import { expoPage } from './expoPage/reducer';
import { exhibitorPage } from './exhibitorPage/reducer';
import { resetPassword } from './resetPassword/reducer';
import { landingData } from './landing/reducer';
import { isDelegateReducer } from './isDelegateCheck/reducer';
import { eventExistsReducer } from './eventExists/reducer';
import { platformInfo } from './platformInfo/reducer';
import { profilePage } from './profilePage/reducer';
import { sessionsInfo } from './sessions/reducer';
import { polls } from './polls/reducer';
import { broadcastMessages } from './broadcastMessages/reducer';
import { notifications } from './notifications/reducer';
import { eventManagementPage } from './eventManagementPage/reducer';
import { demand } from './demandPage/reducer';
import { livestream } from './livestream/reducer';
import { checkFormValidation } from './checkFormValidation/reducer';
import { registerDelegate } from './registerDelegate/reducer';
import { inactiveUserPage } from './inactiveUserPage/reducer';
import { emailApprovePage } from './emailApprovePage/reducer';
import { escalatedQuestions } from './escalatedQuestions/reducer';
import { promocode } from './promocodeValidation/reducer';

export const history = createBrowserHistory();

export default combineReducers({
  router: connectRouter(history),
  registration,
  registrationFieldValues,
  loginPageReducer,
  spinner,
  stripe,
  networkingPage,
  agenda,
  dashboardPage,
  expoPage,
  exhibitorPage,
  resetPassword,
  interactionPanel,
  isDelegateReducer,
  eventExistsReducer,
  landingData,
  platformInfo,
  profilePage,
  sessionsInfo,
  polls,
  broadcastMessages,
  notifications,
  eventManagementPage,
  demand,
  livestream,
  checkFormValidation,
  registerDelegate,
  inactiveUserPage,
  emailApprovePage,
  escalatedQuestions,
  promocode,
});
