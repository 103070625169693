import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.tabBorder};
  border-radius: 4px;
  > a {
    border: none;
    &:hover {
      border: none;
      cursor: default;
    }
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 400px;
  }
`;

export const BadgeWrapper = styled.div`
  top: 25px;
  right: 10px;
  margin-right: 15px;
`;
