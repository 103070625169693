import React, { useCallback } from 'react';
import { isEqual } from 'deep-equal';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '@/components/blocks/Modal';
import TextInput from '@/components/controls/TextInput';
import SelectDelegates from './SelectDelegates';
import GenericButton from '@/components/controls/buttons/GenericButton';
import { DatePicker } from './DatePicker';
import { TimePicker } from './TimePicker';

import {
  InvitedUsersContainer,
  Title,
  DateContainer,
  InputWrapper,
  DateWrapper,
  TimeWrapper,
  CloseTitleIcon,
  SuccessMsgSpan, InvitationErrorMsg,
} from './styles';
import {
  getSelectedDelegates,
  sendInvitationDelegates,
  clearSelectedDelegates,
  getIsFetchingInvitation,
  getIsSuccessInvitation,
  setIsSuccessInvitation,
  getErrorMsg,
} from '@/store/dashboardPage';
import { invitedUsersSchema } from '@/components/controls/validationSchemas/invitedUsersSchema';
import { closeIcon } from './icons';

const InvitationCardModal = ({ hideModal }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const selectedDelegates = useSelector(getSelectedDelegates);
  const isFetchingInvitation = useSelector(getIsFetchingInvitation);
  const isSuccessInvitation = useSelector(getIsSuccessInvitation);
  const errorMsg = useSelector(getErrorMsg);

  const hideModalHandler = useCallback(() => {
    hideModal();
    dispatch(clearSelectedDelegates());
  }, []);

  if (isSuccessInvitation) {
    setTimeout(() => {
      dispatch(setIsSuccessInvitation(false));
      hideModalHandler();
    }, 3000);
  }

  const onSubmit = useCallback(
    event => {
      const time = `${event.date.toDateString()} ${event.time.toTimeString()}`;
      const idDelegates = selectedDelegates.map(i => ({ recipient: i.id }));

      const invitation = {
        link: event.meetingLink,
        time: new Date(time),
        meeting_title: event.meetingDescription,
        description: event.description,
        delegates_set: idDelegates,
      };
      !!selectedDelegates[0] && dispatch(sendInvitationDelegates(invitation, id));
    },
    [selectedDelegates, dispatch],
  );

  return (
    <Modal adaptiveSize={true}>
      <InvitedUsersContainer>
        <Title>
          <span />
          <span>Invitation card builder</span>
          <CloseTitleIcon onClick={hideModalHandler}>{closeIcon}</CloseTitleIcon>
        </Title>
        <Formik
          initialValues={{
            meetingDescription: '',
            description: '',
            meetingLink: '',
            date: '',
            time: '',
          }}
          validationSchema={invitedUsersSchema}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <InputWrapper>
                <Field name="meetingDescription">
                  {({ field, form }) => (
                    <TextInput
                      {...field}
                      form={form}
                      placeholder="Type in the title or short description of your meeting"
                      label="Meeting title"
                    />
                  )}
                </Field>
              </InputWrapper>
              <InputWrapper>
                <Field name="description">
                  {({ field, form }) => (
                    <TextInput
                      {...field}
                      form={form}
                      placeholder="Type in the title or short description of your meeting"
                      label="Description"
                    />
                  )}
                </Field>
              </InputWrapper>
              <InputWrapper>
                <Field name="meetingLink">
                  {({ field, form }) => (
                    <TextInput
                      {...field}
                      form={form}
                      placeholder="Paste a Zoom, Google Meets or some other meeting link"
                      label="Meeting Url "
                    />
                  )}
                </Field>
              </InputWrapper>
              <DateContainer>
                <DateWrapper>
                  <Field name="date">
                    {({ field, form }) => (
                      <DatePicker
                        {...field}
                        form={form}
                        placeholder="Pick meeting date"
                        label="Date"
                      />
                    )}
                  </Field>
                </DateWrapper>
                <TimeWrapper>
                  <Field name="time">
                    {({ field, form }) => (
                      <TimePicker
                        {...field}
                        form={form}
                        placeholder="indicate meeting time"
                        label="Time"
                      />
                    )}
                  </Field>
                </TimeWrapper>
              </DateContainer>
              <SelectDelegates />
              {errorMsg && <InvitationErrorMsg>
                {errorMsg}
              </InvitationErrorMsg>}
              {isSuccessInvitation ? (
                <SuccessMsgSpan>Invitations have been sent</SuccessMsgSpan>
              ) : (
                <GenericButton
                  isLoading={isFetchingInvitation}
                  label="Send"
                  type="submit"
                  color={!selectedDelegates[0] ? 'grey' : 'green'}
                  disabled={!selectedDelegates[0]}
                />
              )}
            </Form>
          )}
        </Formik>
      </InvitedUsersContainer>
    </Modal>
  );
};

export default React.memo(InvitationCardModal, isEqual);
