import { broadcastMessagesActions } from '@/constants/actions/broadcastMessagesActions';

export const setBroadcastMessages = data => ({
  type: broadcastMessagesActions.SetBroadcastMessages,
  payload: data,
});

//saga actions
export const startBroadcastMessagesPolling = (data) => ({
  type: broadcastMessagesActions.StartBroadcastMessagesPolling,
  payload: data
});

export const stopBroadcastMessagesPolling = (data) => ({
  type: broadcastMessagesActions.StopBroadcastMessagesPolling,
  payload: data
});
