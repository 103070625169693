import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

export const EmailApproveMessage = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;

  text-align: center;
  color: ${({ theme }) => theme.colors.regularText};
`;
