import styled from 'styled-components';

export const Button = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  color: ${({ theme, color, disabled }) => (disabled ? theme.colors.disabledStuff : color)};
  font-size: 15px;
  font-weight: 700;
  line-height: 30px;
  text-transform: capitalize;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  & svg {
    margin-right: 14px;
    height: 24px;
    width: 24px;
    fill: ${({ theme, color, disabled }) => (disabled ? theme.colors.disabledStuff : color)};
  }
`;
