import styled from 'styled-components'
import Scrollspy from 'react-scrollspy'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'

export const HeaderContainer = styled.div`
  position: sticky;
  z-index: 20;
  top: 0;
  overflow: auto;
  background: #fff;
  display: flex;
  justify-content: center;
  width: 1260px;
  padding-top: 50px;
  padding-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 1024px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
  }
`

export const HeaderContent = styled.div` 
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; 
  width: 1140px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 924px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
    margin: 0px 20px;
  }
`

export const Logo = styled.img`
  height: 65px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    height: 50px;
  }
`

export const MenuContainer = styled(Scrollspy)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 160px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    margin-left: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    display: none;
  }
`

export const SmallMenuContainer = styled(Scrollspy)`
  width: 100%;
  margin-top: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    display: flex!important;
    flex-direction: column;
    align-items: center;
  }
`

export const SmallMenuButton = styled(Button)`
  display: none!important;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    display: block!important;
    align-items: center!important;
  }
`

export const SmallMenu = styled(Menu)`
  .MuiMenu-paper {
    top: 50px!important;
    right: 20px
  }
`

export const SmallMenuIten = styled(MenuItem)`
  width: 100%!important;
  display: flex!important;
  justify-content: center!important;
`

export const MenuLink = styled.a`
  margin-right: 30px;
  color: #52595E;
  font-size: 15px;
  line-height: 20px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    margin-left: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
    justify-content: center;
  }
`

export const RegButton = styled.button`
  color:${(props) => props.type === 'login' ? '#00806C' : '#FFFFFF'};
  background:${(props) => props.type === 'login' ? '#E6FBF8' : '#00967F'};
  margin-left: ${(props) => props.type === 'login' ? '0px' : '15px'};
  height: 40px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`

export const ButtonText = styled.p`
  font-size: 15px;
  line-height: 30px;
  margin: 0 30px;
`

