import { platformInfoActions } from '@/constants/actions/platformInfoActions';

const initialState = {
  delegateInfo: {},
  permissions: {},
  isFetchingData: true,
  eventInfo: {},
  locationPathname: ''
};

export const platformInfo = (state = initialState, action) => {
  switch (action.type) {
    case platformInfoActions.SetUserInfo:
      return {
        ...state,
        delegateInfo: action.payload,
      };
    case platformInfoActions.SetEventInfo:
      return {
        ...state,
        eventInfo: action.payload,
      };
    case platformInfoActions.SetPermissions:
      return {
        ...state,
        permissions: action.payload,
      };
    case platformInfoActions.SetIsFetchingData:
      return {
        ...state,
        isFetchingData: action.payload,
      };
    case platformInfoActions.SetLocationPathName:
      return {
        ...state,
        locationPathname: action.payload,
      };
    default:
      return state;
  }
};
