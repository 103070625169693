import { exhibitorPageActions } from '@/constants/actions/exhibitorPageActions';

export const setExhibitor = data => ({
  type: exhibitorPageActions.SetExhibitor,
  payload: data,
});

export const setErrorDescriptionMsg = data => ({
  type: exhibitorPageActions.SetErrorDescriptionMsg,
  payload: data,
});

export const setIsFetchingExhibitor = data => ({
  type: exhibitorPageActions.SetIsFetchingExhibitor,
  payload: data,
});

export const setIsInactiveStand = data => ({
  type: exhibitorPageActions.SetIsInactiveStand,
  payload: data,
});

export const setDescriptionVideo = data => ({
  type: exhibitorPageActions.SetDescriptionVideo,
  payload: data,
});

export const setIsActiveEditHeader = data => ({
  type: exhibitorPageActions.SetIsActiveEditHeader,
  payload: data,
});

export const setIsActiveEditDescription = data => ({
  type: exhibitorPageActions.SetIsActiveEditDescription,
  payload: data,
});

export const setStandVideo = data => ({
  type: exhibitorPageActions.SetStandVideo,
  payload: data,
});

export const setStandImage = data => ({
  type: exhibitorPageActions.SetStandImage,
  payload: data,
});

export const setIsBookmarkExhibitorSession = (sessionId, isBookmark) => ({
  type: exhibitorPageActions.SetIsBookmarkSession,
  sessionId,
  isBookmark,
});

export const setIsBookmarkExhibitor = data => ({
  type: exhibitorPageActions.SetIsBookmarkExhibitor,
  payload: data,
});

export const setIsLoading = data => ({
  type: exhibitorPageActions.SetIsLoading,
  payload: data,
});

//saga actions
export const getExhibitor = data => ({
  type: exhibitorPageActions.GetExhibitor,
  payload: data,
});

export const updateDescriptionVideo = data => ({
  type: exhibitorPageActions.updateDescriptionVideo,
  payload: data,
});

export const updateVideo = data => ({
  type: exhibitorPageActions.UpdateVideo,
  payload: data,
});

export const updateImage = data => ({
  type: exhibitorPageActions.UpdateImage,
  payload: data,
});

export const bookmarkExhibitorSession = data => ({
  type: exhibitorPageActions.BookmarkSession,
  payload: data,
});

export const unBookmarkExhibitorSession = data => ({
  type: exhibitorPageActions.UnBookmarkSession,
  payload: data,
});

export const bookmarkExhibitor = data => ({
  type: exhibitorPageActions.BookmarkExhibitor,
  payload: data,
});

export const unBookmarkExhibitor = data => ({
  type: exhibitorPageActions.UnBookmarkExhibitor,
  payload: data,
});

export const addResources = (event_pk, exhibitorId, data) => ({
  type: exhibitorPageActions.AddResources,
  payload: { event_pk, exhibitorId, data },
});

export const removeResources = (event_pk, exhib_pk, id) => ({
  type: exhibitorPageActions.RemoveResources,
  payload: { event_pk, exhib_pk, id },
});

export const downloadExhibitorDoc = (event_pk, exhib_pk, id) => ({
  type: exhibitorPageActions.DownloadExhibitorDoc,
  payload: { event_pk, exhib_pk, id },
});
