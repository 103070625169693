import React, { useEffect } from 'react';
import { isEqual } from 'deep-equal';
import {
  ChangePasswordContainer,
  ChangePasswordFormContainer,
  ChangePasswordHeader,
  ChangePasswordWrapper,
  LogoWrapper,
} from './styles';
import Logo from '../../controls/Logo';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ChangePasswordForm from '../../forms/ChangePasswordForm/component';
import { useDispatch } from 'react-redux';
import { resetConfirm } from '@/store/resetPassword/actions';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { email, event, token } = queryString.parse(location.search);

  useEffect(() => {
    dispatch(resetConfirm(token));
  }, []);
  return (
    <ChangePasswordContainer>
      <ChangePasswordWrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <ChangePasswordHeader>
          Create new password for <span>{email}</span>
        </ChangePasswordHeader>
        <ChangePasswordFormContainer>
          <ChangePasswordForm accessToken={token} eventId={event} />
        </ChangePasswordFormContainer>
      </ChangePasswordWrapper>
    </ChangePasswordContainer>
  );
};

export default React.memo(ChangePassword, isEqual);
