import { days, months } from '@/constants/date';

export const getDate = datetime => new Date(datetime);

export const getDateString = (datetime, utc=false) => {
  const date = new Date(datetime);
  return utc 
    ? `${days[date.getUTCDay()]} ${months[date.getUTCMonth()]} ${date.getUTCDate()}, ${date.getUTCFullYear()}`
    : `${days[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};
  

export const getTimeString = datetime => {
  const date = new Date(datetime);
  return date.toLocaleTimeString().replace(/(.*)\D\d+/, '$1');
};

export const getDateTimeString = datetime => `${getDateString(datetime)} ${getTimeString(datetime)}`;

export const isSameDay = (start, end) => {
  const [dateStart, dateEnd] = [new Date(start), new Date(end)];
  return dateStart.setHours(0, 0, 0, 0) === dateEnd.setHours(0, 0, 0, 0);
};

export const getTimeIntervalString = (start, end) => 
  `${getTimeString(start)} - ${getTimeString(end)}`;

export const getDateTimeIntervalString = (start, end) => {
  return `${getDateTimeString(start)} - ${isSameDay(start, end) ? getTimeString(end) : getDateTimeString(end)}`;
}