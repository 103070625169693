import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'deep-equal';
import ResultsModal from '../ResultsModal/component';
import RadioButtonColumn from '../../../RadioButtonColumn';
import { patchModeratorPoll } from '@/store/eventManagementPage/actions';
import { PollCardContainer, TextContainer, TextTitle, TextResults } from './styles';

const PoollCard = ({ data, session }) => {
  const dispatch = useDispatch();
  const { id, question, status, options_set } = data;
  const isActive = status === 'active' ? true : false;
  const [loading, setLoading] = useState({ [id]: false });
  const [showModal, setShowModal] = useState(false);

  const dataPatchModeratorPoll =
    status === 'active'
      ? {
          status: 'inactive',
        }
      : {
          status: 'active',
        };

  const switchButtonHandler = useCallback(() => {
    setLoading({ [id]: true });
    dispatch(patchModeratorPoll(session, id, dataPatchModeratorPoll));
  }, [dispatch, session, id, dataPatchModeratorPoll]);

  useEffect(() => {
    setLoading({ [id]: false });
  }, [isActive]);

  const handleShowModal = useCallback(() => {
    setShowModal(prev => !prev);
  }, [showModal]);

  return showModal ? (
    <ResultsModal id={id} onClick={handleShowModal} question={question} options={options_set} />
  ) : (
    <PollCardContainer>
      <TextContainer>
        <TextTitle>{question}</TextTitle>
        <TextResults onClick={handleShowModal}>see results</TextResults>
      </TextContainer>
      <RadioButtonColumn
        textActive="Active"
        textInactive="Inactive"
        isActive={isActive}
        isLoading={loading[id]}
        onClick={switchButtonHandler}
      />
    </PollCardContainer>
  );
};
export default React.memo(PoollCard, isEqual);
