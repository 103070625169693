import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(219, 226, 235, 0.15);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px 30px;
  > div {
    :first-child {
      margin-bottom: 40px;
    }
    margin-bottom: 25px;
  }
`;

export const PollTitle = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.originBlack};
  margin-bottom: 40px;
`;

export const PollCardWrapper = styled.div`
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: 10px;
  height: 812px;
  > div {
    margin-bottom: 25px;
  }
`;
