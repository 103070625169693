export const escalatedQuestionsActions = {
  SetEscalatedQuestions: 'escalatedQuestions/SET_ESCALATED_QUESTIONS',
  SetIsFetchingQuestions: 'escalatedQuestions/SET_IS_FETCHING_QUESTIONS',
  GetEscalatedQuestions: 'escalatedQuestions/GET_ESCALATED_QUESTIONS',
  SignInChair: 'escalatedQuestions/SIGN_IN_CHAIR',
  SetEscalatedSignInErrMsg: 'escalatedQuestions/SET_ESCALATED_SIGN_IN_ERR_MSG',
  SetIsLoadingSignIn: 'escalatedQuestions/SET_IS_LOADING_SIGN_IN',
  StartGetEscalatedQuestionsPolling: 'escalatedQuestions/START_GET_ESCALATED_QUESTIONS_POLLING',
  StopGetEscalatedQuestionsPolling: 'escalatedQuestions/STOP_GET_ESCALATED_QUESTIONS_POLLING',
};
