import React from 'react';
import { isEqual } from 'deep-equal';
import {
  BookmarkSection,
  MicroAgendaSection,
  NowLiveSection,
  NowLiveSpan,
  PanelDiscussion,
  PanelMain,
  PanelSpan,
  UpNextSection,
  UpNextSpan,
  PanelInformation,
  OrganisationLogo,
  OrganisationName,
  BookmarkIcon,
} from './styles';
import ellipseIcon from './icons/ellipseIcon.svg';
import ellipseLiveIcon from './icons/ellipseLiveIcon.svg';
import { timeParserToString } from '../../../helpers';
import { useDispatch } from 'react-redux';
import { bookmarkAgendaSessions, unBookmarkAgendaSessions } from '@/store/agenda';

const MicroAgenda = ({
  id,
  eventId,
  title,
  isWithSpeakers,
  speakers,
  isUpNext,
  isNowLive,
  isOrganisation,
  start_time,
  end_time,
  is_bookmark,
  isOnLivestream,
  status,
}) => {
  const dispatch = useDispatch();
  const colorSchema = isNowLive ? 'live' : 'regular';

  const onIsBookmark = () => {
    if (is_bookmark) {
      dispatch(unBookmarkAgendaSessions({ eventId, sessionId: id, isBookmark: false }));
    } else {
      dispatch(bookmarkAgendaSessions({ eventId, sessionId: id, isBookmark: true }));
    }
  };

  return (
    <MicroAgendaSection isNowLive={isNowLive} isWithSpeakers={isWithSpeakers}>
      <PanelMain>
        {isOrganisation ? (
          <PanelInformation>
            <OrganisationLogo></OrganisationLogo>
            <OrganisationName>EDF Energy</OrganisationName>
          </PanelInformation>
        ) : (
          <PanelDiscussion>
            <PanelSpan></PanelSpan>
            <PanelSpan isNowLive={isNowLive}>{title}</PanelSpan>
            <PanelSpan isNowLive={isNowLive}>
              {timeParserToString(start_time, end_time, isOnLivestream)}
            </PanelSpan>
            {isWithSpeakers && speakers?.length && (
              <PanelSpan isNowLive={isNowLive}>
                {speakers.map(s => `${s.first_name} ${s.last_name}`).join(', ')}
              </PanelSpan>
            )}
          </PanelDiscussion>
        )}
        {isUpNext && (
          <UpNextSection>
            <img src={ellipseIcon} alt="ellipse_icon" />
            <UpNextSpan>Up next</UpNextSpan>
          </UpNextSection>
        )}
        {isNowLive && (
          <NowLiveSection>
            <img src={ellipseLiveIcon} alt="ellipse_icon" />
            <NowLiveSpan>Now live</NowLiveSpan>
          </NowLiveSection>
        )}
      </PanelMain>
      {!isNowLive && status === 'scheduled' && (
        <BookmarkSection>
          <BookmarkIcon onClick={onIsBookmark} isBookmark={is_bookmark} colorSchema={colorSchema} />
        </BookmarkSection>
      )}
    </MicroAgendaSection>
  );
};

export default React.memo(MicroAgenda, isEqual);
