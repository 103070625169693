import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { isEqual } from 'deep-equal';
import { getExhibitorStandCsv } from '@/store/dashboardPage/actions';
import { getExhibitorStandCsvInfo } from '@/store/dashboardPage/selectors';
import {
  CardContainer,
  TitleBlock,
  DownloadIco,
  Title,
  Count,
  SubTitle,
  TitleContainer,
} from './styles';
import { download } from './icon';

const VisitorsCard = ({ title, subTitle, count, urlTag }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const standCsv = useSelector(getExhibitorStandCsvInfo);
  const [csvData, setCsvData] = useState('');

  useEffect(() => {
    urlTag && dispatch(getExhibitorStandCsv(id, urlTag));
  }, [id, urlTag, dispatch]);

  const downloadHandler = useCallback(() => {
    urlTag && dispatch(getExhibitorStandCsv(id, urlTag));
    if (!!standCsv) {
      setCsvData(standCsv);
    } else {
      return false;
    }
  }, [standCsv, id, urlTag]);

  return (
    <CardContainer>
      <TitleBlock>
        <TitleContainer>
          <DownloadIco>
            <CSVLink
              data={csvData}
              filename={'detailed-visitors-activity-report.csv'}
              target="_blank"
              onClick={downloadHandler}
            >
              {download}
            </CSVLink>
          </DownloadIco>
          <Title>{title}</Title>
        </TitleContainer>
        <Count>{count}</Count>
      </TitleBlock>
      <SubTitle>{subTitle}</SubTitle>
    </CardContainer>
  );
};
export default React.memo(VisitorsCard, isEqual);
