import { createSelector } from 'reselect';

export const getEventManagementPageValues = state => state.eventManagementPage;

export const getModeratorQuestionsInfo = createSelector([getEventManagementPageValues], state => {
  return state.moderatorQuestions;
});

export const getEventStatisticInfo = createSelector([getEventManagementPageValues], state => {
  return state.eventStatistic;
});

export const getEventStatisticsLatestInfo = createSelector([getEventManagementPageValues], state => {
  const getTheLatestElement = (storeSection, key) => {
    const array = storeSection[key];
    return array?.length ? array[array.length - 1][1] : null; // items in series are arrays of shape [datetime, count], we take the count value
  }
  return {
    online: getTheLatestElement(state.eventStatistic, 'online'),
    joined: getTheLatestElement(state.eventStatistic, 'joined'),
    left: getTheLatestElement(state.eventStatistic, 'left'),
  };
});

export const getRejectedAllQuestionLoaderInfo = createSelector(
  [getEventManagementPageValues],
  state => {
    return state.rejectedAllQuestionLoader;
  },
);

export const getClearAllQuestionLoaderInfo = createSelector(
  [getEventManagementPageValues],
  state => {
    return state.clearAllQuestionLoader;
  },
);

export const getStreamStatusInfo = createSelector([getEventManagementPageValues], state => {
  return state.streamStatus;
});

export const getStreamStatusLoaderInfo = createSelector([getEventManagementPageValues], state => {
  return state.streamStatusLoader;
});

export const getbroadcastMessageInfo = createSelector([getEventManagementPageValues], state => {
  return state.broadcastMessage;
});

export const getloadingBroadcastMessageInfo = createSelector(
  [getEventManagementPageValues],
  state => {
    return state.loadingBroadcastMessage;
  },
);

export const getloadingPatchBroadcastInfo = createSelector(
  [getEventManagementPageValues],
  state => {
    return state.loadingPatchBroadcast;
  },
);

export const getModeratorPollsInfo = createSelector([getEventManagementPageValues], state => {
  return state.moderatorPolls;
});
