import React from 'react';
import { Button } from './styles';

const IconButton = ({ text, icon, disabled, onClick, color }) => {
  return (
    <Button onClick={onClick} disabled={disabled} color={color}>
      {icon}
      {text}
    </Button>
  );
};

export default IconButton;
