import React from 'react';
import { isEqual } from 'deep-equal';
import { Divider } from '@/components/controls/Divider';
import GenericButton from '@/components/controls/buttons/GenericButton';

import { Wrapper, InfoSection, ButtonSection, DayName, DayTopic } from './styles';

const Delimiter = ({ name, topic, id, handleRegister }) => {
  return (
    <Wrapper id={id}>
      <InfoSection>
        <DayName>{name}</DayName>
        <DayTopic>{topic}</DayTopic>
      </InfoSection>
      <ButtonSection>
        <GenericButton label="Register" styleType="primary" onClick={handleRegister} />
      </ButtonSection>
    </Wrapper>
  );
};

export default React.memo(Delimiter, isEqual);
