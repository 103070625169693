import { call, put, takeEvery, takeLeading, select, takeLatest } from 'redux-saga/effects';
import { networkingActions } from '@/constants/actions/networkingActions';
import { networkingPageAPI } from '../../api/networkingPageApi';
import {
  addCheckedFilterProperties,
  setDelegates,
  setFilters,
  setIsFetchingDelegates,
  setIsFetchingFilters,
  setSortInfo,
  setIsBookmarkDelegates,
  setNextDelegatesUrl,
  setNextDelegatesList,
  setIsLoadingNextDelegateList,
} from './actions';
import { networkingObjectParser } from '../../helpers';
import { getNextDelegatesUrl } from './selectors';
import { baseURL } from '@/constants/baseURL';
import { addFavoriteDelegate, removeFavoriteDelegate } from '../interactionPanel/actions';

function* getNetworkingFilters(action) {
  try {
    yield put(setIsFetchingFilters(true));
    const response = yield call(networkingPageAPI.getNetworkingFilters, action.payload);
    yield put(setFilters(response.data.data.filters));
    yield put(setSortInfo(response.data.data.sort));
    yield put(addCheckedFilterProperties(networkingObjectParser(response.data.data.filters)));
  } catch (error) {
  } finally {
    yield put(setIsFetchingFilters(false));
  }
}

function* getDelegates(action) {
  try {
    yield put(setIsFetchingDelegates(true));
    const response = yield call(networkingPageAPI.getDelegates, action.payload);
    yield put(setDelegates(response.data.results));
    yield put(setNextDelegatesUrl(response.data.next));
  } catch (error) {
  } finally {
    yield put(setIsFetchingDelegates(false));
  }
}

function* getNextDelegatesList(action) {
  const baseNextDelegateUrl = yield select(getNextDelegatesUrl);
  try {
    const nextDelegateUrl = yield baseNextDelegateUrl.replace(baseURL, '');
    yield put(setIsLoadingNextDelegateList(true));
    const response = yield call(networkingPageAPI.getNextDelegatesList, nextDelegateUrl);
    yield put(setNextDelegatesList(response.data.results));
    yield put(setNextDelegatesUrl(response.data.next));
  } catch (error) {
  } finally {
    yield put(setIsLoadingNextDelegateList(false));
  }
}

function* filteringDelegates({ payload: { id, searchMsg, selectedSort, checkedFilters } }) {
  try {
    const response = yield call(
      networkingPageAPI.getDelegates,
      id,
      searchMsg,
      selectedSort,
      checkedFilters,
    );
    yield put(setDelegates(response.data.results));
    yield put(setNextDelegatesUrl(response.data.next));
  } catch (error) {}
}

function* bookmarkDelegate({ payload: { id, delegateId, isBookmark, delegate } }) {
  try {
    yield put(setIsBookmarkDelegates(delegateId, isBookmark));
    yield put(addFavoriteDelegate(delegate));
    yield call(networkingPageAPI.bookmarkDelegate, id, delegateId);
  } catch (error) {
    yield put(setIsBookmarkDelegates(delegateId, false));
    yield put(removeFavoriteDelegate(delegate));
  }
}

function* unBookmarkDelegate({ payload: { id, delegateId, isBookmark, delegate } }) {
  try {
    yield put(setIsBookmarkDelegates(delegateId, isBookmark));
    yield put(removeFavoriteDelegate(delegate));
    yield call(networkingPageAPI.unBookmarkDelegate, id, delegateId);
  } catch (error) {
    yield put(setIsBookmarkDelegates(delegateId, true));
    yield put(addFavoriteDelegate(delegate));
  }
}

export function* watchNetworking() {
  yield takeEvery(networkingActions.OnFilteringDelegates, filteringDelegates);
  yield takeEvery(networkingActions.GetNetworkingFilters, getNetworkingFilters);
  yield takeEvery(networkingActions.GetDelegates, getDelegates);
  yield takeLatest(networkingActions.GetNextDelegatesList, getNextDelegatesList);
  yield takeLeading(networkingActions.BookmarkDelegate, bookmarkDelegate);
  yield takeLeading(networkingActions.UnBookmarkDelegate, unBookmarkDelegate);
}
