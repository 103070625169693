import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEqual } from 'deep-equal';
import orderBy from 'lodash.orderby';
import CircularProgress from '@material-ui/core/CircularProgress';
import ContactCard from '@/components/blocks/ContactCard';
import ChatInput from '../../../../blocks/Chat/components/ChatInput';
import {
  pollAllQuestions,
  sendQuestion,
  stopQuestionsPolling,
} from '@/store/interactionPanel/actions';
import {
  getAllQuestionsSelector,
  getSendingQuestionsSelector,
  getAreQuestionsLoadingSelector,
} from '@/store/interactionPanel/selectors';
import { getDelegateInfo } from '@/store/platformInfo/selectors';
import {
  Body,
  Container,
  Message,
  MessageText,
  MessageAuthor,
  CustomInputWrapper,
  ModerationInfoNotification,
} from './styles';
import { EmptyContainer } from '../../styles';

const Questions = () => {
  const { id } = useParams();
  const qAndARef = useRef(null);
  const dispatch = useDispatch();
  const questions = useSelector(getAllQuestionsSelector);
  const sendingQuestions = useSelector(getSendingQuestionsSelector);
  const isLoading = useSelector(getAreQuestionsLoadingSelector);
  const profileInfo = useSelector(getDelegateInfo);

  useEffect(() => {
    qAndARef.current && qAndARef.current.scrollTo(0, qAndARef.current.scrollHeight);
  }, [sendingQuestions, qAndARef, isLoading]);

  useEffect(() => {
    dispatch(pollAllQuestions(id));

    return () => {
      dispatch(stopQuestionsPolling());
    };
  }, [dispatch, id]);

  const handleSendQuestion = val => {
    const question = {
      question: val,
      isCurrentlyModerated: true,
    };
    dispatch(sendQuestion({ question, id }));
  };

  return (
    <Container>
      {!isLoading ? (
        <>
          {(questions && questions.length > 0) ||
          (sendingQuestions && sendingQuestions.length > 0) ? (
            <Body ref={qAndARef}>
              {orderBy(questions, ['created_date'], ['asc']).map(
                ({ id, isCurrentlyModerated, question, delegate_info }) => (
                  <Message
                    key={!isCurrentlyModerated ? `${id}-approved` : `${id}-not-approved`}
                    isCurrentlyModerated={isCurrentlyModerated}
                  >
                    <MessageText>{question}</MessageText>
                    <MessageAuthor withBorderTop>
                      <ContactCard
                        delegateId={delegate_info ? delegate_info.id : profileInfo.user}
                        name={`${
                          delegate_info ? delegate_info.first_name : profileInfo.first_name
                        } ${delegate_info ? delegate_info.last_name : profileInfo.last_name}`}
                        organisation={
                          delegate_info ? delegate_info.organisation : profileInfo.organisation
                        }
                        jobRole={delegate_info ? delegate_info.role : profileInfo.role}
                        photo={delegate_info ? delegate_info.headshot : profileInfo.headshot}
                        isOnline={delegate_info ? delegate_info.online : profileInfo.online}
                      />
                    </MessageAuthor>
                    {isCurrentlyModerated && (
                      <ModerationInfoNotification>
                        Your question is awaiting moderation
                      </ModerationInfoNotification>
                    )}
                  </Message>
                ),
              )}
              {sendingQuestions.map((item, i) => (
                <Message key={i} isCurrentlyModerated>
                  <MessageText>{item.question}</MessageText>
                  <MessageAuthor withBorderTop>
                    <ContactCard
                      delegateId={profileInfo.user}
                      name={`${profileInfo.first_name} ${profileInfo.last_name}`}
                      organisation={profileInfo.organisation}
                      jobRole={profileInfo.role}
                      photo={profileInfo.headshot}
                      isOnline={profileInfo.is_online}
                    />
                  </MessageAuthor>
                  <ModerationInfoNotification>
                    Your question is awaiting moderation
                  </ModerationInfoNotification>
                </Message>
              ))}
            </Body>
          ) : (
            <Body>
              <EmptyContainer>
                <span>This section is empty</span>
              </EmptyContainer>
            </Body>
          )}
          <CustomInputWrapper>
            <ChatInput
              handleSendMessage={handleSendQuestion}
              disabledCheck={inputValue => inputValue.length < 4}
            />
          </CustomInputWrapper>
        </>
      ) : (
        <EmptyContainer>
          <CircularProgress />
        </EmptyContainer>
      )}
    </Container>
  );
};

export default React.memo(Questions, isEqual);
