import { LandingActions } from '@/constants/actions/landingActions'

const initialState = {
  event: {},
  speakers: null,
  sponsors: null,
  agenda: null,
}

export const landingData = (state = initialState, action) => {
  switch (action.type) {
    case LandingActions.OnGetEventData:
      return {
        ...state,
        event: action.payload
      }
    case LandingActions.OnGetEventSpeakersData:
      return {
        ...state,
        speakers: action.payload
      }
    case LandingActions.putEventSponsorsData:
      return {
        ...state,
        sponsors: action.payload
      }
    case LandingActions.OnGetEventAgendaData:
      return {
        ...state,
        agenda: action.payload
      }
    default:
      return state;
  }
};