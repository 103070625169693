import { AgendaActions } from '@/constants/actions/agendaActions';

const initialState = {
  agendaData: [],
  showAgendaLoader: true,
  speakersData: null,
};

export const agenda = (state = initialState, action) => {
  switch (action.type) {
    case AgendaActions.setAgendaData:
      return {
        ...state,
        agendaData: action.payload,
      };
    case AgendaActions.setAgendaLoader:
      return {
        ...state,
        showAgendaLoader: action.payload,
      };
    case AgendaActions.SetIsBookmark:
      return {
        ...state,
        agendaData: state.agendaData.map(a => {
          return {
            ...a,
            sessions: a.sessions.map(s => {
              if (s.id === action.sessionId) {
                return { ...s, is_bookmark: action.isBookmark };
              }
              return s;
            }),
          };
        }),
      };
    default:
      return state;
  }
};
