import React from 'react';
import { Container, MenuItem } from './styles';

const Menu = handleLogout => {
  return (
    <Container>
      <MenuItem onClick={handleLogout}>Log Out</MenuItem>
    </Container>
  );
};

export default Menu;
