import styled from 'styled-components';

export const EditInterestsContainer = styled.div`
  width: 100%;
`;

export const EditInterestsButtons = styled.div`
  display: flex;
  margin-bottom: 30px;
  
  > button:nth-child(1) {
    width: 100px;
    margin-right: 15px;
  }

  > button:nth-child(2) {
    width: 115px;
    margin-right: 30px;
  }
`;
