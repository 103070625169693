import React, { useCallback, useRef, useState } from 'react';
import { isEqual } from 'deep-equal';
import {
  ArrowContainer,
  CustomOption,
  CustomOptions,
  SortContainer,
  SortContainerTrigger,
  SortContainerWrapper,
  Span,
  SpanDefault,
} from './styles';
import { ReactComponent as IconDown } from './icons/arrowIcon.svg';
import { useHandleClickOutside } from '@/helpers/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getSort } from '@/store/networkingPage';
import {setSelectedSort} from "../../../store/networkingPage";

const InputSort = ({ label }) => {
  const [display, setDisplay] = useState(false);
  const dispatch = useDispatch();
  const sort = useSelector(getSort);
  const [displayValue, setDisplayValue] = useState('');

  const handleClickOutside = useCallback(() => setDisplay(false), []);
  const ref = useRef();
  useHandleClickOutside(ref, handleClickOutside);

  const handleExpand = useCallback(() => {
    setDisplay(true);
  }, []);

  const handleSelect = useCallback(
    (id, label) => {
      dispatch(setSelectedSort(id));
      setDisplayValue(label);
      setDisplay(false);
    },
    [setDisplay, setDisplayValue],
  );

  return (
    <SortContainerWrapper>
      <SortContainer>
        <label>{label}</label>
        <SortContainerTrigger onClick={handleExpand}>
          {!displayValue ? (
            <SpanDefault>-- Select option --</SpanDefault>
          ) : (
            <Span>{displayValue}</Span>
          )}
          <ArrowContainer display={display ? 1 : 0}>
            <IconDown />
          </ArrowContainer>
        </SortContainerTrigger>
        {display && (
          <CustomOptions ref={ref}>
            {sort.map(o => (
              <CustomOption key={o.id} onClick={() => handleSelect(o.id, o.name)}>
                {o.name}
              </CustomOption>
            ))}
          </CustomOptions>
        )}
      </SortContainer>
    </SortContainerWrapper>
  );
};
export default React.memo(InputSort, isEqual);
