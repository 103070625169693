import { call, delay, put, race, take, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { GET_BROADCAST_MESSAGES_DELAY } from '@/constants/longPollingDelay';
import { broadcastMessagesActions } from '@/constants/actions/broadcastMessagesActions';
import { setBroadcastMessages } from './actions';
import { broadcastMessagesAPI } from '@/api/broadcastMessagesAPI';
import { getBroadcastMessage } from './selectors';
import { toasterTypesAssociations, toasterFunctionsAssociations } from '../../constants/toaster';

function compareAndUpdateMessage(prevData, newData){
  const customId = 'brodcastMessage';
  const hasNewData = !!Object.keys(newData).length;
  const hasPrevData = !!Object.keys(prevData).length;
  const isToastVisible = toast.isActive(customId);
  const isMessageChanged = prevData.message !== newData.message;
  const isTypeChanged = prevData.type !== newData.type;

  if(hasNewData) {
    if(!isToastVisible && (isMessageChanged || isTypeChanged)) {
      const shootToast = toasterFunctionsAssociations[newData.type];
      shootToast(newData.message, {
        toastId: customId,
        autoClose: false
      });
    }
    if(hasPrevData && (isMessageChanged || isTypeChanged)) {
      const update = { 
        render: newData.message, 
        type: toasterTypesAssociations[newData.type],
      }
      toast.update(customId, update)
    }
  } else if (isToastVisible) {
      toast.update(customId, { autoClose: 10 })
  }
}

function* getBroadcastMessages(action) {
  while (true) {
    try {
      const response = yield call(broadcastMessagesAPI.getBroadcastMessages, action.payload);
      const prevData = yield select(getBroadcastMessage);
      yield call(() => compareAndUpdateMessage(prevData, response.data))
      yield put(setBroadcastMessages(response.data));
      yield delay(GET_BROADCAST_MESSAGES_DELAY);
    } catch (error) {
      console.log(error);
    }
  }
}

export function* watchGetBroadcastMessages() {
  while (true) {
    const payload = yield take(broadcastMessagesActions.StartBroadcastMessagesPolling);
    yield race([
      call(getBroadcastMessages, payload),
      take(broadcastMessagesActions.StopBroadcastMessagesPolling),
    ]);
  }
}
