import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'deep-equal';
import VideoThumbnailLink from './components/VideoThumbnailLink';
import ContactCard from '@/components/blocks/ContactCard';
import { getYouTubeVideoId } from '@/helpers/parseYouTubeVideoUrl';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bookmarkAgendaSessions, unBookmarkAgendaSessions } from '@/store/agenda';
import { getDateTimeIntervalString } from '@/helpers';
import { history } from '@/store/rootReducer';
import { generateDemandUrl } from '@/constants/routes';
import { unBookmarkRemainingSession, bookmarkRemainingSession } from '@/store/sessions';
import { setIsBookmarkSession, getProfileInfo } from '@/store/profilePage';

import {
  CardWrapper,
  CardHeaderSection,
  CardBodySection,
  CardContentSpeakersSection,
  CardContentVideoSection,
  CardContent,
  CardThumbnailContainerRigth,
  CardThumbnailContainerTopRigth,
  SpeakersSection,
  SpeakersSectionWrapper,
  SpeakerWrapper,
  SpeakerRole,
  OnDemandLabel,
  NowLiveLabel,
  Time,
  BookmarkIcon,
  ShareIcon,
  Title,
  Description,
  LiveStreamCardContentVideoSection,
  DemandLabel,
} from './styles';

const SessionCard = ({
  dayID,
  session,
  isLive,
  allowOnDemand,
  allowBookmarking,
  allowSharing,
  isOnLivestream,
  isOnDemandPage,
  liveSession,
  remainingSession,
  liveSessionRef,
  agendaRef
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const userInfo = useSelector(getProfileInfo);
  const { is_bookmark: isBookmark, id: sessionId } = session;

  const onIsBookmarkSession = useCallback(() => {
    if (isBookmark) {
      remainingSession
        ? dispatch(unBookmarkRemainingSession({ eventId: id, sessionId, isBookmark: false }))
        : dispatch(unBookmarkAgendaSessions({ eventId: id, sessionId, isBookmark: false }));
      userInfo.session_set && dispatch(setIsBookmarkSession(id, false));
    } else {
      remainingSession
        ? dispatch(bookmarkRemainingSession({ eventId: id, sessionId, isBookmark: true }))
        : dispatch(bookmarkAgendaSessions({ eventId: id, sessionId, isBookmark: true }));
      userInfo.session_set && dispatch(setIsBookmarkSession(id, true));
    }
  }, [isBookmark, sessionId, remainingSession, id, userInfo.session_set, dispatch]);

  const colorSchema = isLive ? 'live' : 'regular';
  const { start_time, end_time, streaming_link, status, is_break: isBreak } = useMemo(
    () => session,
    [session],
  );

  const videoId = useMemo(() => (streaming_link ? getYouTubeVideoId(streaming_link) : null), [
    streaming_link,
  ]);

  const isOnDemand = useMemo(
    () => allowOnDemand && Boolean(videoId) && status === 'finished' && !liveSession,
    [videoId],
  ); // the session can be demanded once it has a video url

  const onRedirectToDemand = useCallback(() => {
    history.push(generateDemandUrl(id, sessionId, videoId));
  }, [id, sessionId, videoId]);

  const orderSpeakers = session.speakers_set?.sort(
    (a, b) => a.session_sequence_order - b.session_sequence_order,
  );

  return (
    <CardWrapper colorSchema={colorSchema} ref={liveSessionRef}>
      <CardHeaderSection>
        {isLive && !isOnLivestream && !isOnDemandPage && <NowLiveLabel />}
        {isOnDemandPage && (
          <DemandLabel>ON DEMAND / {getDateTimeIntervalString(start_time, end_time)}</DemandLabel>
        )}
        {isOnDemand && !isLive && <OnDemandLabel />}
        {!isOnDemandPage && (
          <Time colorSchema={colorSchema}>{getDateTimeIntervalString(start_time, end_time)}</Time>
        )}
        {allowSharing && (
          <ShareIcon dayID={dayID} sessionID={session.id} colorSchema={colorSchema} status={status} agendaRef={agendaRef}/>
        )}
        {allowBookmarking && status === 'scheduled' && (
          <BookmarkIcon
            onClick={onIsBookmarkSession}
            isBookmark={isBookmark}
            colorSchema={colorSchema}
          />
        )}
      </CardHeaderSection>
      <CardBodySection>
        <CardContentSpeakersSection>
          {!isOnLivestream ? (
            <CardContentVideoSection>
              <CardContent isOnDemand={isOnDemand}>
                <Title colorSchema={colorSchema}>{session.title}</Title>
                <Description colorSchema={colorSchema}>{session.description}</Description>
              </CardContent>
              {isOnDemand && (
                <CardThumbnailContainerTopRigth onClick={onRedirectToDemand}>
                  <VideoThumbnailLink videoId={videoId} />
                </CardThumbnailContainerTopRigth>
              )}
            </CardContentVideoSection>
          ) : (
            <LiveStreamCardContentVideoSection isBreak={isBreak}>
              <CardContent>
                <Title colorSchema={colorSchema}>{session.title}</Title>
                <Description colorSchema={colorSchema}>{session.description}</Description>
              </CardContent>
            </LiveStreamCardContentVideoSection>
          )}
          {!isBreak && (
            <SpeakersSectionWrapper>
              <SpeakersSection>
                {orderSpeakers.map(speaker => {
                  return (
                    <SpeakerWrapper key={speaker.id} isOnDemand={isOnDemand}>
                      <SpeakerRole colorSchema={colorSchema}>{speaker.business_role}</SpeakerRole>
                      <ContactCard
                        delegateId={speaker.delegate_id}
                        name={`${speaker['first_name']} ${speaker['last_name']}`}
                        photo={speaker.headshot}
                        organisation={speaker.organisation}
                        jobRole={speaker.job_title}
                        colorSchema={colorSchema}
                        isOnline={speaker.online}
                      />
                    </SpeakerWrapper>
                  );
                })}
              </SpeakersSection>
            </SpeakersSectionWrapper>
          )}
        </CardContentSpeakersSection>
        {isOnDemand && (
          <CardThumbnailContainerRigth onClick={onRedirectToDemand}>
            <VideoThumbnailLink videoId={videoId} />
          </CardThumbnailContainerRigth>
        )}
      </CardBodySection>
    </CardWrapper>
  );
};

export default React.memo(SessionCard, isEqual);
