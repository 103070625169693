import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { isEqual } from 'deep-equal';
import { unBookmarkAgendaSessions } from '@/store/agenda';
import { generateExhibitorUrl } from '@/constants/routes';
import {
  BookmarkSection,
  MicroAgendaSection,
  NowLiveSection,
  NowLiveSpan,
  TimeSection,
  PanelDiscussion,
  PanelMain,
  PanelSpan,
  TimeSpan,
  UpNextSection,
  UpNextSpan,
  PanelInformation,
  LogoSection,
  OrganisationName,
} from './styles';
import bookmarkIcon from './icons/activeBookmark.svg';
import ellipseIcon from './icons/ellipseIcon.svg';
import ellipseLiveIcon from './icons/ellipseLiveIcon.svg';
import { unBookmarkExhibitor } from '@/store/exhibitorPage';

const MicroAgenda = ({
  id: agendaId,
  title,
  time,
  isWithSpeakers,
  speakers,
  isUpNext,
  isNowLive,
  logo,
  name,
  isOrganisation,
  stand_url_tag,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const exhibitorRoute = generateExhibitorUrl(id, stand_url_tag);

  const handleUnBookmark = () => {
    isOrganisation
      ? dispatch(unBookmarkExhibitor({ id, exhibitorId: agendaId, isBookmark: false }))
      : dispatch(unBookmarkAgendaSessions({ eventId: id, sessionId: agendaId, isBookmark: false }));
  };

  return (
    <MicroAgendaSection isNowLive={isNowLive} isWithSpeakers={isWithSpeakers}>
      <PanelMain>
        {isOrganisation ? (
          <PanelInformation>
            <Link to={exhibitorRoute}>
              <LogoSection>
                <img src={`${logo}`} alt="logo" />
              </LogoSection>
            </Link>
            <OrganisationName>{name}</OrganisationName>
          </PanelInformation>
        ) : (
          <PanelDiscussion>
            <TimeSection>
              {isNowLive && (
                <NowLiveSection>
                  <img src={ellipseLiveIcon} alt="ellipse_icon" />
                  <NowLiveSpan>Now live</NowLiveSpan>
                </NowLiveSection>
              )}
              <div />
              <TimeSpan isNowLive={isNowLive}>{time}</TimeSpan>
            </TimeSection>

            <PanelSpan isNowLive={isNowLive}>{title}</PanelSpan>
            {isWithSpeakers && speakers?.length && (
              <PanelSpan isNowLive={isNowLive}>{speakers.join(', ')}</PanelSpan>
            )}
          </PanelDiscussion>
        )}
        {isUpNext && (
          <UpNextSection>
            <img src={ellipseIcon} alt="ellipse_icon" />
            <UpNextSpan>Up next</UpNextSpan>
          </UpNextSection>
        )}
      </PanelMain>
      <BookmarkSection>
        <img src={bookmarkIcon} onClick={handleUnBookmark} alt="bookmark_icon" />
      </BookmarkSection>
    </MicroAgendaSection>
  );
};

export default React.memo(MicroAgenda, isEqual);
