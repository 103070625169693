import styled from 'styled-components';
import Avatar from 'react-avatar';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.cards.border};

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.minWidth.landscape.desktopS}) and (min-width: ${({ theme }) =>
      theme.breakpoints.minWidth.landscape.tablet}) {
    flex-direction: ${({ paramsDelegateId }) => (paramsDelegateId ? 'row' : 'column')};
    margin-bottom: ${({ paramsDelegateId }) => (paramsDelegateId ? '30px' : '0px')};
  }
`;

export const CardContainer = styled.div`
  border-radius: 8px;
  padding: 45px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.minWidth.landscape.desktopS}) and (min-width: ${({ theme }) =>
      theme.breakpoints.minWidth.landscape.tablet}) {
    width: ${({ paramsDelegateId }) => (paramsDelegateId ? '50%' : '100%')};
    border-right: ${({ paramsDelegateId, theme }) =>
      paramsDelegateId ? `1px solid ${theme.colors.cards.border}` : 'none'};
    border-radius: ${({ paramsDelegateId }) => (paramsDelegateId ? '8px 0 0 8px' : '8px')};
  }
`;

export const ProfileAvatar = styled(Avatar)`
  position: relative;
  ::before {
    content: '';
    display: ${({ isOnline }) => (isOnline ? 'inline-block' : 'none')};
    position: absolute;
    background: ${({ theme }) => theme.colors.statusOnline};
    height: 15px;
    width: 15px;
    border-radius: 50%;
    bottom: 0;
    right: 15px;
  }
`;

export const UserName = styled.div`
  margin: 30px 0px 25px 0px;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
`;

export const UserJob = styled.span`
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textDescriptionGrey};
`;

export const UserCountry = styled.span`
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  margin: 20px 0 15px 0;
`;

export const Social = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.colors.originWhite};
  > div {
    position: relative;
    display: flex;
    justify-content: center;
    height: ${({ paramsDelegateId }) => (paramsDelegateId ? '20px' : 'auto')};
    margin-bottom: ${({ paramsDelegateId }) => (paramsDelegateId ? '0px' : '30px')};
    width: 100%;
    border-radius: 0px 0px 8px 8px;

    @media (max-width: ${({ theme }) =>
        theme.breakpoints.minWidth.landscape.desktopS}) and (min-width: ${({ theme }) =>
        theme.breakpoints.minWidth.landscape.tablet}) {
      height: auto;
      margin-bottom: 30px;
    }
  }
`;

export const SocialIco = styled.a`
  background: ${({ theme }) => theme.colors.originWhite};
  border: 1px solid ${({ theme }) => theme.colors.cards.border};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    margin-left: 0px;
  }
`;

export const ContactsContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.originWhite};
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.minWidth.landscape.desktopS}) and (min-width: ${({ theme }) =>
      theme.breakpoints.minWidth.landscape.tablet}) {
    width: 50%;
    justify-content: center;
  }
  svg {
    margin-right: 17px;
  }
  > div:first-child {
    display: flex;
    justify-content: center;
    background: ${({ theme }) => theme.colors.originWhite};
    border-bottom: 1px solid ${({ theme }) => theme.colors.cards.border};
    border-radius: 0px 0px 8px 8px;
    @media (max-width: ${({ theme }) =>
        theme.breakpoints.minWidth.landscape.desktopS}) and (min-width: ${({ theme }) =>
        theme.breakpoints.minWidth.landscape.tablet}) {
      display: none;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    margin: 0 30px 30px 30px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 30px;
  button {
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.primaryGreen};
    border: 1px solid ${({ theme }) => theme.colors.primaryGreen};
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
