import styled from 'styled-components'

export const FooterContainer = styled.div`
  width: 100%;
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    padding: 0px 20px 0px 20px;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;
  }
`

export const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
  }
`

export const FooterLogo = styled.img`
  width: 250px;
  height: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 210px;
    height: 85px;
  }
`

export const MenuLink = styled.a`
  margin-bottom: 20px;
  margin-left: 20px;
  color: #52595E;
  font-size: 15px;
  line-height: 20px;
  text-decoration-line: underline;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    margin-left: 0px;
  }
`

export const FooterSecondColumn = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    margin-top: 20px;
  }
`

export const Title = styled.div`
  color: #17191A;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
`

export const EventDates = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  color: #52595E;
`

export const ShareImages = styled.div`
  margin-top: 25px;
`

export const Image = styled.img`
  margin-left: 25px;
`

export const GetInTouchColumn = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
  }
`

export const GetInTouchTitle = styled.div`
  color: #17191A;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
`

export const GetInTouchRow = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: no-wrap;
`

export const GetInTouchRowIcon = styled.div`
  margin-right: 15px;
`

export const GetInTouchRowText = styled.div`
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #52595E;
`

export const FooterEnd = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    padding: 0px 20px 0px 20px;
  }
`

export const FooterLinks = styled.div`
  margin-left: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    display: flex;
    margin-left: 0px;
    flex-direction: column;
  }
`

export const FooterLink = styled.a`
  margin-right: 30px;
  color: #808B94;
  font-size: 15px;
  line-height: 20px;
  text-decoration-line: underline;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    font-size: 12px;
  }
`

export const PoweredBy = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: #808B94;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    font-size: 12px;
  }
`
