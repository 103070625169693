import React, { useCallback } from 'react';
import { isEqual } from 'deep-equal';
import { Field, Formik } from 'formik';
import {
  ErrorMsg,
  SignInChairForm,
  SignInChairSection,
  SignInChairTitle,
  SignInChairWrapper,
} from './styles';
import TextInput from '@/components/controls/TextInput';
import PasswordInput from '@/components/controls/PasswordInput';
import Button from '@/components/controls/buttons/GenericButton/component';
import { signInSchema } from '@/components/controls/validationSchemas/signInSchema';
import {useDispatch, useSelector} from 'react-redux';
import { signInChair, getIsLoadingSignIn, getEscalatedSignInErrMsg } from '@/store/escalatedQuestions';
import { useParams } from 'react-router-dom';

const SignInChair = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoadingSignIn = useSelector(getIsLoadingSignIn);
  const escalatedSignInErrMsg = useSelector(getEscalatedSignInErrMsg);

  const onSubmit = useCallback(values => {
    dispatch(signInChair({ ...values, eventId: id }));
  }, []);
  return (
    <SignInChairWrapper>
      <SignInChairSection>
        <SignInChairTitle>Sign-in to your account as Chair</SignInChairTitle>
        <Formik
          onSubmit={onSubmit}
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={signInSchema}
          validateOnChange={false}
        >
          {() => (
            <SignInChairForm>
              <Field name="email">
                {({ field, form }) => (
                  <TextInput
                    {...field}
                    form={form}
                    placeholder="e.g j.smith@example.com..."
                    label="Email address"
                  />
                )}
              </Field>
              <Field name="password">
                {({ field, form }) => <PasswordInput {...field} form={form} />}
              </Field>
              <ErrorMsg>
                {escalatedSignInErrMsg}
              </ErrorMsg>
              <Button label="Sign-in" styleType="primary" type="submit" isLoading={isLoadingSignIn}/>
            </SignInChairForm>
          )}
        </Formik>
      </SignInChairSection>
    </SignInChairWrapper>
  );
};

export default React.memo(SignInChair, isEqual);
