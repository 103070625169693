import styled from 'styled-components';
import { zIndexes } from '@/constants/zIndexes';

export const Platform = styled.div`
  position: relative;
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    flex-direction: column-reverse;
    height: 100vh;
  }
`;

export const BodyPlatform = styled.div`
  position: relative;
  display: flex;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    flex-direction: column-reverse;
    height: calc(100vh - 90px);
    overflow-y: auto;
  }
`;

export const MenuSection = styled.div`
  position: relative;
  height: 100%;
  min-width: 90px;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    height: 80px;
    overflow-x: ${({ isHorizontal }) => isHorizontal && 'scroll'};
    overflow-y: hidden;
  }
`;

export const MenuWrapper = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    position: relative;
    height: 80px;
    min-width: 485px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  }
`;

export const PlatformWrapperSection = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tablet}) {
    overflow-y: unset;
    flex-direction: row;
  }
`;

export const PageContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const StreamContainer = styled.div`
  width: ${props => (props.displayTopRight ? '360px' : 'calc(100% - 60px)')};
  position: ${props => (props.displayTopRight ? 'fixed' : 'absolute')};
  top: ${props => (props.displayTopRight ? '0px' : '0px')}; /* header height + padding top */
  left: ${props => (props.displayTopRight ? 'auto' : '30px')};
  right: ${props => (props.displayTopRight ? '0px' : 'auto')};
  z-index: ${props => (props.displayTopRight ? `${zIndexes.streamVideoTop}` : 'auto')};

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    width: ${props => (props.displayTopRight ? '100%' : 'calc(100% - 60px)')};
    /* top: ${props => (props.displayTopRight ? '70px' : 'calc(15px + 90px + 25px)')}; */
  }
`;

export const StreamingSection = styled.div`
  position: relative;
  min-width: 360px;
  width: 360px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const MiniVideoStreamPlaceholder = styled.div`
  width: 100%;
  padding-top: ${({ isValidationEventVideo }) =>
    isValidationEventVideo ? '56.25%' : '0'}; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    padding-top: ${({ isValidationEventVideo }) => (isValidationEventVideo ? '47.25%' : '0')};
  }
`;

export const ChatSection = styled.div`
  flex-grow: 1;
  border: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  overflow-y: auto;
  position: relative;
`;

export const MobileTabletHeader = styled.div``;

export const ScrollContainer = styled.div`
  ${({ isAtHomepage }) => (isAtHomepage ? 'overflow: auto;' : 'height: calc(100% - 70px);')}

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tablet}) {
  }
`;
