import styled from 'styled-components'

export const RegistrationBlockContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  background: rgba(0, 212, 179, 0.1);
  border-radius: 4px;
  height: 205px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const Title = styled.div`
  width: 100%;
  margin-top: 55px;
  text-align: center;
  color: #00806C;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 140%;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    margin-top: 35px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    margin-top: 15px;
    font-size: 20px;
  }
`

export const Button = styled.button`
  margin-top: 30px;
  width: 265px;
  color:${(props) => props.type === 'login' ? '#00806C' : '#FFFFFF'};
  background:${(props) => props.type === 'login' ? '#E6FBF8' : '#00967F'};
  height: 40px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`

export const ButtonText = styled.p`
  font-size: 15px;
  line-height: 30px;
  margin: 0 30px;
`
