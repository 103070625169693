import { call, put, takeEvery, select } from 'redux-saga/effects';
import { eventRegistrationAPI } from '@/api/eventRegistrationApi';
import { PromocodeActions } from '@/constants/actions/promocodeValidation';
import { setRegistrationStep, setTicketType } from '@/store/registration';
import { onCheckPromocode, setPromocodeError, setPromocodeStatus } from './actions';
import { generateRegisterUrl } from '@/constants/routes';
import { history } from '@/store/rootReducer';
import {
  getInterestsInfo,
  getProfileData,
} from '../registration'

function* promocodeValidation(action) {
  const interests = yield select(getInterestsInfo)
  const profile = yield select(getProfileData)

  try {
    const response = yield call(eventRegistrationAPI.checkPromocode,
      {
        "code": action.payload.data,
        "event": action.payload.event_pk,
      },
      action.payload.event_pk
    );
    yield put(onCheckPromocode(action.payload.data))
    yield put(setPromocodeStatus(response.data.success))
    const token = yield localStorage.getItem('accessToken');
    const RegisterRoute = yield generateRegisterUrl(action.payload.event_pk);
    if (response.data.success) {
      yield put(setTicketType(false))
      sessionStorage.setItem('ticketType', false)
      yield put(setPromocodeError(''))
      if (token) {
        if (Object.keys(profile).length > 0 && Object.keys(interests).length > 0) {
          yield put(setRegistrationStep('summary'))
        } else {
          yield put(setRegistrationStep('profile'));
        }
      }
      history.push(RegisterRoute);
    }
  } catch (error) {
    yield put(setPromocodeError("Wrong promotional code"))
  }
}

export function* watchCheckPromocode() {
  yield takeEvery(PromocodeActions.CheckPromocode, promocodeValidation)
}