import React from 'react';
import { isEqual } from 'deep-equal';

import { LabelWrapper, Dot, Text } from './styles';

const LiveNowLabel = () => {
  return (
    <LabelWrapper>
      <Dot />
      <Text>Now Live</Text>
    </LabelWrapper>
  );
};

export default React.memo(LiveNowLabel, isEqual);
