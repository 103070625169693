import React, { useCallback } from 'react';
import Modal from '@/components/blocks/Modal/component';
import {
  SummaryModalButton,
  SummaryModalContainer,
  SummaryModalSection,
  SummaryModalTitle,
} from './styles';
import { isEqual } from 'deep-equal';
import Button from '@/components/controls/buttons/GenericButton/component';
import { history } from '@/store/rootReducer';
import { generateLandingUrl } from '@/constants/routes';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsShowSummaryModal } from '@/store/registerDelegate';

const SummaryModal = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onRedirectToLanding = useCallback(() => {
    history.push(generateLandingUrl(id));
    dispatch(setIsShowSummaryModal(false));
    localStorage.removeItem('isRegistrationInProgress');
  }, [dispatch, id]);

  return (
    <Modal>
      <SummaryModalContainer>
        <SummaryModalTitle>Thank you</SummaryModalTitle>
        <SummaryModalSection>
          The Climate Action team will review your application and will get back to you within 24
          hours.
        </SummaryModalSection>
        <SummaryModalButton>
          <Button label="Return to the homepage" onClick={onRedirectToLanding} />
        </SummaryModalButton>
      </SummaryModalContainer>
    </Modal>
  );
};

export default React.memo(SummaryModal, isEqual);
