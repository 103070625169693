import React from 'react';
import { isEqual } from 'deep-equal';
import editIcon from './icons/editIcon.svg';
import activeEditIcon from './icons/activeEditIcon.svg';
import { Container, Icon, Label } from './styles';
import { useDispatch } from 'react-redux';

const EditHeader = ({ label, active, setActive }) => {
  const dispatch = useDispatch();
  return (
    <Container onClick={() => dispatch(setActive(true))}>
      {active ? (
        <Icon src={activeEditIcon} alt="active_edit_icon" />
      ) : (
        <Icon src={editIcon} alt="edit_icon" />
      )}
      <Label active={active}>{label}</Label>
    </Container>
  );
};

export default React.memo(EditHeader, isEqual);
