import React, { useEffect } from 'react';
import { isEqual } from 'deep-equal';
import { useParams } from 'react-router-dom';
import {
  DemandPageContainer,
  DemandPageVideo,
  PartnersContainer,
  PartnersTitle,
  SessionContainer,
} from './styles';
import YoutubeVideo from '../../blocks/ExhibitorCard/YoutubeVideo/component';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSession,
  getSessionInfo,
  getIsFetchingDemand,
  getEventPartnersInfo,
  getEventPartners,
} from '@/store/demandPage';
import SessionCard from '../../blocks/Agenda/components/SessionCard/component';
import Preloader from '../../blocks/Preloader';
import PartnersGroup from '../Landing/components/SponsorsAndPartners/components/PartnersGroup';

const DemandPage = () => {
  const dispatch = useDispatch();
  const session = useSelector(getSessionInfo);
  const isFetchingDemand = useSelector(getIsFetchingDemand);
  const eventPartners = useSelector(getEventPartnersInfo);
  const { id, sessionId, videoId } = useParams();
  useEffect(() => {
    dispatch(getSession(sessionId));
    dispatch(getEventPartners(id));
  }, []);
  return (
    <>
      {isFetchingDemand ? (
        <Preloader />
      ) : (
        <DemandPageContainer>
          <DemandPageVideo>
            <YoutubeVideo idVideo={videoId} />
          </DemandPageVideo>
          <SessionContainer>
            <SessionCard session={session} isLive isOnDemandPage isOnLivestream />
          </SessionContainer>
          <PartnersContainer>
            <PartnersTitle>Event partners</PartnersTitle>
            {eventPartners?.map(partnersGroup => (
              <PartnersGroup partnersGroup={partnersGroup} key={partnersGroup.id} isOnLivestream isOnDemandPage/>
            ))}
          </PartnersContainer>
        </DemandPageContainer>
      )}
    </>
  );
};

export default React.memo(DemandPage, isEqual);
