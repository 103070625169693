import React, { useCallback, useEffect } from 'react';
import { isEqual } from 'deep-equal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getEscalatedQuestions,
  getEscalatedQuestionsInfo,
  getIsFetchingQuestions,
  startGetEscalatedQuestionsPolling,
  stopGetEscalatedQuestionsPolling,
} from '@/store/escalatedQuestions';
import Preloader from '../../blocks/Preloader';
import EscalatedQuestion from './EscalatedQustion/component';
import {
  EscalatedQuestionsContainer,
  EscalatedQuestionSection,
  EscalatedQuestionsHeader,
  EscalatedQuestionsPageContainer,
  EscalatedQuestionsTitle,
  LogOutButton,
} from './styles';
import Button from '../../controls/buttons/GenericButton/component';
import { generateEscalatedQuestionsSignInUrl } from '@/constants/routes';
import { history } from '@/store/rootReducer';

const EscalatedQuestionsPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isFetchingQuestions = useSelector(getIsFetchingQuestions);
  const escalatedQuestions = useSelector(getEscalatedQuestionsInfo);

  useEffect(() => {
    dispatch(getEscalatedQuestions(id));
    dispatch(startGetEscalatedQuestionsPolling(id));

    return () => {
      dispatch(stopGetEscalatedQuestionsPolling(id));
    };
  }, [id]);

  const onLogOut = useCallback(() => {
    localStorage.removeItem('chairAccessToken');
    localStorage.removeItem('chairRefreshToken');
    history.push(generateEscalatedQuestionsSignInUrl(id));
  }, []);
  return (
    <>
      {isFetchingQuestions ? (
        <Preloader label="Checking is delegate as chair..." />
      ) : (
        <EscalatedQuestionsPageContainer>
          <EscalatedQuestionsHeader>
            <EscalatedQuestionsTitle>Escalated questions</EscalatedQuestionsTitle>
            <LogOutButton>
              <Button label="Log out" styleType="outline" onClick={onLogOut} />
            </LogOutButton>
          </EscalatedQuestionsHeader>
          <EscalatedQuestionsContainer>
            {escalatedQuestions.map(q => (
              <EscalatedQuestionSection key={q.id}>
                <EscalatedQuestion {...q} />
              </EscalatedQuestionSection>
            ))}
          </EscalatedQuestionsContainer>
        </EscalatedQuestionsPageContainer>
      )}
    </>
  );
};

export default React.memo(EscalatedQuestionsPage, isEqual);
