import styled from 'styled-components';

export const ExpoContainer = styled.div`
  height: 100%;
  overflow: auto;
  > div {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0 30px 30px;
  }
`;
export const ExpoSection = styled.div`
  flex-basis: 33.3%;
  margin-bottom: 30px;

  > div {
    padding-right: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopL}) {
    flex-basis: 50%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    flex-basis: 100%;
  }
`;
