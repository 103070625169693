import React from 'react';
import { isEqual } from 'deep-equal';

import { Container, Message } from './styles';

const EventNotFound = () => {
  return (
    <Container>
      <Message>{`Requested event not found.`}</Message>
    </Container>
  );
};

export default React.memo(EventNotFound, isEqual);
