import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '@/components/controls/TextInput';
import { generateSignInUrl } from '@/constants/routes';
import GenericButton from '@/components/controls/buttons/GenericButton';
import { getResetPassworError } from '@/store/resetPassword/selectors';
import { onResetPassword } from '@/store/resetPassword/actions';

import styles from './styles.module.css';
import { getResetPassworSpinner } from '@/store/resetPassword/selectors';

export const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const signInLink = generateSignInUrl(id);
  const spinner = useSelector(getResetPassworSpinner);

  const errorMsg = useSelector(getResetPassworError);

  const onSubmit = values => {
    dispatch(onResetPassword({ email: values.email, event: id }));
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          email: '',
        }}
        validateOnChange={false}
      >
        {() => (
          <Form className={styles['sign-in-form']}>
            <Field name="email">
              {({ field, form }) => (
                <TextInput
                  {...field}
                  form={form}
                  placeholder="e.g j.smith@example.com..."
                  label="Email address"
                />
              )}
            </Field>
            <div className={styles['error-text']}>{errorMsg}</div>
            <GenericButton label="Reset password" styleType="primary" type="submit" isLoading={spinner}/>
            <Link to={signInLink} className={styles['input-link']}>
              Back to sign in page
            </Link>
          </Form>
        )}
      </Formik>
    </>
  );
};
