import React, { useCallback} from 'react';
import { Field, Formik } from 'formik';
import GenericButton from '@/components/controls/buttons/GenericButton';
import PasswordInput from '../../controls/PasswordInput';
import { isEqual } from 'deep-equal';
import { ChangePasswordFormErr, StyledChangePasswordForm, SuccessSpan } from './styles';
import { changePasswordSchema } from '../../controls/validationSchemas/changePasswordSchema';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsSuccessChangePassword,
  getIsLoadingChangePassword,
  getResetPassworError,
} from '@/store/resetPassword/selectors';
import { history } from '@/store/rootReducer';
import { generateSignInUrl } from '@/constants/routes';
import { changePassword, setIsSuccessChangePassword } from '@/store/resetPassword/actions';
const ChangePasswordForm = ({ accessToken, eventId }) => {
  const dispatch = useDispatch();
  const isLoadingChangePassword = useSelector(getIsLoadingChangePassword);
  const isSuccessChangePassword = useSelector(getIsSuccessChangePassword);
  const errMsg = useSelector(getResetPassworError);

  const onSubmit = useCallback(values => {
    dispatch(changePassword({ password: values.password }));
  }, []);

  if (isSuccessChangePassword) {
    setTimeout(() => {
      history.push(generateSignInUrl(eventId));
      dispatch(setIsSuccessChangePassword(false));
    }, 3000);
  }

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          password: '',
          repeatPassword: '',
        }}
        validateOnChange={false}
        validationSchema={changePasswordSchema}
      >
        {({ errors, values }) => (
          <StyledChangePasswordForm>
            <Field name="password">
              {({ field, form }) => <PasswordInput {...field} form={form} />}
            </Field>
            <Field name="repeatPassword">
              {({ field, form }) => (
                <PasswordInput {...field} form={form} label="Repeat password" />
              )}
            </Field>
            <ChangePasswordFormErr>
              {values.password !== values.repeatPassword && values.password && values.repeatPassword
                ? 'Passwords do not match!'
                : errMsg}
            </ChangePasswordFormErr>
            {isSuccessChangePassword ? (
              <SuccessSpan>You have successfully changed your password</SuccessSpan>
            ) : (
              <GenericButton
                label="Change password"
                styleType="primary"
                type="submit"
                isLoading={isLoadingChangePassword}
              />
            )}
          </StyledChangePasswordForm>
        )}
      </Formik>
    </>
  );
};

export default React.memo(ChangePasswordForm, isEqual);
