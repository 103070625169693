import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px 30px 30px;
  height: 100%;
  overflow: auto;
  /* width: 90%; */

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    flex-direction: column;
  }
`;

export const ExhibitorInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const ResourcesSectionWrapper = styled.div`
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    display: none;
  }
`;

export const ExhibitorResourcesLabel = styled.div`
  position: relative;

  align-items: center;
  div:first-child {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  }
  div:last-child {
    > input {
      opacity: 1;
      left: 150px;
      cursor: pointer;
    }
  }
`;

export const ResourcesSectionWrapperAdaptive = styled(ResourcesSectionWrapper)`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    display: block;
    margin-top: 30px;
  }
`;

export const ExhibitorResources = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const ExhibitorResource = styled.div`
  margin-bottom: 15px;
`;

export const ExhibitorMainSection = styled.div`
  width: 70%;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 80%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
  }
`;
export const ExhibitorVideoSection = styled.div`
  width: 100%;
  margin-bottom: 50px;

  > img {
    width: 100%;
    margin-bottom: 30px;
  }
`;
export const VideoDescriptionSection = styled.div`
  span {
    font-size: 15px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.textDescriptionDarkGrey};
  }
`;

export const ExhibitorTeamSection = styled.div`
  > span {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  }

  > div {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const MemberSection = styled.div`
  flex-basis: 40%;
  margin: 0 30px 30px 0;
`;

export const ExhibitorSessionSection = styled.div`
  > span {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  }

  > div {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const SessionSection = styled.div`
  margin-bottom: 15px;
  flex-basis: 100%;
`;

export const InactiveStand = styled.span`
  display: flex;
  justify-content: center;
  margin-top: 140px;
  font-weight: bold;
  font-size: 25px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
`;

export const ErrorMsg = styled.div`
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.alert};
`;
