import { PromocodeActions } from '@/constants/actions/promocodeValidation'

const initialState = {
  promocode: '',
  promocodeStatus: false,
  error: '',
}

export const promocode = (state = initialState, action) => {
  switch (action.type) {
    case PromocodeActions.OnCheckPromocode:
      return {
        ...state,
        promocode: action.payload
      }
    case PromocodeActions.SetPromocodeError:
      return {
        ...state,
        error: action.payload
      }
    case PromocodeActions.SetPromocodeStatus:
      return {
        ...state,
        promocodeStatus: action.payload
      }
    default:
      return state;
  }
};