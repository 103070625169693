import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { ButtonStyle } from './buttonStyle';

export const ButtonOutline = ({ text, color, onClick }) => {
  const StyledButton = withStyles({
    root: {
      border: `1px solid ${color}`,
      background: 'white',
      '&:hover': {
        boxShadow: 'none',
      },
    },
    label: {
      color: `${color}`,
      fontWeight: 'bold',
    },
  })(ButtonStyle);
  return (
    <StyledButton variant="outlined" disableRipple onClick={onClick}>
      {text}
    </StyledButton>
  );
};
