import React, { useCallback } from 'react';
import { isEqual } from 'deep-equal';

import { ButtonSection, Container, EmailResend, Message, Wrapper } from './styles';
import Button from '../../controls/buttons/GenericButton/component';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getIsLoadingResend, getIsEmailResend, resendTicket } from '@/store/inactiveUserPage';

const InactiveUserPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isLoadingResend = useSelector(getIsLoadingResend);
  const isEmailResend = useSelector(getIsEmailResend);

  const onResendTicket = useCallback(() => {
    dispatch(resendTicket(id));
  }, [id]);
  return (
    <Container>
      <Wrapper>
        <Message>
          You haven’t confirmed you email address. Please enter the platform from the Application
          confirmation email or write to contact email.
        </Message>
        <ButtonSection>
          {isEmailResend ? (
            <EmailResend>Approval email has been sent</EmailResend>
          ) : (
            <Button label="Resend ticket" isLoading={isLoadingResend} onClick={onResendTicket} />
          )}
        </ButtonSection>
      </Wrapper>
    </Container>
  );
};

export default React.memo(InactiveUserPage, isEqual);
