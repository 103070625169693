import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash.isequal';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getExpoPageValues = state => state.expoPage;

export const getExhibitorsInfo = createDeepEqualSelector([getExpoPageValues], state => {
  return state.exhibitors;
});

export const getBookmarkedExhibitors = createDeepEqualSelector([getExpoPageValues], state => {
  return state.exhibitors.filter(exhibitor => exhibitor.is_bookmark);
});

export const getIsFetchingExhibitors = createSelector([getExpoPageValues], state => {
  return state.isFetchingExhibitors;
});
