import React from 'react';
import { isEqual } from 'deep-equal';
import {
  OnePartnerContainer,
  OnePartnerLogoContainer,
  OnePartnerTitle,
  OnePartnerLogo,
  OnePartnerInfo,
  OnePartnerDescription,
} from './styles';

const OnePartner = ({ partner, isOnLivestream }) => {
  return (
    <OnePartnerContainer isOnLivestream={isOnLivestream}>
      <OnePartnerLogoContainer isOnLivestream={isOnLivestream}>
        <OnePartnerLogo src={partner.logo} alt="partner_logo" isOnLivestream={isOnLivestream}/>
      </OnePartnerLogoContainer>
      <OnePartnerInfo isOnLivestream={isOnLivestream}>
        <OnePartnerTitle isOnLivestream={isOnLivestream}>{partner.name}</OnePartnerTitle>
        {isOnLivestream && <OnePartnerDescription>{partner.description}</OnePartnerDescription>}
      </OnePartnerInfo>
    </OnePartnerContainer>
  );
};

export default React.memo(OnePartner, isEqual);
