import axios from 'axios';
import { baseURL } from '@/constants/baseURL';
import { responseHandlerInterceptor, sendTokenInterceptor } from '../helpers/interceptors';

const instance = axios.create({
  baseURL,
});

// sets XSRF token for Django
instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

instance.interceptors.request.use(request => {
  return sendTokenInterceptor(request);
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return responseHandlerInterceptor(error, instance);
  },
);

export const platformInfoAPI = {
  getEventInfo(event_pk) {
    return instance.get(`/events/event/${event_pk}/`);
  },
  getDelegateInfo(event_pk) {
    return instance.get(`/events/event/${event_pk}/delegate/profile/`);
  },
  getPermissions(event_pk) {
    return instance.get(`/events/event/${event_pk}/permissions/`);
  },
  pingIsUserOnline(event_pk, pageUrl) {
    return instance.post(`/events/event/${event_pk}/ping-online/`, {
      page_url: pageUrl,
    });
  },
  delegateCheck(event_pk) {
    return instance.get(`events/event/${event_pk}/delegate-check`);
  },
};
