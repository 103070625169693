import React from 'react';
import { isEqual } from 'deep-equal';
import DownloadCard from '@/components/controls/DownloadCard';
import { Wrapper, BadgeWrapper } from './styles';
import BadgeCount from '../BadgeCount';

const DashboardCard = ({ title, subTitle, count }) => {
  return (
    <Wrapper>
      <DownloadCard fileName={title} filePath={subTitle} hideDownload={true} />
      <BadgeWrapper>{<BadgeCount count={count} color="green" />}</BadgeWrapper>
    </Wrapper>
  );
};
export default React.memo(DashboardCard, isEqual);
