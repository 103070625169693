import styled from 'styled-components'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1260px;
  height: auto;
  background: #17191A;
  border-radius: 8px;
  margin-top: 75px;
  margin-bottom: 110px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    width: 1024px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
    border-radius: 0px;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px 0px 20px;
  }
`

export const ContentImage = styled.img`
  object-fit: cover;
  height: 395px;
  width: 455px;
  border-radius: 8px;
  margin: 60px 45px 60px 60px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.desktopS}) {
    height: 250px;
    width: 290px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    width: 100%;
    max-width: 450px;
    height: auto;
    margin: 20px 0px 20px 0px;
  }
`

export const ContentInfo = styled.div`
  margin: 60px 60px 60px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.landscape.tablet}) {
    margin: 0px 0px 20px 0px;
  }
`

export const ContentMainInfo = styled.div`
  h1, h2, h3, h4, h5, h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 43px;
    color: #FFFFFF;
  };
  
  p {
    margin-top: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #DBE2EB;
  };
`

export const Button = styled.button`
  margin-top: 32px;
  color:${(props) => props.type === 'login' ? '#00806C' : '#FFFFFF'};
  background:${(props) => props.type === 'login' ? '#E6FBF8' : '#00967F'};
  height: 40px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`
export const ButtonText = styled.p`
  font-size: 15px;
  line-height: 30px;
  margin: 0 30px;
`
