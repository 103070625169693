import styled from 'styled-components';
import { ButtonOutline } from '@/components/controls/buttons/ButtonOutline';

export const Container = styled.div`
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 30px;
  padding: 20px 0px;
  margin-bottom: 100px;
  > div {
    :first-child {
      margin-bottom: 40px;
    }
    margin-bottom: 25px;
  }
`;

export const QATitle = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.originBlack};
`;

export const InteractionContainer = styled.div`
  position: relative;
  height: 100%;
  background: ${({ theme }) => theme.colors.originWhite};

  @media (max-width: ${({ theme }) => theme.breakpoints.minWidth.portrait.tabletV}) {
    position: fixed;
    width: 100%;
    height: calc(100% - 88px);
    top: 0;
    left: 0;
    z-index: 1;
  }
`;

export const TabsContainer = styled.div`
  height: 56px;
  display: flex;
`;

export const Tab = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3);
  margin: 0px;
  font-size: 15px;
  font-weight: 700;
  background: ${({ theme }) => theme.colors.originWhite};
  color: ${({ theme, active }) =>
    active ? theme.colors.regularText : theme.colors.fieldsSummaryCardTitleGrey};

  border: none;
  border-left: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  border-bottom: ${({ theme, active }) =>
    active ? 'none' : `1px solid ${theme.colors.tabBorder}`};
  border-top: ${({ theme, active }) => (active ? `1px solid ${theme.colors.tabBorder}` : 'none')};
  cursor: pointer;

  &:first-child {
    border-left: ${({ theme, active }) =>
      active ? `1px solid ${theme.colors.tabBorder}` : 'none'};
  }
  &:last-child {
    border-right: ${({ theme, active }) =>
      active ? `1px solid ${theme.colors.tabBorder}` : 'none'};
  }
  div {
    margin-left: 20px;
  }
`;

export const BodyContainer = styled.div`
  padding: 30px;
  height: calc(100% - 56px);
  max-height: calc(100% - 56px);
  border: ${({ theme }) => `1px solid ${theme.colors.tabBorder}`};
  border-top: none;
  height: 800px;
  overflow-y: auto;
`;

export const CustomButtonOutline = styled(ButtonOutline).attrs(({ theme }) => ({
  color: theme.colors.primaryGreen,
}))``;

export const EmptyContainer = styled.div`
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};
  font-size: 15px;
  font-weight: 400;
  text-align: center;
`;

export const EmptyContainerButtonWrapper = styled.div`
  width: 100%;
  margin-top: 15px;
`;

export const Clear = styled.div`
  display: flex;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.blue};
  margin-top: 10px;
  cursor: pointer;
`;

export const BadgeCountWrapper = styled.div`
  div {
    margin: 0;
  }
`;
