import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEqual } from 'deep-equal';
import ReactApexChart from 'react-apexcharts';
import { startEventStatisticPolling, stopEventStatisticPolling } from '@/store/eventManagementPage';

import { ChartContainer } from './styles';

const EventManagementChart = () => {
  const dispatch = useDispatch();
  const { id: event_pk } = useParams();
  const [chartState, setChartState] = useState({
    series: [
      {
        name: 'online',
        data: [],
      },
      {
        name: 'joined',
        data: [],
      },
      {
        name: 'left',
        data: [],
      },
    ],
    options: {
      chart: {
        id: 'realtime-statistics',
        height: 380,
        type: 'area',
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
      },
      colors: ['#3751FF', '#DFE0EB', '#FF8B37'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Event manager dashboard',
        align: 'left',
      },
      xaxis: {
        type: 'datetime',
        labels: {
          formatter: function (timestamp) {
            const date = new Date(timestamp * 1000); // converts UNIX timestamp to JS timestamp
            return date.toLocaleTimeString().replace(/(.*)\D\d+/, '$1'); // The formatter function overrides format property
          },
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) { return Math.round(value)},
        }
      },
      legend: {
        show: true,
        position: 'bottom',
        offsetY: 10,
        itemMargin: {
          vertical: 15,
        },
      },
    },
  });

  useEffect(() => {
    dispatch(startEventStatisticPolling(event_pk));
    return () => {
      dispatch(stopEventStatisticPolling(event_pk));
    };
  }, [event_pk]);

  return (
    <ChartContainer>
      <ReactApexChart options={chartState.options} series={chartState.series} height={400} />
    </ChartContainer>
  );
};
export default React.memo(EventManagementChart, isEqual);
