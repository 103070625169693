import { call, takeEvery } from 'redux-saga/effects';
import { linkedinUpActions } from '@/constants/actions/linkedinUpActions';
import { linkedinAPI } from '../../api/linkedinAPI';
import { history } from '@/store/rootReducer';
import { generatePlatformUrl, generateRegisterUrl } from '@/constants/routes';
import { LINKEDIN_REDIRECT } from '@/constants/linkedinURL';
import { localStorageServices } from '@/helpers';

function* linkedinUp({ payload: { linkedinToken, clientId } }) {
  const eventId = localStorage.getItem('event_id');
  const isSignIn = localStorage.getItem('signIn');
  const redirectUri = isSignIn ? generatePlatformUrl : generateRegisterUrl;
  try {
    localStorageServices.clearAllTokens();
    const response = yield call(linkedinAPI.linkedinAuth, linkedinToken, LINKEDIN_REDIRECT);
    const res = yield call(linkedinAPI.linkedinUp, response.data.access_token, clientId);
    localStorage.setItem('accessToken', res.data.access_token);
    localStorage.setItem('refreshToken', res.data.refresh_token);
    history.push(redirectUri(eventId));
  } catch (error) {
    history.push(redirectUri(eventId));
  } finally {
    localStorage.removeItem('event_id');
    localStorage.removeItem('signIn');
  }
}

export function* watchLinkedinUp() {
  yield takeEvery(linkedinUpActions.LinkedinUp, linkedinUp);
}
