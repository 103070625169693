import styled from 'styled-components';

export const SortContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SortSpan = styled.span.attrs(() => ({
  tabIndex: 0,
}))`
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: 30px;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.fieldsSummaryCardTitleGrey};

  &:focus {
    color: ${({ theme }) => theme.colors.regularText};
    background: ${({ theme }) => theme.colors.backgroundDownloadCard};
    outline: none;
  }
`;
