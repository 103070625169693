const getURLWithQueryParams = (base, params) => {
    const query = Object
        .entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&')

    return `${base}?${query}`
}


const LINKEDIN_SCOPE = 'r_liteprofile r_emailaddress'
export const LINKEDIN_REDIRECT = `${process.env.REACT_APP_BASE_URL}linkedin-auth`
const LINKEDIN_CLIENT_ID = '77bl85t6k3rtnw'
export const LINKEDIN_URL = getURLWithQueryParams('https://www.linkedin.com/oauth/v2/authorization', {
    response_type: "code",
    client_id: LINKEDIN_CLIENT_ID,
    redirect_uri: LINKEDIN_REDIRECT,
    scope: LINKEDIN_SCOPE
})

export const LINKEDIN_AUTH_URL = getURLWithQueryParams('https://www.linkedin.com/oauth/v2/authorization', {
    response_type: "code",
    client_id: LINKEDIN_CLIENT_ID,
    redirect_uri: LINKEDIN_REDIRECT,
    scope: LINKEDIN_SCOPE
})